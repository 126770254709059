import actions from "./actions"
import initialState from "./initialState"

export default (state = initialState(), action) => {
  const { type, payload } = action
  switch (type) {
    case actions.RESET_BOOKING_PROCESS:
      return initialState()
    case actions.SET_SEGMENT_INDEX_BY_TYPE:
    case actions.SET_TICKET_INDEX_BY_TYPE:
    case actions.SET_TICKET_BY_TYPE:
      return {
        ...state,
        isBusy: true,
      }
    case actions.SET_SEGMENT_INDEX_BY_TYPE_SUCCESS:
      return {
        ...state,
        isBusy: false,
        [`${payload.type}Index`]: payload.index,
      }
    case actions.SET_SEGMENT_DIRECTION_SUCCESS:
      return {
        ...state,
        isBusy: false,
        segmentDirection: payload,
      }
    case actions.SET_TICKET_INDEX_BY_TYPE_SUCCESS:
      return {
        ...state,
        isBusy: false,
        [`${payload.type}Index`]: payload.index,
      }
    case actions.SET_TICKET_BY_TYPE_SUCCESS:
      return {
        ...state,
        isBusy: false,
        [`${payload.type}`]: payload.ticket,
        returnFlights: payload.returnFlights,
        bookingToken: payload.bookingToken,
        [`${payload.type}SummaryPrice`]: payload[`${payload.type}SummaryPrice`],
        [`${payload.type}LayoverTimes`]: payload[`${payload.type}LayoverTimes`],
        [`${payload.type}TotalTripTime`]: payload[`${payload.type}TotalTripTime`],
      }
    default:
      return state
  }
}
