import { store } from "../redux/store"

const loyaltyFeatureFlagName = "loyalty"
const door2DoorFeatureFlagName = "door2Door"
const connectionGarantieFeatureFlagName = "connectionGarantie"
const editTripFeatureFlagName = "editTrip"
const changeTripSeatFeatureFlagName = "changeTripSeat"
const referralFeatureFlagName = "referral"
const showCarouselFeatureFlagName = "showCarousel"
const showFlightSearchFeatureFlagName = "showFlightSearch"

const isFlagActive = flagName => {
  const {
    settings: { flags },
  } = store.getState()

  return !!flags.find(({ name }) => name === flagName)?.value
}

export default () => {
  return {
    loyaltyFeatureFlag: isFlagActive(loyaltyFeatureFlagName),
    door2DoorFeatureFlag: isFlagActive(door2DoorFeatureFlagName),
    connectionGarantieFeatureFlag: isFlagActive(connectionGarantieFeatureFlagName),
    editTripFeatureFlag: isFlagActive(editTripFeatureFlagName),
    changeTripSeatFeatureFlag: isFlagActive(changeTripSeatFeatureFlagName),
    referralFeatureFlag: isFlagActive(referralFeatureFlagName),
    showCarouselFeatureFlag: isFlagActive(showCarouselFeatureFlagName),
    showFlightSearchFeatureFlag: isFlagActive(showFlightSearchFeatureFlagName),
  }
}
