const FargoData = {
    from: {
        title: "From Fargo",
        stops: [
            {
                type:"",
                name:"DAYS"
            },
            {
                type: "Depart",
                name: "FAR"
            },
            {
                type: "Arrive",
                name: "MSP T2"
            },
            {
                type: "Arrive",
                name: "MSP T1"
            },
        ],
        itinerary: [
            ["MON, TUE, THU, FRI, SAT, SUN", "9:05 AM", "1:10 PM", "1:25 PM"],
        ]
    },
    to: {
        title: "To Fargo",
        stops: [
            {
                type:"",
                name:"DAYS"
            },
            {
                type: "Depart",
                name: "MSP T1"
            },
            {
                type: "Depart",
                name: "MSP T2"
            },
            {
                type: "Arrive",
                name: "FAR"
            },
        ],
        itinerary: [
            ["MON, WED, THU, FRI, SAT, SUN", "4:45 PM", "5:05 PM", "9:10 PM"],
        ]
    }
}


export default FargoData