import * as Sentry from "@sentry/browser"

import config from "./config"

const isEnabled = () => !!config.SENTRY_DSN
export const init = () => {
  if (isEnabled()) {
    Sentry.init({
      dsn: config.SENTRY_DSN,
    })
  }
}
export const logException = ({ error, extras = {}, level = "error", fingerprint }) => {
  if (!isEnabled()) {
    return
  }
  Sentry.withScope(scope => {
    if (extras) {
      scope.setExtras(extras)
    }
    if (level) {
      scope.setLevel(level)
    }
    if (fingerprint) {
      scope.setFingerprint([fingerprint || "{{ default }}"])
    }
    return Sentry.captureException(error)
  })
}

export default Sentry
