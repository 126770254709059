import "./TripDetailsPage.scss"

import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import { instructionSelector, tripSelector } from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"
import tripUtil, { getVidecomPNR, getVidecomState } from "../../../utils/tripUtil-util"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"
import TripItem from "../../organisms/TripItem"

const TripDetailsPage = ({ history }) => {
  const dispatch = useDispatch()
  const trip = useSelector(state => tripSelector(state))
  const instruction = useSelector(state => instructionSelector(state))

  const fetchSeat = useCallback(
    (pnr, segment) => dispatch(tripActions.fetchSeat({ pnr, segment })),
    [dispatch],
  )

  const changeSeats = (pnr, segment) => {
    fetchSeat(pnr, segment)
    history.push("/seats/change")
  }

  if (instruction.isBusy) {
    return <PageLayout render={() => <Loading />} />
  }

  if (!trip.detail) {
    return <Redirect to="/trips" />
  }

  const { detail: flight } = trip

  const departureData = tripUtil.getTripDirection(flight, 0)
  const returnData = tripUtil.getTripDirection(flight, 1)

  return (
    <TabNavbarLayout
      render={() => (
        <div className="SectionWrapper">
          {departureData.segments.length > 0 && (
            <TripItem
              pnr={getVidecomPNR(flight.tickets)}
              flightReservation={flight}
              allPassengers={departureData.passengers}
              changeSeats={changeSeats}
              departureData={departureData}
              returnData={returnData}
              tripState={getVidecomState(flight.tickets)}
              departureLayoverTimes={departureData.layoverTimes}
              returnLayoverTimes={returnData.layoverTimes}
              history={history}
              isCurrentTrip={!flight.is_past_trip}
            />
          )}
        </div>
      )}
    />
  )
}

export default TripDetailsPage

TripDetailsPage.propTypes = {
  history: PropTypes.instanceOf(Object),
}
TripDetailsPage.defaultProps = {
  history: {},
}
