import "./ReferalPage.scss"

import classNames from "classnames"
import {
  CollapsibleButton,
  InputPreview,
  ListOfSuccessReferrals,
  NotificationConsumer,
  PageTitle,
  ReferralCodeInput,
} from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import { REFERRAL } from "../../../constants/routes"
import accountActions from "../../../redux/account/actions"
import { accountSelector, loyaltySelector } from "../../../redux/selector"
import config from "../../../utils/config"
import Loading from "../../atoms/Loading"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"

const ReferalPage = ({ history }) => {
  const dispatch = useDispatch()

  const [state, setState] = useState({
    isCopied: false,
  })

  const loyalty = useSelector(loyaltyState => loyaltySelector(loyaltyState))
  const walletBalance = (loyalty.wallet || {}).points
  const loyaltyNumber = (loyalty.wallet || {}).loyalty_number

  const getReferralData = useCallback(() => dispatch(accountActions.getReferralData(), [dispatch]))
  const referralData = useSelector(referralState => accountSelector(referralState))

  const referalPageClass = classNames("referal-page-wrapper SectionWrapper")

  useEffect(() => {
    getReferralData()
  }, [])
  const copiedSuccesfully = () => {
    setState(previousState => ({ ...previousState, isCopied: true }))
  }
  const referralCode = referralData.referral ? referralData.referral.referral_id : undefined
  const succesfullReferals = referralData.referral ? referralData.referral.list_sign_ups : []
  const { isAuthenticated } = useSelector(authState => accountSelector(authState))

  return (
    <>
      <NotificationConsumer>
        {({ actions }) => {
          return (
            <TabNavbarLayout
              render={() => {
                if (!isAuthenticated) {
                  history.push(REFERRAL)
                  return <Redirect to="/login" />
                }

                if (referralData.isBusy) {
                  return <Loading />
                }
                if (state.isCopied) {
                  actions.show({ type: "is-success", message: "Link copied successfully" })
                  setState(prevState => ({ ...prevState, isCopied: false }))
                  return false
                }

                return (
                  <div className={referalPageClass}>
                    <PageTitle title={`Hi ${referralData.profile.first_name}, `} />
                    <ReferralCodeInput
                      referralCode={referralCode}
                      publicUrl={config.PUBLIC_URL}
                      copiedSuccesfully={copiedSuccesfully}
                    />

                    <div className="referal-page-succesfull-referals">
                      <CollapsibleButton
                        title={
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: 20,
                              lineHeight: "100%",
                              color: "#3C3835",
                            }}
                          >
                            History
                          </span>
                        }
                      >
                        {succesfullReferals.length > 0 ? (
                          <ListOfSuccessReferrals referralList={succesfullReferals} />
                        ) : (
                          <div style={{ marginTop: "10px" }}>
                            You don&apos;t have any referrals yet{" "}
                          </div>
                        )}
                      </CollapsibleButton>
                    </div>
                    <PageTitle
                      title="LL MileMarkers Miles"
                      fontSize={20}
                      style={{ marginTop: 20 }}
                    />
                    <InputPreview title="YOUR MILEMARKERS NUMBER" value={`#${loyaltyNumber}`} />
                    <InputPreview title="YOUR MILES" value={walletBalance} />
                  </div>
                )
              }}
            />
          )
        }}
      </NotificationConsumer>
    </>
  )
}

export default ReferalPage

ReferalPage.propTypes = {
  history: PropTypes.instanceOf(Object),
}
ReferalPage.defaultProps = {
  history: {},
}
