import actions from "./actions"
import initialState from "./initialState"

export default (state = initialState(), action) => {
  const { type } = action
  switch (type) {
    case actions.REGISTER_FOR_UPDATE:
      return {
        isBusy: true,
      }
    case actions.REGISTER_FOR_UPDATE_SUCCESS:
      return {
        isBusy: false,
      }
    case actions.REGISTER_FOR_UPDATE_FAIL:
      return {
        isBusy: false,
        errored: true,
      }
    default:
      return state
  }
}
