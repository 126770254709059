import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { logException } from "../../../utils/sentry"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error, errorInfo) {
    const { stateInfo } = this.props
    logException({
      error,
      extras: { errorInfo, stateInfo },
      fingerprint: window.location.pathname,
    })
    this.setState({ hasError: true })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return <ErrorMessage>Could not complete this operation now</ErrorMessage>
    }
    return children
  }
}

export default connect(state => {
  return {
    stateInfo: state,
  }
})(ErrorBoundary)

const ErrorMessage = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
  padding: 20px;
`

ErrorBoundary.propTypes = {
  stateInfo: PropTypes.instanceOf(Object),
  children: PropTypes.element.isRequired,
}

ErrorBoundary.defaultProps = {
  stateInfo: {},
}
