import React from "react"
import Carousel from "@brainhubeu/react-carousel"

export function Hero({ carouselImages }) {
  return carouselImages?.length ? (
    <div className="Home--hero">
      <Carousel dots autoPlay={5000} animationSpeed={1000} infinite draggable={false}>
        {carouselImages.map((carouselImage, i) =>
          carouselImage.redirect_url ? (
            <a
              className="carousel-wrapper"
              style={{ backgroundColor: carouselImage.color }}
              href={carouselImage.redirect_url}
              key={carouselImage.image + i}
            >
              <img
                src={carouselImage.image}
                className="carousel-images"
                alt={carouselImage.description}
              />
            </a>
          ) : (
            <div
              className="carousel-wrapper"
              style={{ backgroundColor: carouselImage.color }}
              key={carouselImage.image + i}
            >
              <img
                src={carouselImage.image}
                className="carousel-images"
                alt={carouselImage.description}
              />
            </div>
          ),
        )}
      </Carousel>
    </div>
  ) : (
    <>
      <div className="Home--hero no-carousel">
        <div className="Home--hero-caption">
          <span>Nonstop bus service to MSP</span>
        </div>
      </div>
    </>
  )
}
