import React from "react";
import propTypes from "prop-types";

const Schedule = (props) => {
    return (
        <>
            <div className="schedule-page-schedules-container">
                <div className="schedule-page-schedules-inner-container">
                    {props.children}
                </div>
                <p className="schedules-page-schedules-disclaimer">*Schedules are subject to change. The most up-to-date departures/arrivals are available when booking.</p>
            </div>
        </>
    )
}

const ScheduleTable = ({ schedule }) => {
    return (
        <div className="schedule-table-container">
            <table className="schedule-table">
                <tr>
                    <th colSpan={schedule.stops.length} className="schedule-table-title">{schedule.title}</th>
                </tr>
                < ScheduleHeader stops={schedule.stops} />
                < ScheduleItinerary itinerary={schedule.itinerary} />
            </table>
        </div>

    )
}

const ScheduleItinerary = ({ itinerary }) => {
    let rotation = 0

    function getClassName() {
        if (rotation == 0) {
            rotation = 1
            return "schedule-itinerary-item white-background"
        }
        else {
            rotation = 0
            return "schedule-itinerary-item gray-background"
        }
    }

    return (
        <>
            {itinerary.map((item) => {
                return (
                    <tr className={getClassName()}>
                        {item.map(value => {
                            return (
                                <td>{value}</td>
                            )
                        })}
                    </tr>
                )
            })}
        </>
    )
}

const ScheduleHeader = ({ stops }) => {
    return (
        <>
            <tr>
                {stops.map(stop => {
                    const column_width = 100 / stops.length + "%"
                    return (
                        <th className="schedule-table-header" style={{width: column_width}}>
                            <p className="stop-type">{stop.type}</p>
                            <p className="stop-name">{stop.name}</p>
                        </th>
                    )
                })}
            </tr>
        </>
    )
}

ScheduleTable.propTypes = {
    schedule: propTypes.object
}

ScheduleTable.defaultProps = {
    schedule: {
        stops: [],
        title: "",
        itinerary: []
    }
}

export {
    ScheduleTable,
    Schedule
}