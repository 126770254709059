import React from "react";
import PropTypes from "prop-types"

const ModuleTitle = ({title}) => {

    return (
        <div className="schedule-module-title-container">
            <h4 className="schedule-module-title">{title}</h4>
            <div className="schedule-module-title-ruler"></div>
        </div>
    )
}

ModuleTitle.propTypes = {
    title: PropTypes.string
}

ModuleTitle.defaultProps = {
    title: "Module"
}

export default ModuleTitle