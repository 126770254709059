import { all, put, takeLatest } from "redux-saga/effects"

import actions from "./actions"

export function* setRedirectId$({ payload }) {
  try {
    yield put(actions.setRedirectIdSuccess(payload))
  } catch (error) {
    yield put(actions.setRedirectIdFail())
  }
}
export function* setRedirectURL$({ payload }) {
  try {
    yield put(actions.setRedirectURLSuccess(payload))
  } catch (error) {
    yield put(actions.setRedirectURLFail())
  }
}

export function* clearRedirectURL$({ payload }) {
  try {
    yield put(actions.clearRedirectURLSuccess(payload))
  } catch (error) {
    yield put(actions.clearRedirectURLFail())
  }
}

export default function*() {
  yield all([
    takeLatest(actions.setRedirectId, setRedirectId$),
    takeLatest(actions.setRedirectURL, setRedirectURL$),
    takeLatest(actions.clearRedirectURL, clearRedirectURL$),
  ])
}
