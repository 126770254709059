import "react-dates/initialize"
import "./constants/shared.scss"
import "./index.scss"

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import App from "./components/App"
import GoogleTag from "./components/pages/GoogleTagPage"
import { store } from "./redux/store"
import { init } from "./utils/sentry"
import * as serviceWorker from "./utils/serviceWorker"

init()
GoogleTag.init()

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

ReactDOM.render(<AppWrapper />, document.getElementById("root"))
serviceWorker.unregister()
