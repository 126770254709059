const PenguinPerksMeta = {
    header: {
        title: "Penguin Perks",
        description: "Welcome to the new Landline Loyalty Program, where every journey counts towards exciting rewards and exclusive perks! \nEarn Penguin Perks with every ride on Landline. Our premium shuttle service is designed with your comfort and convenience in mind. Whether \nyou're traveling for work or pleasure, each trip brings you closer to unlocking incredible benefits through our Penguin Perks loyalty program."
    }
}

const HowItWorks = {
    headline: "How It Works",
    elements: [
        {
            title: "Earn FREE Rides",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Bus-Icon.png",
            description: "Every Landline ride gets you closer to your next free ride. Trips accumulate automatically."
        },
        {
            title: "Level Up",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Level-Up-Icon.png",
            description: "There are three loyalty tiers. Your status is determined by the number of rides you take per year."
        },
        {
            title: "Redeem Rewards",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Gift-Icon.png",
            description: "Earn more Penguin Perks and gifts with every tier your ascend. Ride more to save more!"
        }
    ]
}

const RiderBenefits = {
    discount: "10% discount on every booking",
    free_trip_silver: "1 FREE bonus ride per year",
    free_trip_gold_plat: "2 FREE bonus rides per year",
    refundable: "Refundability on cancelled legs",
    free_ride_silver: "Earn 1 FREE ride every 10th ride",
    free_ride_gold: "Earn 1 FREE ride every 9th ride ",
    free_ride_plat: "Earn 1 FREE ride every 8th ride",
    boarding: "Priority boarding at FNL",
    partner_pass: "Partner Pass"
}

const RiderTiers = {
    headline: "Rider Tiers",
    tiers: [
        {
            name: "Silver",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Silver.png",
            threshold: "5 RIDES / YEAR",
            benefits: [
                RiderBenefits.discount,
                RiderBenefits.free_trip_silver,
                RiderBenefits.free_ride_silver,
                RiderBenefits.refundable,
            ]
        },
        {
            name: "Gold",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Gold.png",
            threshold: "10 RIDES / YEAR",
            benefits: [
                RiderBenefits.discount,
                RiderBenefits.free_ride_gold,
                RiderBenefits.free_trip_gold_plat,
                RiderBenefits.refundable,
                RiderBenefits.boarding
            ]
        },
        {
            name: "Platinum",
            image: "https://content.landline.com/wp-content/uploads/2024/10/Platinum.png",
            threshold: "15 RIDES / YEAR",
            benefits: [
                RiderBenefits.discount,
                RiderBenefits.free_ride_plat,
                RiderBenefits.free_trip_gold_plat,
                RiderBenefits.refundable,
                RiderBenefits.boarding,
                RiderBenefits.partner_pass,
            ]
        }
    ]
}

const FAQ = [
    {
        q: "What happened to Mile Markers?",
        a: "Penguin Perks is our newly designed loyalty program. MileMarkers has been discontinued, but don't worry your previously accrued miles will be converted into store credit you will be able to use to purchase rides on Landline. The login for your Penguin Perks account will be the same as your MileMarkers account. There is no need to sign up again."
    },
    {
        q: "How do I apply the 10% discount?",
        a: "Your loyalty discount will be applied automatically at checkout. Make sure you are signed into your Penguin Perks loyalty account (Penguin Profile) before you book your trip to receive this perk."
    },
    {
        q: "How do I redeem the free rides?",
        a: "Once you earn a free ride on Landline we will send you an email with a unique coupon code for a free ride that can be redeemed at checkout. See how many rides you have taken and how close you are to your next free ride on your Penguin Profile page."
    },
    {
        q: "How do I level up to the next tier?",
        a: "Ride Landline more! If you ride Landline 5 times within a 12 month period, you will earn Silver tier status, ride us 10 times (that is only 5 round trips a year) earn Gold tier status, and ride 15 or more times a year to reach Platinum tier status."
    },
    {
        q: "Once I reach a certain tier, do I maintain that status for life?",
        a: "Once you reach a tier, you will receive those benefits for an entire year after, but you have to keep riding to maintain your status for the year(s) after that."
    },
    {
        q: "I need to cancel my trip. How do I get a refund?",
        a: "Only our Penguin Perks Silver, Gold and Platinum riders get refund-ability. Trips can be canceled online through the My Trips tab. Refunds will be automatically applied to the original form of payment. Please note refunds take 5-10 business days to process. Trips must be canceled before the first leg of travel and at least 12 hours before the time of departure to qualify for a refund."
    },
    {
        q: "What is a partner pass?",
        a: "For our Platinum tier riders, you will get the benefit of always being allowed to have a companion ride with you for free! That's right, buy your ticket at your discounted rate, and add a second adult passenger for FREE every time you ride!!!! Don't forget kids 12 and under are always free on Landline."
    },
    {
        q: "How does free ride redemption work in Duluth or Fargo?",
        a: "In our Duluth and Fargo markets, pricing is dynamic which means the earlier you book the better the price you get. FREE ride vouchers are valued at $35. If you are trying to redeem a FREE ride on a one-way segment that is more than the voucher's value, please contact our customer service agents at 888-428-1149. Our team can assist you and ensure you get that segment at no cost. "
    }
]

export {
    PenguinPerksMeta,
    HowItWorks,
    RiderTiers,
    FAQ
}