import "./Alert.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const Alert = ({ type, children }) => {
  const alertClass = classNames("alert-container", {
    "red-alert": type === "red",
    "orange-alert": type === "orange",
    "green-alert": type === "green",
  })
  return <div className={alertClass}>{children}</div>
}

Alert.propTypes = {
  type: PropTypes.oneOf(["red", "orange", "red"]),
  children: PropTypes.element.isRequired,
}

Alert.defaultProps = {
  type: "red",
}

export default Alert
