import "./style.scss"

import { Button, Icon, NotificationConsumer, PassengerSeats } from "ibe-components"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, useLocation, withRouter } from "react-router-dom"

import { DEPART_SEATS } from "../../../constants/routes"
import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../../constants/segments"
import { findReservedSeats } from "../../../redux/itinerary/sagas"
import passengerActions from "../../../redux/passenger/actions"
import {
  errorSelector,
  itinerarySelector,
  loyaltySelector,
  passengerSelector,
  reservationsSelector,
  settingsSelector,
  ticketSelector,
  tripSelector,
} from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"
import {
  getRouteIndex,
  goToNextSegment,
  isBusAirline,
  isFlightAirline,
} from "../../../utils/tripUtil-util"
import Breadcrumbs from "../../atoms/Breadcrumbs"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"
import MobileBreadcrumbs from "../../atoms/Breadcrumbs/MobileBreadcrumbs"

export default withRouter(({ history }) => {
  const reservations = useSelector(state => reservationsSelector(state))
  const trip = useSelector(state => tripSelector(state))
  const itinerary = useSelector(state => itinerarySelector(state))
  const bookingTicket = useSelector(state => ticketSelector(state))
  const error = useSelector(state => errorSelector(state))
  const passenger = useSelector(state => passengerSelector(state))
  const dispatch = useDispatch()
  const loyalty = useSelector(state => loyaltySelector(state))
  const isLoyaltyActive = loyalty.isActive
  const { LANDLINE_IATA_CODE } = useSelector(state => settingsSelector(state))

  const setCurrentPassenger = useCallback(
    index => dispatch(passengerActions.setCurrentPassenger(index)),
    [dispatch],
  )
  const setPassengerSeat = useCallback(
    params => dispatch(passengerActions.setPassengerSeat(params)),
    [dispatch],
  )

  const clearPassengerSeat = useCallback(
    params => dispatch(passengerActions.clearPassengerSeat(params)),
    [dispatch],
  )

  const location = useLocation()

  const segmentDirection = location.pathname === DEPART_SEATS ? SEGMENT_DEPART : SEGMENT_RETURN
  const { departureLandlineSegmentIndex, returnLandlineSegmentIndex, bookingFlowIndex } = trip

  const [seatSegmentIndex, setSeatSegmentIndex] = useState(
    segmentDirection === SEGMENT_DEPART
      ? departureLandlineSegmentIndex
      : returnLandlineSegmentIndex,
  )

  const landlineSegments = useMemo(() => {
    if (segmentDirection === SEGMENT_DEPART) {
      return bookingTicket.departureTicket.filter(segment => !isFlightAirline(segment))
    }

    return bookingTicket.returnTicket.filter(segment => !isFlightAirline(segment))
  }, [segmentDirection])

  useEffect(() => {
    setCurrentPassenger()
    setSeatSegmentIndex(0)
  }, [])

  const departureSeatsCategory = "departure_seats"
  const returnSeatsCategory = "return_seats"

  const departureSeatSelect = seat => {
    setCurrentPassenger(seat.passengerId)
    setPassengerSeat({
      category: departureSeatsCategory,
      seat,
      seatIndex: seatSegmentIndex,
    })
  }

  const returnSeatSelect = seat => {
    setCurrentPassenger(seat.passengerId)
    setPassengerSeat({
      category: returnSeatsCategory,
      seat,
      seatIndex: seatSegmentIndex,
    })
  }

  const departureClearSeat = seat => {
    setCurrentPassenger(seat.passengerId)
    clearPassengerSeat({
      category: departureSeatsCategory,
      seat,
      seatIndex: seatSegmentIndex,
    })
  }

  const returnClearSeat = seat => {
    setCurrentPassenger(seat.passengerId)
    clearPassengerSeat({
      category: returnSeatsCategory,
      seat,
      seatIndex: seatSegmentIndex,
    })
  }

  const routeIndex = getRouteIndex(location.pathname)

  useEffect(() => {
    if (bookingFlowIndex !== routeIndex) {
      dispatch(tripActions.setBookingFlowIndex(routeIndex))
    }
  }, [routeIndex])

  const passengers = (itinerary.payload || {}).passengers || []

  const passengersArray = []
  for (let i = 0; i < passengers.length; i++) {
    passengersArray.push(passenger[i])
  }

  const payloadDepartureSeats = (itinerary.payload || {})[departureSeatsCategory] || []

  const payloadReturnSeats = (itinerary.payload || {})[returnSeatsCategory] || []

  const reservedDepartureSeats = findReservedSeats(
    passenger,
    passengers.length,
    departureSeatsCategory,
    seatSegmentIndex,
  )

  const reservedReturnSeats = findReservedSeats(
    passenger,
    passengers.length,
    returnSeatsCategory,
    seatSegmentIndex,
  )

  const handleGoNext = () => {
    const newIndex = seatSegmentIndex + 1

    if (newIndex < landlineSegments.length && isBusAirline(landlineSegments[newIndex])) {
      setSeatSegmentIndex(newIndex)
      window.scrollTo(0, 0)
    } else {
      setSeatSegmentIndex(
        segmentDirection === SEGMENT_DEPART
          ? departureLandlineSegmentIndex
          : returnLandlineSegmentIndex,
      )
      goToNextSegment(history)
    }
  }

  const ActionButton = ({ includeIcon = true }) => {
    const shouldGoNext =
      segmentDirection === SEGMENT_DEPART
        ? reservedDepartureSeats.length === passengers.length
        : reservedReturnSeats.length === passengers.length

    if (shouldGoNext) {
      return (
        <Button
          fullWidth
          type={isLoyaltyActive ? "secondary" : "primary"}
          onClick={handleGoNext}
          icon={
            includeIcon ? (
              <Icon align="right" name="arrowRight" style={{ marginRight: 10 }} />
            ) : null
          }
        >
          Continue
        </Button>
      )
    }

    return (
      <Button onClick={handleGoNext} fullWidth type="regular">
        Skip Seat Selection & Continue
      </Button>
    )
  }

  return (
    <ErrorBoundary>
      <NotificationConsumer>
        {({ actions }) => {
          return (
            <PageLayout
              background={"#fff"}
              render={() => {
                if (itinerary.isBusy || passenger.isBusy) {
                  return <Loading />
                }
                if (error) {
                  actions.show({
                    type: "is-danger",
                    message: error || "Your session has expired",
                  })
                  return <Redirect to="/" />
                }

                const searchParams = reservations.params

                if (!searchParams.dep_from || !itinerary.payload) {
                  return <Redirect to="/" />
                }

                const airplainsBeforeLandlineDepart = bookingTicket.departureTicket
                  ? bookingTicket.departureTicket.filter(
                      (segment, index) =>
                        segment.airline !== LANDLINE_IATA_CODE && index < seatSegmentIndex,
                    ).length
                  : 0
                const departureSegments =
                  payloadDepartureSeats[seatSegmentIndex - airplainsBeforeLandlineDepart] || {}
                const departureSeats = departureSegments.seats || []
                let departureFlight = departureSegments.flight || {}
                departureFlight = {
                  ...departureFlight,
                  departure_info: {
                    code: (bookingTicket.departureTicket[seatSegmentIndex] || {}).departure_code,
                  },
                }

                const airplainsBeforeLandlineReturn = bookingTicket.returnTicket
                  ? bookingTicket.returnTicket.filter(
                      (segment, index) =>
                        segment.airline !== LANDLINE_IATA_CODE && index < seatSegmentIndex,
                    ).length
                  : 0
                const returnSegments =
                  payloadReturnSeats[seatSegmentIndex - airplainsBeforeLandlineReturn] || {}
                const returnSeats = returnSegments.seats || []
                let returnFlight = returnSegments.flight || {}
                if (Object.keys(returnFlight).length > 0 && bookingTicket.returnTicket) {
                  returnFlight = {
                    ...returnFlight,
                    departure_info: {
                      code: (bookingTicket.returnTicket[seatSegmentIndex] || {}).departure_code,
                    },
                  }
                }

                return (
                  <>
                    <Breadcrumbs history={history} className="seat-page-breadcrumbs" />
                    <MobileBreadcrumbs history={history} className="seat-page-mobile-breadcrumbs" />

                    <div className="SectionWrapper seat-page-container">
                      {segmentDirection === SEGMENT_DEPART && (
                        <div className="passenger-seats-depart-container">
                          <PassengerSeats
                            key={`Depart-${seatSegmentIndex}`}
                            title="Departure"
                            leg={departureFlight}
                            allSelectedSeats={reservedDepartureSeats}
                            passengers={passengersArray}
                            seats={departureSeats}
                            onSeatClicked={departureSeatSelect}
                            segments={bookingTicket.departureTicket}
                            totalTripTime={bookingTicket.departureTicketTotalTripTime}
                            layoverTimes={bookingTicket.departureTicketLayoverTimes}
                            clearPassengerSeat={departureClearSeat}
                          />
                        </div>
                      )}

                      {segmentDirection === 2 && (
                        <div className="passenger-seats-return-container">
                          <PassengerSeats
                            key={`Return-${seatSegmentIndex}`}
                            title="Return"
                            leg={returnFlight}
                            allSelectedSeats={reservedReturnSeats}
                            passengers={passengersArray}
                            seats={returnSeats}
                            onSeatClicked={returnSeatSelect}
                            totalTripTime={bookingTicket.returnTicketTotalTripTime}
                            segments={bookingTicket.returnTicket || []}
                            layoverTimes={bookingTicket.returnTicketLayoverTimes}
                            clearPassengerSeat={returnClearSeat}
                          />
                        </div>
                      )}

                      <div className="seats-buttons">
                        <ActionButton includeIcon={false} />
                      </div>
                    </div>
                  </>
                )
              }}
            />
          )
        }}
      </NotificationConsumer>
    </ErrorBoundary>
  )
})
