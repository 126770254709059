import "./LoyaltyBuyPointsPage.scss"

import classNames from "classnames"
import {
  LoyaltyBuyPoints,
  LoyaltyPointsBalance,
  NotificationConsumer,
  // Section,
} from "ibe-components"
// import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { Elements, StripeProvider } from "react-stripe-elements"

import { BUY_MILES, MILE_ACCRUAL_LOG, MILES_CONFIRM } from "../../../constants/routes"
import loyaltyActions from "../../../redux/loyalty/actions"
import redirectActions from "../../../redux/redirect/actions"
import { accountSelector, loyaltySelector, settingsSelector } from "../../../redux/selector"
import settingsActions from "../../../redux/settings/actions"
import { roundToPrecision } from "../../../utils/common"
import { validateBuyPoints } from "../../../utils/validation"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"
import CreditCardForm from "../../organisms/CreditCardForm"
import { TabNavbar } from "../../organisms/Navbar"

const LoyaltyBuyPointsPage = () => {
  const loyaltyBuyPointsPageClass = classNames("loyalty-buy-points-page-wrapper")
  const loyalty = useSelector(state => loyaltySelector(state))
  const walletBalance = (loyalty.wallet || {}).points
  const dispatch = useDispatch()
  const settings = useSelector(state => settingsSelector(state))
  const { isAuthenticated } = useSelector(state => accountSelector(state))
  const setRedirectURL = useCallback(url => dispatch(redirectActions.setRedirectURL(url)), [
    dispatch,
  ])
  const checkPointsPrice = useCallback(
    (points, pointsBeforePayment) =>
      dispatch(loyaltyActions.checkLoyaltyPointsPrice({ points, pointsBeforePayment })),
    [dispatch],
  )
  const getStripePublishableKey = useCallback(() =>
    dispatch(settingsActions.getSettingsData(), [dispatch]),
  )
  useEffect(() => {
    setRedirectURL(BUY_MILES)
  }, [])
  const tabItems = [
    {
      to: BUY_MILES,
      matches: [BUY_MILES],
      title: "Buy miles",
    },
    {
      to: MILE_ACCRUAL_LOG,
      matches: [MILE_ACCRUAL_LOG],
      title: "Mile History",
    },
  ]

  const [state, setState] = useState({
    stripeError: "",
    isChanged: true,
    priceForPoints: 0,
    pointsToBuy: 0,
    buyPointsFormErrors: {},
    tax: 0,
    totalPrice: 0,
    cardHolderName: "",
    paymentMethod: "SP",
    formErrors: {},
    modalErrors: {},
    showPaypalSummary: false,
    paymentInitiated: false,
  })
  const changeState = params => setState(prevState => ({ ...prevState, ...params }))

  const buyLoyaltyPoints = useCallback(
    (stripeToken, pointsAmount) =>
      dispatch(loyaltyActions.buyLoyaltyPoints({ stripeToken, pointsAmount })),
    [dispatch],
  )
  const onCreditCardSubmitted = async (e, stripe) => {
    e.preventDefault()
    const { error: stripeError, token } = await stripe.createToken({ type: "card" })
    if (stripeError) {
      changeState({ error: stripeError.payment_failed })
    } else if (token) {
      if (state.isChanged) {
        changeState({ stripeError: "Please click calculate before payment" })
        return false
      }
      if (state.pointsToBuy === 0) {
        changeState({ stripeError: "Miles must be greater than 0" })
        return false
      }
      buyLoyaltyPoints(token, state.pointsToBuy)
      changeState({ paymentInitiated: true })
    }
    return true
  }

  const onLoyaltyInputChange = points => {
    let newPoints = points
    changeState({ isChanged: true })
    if (!Number.isNaN(newPoints)) {
      if (newPoints > settings.MILE_MIN) {
        newPoints = roundToPrecision(newPoints, 1)
      }
      changeState({ pointsToBuy: newPoints })
    }
  }

  const makeMaskFromPointsLimit = max => {
    let mask = ""
    if (max) {
      const maxString = max.toString()
      for (let i = 0; i < maxString.length; i++) {
        mask = mask.concat("0")
      }
    }
    return mask
  }

  const onCalculateButtonClick = () => {
    let pointsToBuy
    if (!Number.isNaN(state.pointsToBuy) && state.pointsToBuy > 0) {
      pointsToBuy = Number(state.pointsToBuy)
    }
    let validatedPoints = validateBuyPoints({ points: pointsToBuy })

    if (!validatedPoints) {
      if (pointsToBuy < settings.MILE_MIN || pointsToBuy > settings.MILE_MAX) {
        validatedPoints = {
          points: `Mile number must be value between ${settings.MILE_MIN} and ${settings.MILE_MAX}`,
        }
      }
    }

    if (validatedPoints) {
      changeState({ buyPointsFormErrors: validatedPoints.points })
    } else {
      changeState({ isChanged: false })
      checkPointsPrice(pointsToBuy, walletBalance)
    }
  }

  useEffect(() => {
    getStripePublishableKey()
    changeState({
      priceForPoints: loyalty.priceForPoints,
      totalPrice: Number(loyalty.priceForPoints) + state.tax,
    })
  }, [])

  // const onSectionGoBackClicked = () => {
  //   history.push(HOME)
  // }

  return (
    <NotificationConsumer>
      {({ actions }) => {
        return (
          <ErrorBoundary>
            <PageLayout
              render={() => {
                if (!isAuthenticated) {
                  return <Redirect to="/login" />
                }

                if (loyalty.isBusy) {
                  return <Loading />
                }
                if (state.paymentInitiated) {
                  return <Redirect to={MILES_CONFIRM} />
                }
                if (state.stripeError) {
                  actions.show({ type: "is-danger", message: state.stripeError })
                  setState(prevState => ({ ...prevState, stripeError: null }))
                  return false
                }
                if (state.buyPointsFormErrors) {
                  actions.show({ type: "is-danger", message: state.buyPointsFormErrors })
                  setState(prevState => ({ ...prevState, buyPointsFormErrors: null }))
                  return false
                }

                return (
                  <div className={loyaltyBuyPointsPageClass}>
                    <TabNavbar items={tabItems} />
                    <div className="SectionWrapper">
                      {/* <Section
                        title="Purchase Miles"
                        type="loyalty"
                        leftIconName="closeIcon"
                        onGoBackClicked={onSectionGoBackClicked}
                      /> */}
                      <div className="loyalty-buy-points-page-content">
                        <div className="loyalty-buy-points-buyPoints-container">
                          <LoyaltyBuyPoints
                            mask={makeMaskFromPointsLimit(settings.MILE_MAX)}
                            onChange={e => onLoyaltyInputChange(e.target.value)}
                            value={state.pointsToBuy}
                            onCalculateButtonClick={onCalculateButtonClick}
                            minimum={settings.MILE_MIN}
                            maximum={settings.MILE_MAX}
                          />
                        </div>

                        <div className="loyalty-buy-points-page-LoyaltyPointsBalance-container">
                          <LoyaltyPointsBalance
                            isChanged={state.isChanged}
                            currentPoints={walletBalance}
                            fees={loyalty.priceForPoints}
                            taxes={0}
                            pointsToBuy={state.pointsToBuy}
                            currency="USD"
                          />
                        </div>
                        {settings.STRIPE_PUBLISHABLE_KEY && (
                          <div style={{ padding: "20px 0" }}>
                            <StripeProvider apiKey={settings.STRIPE_PUBLISHABLE_KEY}>
                              <Elements>
                                <CreditCardForm
                                  hideBookingInformation
                                  isLoyaltyActive={false}
                                  holderName={state.cardHolderName}
                                  formErrors={state.formErrors}
                                  onHolderNameChanged={cardHolderName =>
                                    changeState({ cardHolderName })
                                  }
                                  onSubmit={onCreditCardSubmitted}
                                  onError={e => {
                                    if (e.error) {
                                      changeState({ stripeError: e.error.message })
                                    }
                                  }}
                                />
                              </Elements>
                            </StripeProvider>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }}
            />
          </ErrorBoundary>
        )
      }}
    </NotificationConsumer>
  )
}

// LoyaltyBuyPointsPage.propTypes = {}

// LoyaltyBuyPointsPage.defaultProps = {}

export default LoyaltyBuyPointsPage
