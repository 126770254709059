import { InputPreview, PageTitle, Price } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"


const StoreCreditsInfo = ({ balance }) => {
  return (
    <div>
      <PageTitle
        title="Store credits"
        fontSize="20px"
        margin="0 0 20px 0"
        style={{
          height: 40,
          boxSizing: "content-box",
          display: "flex",
          alignItems: "center",
        }}
      />

      <InputPreview title="YOUR STORE CREDITS" value={"$" + balance} />
    </div>
  )
}

StoreCreditsInfo.propTypes = {
  balance: PropTypes.number.isRequired,
}

StoreCreditsInfo.defaultProps = {}

export default StoreCreditsInfo
