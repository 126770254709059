import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const clearError = generateSagaActions(
  "[ERROR] Clear error",
  "CLEAR_ERROR",
  "clearError",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setError = generateSagaActions(
  "[ERROR] Set error",
  "SET_ERROR",
  "setError",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...clearError.actionMap,
  ...clearError.actionCreatorMap,
  ...setError.actionMap,
  ...setError.actionCreatorMap,
}
