import "./CancelConfirmationModalContent.scss"

import classNames from "classnames"
import { Button, Loading, Price } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import cartAction from "../../../redux/cart/actions"
import { cartSelector, tripSelector } from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"

const CancelConfirmationModalContent = ({
  pnr,
  changeModalState,
  setShowOnGoBack,
  isRoundTrip,
  cartUuid,
  cancelState,
}) => {
  const cancelConfirmationModalContentClass = classNames("cancel-confirmation-container")
  const trip = useSelector(state => tripSelector(state))
  const cart = useSelector(state => cartSelector(state))

  const dispatch = useDispatch()

  const cancelTrip = useCallback(
    (tripPnr, segmentToCancel) =>
      dispatch(tripActions.cancelTrip({ pnr: tripPnr, segment: segmentToCancel })),
    [dispatch],
  )
  const getRefundDetails = useCallback(
    (cartid, segment) => dispatch(cartAction.getRefundDetails({ uuid: cartid, segment })),
    [dispatch],
  )

  const [successfullyCanceled, setSuccessfullyCanceled] = useState(false)
  const getSegmentToCancel = () => {
    if (!isRoundTrip && cancelState.segmentToCancel === 1) {
      return undefined
    }
    return cancelState.segmentToCancel
  }
  useEffect(() => {
    // if user cancel one way trip, call refund details without params
    getRefundDetails(cartUuid, getSegmentToCancel())
  }, [cartUuid])

  const getAreYouSureCancelText = () => {
    if (!cancelState.segmentToCancel && isRoundTrip) return "Round"
    if (cancelState.segmentToCancel === 1) return "Departure"
    if (cancelState.segmentToCancel === 2) return "Return"
    return null
  }

  useEffect(() => {
    if (trip.isCanceled) {
      setSuccessfullyCanceled(true)
      setShowOnGoBack(false)
    }
  }, [trip.isCanceled])

  const history = useHistory()

  if (trip.isBusy) {
    return <Loading small />
  }

  const checkIfNoRefund = () => {
    return (
      ((cart || {}).refundDetails || {}).amount === 0 &&
      ((cart || {}).refundDetails || {}).miles === 0 &&
      ((cart || {}).refundDetails || {}).store_credits === 0
    )
  }

  return (
    <>
      {successfullyCanceled ? (
        <div className="canceled-container">
          <div className="big-title">Cancellation complete!</div>
          {((cart || {}).refundDetails || {}).amount !== 0 && (
            <div className="small-text">
              Refund will be processed in <span className="orange-text">5-10 business days</span>,
              please be patient.
            </div>
          )}
        </div>
      ) : (
        <div className={cancelConfirmationModalContentClass}>
          <div className="big-title">
            Are you sure you want to cancel{" "}
            <span className="red-text">
              {getAreYouSureCancelText()}&nbsp;{pnr}
            </span>
            &nbsp; trip
          </div>
          <div className="cancel-confirmation-buttons-container">
            <Button
              type="destructive"
              margin="0px 16px 0px 0px"
              onClick={() => {
                if (trip.isCanceled) {
                  history.push("/trips")
                } else {
                  cancelTrip(pnr, getSegmentToCancel())
                }
              }}
            >
              Yes
            </Button>
            <Button
              type="primary"
              onClick={() => {
                changeModalState({
                  isCancelTripConfirmationModalVisible: false,
                  isCancelTripModalVisible: true,
                })
              }}
            >
              No
            </Button>
          </div>
          <div className="bottom-content-container">
            <div className="important-info">Important info</div>
            {cart.isBusy ? (
              <Loading small />
            ) : (
              <div className="refund-container">
                Eligible for a refund of{" "}
                {((cart || {}).refundDetails || {}).amount > 0 && (
                  <span>
                    <Price amount={((cart || {}).refundDetails || {}).amount} currency="USD" />
                  </span>
                )}
                {((cart || {}).refundDetails || {}).miles > 0 && (
                  <span>
                    <Price amount={((cart || {}).refundDetails || {}).miles} currency="PTS" />
                  </span>
                )}
                {((cart || {}).refundDetails || {}).store_credits > 0 && (
                  <span>
                    <span>Store Credits: {((cart || {}).refundDetails || {}).store_credits}</span>
                  </span>
                )}
                {checkIfNoRefund() && (
                  <span>
                    <Price amount={((cart || {}).refundDetails || {}).amount} currency="USD" />
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

CancelConfirmationModalContent.propTypes = {
  pnr: PropTypes.string.isRequired,
  changeModalState: PropTypes.func.isRequired,
  setShowOnGoBack: PropTypes.func.isRequired,
  isRoundTrip: PropTypes.bool.isRequired,
  cancelState: PropTypes.instanceOf(Object).isRequired,
  cartUuid: PropTypes.string.isRequired,
}

CancelConfirmationModalContent.defaultProps = {}

export default CancelConfirmationModalContent
