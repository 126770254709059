import "./PaymentPrices.scss"

import classNames from "classnames"
import { PageTitle, Price } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback } from "react"

import { allCardProductsEnums, paymentTypeEnums } from "../../../utils/cart-util"
// import { isBlackCarAirline } from "../../../utils/tripUtil-util"
import TotalPrice from "../../molecules/TotalPrice"

const PaymentPrices = ({
  items,
  summary,
  summaryLp,
  isLoyaltyActive,
  voucherCode,
  onPromoCodeChanged,
  onAddPromoCodeClicked,
  onRemovePromoCodeClicked,
  preview,
  voucher,
  loyaltyBalance,
  paymentNeeded,
  setRedirectURL,
  previousTicket,
  editTrip,
  cancelTrip,
  hideVoucher,
  refundData,
  count,
  oneWay,
}) => {
  const paymentPricesClass = classNames("payment-prices-wrapper")
  const getProductName = item => {
    const { product } = item
    let additionalInformation = ""
    if (allCardProductsEnums.LANDLINE_TICKET === product.category && !oneWay) {
      additionalInformation =
        item.data.direction_type === "departure_flight" ? "(depart)" : "(return)"
    }
    return `${product.name} ${additionalInformation}`
  }

  const getTicketName = useCallback(ticket => {
    const { name } = ticket.product

    // const airline = ticket.data.flight_number.substring(0, 2)
    // const flightNo = ticket.data.flight_number.substring(2)

    // if (isBlackCarAirline({ airlineCode: airline, parsedFlightNumber: flightNo })) {
    //   name = "Landline Select ticket"
    // }

    return name
  }, [])

  const getParsedListItems = () => {
    const allParsedItems = items.filter(
      ticket =>
        ![allCardProductsEnums.FLIGHT_TICKET, allCardProductsEnums.LANDLINE_TICKET].includes(
          ticket.product.category,
        ),
    )

    const allDepartTickets = items.filter(
      ticket =>
        [allCardProductsEnums.FLIGHT_TICKET, allCardProductsEnums.LANDLINE_TICKET].includes(
          ticket.product.category,
        ) && ["departure_trip", "departure_flight"].includes(ticket.data.direction_type),
    )

    let departTicket = {}
    for (let i = 0; i < allDepartTickets.length; i++) {
      const ticket = allDepartTickets[i]

      if (ticket.product.category === allCardProductsEnums.LANDLINE_TICKET) {
        departTicket = {
          ...ticket,
          price: (departTicket.price || 0) + ticket.price,
          price_lp: (departTicket.price_lp || 0) + ticket.price_lp,
          product: { ...ticket.product, name: getTicketName(ticket) },
        }
      } else {
        departTicket.price = (departTicket.price || 0) + ticket.price
        departTicket.price_lp = (departTicket.price_lp || 0) + ticket.price_lp
      }
    }
    if (departTicket.price || departTicket.price_lp) {
      allParsedItems.push(departTicket)
    }

    const allReturnTickets = items.filter(
      ticket =>
        [allCardProductsEnums.FLIGHT_TICKET, allCardProductsEnums.LANDLINE_TICKET].includes(
          ticket.product.category,
        ) && ["return_trip", "return_flight"].includes(ticket.data.direction_type),
    )

    let returnTicket = {}
    for (let i = 0; i < allReturnTickets.length; i++) {
      const ticket = allReturnTickets[i]
      if (ticket.product.category === allCardProductsEnums.LANDLINE_TICKET) {
        returnTicket = {
          ...ticket,
          price: (returnTicket.price || 0) + ticket.price,
          price_lp: (returnTicket.price_lp || 0) + ticket.price_lp,
          product: { ...ticket.product, name: getTicketName(ticket) },
        }
      } else {
        returnTicket.price = (returnTicket.price || 0) + ticket.price
        returnTicket.price_lp = (returnTicket.price_lp || 0) + ticket.price_lp
      }
    }

    if (returnTicket.price || returnTicket.price_lp) {
      allParsedItems.push(returnTicket)
    }

    return allParsedItems || []
  }

  return (
    <div className={paymentPricesClass}>
      <PageTitle title="Fee Summary" />
      <div className="payment-price-section">
        {getParsedListItems().map(item => {
          const itemPrice =
            item.payment_type === paymentTypeEnums.LOYALTY_POINTS
              ? item.quantity * item.price_lp
              : item.quantity * item.price

          const negative = [
            allCardProductsEnums.DISCOUNT,
            allCardProductsEnums.PREVIOUS_TICKET,
            allCardProductsEnums.STORE_CREDITS,
          ].includes(item.product.category)

          return (
            <div className="payment-prices-row" key={item.id}>
              <div>
                {oneWay ? count : count / 2}x {getProductName(item)}
              </div>
              <Price
                useLoyalty={isLoyaltyActive}
                ceil={item.payment_type === paymentTypeEnums.LOYALTY_POINTS}
                presign={negative ? "-" : ""}
                amount={itemPrice}
                negativeStyle={negative}
                currency={item.payment_type === paymentTypeEnums.LOYALTY_POINTS ? "PTS" : "USD"}
              />
            </div>
          )
        })}
      </div>
      <TotalPrice
        isLoyaltyActive={isLoyaltyActive}
        pricePoints={summaryLp}
        preview={preview}
        price={summary}
        voucher={voucher}
        voucherCode={voucherCode}
        onPromoCodeChanged={onPromoCodeChanged}
        onAddPromoCodeClicked={onAddPromoCodeClicked}
        onRemovePromoCodeClicked={onRemovePromoCodeClicked}
        loyaltyBalance={loyaltyBalance}
        paymentNeeded={paymentNeeded}
        setRedirectURL={setRedirectURL}
        previousTicket={previousTicket}
        refundData={refundData}
        editTrip={editTrip}
        cancelTrip={cancelTrip}
        items={items}
        hideVoucher={hideVoucher}
      />
    </div>
  )
}

PaymentPrices.propTypes = {
  items: PropTypes.instanceOf(Array),
  summary: PropTypes.string,
  summaryLp: PropTypes.string,
  isLoyaltyActive: PropTypes.bool,
  voucher: PropTypes.instanceOf(Object),
  voucherCode: PropTypes.string,
  onPromoCodeChanged: PropTypes.func,
  onAddPromoCodeClicked: PropTypes.func,
  onRemovePromoCodeClicked: PropTypes.func,
  preview: PropTypes.bool,
  loyaltyBalance: PropTypes.number.isRequired,
  paymentNeeded: PropTypes.bool,
  setRedirectURL: PropTypes.func,
  previousTicket: PropTypes.shape({
    refund_details: PropTypes.instanceOf(Object),
  }),
  refundData: PropTypes.instanceOf(Object),
  editTrip: PropTypes.bool,
  cancelTrip: PropTypes.bool,
  hideVoucher: PropTypes.bool,
  count: PropTypes.number,
  oneWay: PropTypes.bool,
}

PaymentPrices.defaultProps = {
  items: [],
  summary: "0.00",
  summaryLp: "0.00",
  isLoyaltyActive: false,
  voucher: {},
  voucherCode: "",
  onPromoCodeChanged: () => {},
  onAddPromoCodeClicked: () => {},
  onRemovePromoCodeClicked: () => {},
  preview: false,
  paymentNeeded: true,
  setRedirectURL: undefined,
  previousTicket: {
    refund_details: {},
  },
  refundData: {},
  editTrip: false,
  cancelTrip: false,
  hideVoucher: false,
  count: 1,
  oneWay: false,
}

export default PaymentPrices
