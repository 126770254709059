import actions from "./actions"
import initialState from "./initialState"

export default (state = initialState(), action) => {
  const { type, payload } = action

  switch (type) {
    case actions.FETCH_INSTRUCTION:
      return {
        ...state,
        isBusy: true,
      }
    case actions.FETCH_INSTRUCTION_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.FETCH_INSTRUCTION_SUCCESS:
      return {
        ...state,
        isBusy: false,
        instruction: payload,
      }
    case actions.FETCH_DOOR2DOORSERVICE:
      return {
        ...state,
        isBusy: true,
      }
    case actions.FETCH_DOOR2DOORSERVICE_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.FETCH_DOOR2DOORSERVICE_SUCCESS:
      return {
        ...state,
        isBusy: false,
        door2DoorService: payload,
      }
    case actions.CLEAR_DOOR2DOORSERVICE_SUCCESS:
      return {
        ...state,
        isBusy: false,
        door2DoorService: [],
      }
    case actions.CLEAR_DOOR2DOORSERVICE_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    default:
      return state
  }
}
