const DuluthData = {
    from: {
        title: "From Duluth",
        stops: [
            {
                type:"",
                name:"DAYS"
            },
            {
                type: "Depart",
                name: "DLH"
            },
            {
                type: "Arrive",
                name: "MSP T2"
            },
            {
                type: "Arrive",
                name: "MSP T1"
            },
        ],
        itinerary: [
            ["MON, THU, FRI", "10:02 AM", "1:00 PM", "1:15 PM"],
            ["SAT", "9:52 AM", "12:50 PM", "1:05 PM"],
            ["SUN", "9:50 AM", "1:00 PM", "1:15 PM"]
        ]
    },
    to: {
        title: "To Duluth",
        stops: [
            {
                type:"",
                name:"DAYS"
            },
            {
                type: "Depart",
                name: "MSP T1"
            },
            {
                type: "Depart",
                name: "MSP T2"
            },
            {
                type: "Arrive",
                name: "DLH"
            },
        ],
        itinerary: [
            ["MON, THU, FRI, SAT, SUN", "4:10 PM", "4:30 PM", "7:28 PM"],
        ]
    }
}


export default DuluthData