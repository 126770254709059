import { Button, InputFieldSet, NotificationConsumer, Section } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import accountActions from "../../../redux/account/actions"
import errorActions from "../../../redux/error/actions"
import { accountSelector, errorSelector } from "../../../redux/selector"
import Loading from "../../atoms/Loading"

const styles = {
  containerStyle: {
    padding: 20,
  },
  legendStyle: {
    backgroundColor: "#ffffff",
    marginTop: -5,
    marginLeft: 1,
    border: "0px solid red",
    padding: "0 30px 0 0",
  },
}

const PasswordEdit = ({ history }) => {
  const dispatch = useDispatch()
  const changePassword = useCallback(params => dispatch(accountActions.changePassword(params)), [
    dispatch,
  ])
  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])
  const account = useSelector(state => accountSelector(state))
  const error = useSelector(state => errorSelector(state))

  const [state, setState] = useState({
    data: {
      current_password: "",
      new_password: "",
      repeated_password: "",
    },
  })
  const onInputChanged = field => value => {
    setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [field]: value,
      },
    }))
  }
  const onSubmit = e => {
    e.preventDefault()
    const { data } = state
    changePassword(data)
  }
  return (
    <NotificationConsumer>
      {({ actions }) => {
        if (account.isBusy || (error && error.isBusy)) {
          return <Loading />
        }
        if (!account.isAuthenticated) {
          return <Redirect to="/" />
        }
        if (error) {
          actions.show({ type: "is-danger", message: error })
          clearError()
        }
        if (account.passwordChanged) {
          actions.show({
            type: "is-warning",
            message: "Password changed successful",
          })
          return <Redirect to="/profile" />
        }
        return (
          <>
            <Section title="Change Password" onGoBackClicked={() => history.push("/profile")} />
            <div style={styles.containerStyle}>
              <ChangePasswordForm
                data={state.data}
                onInputChanged={onInputChanged}
                onSubmit={onSubmit}
              />
            </div>
          </>
        )
      }}
    </NotificationConsumer>
  )
}

export default PasswordEdit

PasswordEdit.propTypes = {
  history: PropTypes.instanceOf(Object),
}
PasswordEdit.defaultProps = {
  history: {},
}

const ChangePasswordForm = ({ data, onInputChanged, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <InputFieldSet
        fullWidth
        labelText="CURRENT PASSWORD"
        mandatory
        inputType="password"
        inputValue={data.current_password}
        onChange={e => onInputChanged("current_password")(e.target.value)}
        margin="10px 0 20px 0"
      />

      <InputFieldSet
        fullWidth
        labelText="NEW PASSWORD"
        mandatory
        inputType="password"
        inputValue={data.new_password}
        onChange={e => onInputChanged("new_password")(e.target.value)}
        margin="10px 0 20px 0"
      />

      <InputFieldSet
        fullWidth
        labelText="RE-ENTER PASSWORD"
        mandatory
        inputType="password"
        inputValue={data.repeated_password}
        onChange={e => onInputChanged("repeated_password")(e.target.value)}
        margin="10px 0 20px 0"
      />
      <Button buttonType="submit" type="primary">
        Change Password
      </Button>
    </form>
  )
}

ChangePasswordForm.propTypes = {
  data: PropTypes.instanceOf(Object),
  onInputChanged: PropTypes.func,
  onSubmit: PropTypes.func,
}

ChangePasswordForm.defaultProps = {
  data: {},
  onInputChanged: () => {},
  onSubmit: () => {},
}
