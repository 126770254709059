import { createBrowserHistory } from "history"
import { throttle } from "lodash"
import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import { createReduxHistoryContext } from "redux-first-history"
import immutableCheckMiddleWare from "redux-immutable-state-invariant"
import { createLogger } from "redux-logger"
import createSagaMiddleware from "redux-saga"

import { loadState, saveState } from "../utils/common"
import account from "./account/reducer"
import cart from "./cart/reducer"
import error from "./error/reducer"
import instruction from "./instruction/reducer"
import itinerary from "./itinerary/reducer"
import loyalty from "./loyalty/reducer"
import newsletter from "./newsletter/reducer"
import passenger from "./passenger/reducer"
import redirect from "./redirect/reducer"
import reservations from "./reservations/reducer"
import sagas from "./sagas"
import settings from "./settings/reducer"
import ticket from "./ticket/reducer"
import trip from "./trip/reducer"

// NOTE(sb): plop reduxGen import redux reducer
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
})
const rootReducer = () =>
  combineReducers({
    reservations,
    ticket,
    itinerary,
    passenger,
    error,
    account,
    trip,
    redirect,
    router: routerReducer,
    instruction,
    newsletter,
    loyalty,
    cart,
    settings,

    // NOTE(sb): plop reduxGen implement redux reducer
  })
const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware, routerMiddleware]

const composeEnhancer =
  (process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

if (process.env.NODE_ENV === "development") {
  middleware.push(immutableCheckMiddleWare())
}
const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
  duration: true,
})
middleware.push(loggerMiddleware)
const initialState = loadState()
export const store = createStore(
  rootReducer(),
  initialState,
  composeEnhancer(applyMiddleware(...middleware)),
)
export const history = createReduxHistory(store)
store.subscribe(
  throttle(() => {
    saveState(store.getState())
  }, 1000),
)
sagaMiddleware.run(sagas)
