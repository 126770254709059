import moment from "moment"
import { all, put, select, takeLatest } from "redux-saga/effects"

import { paymentTypeEnums } from "../../utils/cart-util"
import { toDateFormatString } from "../../utils/common"
import { httpPut } from "../../utils/request"
import errorActions from "../error/actions"
import actions from "./actions"

export function* clearPassengers$() {
  try {
    yield put(actions.clearPassengersSuccess())
  } catch (error) {
    yield put(actions.clearPassengersFail())
    yield put(errorActions.setError(error))
  }
}

export function* setLeadPassenger$({ payload }) {
  try {
    yield put(actions.setLeadPassengerSuccess(payload))
  } catch (error) {
    yield put(actions.setLeadPassengerFail())
    yield put(errorActions.setError(error))
  }
}

export function* setPassengerByIndex$({ payload }) {
  try {
    yield put(actions.setPassengerByIndexSuccess(payload))
  } catch (error) {
    yield put(actions.setPassengerByIndexFail())
    yield put(errorActions.setError(error))
  }
}

export function* setCurrentPassenger$({ payload }) {
  try {
    yield put(actions.setCurrentPassengerSuccess(payload))
  } catch (error) {
    yield put(actions.setCurrentPassengerFail())
    yield put(errorActions.setError(error))
  }
}

export function* setSeatStatus$({ payload }) {
  try {
    yield put(actions.setSeatStatusSuccess(payload))
  } catch (error) {
    yield put(actions.setSeatStatusFail())
    yield put(errorActions.setError(error))
  }
}

export function* setPassengerProperty$({ payload }) {
  try {
    yield put(actions.setPassengerPropertySuccess(payload))
  } catch (error) {
    yield put(actions.setPassengerPropertyFail())
    yield put(errorActions.setError(error))
  }
}

export function* clearPassengerSeat$({ payload }) {
  try {
    yield put(actions.clearPassengerSeatSuccess(payload))
  } catch (error) {
    yield put(actions.clearPassengerSeatFail())
    yield put(errorActions.setError(error))
  }
}

export function* updatePassengers$({ payload: callback }) {
  try {
    const reservations = yield select(state => state.reservations)
    const passenger = yield select(state => state.passenger)
    const trip = yield select(state => state.trip)
    const { uuid: cartId } = yield select(state => state.cart)
    const {
      payload: { passengers, uuid },
    } = yield select(state => state.itinerary)

    const { bookingToken } = yield select(state => state.ticket)
    const passengerList = []

    for (let i = 0; i < passengers.length; i++) {
      const currentDate = moment()
      const dateOfBirth = moment(passenger[i].date_of_birth) // was => "MM/DD/YYYY"
      const age = moment.duration(currentDate.diff(dateOfBirth))

      const years = age.years()
      let p = {
        last_name: passenger[i].last_name,
        first_name: passenger[i].first_name,
        gender: passenger[i].gender[0],
        title: passenger[i].title,
        email: passenger[i].email,
        special_requirements: passenger[i].special_requirements,
        phone_number: passenger[i].phone_number,
        date_of_birth: toDateFormatString(passenger[i].date_of_birth),
        departure_seats: passenger[i].departure_seats,
        passenger_type: passengers[i].passenger_type,
        age: years,
      }
      if (!passenger[i].no_flight) {
        p = {
          ...p,
          flight_info: {
            airline: passenger[i].airline,
            flight_number: passenger[i].flight_number,
            final_destination: passenger[i].final_destination,
          },
        }
      }
      if (!reservations.params.one_way) {
        p = {
          ...p,
          return_seats: passenger[i].return_seats,
        }
      }
      passengerList.push(p)
    }

    const { isActive: isLoyaltyActive } = yield select(state => state.loyalty)
    // const { editTrip } = yield select(state => state.trip)
    const {
      params: { is_edit_per_segment: isEditPerSegment },
    } = yield select(state => state.reservations)
    const params = {
      passengers: passengerList,
      cart_id: cartId,
      payment_type: isLoyaltyActive ? paymentTypeEnums.LOYALTY_POINTS : paymentTypeEnums.REGULAR,
      booking_token: bookingToken,
      is_edit: trip.editTrip !== "",
      is_edit_per_segment: isEditPerSegment,
      bnum: 0,
    }

    const passengersResponse = yield httpPut(`bookings/${uuid}/passengers/`, params)

    if (callback) {
      callback()
    }

    yield put(actions.updatePassengersSuccess(passengersResponse))
  } catch (error) {
    let message = error
    if (error && error.error === "NOFLY") {
      message = error.message
    }
    yield put(actions.updatePassengersFail())
    yield put(errorActions.setError(message))
  }
}

export function* setPassengerDepartureSeat$({ payload }) {
  try {
    yield put(actions.setPassengerDepartureSeatSuccess(payload))
  } catch (error) {
    yield put(actions.setPassengerDepartureSeatFail())
    yield put(errorActions.setError(error))
  }
}

export function* setPassengerReturnSeat$({ payload }) {
  try {
    yield put(actions.setPassengerReturnSeatSuccess(payload))
  } catch (error) {
    yield put(actions.setPassengerReturnSeatFail())
    yield put(errorActions.setError(error))
  }
}

export function* setPassengerSeat$({ payload }) {
  try {
    yield put(actions.setPassengerSeatSuccess(payload))
  } catch (error) {
    yield put(actions.setPassengerSeatFail())
    yield put(errorActions.setError(error))
  }
}

export default function*() {
  yield all([
    takeLatest(actions.clearPassengers, clearPassengers$),
    takeLatest(actions.setLeadPassenger, setLeadPassenger$),
    takeLatest(actions.setPassengerByIndex, setPassengerByIndex$),
    takeLatest(actions.setCurrentPassenger, setCurrentPassenger$),
    takeLatest(actions.setSeatStatus, setSeatStatus$),
    takeLatest(actions.clearPassengerSeat, clearPassengerSeat$),
    takeLatest(actions.setPassengerProperty, setPassengerProperty$),
    takeLatest(actions.updatePassengers, updatePassengers$),
    takeLatest(actions.setPassengerSeat, setPassengerSeat$),
    takeLatest(actions.setPassengerDepartureSeat, setPassengerDepartureSeat$),
    takeLatest(actions.setPassengerReturnSeat, setPassengerReturnSeat$),
  ])
}
