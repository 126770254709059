import { goBack } from "redux-first-history"
import { all, put, select, takeLatest } from "redux-saga/effects"

// eslint-disable-next-line import/named
import { HOME, PROFILE } from "../../constants/routes"
import { toDateFormatString } from "../../utils/common"
import { httpGet, httpPost, httpPut } from "../../utils/request"
import cartActions from "../cart/actions"
import errorActions from "../error/actions"
import loyaltyActions from "../loyalty/actions"
import { redirectSelector } from "../selector"
import actions from "./actions"

export function* getReferralData() {
  try {
    const response = yield httpGet("referrals_url/")
    yield put(actions.getReferralDataSuccess(response))
  } catch (error) {
    yield put(actions.getReferralDataFail(error))
    yield put(errorActions.setError(error))
  }
}

export function* registerUser$({ payload }) {
  try {
    const response = yield httpPost("users/", payload)

    if (response.access && response.refresh) {
      yield localStorage.setItem("accessToken", response.access)
      yield localStorage.setItem("refreshToken", response.refresh)
    }

    yield put(actions.authenticateUser(response))
    yield put(actions.registerUserSuccess())
    payload.history.push(PROFILE)
  } catch (error) {
    yield put(actions.registerUserFail(error))
    yield put(errorActions.setError(error))
  }
}

export function* loginUser$({ payload }) {
  try {
    const response = yield httpPost("sessions/", payload)

    if (response.access && response.refresh) {
      yield localStorage.setItem("accessToken", response.access)
      yield localStorage.setItem("refreshToken", response.refresh)
    }

    yield put(actions.authenticateUser(response))

    const { url } = yield select(redirectSelector)

    const { uuid } = yield select(state => state.cart)

    yield put(cartActions.assingCartToUser(uuid))
    yield put(actions.fetchUserProfile())

    yield put(actions.loginUserSuccess())

    if (url && url !== HOME) {
      payload.history.replace(url)
    } else {
      yield put(goBack())
    }
  } catch (error) {
    yield put(actions.loginUserFail(error))
    yield put(errorActions.setError(error))
  }
}

export function* logoutUser$() {
  try {
    yield put(actions.logoutUserSuccess())
  } catch (error) {
    yield put(actions.logoutUserFail(error))
    yield put(errorActions.setError(error))
  }
}

export function* authenticateUser$({ payload }) {
  try {
    yield put(actions.fetchUserProfile(payload))
    yield put(actions.authenticateUserSuccess())
  } catch (error) {
    yield put(actions.authenticateUserFail(error))
    yield put(errorActions.setError(error))
  }
}

export function* fetchUserProfile$() {
  try {
    const { isAuthenticated } = yield select(state => state.account)
    if (isAuthenticated) {
      const response = yield httpGet("profile/")
      yield put(actions.fetchUserProfileSuccess(response))
      yield put(
        loyaltyActions.updateLoyaltyFieldsSuccess({ field: "wallet", value: response.user_wallet }),
      )
    } else {
      yield put(actions.fetchUserProfileFail())
    }
  } catch (error) {
    yield put(actions.fetchUserProfileFail(error))
    yield put(errorActions.setError(error))
  }
}

export function* editUserProfile$() {
  const account = state => state.account
  const {
    profile: { data },
  } = yield select(account)
  try {
    const parsedData = {
      ...data,
      date_of_birth: data.date_of_birth ? toDateFormatString(data.date_of_birth) : null,
      gender: data.gender[0],
    }
    yield put(errorActions.clearError())
    const response = yield httpPut("profile/", parsedData)
    yield put(actions.editUserProfileSuccess(response))
  } catch (error) {
    yield put(actions.editUserProfileFail(error))
    yield put(errorActions.setError(error))
  }
}

export function* changePassword$({ payload }) {
  try {
    const response = yield httpPut("v2/accounts/password/change/", payload.passwordData)
    yield put(actions.changePasswordSuccess(response))
    payload.callback()
  } catch (error) {
    yield put(actions.changePasswordFail(error))
    yield put(errorActions.setError(error))
  }
}

export function* activateUser$({ payload }) {
  try {
    const response = yield httpPut(`activations/${payload.code}/${payload.referralCode}`)
    yield put(actions.activateUserSuccess(response))
  } catch (error) {
    yield put(actions.activateUserFail())
    yield put(errorActions.setError(error))
  }
}

export function* setFormFieldValue$({ payload }) {
  try {
    yield put(
      actions.setFormFieldValueSuccess({
        field: payload.field,
        value: payload.value,
      }),
    )
  } catch (error) {}
}

export function* recoverPassword$({ payload }) {
  try {
    yield put(errorActions.clearError())
    const response = yield httpPost(`recoveries/`, payload)
    yield put(actions.recoverPasswordSuccess(response))
  } catch (error) {
    yield put(errorActions.setError(error))
    yield put(actions.recoverPasswordFail())
  }
}
export function* verifyPasswordRecoveryCode$({ payload }) {
  try {
    yield put(errorActions.clearError())
    const response = yield httpGet(`recoveries/${payload.code}/`)
    yield put(actions.verifyPasswordRecoveryCodeSuccess(response))
  } catch (error) {
    yield put(errorActions.setError(error))
    yield put(actions.verifyPasswordRecoveryCodeFail(error))
  }
}
export function* resetPassword$({ payload }) {
  try {
    const response = yield httpPut(`recoveries/${payload.code}/`, payload.data)
    yield put(actions.resetPasswordSuccess(response))
  } catch (error) {
    yield put(actions.resetPasswordFail(error))
    yield put(errorActions.setError(error))
  }
}

export function* setTokenError$({ payload }) {
  try {
    yield put(actions.setTokenErrorSuccess(payload))
  } catch (error) {
    yield put(actions.setTokenErrorFail())
  }
}

export function* clearTokenError$() {
  try {
    yield put(actions.clearTokenErrorSuccess())
  } catch (error) {
    yield put(actions.clearTokenErrorFail())
  }
}

export default function*() {
  yield all([
    takeLatest(actions.registerUser, registerUser$),
    takeLatest(actions.loginUser, loginUser$),
    takeLatest(actions.logoutUser, logoutUser$),
    takeLatest(actions.authenticateUser, authenticateUser$),
    takeLatest(actions.fetchUserProfile, fetchUserProfile$),
    takeLatest(actions.editUserProfile, editUserProfile$),
    takeLatest(actions.setFormFieldValue, setFormFieldValue$),
    takeLatest(actions.changePassword, changePassword$),
    takeLatest(actions.activateUser, activateUser$),
    takeLatest(actions.recoverPassword, recoverPassword$),
    takeLatest(actions.verifyPasswordRecoveryCode, verifyPasswordRecoveryCode$),
    takeLatest(actions.resetPassword, resetPassword$),
    takeLatest(actions.getReferralData, getReferralData),
    takeLatest(actions.setTokenError, setTokenError$),
    takeLatest(actions.clearTokenError, clearTokenError$),
  ])
}
