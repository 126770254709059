import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const loginUser = generateSagaActions(
  "[ACCOUNT] Login user",
  "LOGIN_USER",
  "loginUser",
  ["FAIL", "SUCCESS"],
  createAction,
)

const authenticateUser = generateSagaActions(
  "[ACCOUNT] Authenticate user",
  "AUTHENTICATE_USER",
  "authenticateUser",
  ["FAIL", "SUCCESS"],
  createAction,
)

const activateUser = generateSagaActions(
  "[ACCOUNT] Activate user",
  "ACTIVATE_USER",
  "activateUser",
  ["FAIL", "SUCCESS"],
  createAction,
)

const fetchUserProfile = generateSagaActions(
  "[ACCOUNT] Fetch user profile",
  "FETCH_USER_PROFILE",
  "fetchUserProfile",
  ["FAIL", "SUCCESS"],
  createAction,
)

const editUserProfile = generateSagaActions(
  "[ACCOUNT] Edit user profile",
  "EDIT_USER_PROFILE",
  "editUserProfile",
  ["FAIL", "SUCCESS"],
  createAction,
)

const changePassword = generateSagaActions(
  "[ACCOUNT] Change user password",
  "CHANGE_PASSWORD",
  "changePassword",
  ["FAIL", "SUCCESS"],
  createAction,
)

const registerUser = generateSagaActions(
  "[ACCOUNT] Register user",
  "REGISTER_USER",
  "registerUser",
  ["FAIL", "SUCCESS"],
  createAction,
)

const logoutUser = generateSagaActions(
  "[ACCOUNT] Logout user",
  "LOGOUT_USER",
  "logoutUser",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setFormFieldValue = generateSagaActions(
  "[ACCOUNT] Set form field value",
  "SET_FORM_FIELD_VALUE",
  "setFormFieldValue",
  ["SUCCESS"],
  createAction,
)

const recoverPassword = generateSagaActions(
  "[ACCOUNT] Recover password",
  "RECOVER_PASSWORD",
  "recoverPassword",
  ["FAIL", "SUCCESS"],
  createAction,
)

const resetPassword = generateSagaActions(
  "[ACCOUNT] Reset password",
  "RESET_PASSWORD",
  "resetPassword",
  ["FAIL", "SUCCESS"],
  createAction,
)

const verifyPasswordRecoveryCode = generateSagaActions(
  "[ACCOUNT] Verify password recovery code",
  "VERIFY_PASSWORD_RECOVERY_CODE",
  "verifyPasswordRecoveryCode",
  ["FAIL", "SUCCESS"],
  createAction,
)

const getReferralData = generateSagaActions(
  "[REFERRAL] Get referal data",
  "GET_REFERRAL_DATA",
  "getReferralData",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setTokenError = generateSagaActions(
  "[ERROR] Set token error",
  "SET_TOKEN_ERROR",
  "setTokenError",
  ["FAIL", "SUCCESS"],
  createAction,
)

const clearTokenError = generateSagaActions(
  "[ERROR] Clear token error",
  "CLEAR_TOKEN_ERROR",
  "clearTokenError",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...loginUser.actionMap,
  ...loginUser.actionCreatorMap,
  ...authenticateUser.actionMap,
  ...authenticateUser.actionCreatorMap,
  ...activateUser.actionMap,
  ...activateUser.actionCreatorMap,
  ...fetchUserProfile.actionMap,
  ...fetchUserProfile.actionCreatorMap,
  ...editUserProfile.actionMap,
  ...editUserProfile.actionCreatorMap,
  ...changePassword.actionMap,
  ...changePassword.actionCreatorMap,
  ...registerUser.actionMap,
  ...registerUser.actionCreatorMap,
  ...logoutUser.actionMap,
  ...logoutUser.actionCreatorMap,
  ...setFormFieldValue.actionMap,
  ...setFormFieldValue.actionCreatorMap,
  ...resetPassword.actionMap,
  ...resetPassword.actionCreatorMap,
  ...verifyPasswordRecoveryCode.actionMap,
  ...verifyPasswordRecoveryCode.actionCreatorMap,
  ...recoverPassword.actionMap,
  ...recoverPassword.actionCreatorMap,
  ...getReferralData.actionMap,
  ...getReferralData.actionCreatorMap,
  ...setTokenError.actionMap,
  ...setTokenError.actionCreatorMap,
  ...clearTokenError.actionMap,
  ...clearTokenError.actionCreatorMap,
}
