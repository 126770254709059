import React from "react"
import "./Socials.scss"

import facebook from "../../media/outline-light-facebook-48.png"
import instagram from "../../media/outline-light-instagram-48.png"
import twitter from "../../media/outline-light-twitter-48.png"

export function Socials() {
  return (
    <div className="social-container">
      <div className="social-icon">
        <a href="https://www.facebook.com/ridelandline" target="_blank">
          <img
            src={facebook}
            style={{
              width: "28px",
            }}
            alt="Follow Landline on Facebook"
          />
        </a>
      </div>
      <div className="social-icon">
        <a href="https://twitter.com/ridelandline" target="_blank">
          <img
            src={twitter}
            style={{
              width: "28px",
            }}
            alt="Follow Landline on Twitter"
          />
        </a>
      </div>
      <div className="social-icon">
        <a href="https://www.instagram.com/ridelandline/" target="_blank">
          <img
            src={instagram}
            style={{
              width: "28px",
            }}
            alt="Follow Landline on Instagram"
          />
        </a>
      </div>
    </div>
  )
}
