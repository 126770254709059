import React from "react"

const ReserveMeta = {
    header: {
        title: "Save Time and Schedule Your TSA Screening",
        subtitle: "",
        description: "Book Landline’s reliable service and combine it with DEN/MSP Reserve to streamline your travel day and breeze through TSA. \nAvoid long security lines at DEN and MSP by making an appointment to reserve your security screening time when it is convenient for you."
    }
}

const ReserveService = <>
    <p>Let Landline’s premium airport shuttle service to/from Denver International Airport (DEN/DIA) and Minneapolis St. Paul International Airport (MSP) be the best part of your travel day.</p>
    <p>Schedule your Landline arrival to coincide with your TSA reservation helping you avoid the stress and expense of driving, parking or ridesharing and long security lines.</p>
    <p>While onboard Landline, enjoy modern conveniences like individual air conditioning controls, in seat power, reclining leather seats, onboard restroom, and get caught up on work or stream your favorite shows while you ride using our complimentary Wi-Fi. </p>
    <p>Our friendly staff will help you with loading and unloading your luggage on both ends of your trip, ensuring a smooth and efficient journey.</p>
    <p>Landline is ideal for families, college students, leisure and business travelers alike looking for a safe, reliable and an affordable way to/ from the airport.</p>
</>

const ReserveFAQ = <>

    <b>How close to my flight can I reserve my appointment?</b>
    <p>Reservations can be made 1-4 hours before your scheduled departure</p>

    <b>How do I use DEN/MSP Reserve?</b>
    <p>Schedule your appointment up to 3 days in advance for service from DEN and up to 7 days in advance for service from MSP, you will receive a QR code upon completion. Show your QR code to airport staff and proceed through the dedicated security lane.  </p>

    <b>What airlines are participating in DEN Reserve and where can I access it?</b>
    <p>All airlines. DEN Reserve is currently only available at the West Security Checkpoint. MSP Reserve is currently only available for passengers departing from Terminal 2.</p>

    <b>What is the cost to use DEN/MSP Reserve? </b>
    <p>It is FREE for all passengers. No fees/memberships are required.</p>

    <b>What if I want to sign up, but the appointment time I want is unavailable? </b>
    <p>Appointments are limited. We recommend signing up early to guarantee your time slot. If the time you need is not available, please plan accordingly and proceed through the standard security lines. </p>

    <b>What is the grace period for DEN/MSP Reserve?</b>
    <p>There is a 15 minute grace period per appointment. If you are outside of that window, you will need to reschedule or proceed through the standard security lane. </p>
</>

const MSPReserve = {
    image: "https://content.landline.com/wp-content/uploads/2024/06/MSP_reserve.jpg",
    title: "How it Works",
    prices: "",
    content: "Reservations are available for passengers traveling out of Terminal 2 between 3:45am to 8:00am and 12:00pm - 4:00pm. \n Make an appointment for your TSA security screening at the Minneapolis St. Paul International Airport (T2) within 7 days of your departure.\n Check your luggage if needed, and head to the security checkpoint and follow the signs for MSP Reserve. Show airport staff your appointment confirmation and proceed through screening. It’s that easy!",
    button: "Make a Reservation / Learn More"
}

const DENReserve = {
    image: "https://content.landline.com/wp-content/uploads/2024/06/DEN_reserve.jpg",
    title: "How it Works",
    prices: "",
    content: "Reservations are available between 5am and 5pm daily.\n Make an appointment for your TSA security screening at the Denver International Airport within 3 days of your departure.\n Check your luggage if needed, and head to the West Security 2 checkpoint on Level 6. You must be there within 15 minutes of your designated appointment time. Proceed to the DEN Reserve dedicated security lane and breeze through TSA and on to your gate. It’s that easy!",
    button: "Make a Reservation / Learn More"
}


export {
    ReserveMeta,
    DENReserve,
    MSPReserve,
    ReserveFAQ,
    ReserveService
}