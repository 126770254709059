import PropTypes from "prop-types"
import React from "react"
import { NavLink as RouterLink } from "react-router-dom"

const NavLink = ({ to, activeClassName, className, children, onClick }) => {
  return /^https?:\/\//.test(to) ? (
    <a href={to} className={className} onClick={onClick}>
      <>{children}</>
    </a>
  ) : (
    <RouterLink to={to} activeClassName={activeClassName} className={className} onClick={onClick}>
      {children}
    </RouterLink>
  )
}
export default NavLink

NavLink.propTypes = {
  to: PropTypes.string,
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
}

NavLink.defaultProps = {
  to: "",
  activeClassName: "",
  className: "",
  onClick: () => {},
}
