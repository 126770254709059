import "./CheckinModal.scss"

import classNames from "classnames"
import { Icon, Loading, TabModalGroup } from "ibe-components"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { CHECKIN_READY } from "../../../constants/checkin"
import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../../constants/segments"
import { settingsSelector, tripSelector } from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"
import {
  containsFlight,
  getFlightTicket,
  getVidecomState,
  isBlackCar,
  isTicketCancelled,
} from "../../../utils/tripUtil-util"
import CheckinModalInfo from "../CheckinModalInfo"

const CheckinModal = ({
  tripData,
  pnr,
  tripToCheckin,
  hasFlight,
  action,
  isWebhookReady,
  history,
  changeSeats,
  onTextBelowActionClick,
}) => {
  const dispatch = useDispatch()

  const { passengers } = tripData
  const checkinModalClass = classNames("checkin-modal-container")

  const checkInTrip = useCallback(payload => dispatch(tripActions.checkInTrip(payload)), [dispatch])

  const checkInFlight = useCallback(
    (flightTicketPnr, segmentDirection) =>
      dispatch(tripActions.checkInFlight({ flightTicketPnr, segmentDirection })),
    [dispatch],
  )
  const sendBoardingPassToMail = useCallback(
    flightTicketPnr => dispatch(tripActions.sendBoardingPassToMail({ flightTicketPnr })),
    [dispatch],
  )

  const resetCheckIn = useCallback(() => dispatch(tripActions.resetCheckIn()), [dispatch])
  const trip = useSelector(state => tripSelector(state))
  const { LANDLINE_IATA_CODE } = useSelector(state => settingsSelector(state))

  const [transportType, setTransportType] = useState(hasFlight ? "flight" : "ground")

  const segmentDirection = { depart: 1, return: 2 }[tripToCheckin]

  useEffect(() => {
    resetCheckIn()
  }, [])

  const filterGroundLegs = legs => legs.filter(leg => !containsFlight([leg]))
  const filteredGroundLegs = filterGroundLegs(tripData.segments)
  const filterGroundLegsLength = filteredGroundLegs.length

  const filterFlightLegs = legs => legs.filter(leg => containsFlight([leg]))
  const filteredFlightLegs = filterFlightLegs(tripData.segments)
  const filterFlightLegsLength = filteredFlightLegs.length

  const getFirstLegDepartureTime = legs =>
    moment(`${legs[0].departure_date}T${legs[0].departure_time}`).format("hh:mm a")

  const getFirstLegDepartureDate = legs =>
    moment(`${legs[0].departure_date}T${legs[0].departure_time}`).format("dddd, MMMM DD, YYYY")

  const getLastLegArrivalDate = (legs, legsLength) =>
    moment(`${legs[legsLength - 1].arrival_date}T${legs[0].arrival_time}`).format(
      "dddd, MMMM DD, YYYY",
    )

  const getlastLegArrivalTime = (legs, legsLength) =>
    moment(`${legs[legsLength - 1].arrival_date}T${legs[legsLength - 1].arrival_time}`).format(
      "hh:mm a",
    )

  const getFirstLegTitle = legs => legs[0].depart.AirportName
  const getFirstLegCityCode = legs => legs[0].depart.iata

  const getLastLegTitle = (legs, legsLength) => legs[legsLength - 1].arrive.AirportName
  const getlastLegCityCode = (legs, legsLength) => legs[legsLength - 1].arrive.iata

  const isTripBlackCar = isBlackCar(tripData.segments)
  const allCheckedIn = tripData.segments[0]?.all_checked_in
  const ticketCancelled = isTicketCancelled(getVidecomState(tripData.tickets))
  const isCheckinReady = tripData.segments[0].checkin_status === CHECKIN_READY

  const checkinBusy =
    trip.checkin || !isCheckinReady || allCheckedIn || ticketCancelled || isTripBlackCar

  const getDepartureCodes = legs => legs.map(leg => leg.depart.iata) // TODO: optimize performance

  const landlineSegment = tripData.segments.find(item => item.airline.iata === LANDLINE_IATA_CODE)
  const leadPassengerHasSeat = !!passengers[0].seats.find(seat => seat.leg === landlineSegment.id)

  const getCheckInGroundActionText = () => {
    if (leadPassengerHasSeat) {
      return allCheckedIn ? "Already checked-in" : "Check-in"
    }
    return "Choose a seat"
  }

  const groundActionText = {
    checkin: getCheckInGroundActionText(),
    boarding: "Send to email",
  }[action]

  const getCheckInGroundTextBelowAction = () => {
    if (leadPassengerHasSeat) {
      return allCheckedIn ? "View your boarding pass" : ""
    }
    return "You need to choose a seat before checking in"
  }

  const groundActionIcon = {
    checkin: <Icon name="checkin-outline-icon" width="24" height="24" withoutProps noAbsolute />,
    boarding: <Icon name="boardingPass" width="24" height="24" withoutProps noAbsolute />,
  }[action]

  const checkinModalIcon = {
    checkin: <Icon name="checkin-icon" width="24" height="24" withoutProps noAbsolute />,
    boarding: <Icon name="boardingPass" width="24" height="24" withoutProps noAbsolute />,
  }[action]

  const flightActionIcon = {
    checkin: <Icon name="email-letter" width="24" height="24" withoutProps noAbsolute />,
    boarding: <Icon name="email-letter" width="24" height="24" withoutProps noAbsolute />,
  }[action]

  const checkinModalTitle = {
    checkin: "Check-in",
    boarding: "Boarding Pass",
  }[action]

  const groundActionButtonType = {
    checkin: allCheckedIn ? "regular" : "primary",
    boarding: "primary",
  }[action]

  const flightActionButtonType = {
    checkin: isWebhookReady ? "primary" : "regular",
    boarding: "primary",
  }[action]

  const groundTextBelowAction = {
    checkin: getCheckInGroundTextBelowAction(),
    boarding: allCheckedIn ? "" : "Please check-in first",
  }[action]

  const flightTextBelowAction = {
    checkin: isWebhookReady
      ? "In order to check-in, send check-in details to your email"
      : "Your check-in details will be available soon ",
    boarding: "",
  }[action]

  const getFlightTextBelowAction = () => {
    if (typeof trip.notification === "boolean" && trip.notification) {
      return <span style={{ color: "#10C492" }}>Email successfully sent</span>
    }
    if (typeof trip.notification === "boolean" && !trip.notification) {
      return <span style={{ color: "#FF4040" }}>There is a problem, please try again.</span>
    }
    return flightTextBelowAction
  }

  const getGroundTextBelowAction = () => {
    if (
      typeof trip.boardingPassNotification === "boolean" &&
      trip.boardingPassNotification &&
      action === "boarding"
    ) {
      return <span style={{ color: "#10C492" }}>Email successfully sent</span>
    }
    if (typeof trip.boardingPassNotification === "boolean" && !trip.boardingPassNotification) {
      return <span style={{ color: "#FF4040" }}>There is a problem, please try again.</span>
    }
    return groundTextBelowAction
  }

  const onFlightActionButtonDisabled = () => {
    if (!isWebhookReady) {
      return true
    }
    return false
  }
  const onGroundActionButtonDisabled = () => {
    if (!leadPassengerHasSeat) {
      return false
    }
    if (checkinBusy && action === "checkin") {
      return true
    }
    if (!allCheckedIn && action === "boarding") return true
    return false
  }

  const tabFirstIcon = { checkin: "Ground check-in", boarding: "Ground boarding pass" }[action]
  const tabSecondIcon = { checkin: "Flight check-in", boarding: "Flights e-ticket" }[action]

  const onFlightActionClick = () => {
    if (action === "checkin") {
      checkInFlight(getFlightTicket(tripData.tickets)?.booking_reference, segmentDirection)
    } else {
      sendBoardingPassToMail(getFlightTicket(tripData.tickets)?.booking_reference)
    }
  }

  const onGroundActionClick = () => {
    if (action === "checkin") {
      if (!leadPassengerHasSeat) {
        changeSeats(pnr, segmentDirection)
        dispatch(
          tripActions.setChangeSeatsDirection(
            tripToCheckin === "depart" ? SEGMENT_DEPART : SEGMENT_RETURN,
          ),
        )
      }
      if (checkinBusy) {
        return
      }
      if (leadPassengerHasSeat) {
        checkInTrip({ pnr, segmentDirection, history })
      }
    } else {
      // ground boarding pass will always send mails through checkin route
      checkInTrip({ pnr, segmentDirection })
    }
  }

  if (trip.isBusy) {
    return <Loading small />
  }

  return (
    <div className={checkinModalClass}>
      <div className="title-container">
        <h4>{checkinModalTitle}</h4>
        {checkinModalIcon}
      </div>

      {hasFlight && (
        <TabModalGroup
          firstActive={transportType === "ground"}
          secondActive={transportType === "flight"}
          onFirstItemClick={() => setTransportType("ground")}
          onSecondItemClick={() => setTransportType("flight")}
          firstItemIcon={
            <Icon
              name="smallBus"
              width="16"
              height="16"
              withoutProps
              noAbsolute
              margin="0px 0px 0px 8px"
            />
          }
          secondItemIcon={
            <Icon
              name="airplane-up"
              width="16"
              height="17"
              withoutProps
              noAbsolute
              margin="0px 0px 0px 8px"
            />
          }
          firstItemTitle={tabFirstIcon}
          secondItemTitle={tabSecondIcon}
        />
      )}

      {transportType === "flight" ? (
        <CheckinModalInfo
          segments={tripData.segments}
          selectedCodes={getDepartureCodes(filteredFlightLegs)}
          departCityName={getFirstLegTitle(filteredFlightLegs)}
          departCityCode={getFirstLegCityCode(filteredFlightLegs)}
          arrivalCityName={getLastLegTitle(filteredFlightLegs, filterFlightLegsLength)}
          arrivalCityCode={getlastLegCityCode(filteredFlightLegs, filterFlightLegsLength)}
          departTime={getFirstLegDepartureTime(filteredFlightLegs)}
          departDate={getFirstLegDepartureDate(filteredFlightLegs)}
          arrivalTime={getlastLegArrivalTime(filteredFlightLegs, filterFlightLegsLength)}
          arrivalDate={getLastLegArrivalDate(filteredFlightLegs, filterFlightLegsLength)}
          onActionClick={onFlightActionClick}
          actionButtonIcon={flightActionIcon}
          actionButtonDisabled={onFlightActionButtonDisabled()}
          actionButtonText="Send to email"
          actionButtonType={flightActionButtonType}
          textBelowAction={getFlightTextBelowAction()}
        />
      ) : (
        <CheckinModalInfo
          onTextBelowActionClick={onTextBelowActionClick}
          segments={tripData.segments}
          selectedCodes={getDepartureCodes(filteredGroundLegs)}
          departCityName={getFirstLegTitle(filteredGroundLegs)}
          departCityCode={getFirstLegCityCode(filteredGroundLegs)}
          arrivalCityName={getLastLegTitle(filteredGroundLegs, filterGroundLegsLength)}
          arrivalCityCode={getlastLegCityCode(filteredGroundLegs, filterGroundLegsLength)}
          departTime={getFirstLegDepartureTime(filteredGroundLegs)}
          departDate={getFirstLegDepartureDate(filteredGroundLegs)}
          arrivalTime={getlastLegArrivalTime(filteredGroundLegs, filterGroundLegsLength)}
          arrivalDate={getLastLegArrivalDate(filteredGroundLegs, filterGroundLegsLength)}
          allCheckedIn={trip.checkin || tripData.segments[0]?.all_checked_in}
          onActionClick={onGroundActionClick}
          actionButtonText={groundActionText}
          actionButtonIcon={groundActionIcon}
          actionButtonDisabled={onGroundActionButtonDisabled()}
          actionButtonType={groundActionButtonType}
          textBelowAction={getGroundTextBelowAction()}
        />
      )}
    </div>
  )
}

CheckinModal.propTypes = {
  tripData: PropTypes.instanceOf(Array),
  pnr: PropTypes.instanceOf(Array),
  tripToCheckin: PropTypes.string,
  hasFlight: PropTypes.bool,
  action: PropTypes.oneOf(["checkin", "boarding"]).isRequired,
  isWebhookReady: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object),
  changeSeats: PropTypes.func,
  onTextBelowActionClick: PropTypes.func,
}

CheckinModal.defaultProps = {
  tripData: [],
  pnr: "",
  tripToCheckin: "",
  hasFlight: false,
  history: {},
  changeSeats: () => {},
  onTextBelowActionClick: () => {},
}

export default CheckinModal
