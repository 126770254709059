import "./PasswordAssistancePage.scss"

import { Button, InputFieldSet, NotificationConsumer } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"

import { ACCOUNT_REGISTER, PASSWORD_RESET, PROFILE } from "../../../constants/routes"
import accountActions from "../../../redux/account/actions"
import redirectActions from "../../../redux/redirect/actions"
import { accountSelector } from "../../../redux/selector"
import config from "../../../utils/config"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"

const PasswordAssistance = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const recoverPassword = useCallback(
    payload => dispatch(accountActions.recoverPassword(payload)),
    [dispatch],
  )

  const account = useSelector(state => accountSelector(state))
  const [email, setEmail] = useState("")
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [notify, setNotify] = useState(false)

  useEffect(() => {
    if (account.profile.id) {
      setNotify(true)
    }
  }, [])

  const onSubmit = e => {
    e.preventDefault()

    if (!email) {
      return
    }

    recoverPassword({
      email,
      is_back_office: false,
      reset_password_url: `${config.PUBLIC_URL}${PASSWORD_RESET}/`,
    })
    setIsSuccessful(true)
    dispatch(redirectActions.setRedirectURL(PROFILE))
  }

  return (
    <NotificationConsumer>
      {({ actions }) => {
        return (
          <PageLayout
            render={() => {
              if (notify) {
                actions.show({
                  type: "is-warning",
                  message: `You must log out first`,
                })
                history.push("/")
              }
              if (account.isBusy) {
                return <Loading />
              }
              if (isSuccessful && account.password.recovery_key) {
                return (
                  <div className="SectionWrapper passwordAssistance">
                    <h2>Recovering your password</h2>
                    <br />
                    <p>{`A password reset link was sent to ${email}`}</p>
                  </div>
                )
              }

              return (
                <div className="SectionWrapper passwordAssistance">
                  <h2>Password assistance</h2>
                  <p>Enter the email address registered against your account</p>
                  <PasswordForm email={email} onEmailChanged={setEmail} onSubmit={onSubmit} />
                  <br />
                  <p>
                    If you don&apos;t have an account,{" "}
                    <Link to={ACCOUNT_REGISTER}>create one now</Link>.
                  </p>
                </div>
              )
            }}
          />
        )
      }}
    </NotificationConsumer>
  )
}

export default PasswordAssistance

const PasswordForm = ({ error, email, onEmailChanged, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <InputFieldSet
        fullWidth
        inputType="email"
        inputProps={{ id: "email", name: "email" }}
        labelText="EMAIL"
        mandatory
        inputValue={email}
        onChange={e => onEmailChanged(e.target.value.trim())}
        error={error}
        margin="10px 0 20px 0"
      />
      <Button type="primary" buttonType="submit">
        Continue
      </Button>
    </form>
  )
}

PasswordForm.defaultProps = {
  error: "",
  email: "",
  onEmailChanged: () => null,
  onSubmit: () => null,
}

PasswordForm.propTypes = {
  error: PropTypes.string,
  email: PropTypes.string,
  onEmailChanged: PropTypes.func,
  onSubmit: PropTypes.func,
}
