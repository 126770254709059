import "./Breadcrumbs.scss"

import { Icon } from "ibe-components"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { reservationsSelector, tripSelector } from "../../../redux/selector"

const Breadcrumbs = ({ history, className }) => {
  const location = useLocation()
  const ref = useRef()

  const { params: bookingSearchParams } = useSelector(state => reservationsSelector(state))
  const trip = useSelector(state => tripSelector(state))

  const getRouteList = () => {
    const newRouteList = []

    newRouteList.push({ path: "/", name: "Book trip" })
    newRouteList.push({ path: "/reservations/depart", name: "Departure trip" })

    if (!bookingSearchParams.one_way) {
      newRouteList.push({ path: "/reservations/return", name: "Return trip" })
    }

    newRouteList.push({ path: "/reservations/summary", name: "Selection summary" })

    if (!trip.editTrip) {
      newRouteList.push({ path: "/passengers", name: "Passenger details" })
    }

    if (trip.breadcrumbsFlow) {
      newRouteList.push(...trip.breadcrumbsFlow)
    }

    const currentIndex = newRouteList.findIndex(item => item.path === location.pathname)

    return newRouteList.filter((item, index) => index <= currentIndex)
  }

  const routeList = getRouteList()

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollLeft = 1000
    }
  }, [routeList, ref.current])

  return (
    <div className={`breadcrumbs-wrapper ${className}`}>
      <div className="breadcrumbs-container" ref={ref}>
        {routeList.map((route, index) => {
          const isFirst = index === 0
          const isLast = index === routeList.length - 1

          return (
            <>
              <div
                className="breadcrumbs-item"
                style={{ marginLeft: isFirst && 0 }}
                onClick={() => {
                  if (route.path !== location.pathname) {
                    const backInt =
                      routeList.findIndex(item => item.path === route.path) -
                      routeList.findIndex(item => item.path === location.pathname)

                    history.go(backInt)
                  }
                }}
              >
                {route.name}
              </div>

              {!isLast && (
                <div className="icon-wrapper">
                  <Icon
                    name="arrow-forward"
                    width="16"
                    height="17"
                    withoutProps
                    noAbsolute
                    margin="0px 0px 5px 0px"
                  />
                </div>
              )}
            </>
          )
        })}
      </div>
    </div>
  )
}

Breadcrumbs.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

Breadcrumbs.defaultProps = {}

export default Breadcrumbs
