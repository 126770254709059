import React from "react"
import SchedulePage from ".."
import ScheduleHeader from "../Header"
import OurService from "../OurService"
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle"
import { ScheduleLocation } from "../Locations"
import { Schedule, ScheduleTable } from "../Schedule"
import Search from "../../HomePage/Search"

import { DuluthLocation, FargoLocation } from "./locations"
import { MSPLocationService, MSPMeta, MSPService } from "./metadata"
import LocationService from "../Service"
import DuluthData from "../Duluth/schedule"
import FargoData from "../Fargo/schedule"

const MSPSchedule = ({ history }) => {
  const goToFargo = () => {
    history.push("/fargo")
  }

  const goToDuluth = () => {
    history.push("/duluth")
  }

  return (
    <SchedulePage>
      <div className="schedule-search-form">
        <Search history={history} remoteCallSearchComponent={true} />
      </div>
      <div className="schedule-page">
        <ScheduleHeader metadata={MSPMeta.header} />

        <ModuleTitle title="Duluth (DLH)" />
        <ScheduleLocation data={DuluthLocation} onButtonClick={goToDuluth} />
        <Schedule>
          <ScheduleTable schedule={DuluthData.to} />
          <ScheduleTable schedule={DuluthData.from} />
        </Schedule>

        <ModuleTitle title="Fargo (FAR)" />
        <ScheduleLocation data={FargoLocation} onButtonClick={goToFargo} />
        <Schedule>
          <ScheduleTable schedule={FargoData.to} />
          <ScheduleTable schedule={FargoData.from} />
        </Schedule>

        <OurService service={MSPService} />

        <LocationService service={MSPLocationService} />
      </div>
    </SchedulePage>
  )
}

export default MSPSchedule
