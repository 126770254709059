import "./TripItem.scss"

import classNames from "classnames"
import {
  Button,
  CollapsibleButton,
  Icon,
  LegConnection,
  LegConnectionVertical,
  SeatPlace,
} from "ibe-components"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"

import featureFlags from "../../../constants/feature-flags"
import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../../constants/segments"
import loyaltyActions from "../../../redux/loyalty/actions"
import passengerActions from "../../../redux/passenger/actions"
import reservationActions from "../../../redux/reservations/actions"
import tripActions from "../../../redux/trip/actions"
import { toDateFormatString } from "../../../utils/common"
import {
  containsFlight,
  getFlightTicket,
  isBlackCar,
  isBlackCarAirline,
  parseSegment,
} from "../../../utils/tripUtil-util"
import TripItemModals from "../TripItemModals"

const TripItem = ({
  pnr,
  allPassengers,
  history,
  isCurrentTrip,
  departureLayoverTimes,
  returnLayoverTimes,
  tripState,
  flightReservation,
  departureData,
  returnData,
  changeSeats,
}) => {
  const [showModalState, changeShowModalState] = useState({
    isChangeOfPlansModalVisible: false,
    isEditTripModalVisible: false,
    isCancelTripModalVisible: false,
    isCancelTripConfirmationModalVisible: false,
    checkinModalVisible: false,
    boadingPassVisible: false,
  })
  const [tripToCheckin, setTripToCheckin] = useState("depart")

  const changeModalState = params =>
    changeShowModalState(prevState => ({ ...prevState, ...params }))

  const dispatch = useDispatch()

  const updateReservation = useCallback(() => dispatch(reservationActions.updateReservation()), [
    dispatch,
  ])

  const setEditTripPNR = useCallback(tripPNR => dispatch(tripActions.setEditTripPNR(tripPNR)), [
    dispatch,
  ])
  const setReservationProperty = useCallback(
    (field, value) => dispatch(reservationActions.setReservationProperty({ field, value })),
    [dispatch],
  )
  const activateLoyalty = useCallback(
    isActive => dispatch(loyaltyActions.activateLoyalty(isActive)),
    [dispatch],
  )

  const setPassengerByIndex = useCallback(passenger =>
    dispatch(passengerActions.setPassengerByIndex(passenger)),
  )

  const clearSearch = useCallback(() => dispatch(reservationActions.clearSearch()), [dispatch])

  const isCanceled = () => {
    if (tripState === 2) {
      // TODO: get from options
      return true
    }
    return false
  }
  const beginEditTrip = (
    direction,
    filterDate,
    departSegmentWithoutFlight,
    returnSegmentWithoutFlight,
  ) => {
    clearSearch()
    setEditTripPNR(pnr)
    setReservationProperty(
      "editingTripContainsFlight",
      containsFlight(departureData.segments) || containsFlight(returnData.segments),
    )

    const isLoyalty = flightReservation.is_paid_with_miles
    const departureSegment = departureData.segments
    let returnSegment
    if (returnData.segments.length > 0) {
      returnSegment = returnData.segments
    }
    const departureDate = departureSegment[0].departure_date
    let returnDate
    if (returnSegment) {
      returnDate = returnSegment[0].departure_date
    }
    const from = {
      code: departureSegment[0].depart.iata,
      name: departureSegment[0].depart.AirportName,
    }
    const to = {
      code: departureSegment[departureSegment.length - 1].arrive.iata,
      name: departureSegment[departureSegment.length - 1].arrive.AirportName,
    }

    if (direction === "depart") {
      // if user edits only depart segment
      setReservationProperty("is_edit_per_segment", true)
      setReservationProperty("editTripLessThenFilterDate", filterDate)
      setReservationProperty("fromMaxDate", filterDate)
      setReservationProperty("fromMinDate", moment())
      setReservationProperty("disableTo", true)
      setReservationProperty("dep_to", {
        code: returnSegmentWithoutFlight[0].depart.iata,
        name: returnSegmentWithoutFlight[0].depart.AirportName,
      })
      setReservationProperty("dep_from", {
        code: departSegmentWithoutFlight[0].depart.iata,
        name: departSegmentWithoutFlight[0].depart.AirportName,
      })
      setReservationProperty("disableEndDate", true)
      if (containsFlight(departureData.segments)) setReservationProperty("disableStartDate", true)
      setReservationProperty(
        "departure_date",
        `${departSegmentWithoutFlight[0].departure_date}T${departSegmentWithoutFlight[0].departure_time}`,
      )
    }
    if (direction === "return") {
      // if user edits only return segment
      setReservationProperty("is_edit_per_segment", true)
      setReservationProperty("editTripGreaterThanFilterDate", filterDate)
      setReservationProperty("fromMinDate", filterDate)
      setReservationProperty("disableFrom", true)
      setReservationProperty("disableEndDate", true)
      if (containsFlight(returnData.segments)) setReservationProperty("disableStartDate", true)
      setReservationProperty("dep_from", {
        code: departSegmentWithoutFlight[departSegmentWithoutFlight.length - 1].arrive.iata,
        name: departSegmentWithoutFlight[departSegmentWithoutFlight.length - 1].arrive.AirportName,
      })
      setReservationProperty("dep_to", {
        code: returnSegmentWithoutFlight[0].arrive.iata,
        name: returnSegmentWithoutFlight[0].arrive.AirportName,
      })
      setReservationProperty(
        "departure_date",
        `${returnSegmentWithoutFlight[0].departure_date}T${returnSegmentWithoutFlight[0].departure_time}`,
      )
    }
    if (!direction) {
      // if user edits whole trip
      setReservationProperty("departure_date", departureDate)
      if (returnDate) setReservationProperty("return_date", returnDate)
      setReservationProperty("dep_from", from)
      setReservationProperty("dep_to", to)
    }

    activateLoyalty(isLoyalty)
    setReservationProperty(
      "passengers_adults",
      allPassengers.filter(passenger => passenger.passenger_type === "AD").length,
    )
    setReservationProperty(
      "passengers_children",
      allPassengers.filter(passenger => passenger.passenger_type === "CH").length,
    )
    allPassengers.forEach((passenger, index) => {
      setPassengerByIndex({
        ...passenger,
        title: passenger.title.charAt(0).toUpperCase() + passenger.title.slice(1).toLowerCase(),
        phone: passenger.phone_number,
        index,
      })
    })
    updateReservation()
    history.push("/reservations/depart")
  }
  const isRoundTrip = () => {
    if (returnData && returnData.segments.length > 0) {
      return true
    }
    return false
  }

  const tripDepartLegContainer = classNames("departure-segment-container", {
    "trip-leg-container-black-car": isBlackCar(departureData.segments),
  })
  const tripReturnLegContainer = classNames("return-segment-container", {
    "trip-leg-container-black-car": isBlackCar(returnData.segments),
  })

  const MMBFlightLink = ((getFlightTicket(departureData.tickets) || {}).data || {}).mmb_link

  const getCheckinButtonIcon = iconName => {
    return <Icon name={iconName} withoutProps noAbsolute width="20" height="20" />
  }

  // Intentionally returning false below to prevent these buttons from rending
  // on the trip item page. Today we want to remove this functionality, but we
  // may want it again in the future to support interlining.
  const collapsibleButtonCheckInContent = way => {
    return (
      <>
        {false &&
          isCurrentTrip &&
          !isBlackCar(way === "depart" ? departureData.segments : returnData.segments) && (
            <>
              <div className="checkin-button-wrapper">
                <Button
                  className="trip-check-in-button"
                  type="primary"
                  fullWidth
                  margin="0px 16px 0px 0px"
                  icon={getCheckinButtonIcon("checkin-icon")}
                  onClick={() => {
                    changeModalState({ checkinModalVisible: true })
                    setTripToCheckin(way)
                  }}
                >
                  Check-In
                </Button>
              </div>

              <div className="boarding-button-wrapper">
                <Button
                  className="trip-check-in-button"
                  type="regular"
                  fullWidth
                  icon={getCheckinButtonIcon("boardingPass")}
                  onClick={() => {
                    changeModalState({ boadingPassVisible: true })
                    setTripToCheckin(way)
                  }}
                >
                  Boarding Pass
                </Button>
              </div>
            </>
          )}
      </>
    )
  }
  const pnrCancelContainer = classNames("pnr-cancel-container", { canceled: isCanceled() })
  return (
    <div className="trip-container">
      <div className="trip-depart-container">
        <div className="trip-depart-title">
          <div
            className={
              isCurrentTrip ? "trip-depart-title-current-trip" : "trip-depart-title-past-trip"
            }
          >
            <div className={pnrCancelContainer}>
              {isCanceled() && "Canceled "}
              {pnr}
            </div>
          </div>
        </div>
        <div className={tripDepartLegContainer}>
          <div className="trip-leg-container">
            <div className="leg-wrapper">
              <div className="leg-title">
                <span>DEPART</span>
                <span>
                  {toDateFormatString(
                    departureData.segments[0].departure_date,
                    "dddd, MMMM DD, YYYY",
                  )}
                </span>
              </div>
              <LegConnection
                myTrips
                segments={departureData.segments}
                layoverTimes={departureLayoverTimes}
              />
            </div>
          </div>

          <CollapsibleButton
            leftToggleIcon
            title={<span className="trip-additional-info">Additional Information</span>}
            rightContent={collapsibleButtonCheckInContent("depart")}
          >
            <div>
              <div className="change-seat">
                <div className="leg-connection-vertical-wrapper">
                  <LegConnectionVertical
                    segments={departureData.segments}
                    layoverTimes={departureLayoverTimes}
                    inputDateFormat="YYYY-MM-DDTHH:mm"
                  />
                </div>
                <div className="change-seat-container">
                  <div className="passengers-container">
                    <div className="titles">
                      <div className="name-title">Passenger Name</div>
                      <div className="name-title">Ticket Number</div>
                    </div>
                    {/* ========= Beginning of single passenger ========= */}
                    {allPassengers.map(passenger => {
                      const allSeats = departureData.segments.map((leg, index) => {
                        const parsedSegment = parseSegment(leg)
                        const isBlack = isBlackCarAirline(parsedSegment)
                        const isFlight = containsFlight([parsedSegment])
                        const seatCode = passenger.seats.find(seat => {
                          return seat.leg === leg.id
                        })
                        if (seatCode)
                          return {
                            code: seatCode.code,
                            disabled: isBlack || isFlight,
                            legIndex: index + 1,
                          }
                        return {
                          code: "unpopulated",
                          disabled: isBlack || isFlight,
                          legIndex: index + 1,
                        }
                      })
                      return (
                        <div className="passenger">
                          <div className="name">
                            <span>{`${passenger.first_name} ${passenger.last_name}`}</span>
                          </div>
                          <div className="ticket-number">
                            <span>{passenger.data.ticket_number}</span>
                          </div>
                        </div>
                      )
                    })}
                    {/* ========= end of single passenger ========= */}
                  </div>
                </div>
              </div>
            </div>
          </CollapsibleButton>
        </div>
      </div>
      {isRoundTrip() && (
        <>
          <div className={tripReturnLegContainer}>
            <div className="return-segment">
              <div className="leg-wrapper">
                <div className="leg-title">
                  <span>RETURN</span>
                  <span>
                    {toDateFormatString(
                      returnData.segments[0].departure_date,
                      "dddd, MMMM DD, YYYY",
                    )}
                  </span>
                </div>
                <LegConnection
                  myTrips
                  segments={returnData.segments}
                  layoverTimes={returnLayoverTimes}
                />
              </div>
            </div>
            <CollapsibleButton
              leftToggleIcon
              title={<span className="trip-additional-info">Additional Information</span>}
              rightContent={collapsibleButtonCheckInContent("return")}
            >
              <div>
                <div className="change-seat">
                  <div className="leg-connection-vertical-wrapper">
                    <LegConnectionVertical
                      segments={returnData.segments}
                      layoverTimes={returnLayoverTimes}
                      inputDateFormat="YYYY-MM-DDTHH:mm"
                    />
                  </div>
                  <div className="change-seat-container">
                    <div className="passengers-container">
                      <div className="titles">
                        <div className="name-title">Passenger Name</div>
                        <div className="name-title">Ticket Number</div>
                        {!isBlackCar(returnData.segments) && <div className="seat-title">Seat</div>}
                      </div>
                      {/* ========= Beginning of single passenger ========= */}
                      {allPassengers.map(passenger => {
                        const allSeats = returnData.segments.map((leg, index) => {
                          const parsedSegment = parseSegment(leg)
                          const isBlack = isBlackCarAirline(parsedSegment)
                          const isFlight = containsFlight([parsedSegment])
                          const seatCode = passenger.seats.find(seat => {
                            return seat.leg === leg.id
                          })
                          if (seatCode)
                            return {
                              code: seatCode.code,
                              disabled: isBlack || isFlight,
                              legIndex: index + departureData.segments.length + 1,
                            }
                          return {
                            code: "unpopulated",
                            disabled: isBlack || isFlight,
                            legIndex: departureData.segments.length + 1,
                          }
                        })
                        return (
                          <div className="passenger">
                            <div className="name">
                              <span>{`${passenger.first_name} ${passenger.last_name}`}</span>
                            </div>
                            <div className="ticket-number">
                              <span>{passenger.data.ticket_number}</span>
                            </div>
                            {!isBlackCar(returnData.segments) && (
                              <div className="seats-container">
                                <div className="seat-wrapper">
                                  {allSeats.map((seat, index) => (
                                    <div
                                      onClick={() => {
                                        if (!seat.disabled && isCurrentTrip) {
                                          changeSeats(
                                            pnr,
                                            departureData.segments.length + index + 1,
                                          )
                                          dispatch(
                                            tripActions.setChangeSeatsDirection(SEGMENT_RETURN),
                                          )
                                        }
                                      }}
                                    >
                                      {seat.disabled ? null : (
                                        <SeatPlace
                                          value={seat.code === "unpopulated" ? "X" : seat.code}
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      })}
                      {/* ========= end of single passenger ========= */}
                    </div>
                  </div>
                </div>
              </div>
            </CollapsibleButton>
          </div>
        </>
      )}
      {!isCanceled() && isCurrentTrip && featureFlags().editTripFeatureFlag && (
        <>
          {flightReservation.is_editable && (
            <div className="edit-trip-button">
              <div className="edit-trip-container">
                <div className="edit-trip-button-wrapper">
                  <div className="text-container">
                    You want to edit or cancel your trip? No problem! Follow the steps and manage
                    your booking
                  </div>
                  <Button
                    icon={<Icon name="landline-luggage" width="16" height="16" noAbsolute />}
                    type="primary"
                    onClick={() => {
                      changeModalState({ isChangeOfPlansModalVisible: true })
                    }}
                  >
                    Manage Trip
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <TripItemModals
        showModalState={showModalState}
        changeModalState={changeModalState}
        beginEditTrip={beginEditTrip}
        pnr={pnr}
        departureData={departureData}
        returnData={returnData}
        cartUuid={flightReservation.cart}
        tripToCheckin={tripToCheckin}
        isWebhookReady={MMBFlightLink}
        changeSeats={changeSeats}
      />
    </div>
  )
}

TripItem.propTypes = {
  flightReservation: PropTypes.instanceOf(Object),
  pnr: PropTypes.string.isRequired,
  allPassengers: PropTypes.instanceOf(Array).isRequired,
  segments: PropTypes.instanceOf(Array).isRequired,
  history: PropTypes.instanceOf(Object),
  isCurrentTrip: PropTypes.bool,
  departureLayoverTimes: PropTypes.instanceOf(Array),
  returnLayoverTimes: PropTypes.instanceOf(Array),
  tripState: PropTypes.number.isRequired,
  departureData: PropTypes.shape({
    segments: PropTypes.instanceOf(Array),
    passengers: PropTypes.instanceOf(Array),
    layoverTimes: PropTypes.instanceOf(Array),
    tickets: PropTypes.instanceOf(Array),
  }).isRequired,
  returnData: PropTypes.shape({
    segments: PropTypes.instanceOf(Array),
    passengers: PropTypes.instanceOf(Array),
    layoverTimes: PropTypes.instanceOf(Array),
    tickets: PropTypes.instanceOf(Array),
  }).isRequired,
  changeSeats: PropTypes.func,
}

TripItem.defaultProps = {
  flightReservation: {},
  history: {},
  isCurrentTrip: false,
  departureLayoverTimes: [],
  returnLayoverTimes: [],
  changeSeats: () => {},
}

export default TripItem
