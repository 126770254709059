import { NotificationConsumer } from "ibe-components"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import { EXPIRED_SESSION_MESSAGE } from "../../../constants/errorMessages"
import accountActions from "../../../redux/account/actions"
import { accountSelector } from "../../../redux/selector"

const Authentication = () => {
  const dispatch = useDispatch()
  const account = useSelector(state => accountSelector(state))
  const error = account.tokenError
  const [errored, setErrored] = useState("")
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    if (error) {
      dispatch(accountActions.clearTokenError())
      dispatch(accountActions.logoutUser())
      setErrored(error)
    }
  }, [error])

  useEffect(() => {
    if (errored) {
      setRedirect(true)
    }
    return () => {
      setRedirect(false)
    }
  }, [errored])

  if (redirect) {
    return (
      <NotificationConsumer>
        {({ actions }) => {
          actions.show({
            type: "is-danger",
            message: EXPIRED_SESSION_MESSAGE,
          })
          setRedirect(false)
          if (errored === EXPIRED_SESSION_MESSAGE) {
            setErrored("")
            return <Redirect to="/" />
          }
          setErrored("")
          return null
        }}
      </NotificationConsumer>
    )
  }
  return null
}

export default Authentication
