import { all, put, takeLatest } from "redux-saga/effects"

import config from "../../utils/config"
import errorActions from "../error/actions"
import actions from "./actions"

export function* registerForUpdate$({ payload }) {
  try {
    const params = {
      audience_id: config.MAILCHIMP_SUBSCRIBE_LIST_ID,
      email: payload,
    }
    const searchParams = Object.keys(params)
      .map(key => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      })
      .join("&")

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: searchParams,
    }
    const response = yield fetch(`${config.API_BASE_URL}subscribe/`, options)
    yield put(actions.registerForUpdateSuccess(response))
  } catch (e) {
    yield put(actions.registerForUpdateFail(e))
    yield put(errorActions.setError(e))
  }
}

export default function*() {
  yield all([takeLatest(actions.registerForUpdate, registerForUpdate$)])
}
