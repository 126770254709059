import "./Subscribe.scss"
import React from "react"
import ActiveCampaignForm from "../../forms/ActiveCampaign"


export default () => {
  return (
    <div className="subscribe-container">
      <div className="subscribe-inner-container">
        <h2>Subscribe today!</h2>
        <p>Don't miss out on the latest Landline news and exclusive deals.</p>
        <ActiveCampaignForm />
      </div>
    </div>
  )
}
