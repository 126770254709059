import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const clearItinerary = generateSagaActions(
  "[ITINERARY] Clear itinerary",
  "CLEAR_ITINERARY",
  "clearItinerary",
  ["FAIL", "SUCCESS"],
  createAction,
)

const fetchItinerary = generateSagaActions(
  "[ITINERARY] Fetch itinerary",
  "FETCH_ITINERARY",
  "fetchItinerary",
  ["FAIL", "SUCCESS"],
  createAction,
)

const updateItinerary = generateSagaActions(
  "[ITINERARY] Update itinerary",
  "UPDATE_ITINERARY",
  "updateItinerary",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...fetchItinerary.actionMap,
  ...fetchItinerary.actionCreatorMap,
  ...updateItinerary.actionMap,
  ...updateItinerary.actionCreatorMap,
  ...clearItinerary.actionMap,
  ...clearItinerary.actionCreatorMap,
}
