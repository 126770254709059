import React from "react";
import ModuleTitle from "../../atoms/SchedulePages/ModuleTitle";

const LocationService = ({service}) => {
    return (
        <>
        <ModuleTitle title={service.title} />
        <div className="schedule-page-location-service">
          <img src={service.image} alt="" />
          <div className="schedule-page-location-service-panel">
            <div>
            <h4>{service.subtitle}</h4>
            {service.content}
            </div>
          </div>
        </div>
        </>
    )
}

export default LocationService