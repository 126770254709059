import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const setTicketByType = generateSagaActions(
  "[TICKET] Set ticket by type",
  "SET_TICKET_BY_TYPE",
  "setTicketByType",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setTicketIndexByType = generateSagaActions(
  "[TICKET] Set ticket index by type",
  "SET_TICKET_INDEX_BY_TYPE",
  "setTicketIndexByType",
  ["FAIL", "SUCCESS"],
  createAction,
)

const clearBookingProcess = generateSagaActions(
  "[TICKET] Clear booking process",
  "RESET_BOOKING_PROCESS",
  "clearBookingProcess",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...setTicketByType.actionMap,
  ...setTicketByType.actionCreatorMap,
  ...setTicketIndexByType.actionMap,
  ...setTicketIndexByType.actionCreatorMap,
  ...clearBookingProcess.actionMap,
  ...clearBookingProcess.actionCreatorMap,
}
