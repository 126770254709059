import { Button, InputFieldSet, NotificationConsumer } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import { HOME, LOGIN, PROFILE } from "../../../constants/routes"
import accountActions from "../../../redux/account/actions"
import errorActions from "../../../redux/error/actions"
import redirectActions from "../../../redux/redirect/actions"
import { accountSelector, errorSelector } from "../../../redux/selector"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"

const PasswordReset = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const verifyPasswordRecoveryCode = useCallback(payload =>
    dispatch(accountActions.verifyPasswordRecoveryCode(payload)),
  )
  const resetPassword = useCallback(payload => dispatch(accountActions.resetPassword(payload)))
  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])
  const account = useSelector(state => accountSelector(state))
  const error = useSelector(state => errorSelector(state))
  const [formErrors, setFormErrors] = useState({})
  const [notify, setNotify] = useState(false)
  const [state, setState] = useState({
    data: {
      new_password: "",
      repeated_password: "",
    },
  })

  const { code } = match.params

  useEffect(() => {
    verifyPasswordRecoveryCode({ code })
    if (account.profile.id) {
      setNotify(true)
    }
  }, [])

  const onInputChanged = field => value => {
    setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [field]: value,
      },
    }))
  }

  const onSubmit = () => {
    const { data } = state
    resetPassword({
      code,
      data,
    })
    dispatch(redirectActions.setRedirectURL(PROFILE))
  }

  return (
    <PageLayout
      render={() => {
        return (
          <NotificationConsumer>
            {({ actions }) => {
              if (notify) {
                actions.show({
                  type: "is-warning",
                  message: `You must log out first`,
                })
                history.push("/")
              }
              if (!match.params.code) {
                return history.push(HOME)
              }
              if (account.isBusy || (error && error.isBusy)) {
                return <Loading />
              }
              if (account.password && !account.password.recovery_code_active && error) {
                actions.show({ type: "is-danger", message: "Password not changed" })
                clearError()
                return history.push(LOGIN)
              }
              if (account.password && account.password.changed) {
                actions.show({
                  type: "is-success",
                  message: "Password changed successfully",
                })
                return history.push(LOGIN)
              }
              if (error) {
                setFormErrors(error)
                clearError()
              }

              return (
                <div className="SectionWrapper">
                  <Container>
                    <h2 style={{ paddingBottom: 20 }}>Reset Password</h2>
                    <InputFieldSet
                      fullWidth
                      labelText="NEW PASSWORD"
                      mandatory
                      inputType="password"
                      inputValue={state.data.new_password}
                      onChange={e => onInputChanged("new_password")(e.target.value)}
                      error={formErrors.new_password}
                      margin="10px 0 20px 0"
                    />
                    <InputFieldSet
                      fullWidth
                      labelText="RE-ENTER PASSWORD"
                      mandatory
                      inputType="password"
                      inputValue={state.data.repeated_password}
                      onChange={e => onInputChanged("repeated_password")(e.target.value)}
                      error={formErrors.repeated_password}
                      margin="10px 0 20px 0"
                    />
                    <Button type="primary" onClick={() => onSubmit()}>
                      Submit
                    </Button>
                  </Container>
                </div>
              )
            }}
          </NotificationConsumer>
        )
      }}
    />
  )
}

export default PasswordReset

PasswordReset.propTypes = {
  match: PropTypes.instanceOf(Object),
}

PasswordReset.defaultProps = {
  match: {},
}

const Container = styled.div`
  padding: 20px;
  background-color: #fff;
`
