import actions from "./actions"
import initialState from "./initialState"

export default (state = initialState(), action) => {
  const { type, payload } = action

  switch (type) {
    case actions.CLEAR_ITINERARY_SUCCESS:
      return initialState()
    case actions.FETCH_ITINERARY:
    case actions.UPDATE_ITINERARY:
      return {
        ...state,
        isBusy: true,
      }
    case actions.FETCH_ITINERARY_SUCCESS:
      return {
        ...state,
        isBusy: false,
      }
    case actions.FETCH_ITINERARY_FAIL:
    case actions.UPDATE_ITINERARY_FAIL:
      return {
        ...state,
        isBusy: false,
        payload: null,
      }
    case actions.UPDATE_ITINERARY_SUCCESS:
      return {
        ...state,
        isBusy: false,
        payload,
      }

    default:
      return state
  }
}
