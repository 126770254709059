import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import ReactGA from "react-ga"
import { Route } from "react-router-dom"

import config from "../../../utils/config"

const GoogleAnalytics = ({ location, options }) => {
  const prevousLocationRef = useRef(location)

  const logPageChange = (pathname, search = "") => {
    const page = pathname + search
    const { location: widowLocation } = window
    ReactGA.set({
      page,
      location: `${widowLocation.origin}${page}`,
      ...options,
    })
    ReactGA.pageview(page)
  }

  useEffect(() => {
    logPageChange(location.pathname, location.search)
  }, [])

  useEffect(() => {
    const { pathname, search } = prevousLocationRef.current
    const isDifferentPathname = pathname !== location.pathname
    const isDifferentSearch = search !== location.search
    if (isDifferentPathname || isDifferentSearch) {
      logPageChange(location.pathname, location.search)
    }
  }, [location])

  return null
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  options: PropTypes.instanceOf(Object),
}

const RouteTracker = () => <Route component={GoogleAnalytics} />

const init = (options = []) => {
  const isEnabled = !!config.GA_TRACKING_ID
  if (isEnabled) {
    ReactGA.initialize(config.GA_TRACKING_ID, {
      debug: config.DEBUG,
      ...options,
    })
  }
  return isEnabled
}

export default {
  RouteTracker,
  init,
}
