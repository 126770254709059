import "./LoginPage.scss"

import { Button, InputFieldSet, NotificationConsumer, PageTitle } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  ACCOUNT_REGISTER,
  PASSWORD_RECOVERY,
  TERMS_AND_CONDITIONS,
} from "../../../constants/routes"
import accountActions from "../../../redux/account/actions"
import errorActions from "../../../redux/error/actions"
import redirectActions from "../../../redux/redirect/actions"
import { accountSelector, errorSelector } from "../../../redux/selector"
import { reduceErrors } from "../../../utils/common"
import config from "../../../utils/config"
import { validateLogin } from "../../../utils/validation"
import Loading from "../../atoms/Loading"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"

const Login = ({ history }) => {
  const dispatch = useDispatch()
  const loginUser = useCallback(params => dispatch(accountActions.loginUser(params)), [dispatch])
  const setRedirectId = useCallback(url => dispatch(redirectActions.setRedirectId(url)), [dispatch])
  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])

  const error = useSelector(state => errorSelector(state))
  const account = useSelector(state => accountSelector(state))

  const [state, setState] = useState({
    data: {
      email: "",
      password: "",
    },
    modalErrors: [],
    formErrors: [],
  })

  const handleInputChanged = field => value => {
    setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [field]: value,
      },
    }))
  }

  const validateForm = errors => {
    if (errors && Object.keys(errors).length > 0) {
      if (typeof errors === "string") {
        setState(prevState => ({ ...prevState, modalErrors: errors }))
      } else {
        setState(prevState => ({
          ...prevState,
          formErrors: errors,
          modalErrors: reduceErrors(errors),
        }))
      }
      return false
    }
    setState(prevState => ({
      ...prevState,
      formErrors: {},
      modalErrors: {},
    }))
    return true
  }

  const onSubmit = e => {
    e.preventDefault()
    const errors = validateLogin(state.data)
    if (validateForm(errors)) {
      loginUser({ email: state.data.email, password: state.data.password, history })
    }
  }
  const goToForgotPassword = () => {
    setRedirectId(true)
    history.push(PASSWORD_RECOVERY)
  }

  return (
    <NotificationConsumer>
      {({ actions }) => {
        return (
          <TabNavbarLayout
            render={() => {
              if (error && error.isBusy) {
                return <Loading />
              }

              if (error) {
                validateForm(error)
                clearError()
              }

              if (state.modalErrors) {
                actions.show({ type: "is-danger", message: state.modalErrors })
                setState(prevState => ({
                  ...prevState,
                  modalErrors: undefined,
                }))
              }

              const { data } = state
              return (
                <div className="SectionWrapper loginPage__wrapper">
                  <form onSubmit={onSubmit} autoComplete="false">
                    <PageTitle title="Login" margin="0" />
                    <div className="Input--Fields">
                      <InputFieldSet
                        inputType="email"
                        inputProps={{ id: "email", name: "email" }}
                        labelText="YOUR EMAIL"
                        margin="20px 0 20px 0"
                        fullWidth
                        inputValue={data.email}
                        onChange={e => {
                          handleInputChanged("email")(e.target.value)
                        }}
                        error={!!state.formErrors.email}
                      />
                      <InputFieldSet
                        inputType="password"
                        labelText="YOUR PASSWORD"
                        fullWidth
                        inputValue={data.password}
                        onChange={e => {
                          handleInputChanged("password")(e.target.value)
                        }}
                        error={!!state.formErrors.password}
                      />
                    </div>
                    <div className="Login--Buttons">
                      <Button fullWidth type="primary" buttonType="submit">
                        {account.isBusy ? "Please wait" : "Login"}
                      </Button>
                    </div>
                    <div className="Login--remember-me-row" onClick={goToForgotPassword}>
                      <p>Forgot Password?</p>
                    </div>
                    <div className="loginPage--legalSection">
                      By logging in you agree to the
                      <a href={`${config.LANDLINE_URL}${TERMS_AND_CONDITIONS}`}>
                        Terms and Conditions
                      </a>
                      .
                    </div>
                    <div className="loginPage--action">
                      <div className="loginPage--action--question">
                        You don&apos;t have an account?
                      </div>
                      <div className="Login--Buttons">
                        <Button
                          fullWidth
                          type="secondary"
                          onClick={() => {
                            history.push(ACCOUNT_REGISTER)
                          }}
                        >
                          Create Account
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              )
            }}
          />
        )
      }}
    </NotificationConsumer>
  )
}

export default Login

Login.propTypes = {
  history: PropTypes.instanceOf(Object),
}

Login.defaultProps = {
  history: {},
}
