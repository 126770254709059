import "./TripItemModals.scss"

// import classNames from "classnames"
import { Icon, Modal } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { Redirect, useHistory } from "react-router-dom"

import tripActions from "../../../redux/trip/actions"
import { containsFlight, getFlightTicket } from "../../../utils/tripUtil-util"
import EditTripColumn from "../../atoms/EditTripColumn"
import CancelConfirmationModalContent from "../../molecules/CancelConfirmationModalContent"
import ChangeOfPlansModalBody from "../../molecules/ChangeOfPlansModalBody"
import CheckinModal from "../CheckinModal"
import EditTripModalLayout from "../EditTripModalLayout"
import SelectSegmentToEdit from "../SelectSegmentToEdit"

const TripItemModals = ({
  showModalState,
  changeModalState,
  beginEditTrip,
  pnr,
  departureData,
  returnData,
  cartUuid,
  tripToCheckin,
  isWebhookReady,
  changeSeats,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [redirectToCancelTrip, changeRedirectToCancelTrip] = useState(false)
  const [showOnGoBack, setShowOnGoBack] = useState(true)
  const resetCancelState = useCallback(() => dispatch(tripActions.resetCancelState()), [dispatch])
  const [cancelState, setCancelState] = useState({
    segmentToCancel: undefined,
  })
  if (redirectToCancelTrip) {
    return <Redirect to={`/trips/${pnr}/cancel`} />
  }
  return (
    <div className="tripItemModals">
      <Modal
        showFooterButtons={false}
        isOpen={showModalState.isChangeOfPlansModalVisible}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        style={{ content: { padding: 0, position: "relative" } }}
        onCancel={() => changeModalState({ isChangeOfPlansModalVisible: false })}
        render={() => {
          return (
            <>
              <ChangeOfPlansModalBody
                resetCancelState={resetCancelState}
                changeModalState={changeModalState}
                oneWay={!returnData.segments.length > 0}
                beginEditTrip={beginEditTrip}
                changeRedirectToCancelTrip={changeRedirectToCancelTrip}
              />
            </>
          )
        }}
      />
      {/* =============== EDIT TRIP MODAL =============== */}
      <Modal
        showFooterButtons={false}
        isOpen={showModalState.isEditTripModalVisible}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        style={{ content: { padding: 0, position: "relative", minWidth: 310, width: "unset" } }}
        onCancel={() => changeModalState({ isEditTripModalVisible: false })}
        render={() => {
          return (
            <>
              <EditTripModalLayout
                onGoBack={() => {
                  changeModalState({
                    isChangeOfPlansModalVisible: true,
                    isEditTripModalVisible: false,
                  })
                }}
                departOrReturnContainsFlight={
                  containsFlight(departureData.segments) || containsFlight(returnData.segments)
                }
                changeModalState={changeModalState}
                action="edit"
                showModalState={showModalState}
                contents={[
                  <>
                    <EditTripColumn
                      padding="0px 32px 0px 16px"
                      title="Chat with Landline Support"
                      icon={
                        <Icon
                          name="chat-in-orange-rectangle"
                          noAbsolute
                          withoutProps
                          width="32"
                          height="32"
                        />
                      }
                    />
                    <EditTripColumn
                      padding="0px 32px 0px 16px"
                      title="Call Landline Support"
                      icon={
                        <Icon
                          name="phone-in-orange-rectangle"
                          noAbsolute
                          withoutProps
                          width="32"
                          height="32"
                        />
                      }
                    />
                  </>,
                  <SelectSegmentToEdit
                    departureData={departureData}
                    returnData={returnData}
                    onEditActionClick={beginEditTrip}
                    action="edit"
                    pnr={pnr}
                  />,
                ]}
              />
            </>
          )
        }}
      />
      {/* =============== END OF EDIT TRIP MODAL =============== */}
      {/* =============== CANCEL TRIP MODAL =============== */}
      <Modal
        showFooterButtons={false}
        isOpen={showModalState.isCancelTripModalVisible}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        style={{ content: { padding: 0, position: "relative", minWidth: 310, width: "unset" } }}
        onCancel={() => changeModalState({ isCancelTripModalVisible: false })}
        render={() => {
          return (
            <>
              <EditTripModalLayout
                onGoBack={() => {
                  changeModalState({
                    isChangeOfPlansModalVisible: true,
                    isCancelTripModalVisible: false,
                  })
                }}
                departOrReturnContainsFlight={
                  containsFlight(departureData.segments) || containsFlight(returnData.segments)
                }
                changeModalState={changeModalState}
                showModalState={showModalState}
                action="cancel"
                contents={[
                  <>
                    <EditTripColumn
                      padding="0px 32px 0px 16px"
                      title="Chat with Landline Support"
                      icon={
                        <Icon
                          name="chat-in-orange-rectangle"
                          noAbsolute
                          withoutProps
                          width="32"
                          height="32"
                        />
                      }
                    />
                    <EditTripColumn
                      padding="0px 32px 0px 16px"
                      title="Call Landline Support"
                      icon={
                        <Icon
                          name="phone-in-orange-rectangle"
                          noAbsolute
                          withoutProps
                          width="32"
                          height="32"
                        />
                      }
                    />
                  </>,
                  <SelectSegmentToEdit
                    departureData={departureData}
                    returnData={returnData}
                    onCancelActionClick={segmentToCancel => {
                      setCancelState(prevState => ({ ...prevState, segmentToCancel }))
                      changeModalState({
                        isCancelTripConfirmationModalVisible: true,
                        isCancelTripModalVisible: false,
                      })
                    }}
                    action="cancel"
                  />,
                ]}
              />
            </>
          )
        }}
      />
      {/* =============== END OF CANCEL TRIP MODAL =============== */}
      {/* =============== CANCEL TRIP CONFIRMATION MODAL =============== */}
      <Modal
        showFooterButtons={false}
        isOpen={showModalState.isCancelTripConfirmationModalVisible}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        style={{ content: { padding: 0, position: "relative", minWidth: 310, width: "unset" } }}
        onCancel={() => {
          changeModalState({ isCancelTripConfirmationModalVisible: false })
          history.push("/trips")
        }}
        render={() => {
          return (
            <>
              <EditTripModalLayout
                showOnGoBack={showOnGoBack}
                onGoBack={() => {
                  changeModalState({
                    isCancelTripConfirmationModalVisible: false,
                    isCancelTripModalVisible: true,
                  })
                }}
                changeModalState={changeModalState}
                showModalState={showModalState}
                action="cancelConfirmation"
                staticContent={
                  <CancelConfirmationModalContent
                    pnr={pnr}
                    cartUuid={cartUuid}
                    cancelState={cancelState}
                    segmentToCancel={1}
                    isRoundTrip={returnData.segments.length > 0}
                    changeModalState={changeModalState}
                    setShowOnGoBack={setShowOnGoBack}
                  />
                }
              />
            </>
          )
        }}
      />
      {/* =============== END OF CANCEL TRIP CONFIRMATION MODAL =============== */}
      {/* =============== CHECKIN MODAL =============== */}
      <Modal
        showFooterButtons={false}
        isOpen={showModalState.checkinModalVisible}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        style={{ content: { padding: 0, position: "relative", maxWidth: 664, width: "100%" } }}
        onCancel={() => {
          changeModalState({ checkinModalVisible: false })
        }}
        render={() => {
          return (
            <CheckinModal
              onTextBelowActionClick={() => {
                changeModalState({ checkinModalVisible: false, boadingPassVisible: true })
              }}
              changeSeats={changeSeats}
              pnr={pnr}
              action="checkin"
              history={history}
              tripData={tripToCheckin === "depart" ? departureData : returnData}
              tripToCheckin={tripToCheckin}
              isWebhookReady={isWebhookReady}
              hasFlight={
                tripToCheckin === "depart"
                  ? !!getFlightTicket(departureData.tickets)
                  : !!getFlightTicket(returnData.tickets)
              }
            />
          )
        }}
      />
      {/* =============== END OF CHECKIN MODAL =============== */}

      {/* =============== BOARDING MODAL =============== */}
      <Modal
        showFooterButtons={false}
        isOpen={showModalState.boadingPassVisible}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        style={{ content: { padding: 0, position: "relative", maxWidth: 664, width: "100%" } }}
        onCancel={() => {
          changeModalState({ boadingPassVisible: false })
        }}
        render={() => {
          return (
            <CheckinModal
              pnr={pnr}
              action="boarding"
              tripData={tripToCheckin === "depart" ? departureData : returnData}
              tripToCheckin={tripToCheckin}
              isWebhookReady={isWebhookReady}
              hasFlight={
                tripToCheckin === "depart"
                  ? !!getFlightTicket(departureData.tickets)
                  : !!getFlightTicket(returnData.tickets)
              }
            />
          )
        }}
      />
      {/* =============== END OF BOARDING MODAL =============== */}
    </div>
  )
}

TripItemModals.propTypes = {
  showModalState: PropTypes.bool.isRequired,
  changeModalState: PropTypes.func.isRequired,
  beginEditTrip: PropTypes.func.isRequired,
  pnr: PropTypes.string.isRequired,
  departureData: PropTypes.instanceOf(Object),
  returnData: PropTypes.instanceOf(Object),
  cartUuid: PropTypes.string.isRequired,
  tripToCheckin: PropTypes.string,
  isWebhookReady: PropTypes.bool.isRequired,
  changeSeats: PropTypes.func,
}

TripItemModals.defaultProps = {
  departureData: {},
  returnData: {},
  tripToCheckin: "",
  changeSeats: () => {},
}

export default TripItemModals
