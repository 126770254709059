import { ADULT_MIN_AGE, CHILD_MAX_AGE, CHILD_MIN_AGE } from "./passenger-age"

export const birthDayMaxDate = (type, tripDate) => {
  const birthDayMax = new Date(tripDate)
  if (type === "Adult") {
    birthDayMax.setDate(birthDayMax.getDate() + 1)
    birthDayMax.setFullYear(birthDayMax.getFullYear() - ADULT_MIN_AGE)
  }
  if (type === "Child") {
    birthDayMax.setDate(birthDayMax.getDate())
    birthDayMax.setFullYear(birthDayMax.getFullYear() - CHILD_MIN_AGE)
  } else {
    birthDayMax.setDate(birthDayMax.getDate() - 1)
  }
  return birthDayMax
}

export const birthDayMinDate = (type, tripDate) => {
  const birthDayMin = new Date(tripDate)
  if (type === "Child") {
    birthDayMin.setDate(birthDayMin.getDate() + 1)
    birthDayMin.setFullYear(birthDayMin.getFullYear() - CHILD_MAX_AGE - 1)
    return birthDayMin
  }
  return undefined
}

export default { birthDayMaxDate, birthDayMinDate }
