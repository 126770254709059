import { all } from "redux-saga/effects"

import accountSaga from "./account/sagas"
import cartSaga from "./cart/sagas"
import errorSaga from "./error/sagas"
import instructionSaga from "./instruction/sagas"
import itinerarySaga from "./itinerary/sagas"
import loyaltySaga from "./loyalty/sagas"
import newsletterSaga from "./newsletter/sagas"
import passengerSaga from "./passenger/sagas"
import redirectSaga from "./redirect/sagas"
import reservationsSaga from "./reservations/sagas"
import settingsSaga from "./settings/sagas"
import ticketSaga from "./ticket/sagas"
import tripSaga from "./trip/sagas"

// NOTE(sb): plop reduxGen importing redux saga

export default function*() {
  yield all([
    reservationsSaga(),
    ticketSaga(),
    itinerarySaga(),
    accountSaga(),
    instructionSaga(),
    tripSaga(),
    passengerSaga(),
    errorSaga(),
    redirectSaga(),
    newsletterSaga(),
    loyaltySaga(),
    cartSaga(),
    settingsSaga(),
    // NOTE(sb): plop reduxGen implement redux saga
  ])
}
