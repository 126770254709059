import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const getSettingsData = generateSagaActions(
  "[SETTINGS] Get settings data",
  "GET_SETTINGS_DATA",
  "getSettingsData",
  ["FAIL", "SUCCESS"],
  createAction,
)

const getAllCarouselImages = generateSagaActions(
  "[SETTINGS] Get carousel images",
  "GET_CAROUSEL_IMAGES",
  "getAllCarouselImages",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...getSettingsData.actionMap,
  ...getSettingsData.actionCreatorMap,
  ...getAllCarouselImages.actionMap,
  ...getAllCarouselImages.actionCreatorMap,
}
