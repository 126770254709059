import "./LoyaltyBuyPointsConfirmationPage.scss"

import classNames from "classnames"
import { Button, LoyaltyPointsBalance, Section } from "ibe-components"
import React from "react"
import { useSelector } from "react-redux"
import { Redirect, useHistory } from "react-router-dom"

import { BUY_MILES } from "../../../constants/routes"
import { accountSelector, loyaltySelector, redirectSelector } from "../../../redux/selector"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"

const LoyaltyBuyPointsConfirmationPage = () => {
  const loyaltyBuyPointsConfirmationPageClass = classNames(
    "loyalty-buy-points-confirmation-page-wrapper",
  )
  const loyalty = useSelector(state => loyaltySelector(state))
  const redirect = useSelector(state => redirectSelector(state))

  const { isAuthenticated } = useSelector(state => accountSelector(state))

  const history = useHistory()

  const doneRedirect = () => {
    const { url } = redirect
    history.push(url || "/")
  }

  return (
    <PageLayout
      render={() => {
        if (loyalty.isBusy) {
          return <Loading />
        }
        if (!isAuthenticated) {
          return <Redirect to="/login" />
        }
        if (!loyalty.payload.price_of_purchased_points) {
          return <Redirect to={BUY_MILES} />
        }

        return (
          <div className={loyaltyBuyPointsConfirmationPageClass}>
            <div className="SectionWrapper">
              <Section title="Confirmation" type="loyalty" canGoBack={false} />
              <div className="loyalty-buy-points-confirmation-page-content">
                <div className="loyalty-buy-points-confirmation-page-title">
                  Thank you for your purchase!
                </div>
                <div className="loyalty-buy-points-confirmation-page-LoyaltyPointsBalance-container">
                  <LoyaltyPointsBalance
                    currentPoints={loyalty.pointsBeforePayment}
                    fees={loyalty.payload.price_of_purchased_points}
                    taxes={0}
                    pointsToBuy={Math.round(loyalty.payload.purchased_points)}
                    total={loyalty.payload.price_of_purchased_points}
                    currency="USD"
                  />
                </div>
                <Button onClick={doneRedirect} type="regular" fullWidth margin="0px 0px 20px 0px">
                  Done
                </Button>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default LoyaltyBuyPointsConfirmationPage
