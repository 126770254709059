import "./SelectSegmentToEdit.scss"

import classNames from "classnames"
import { Icon } from "ibe-components"
import moment from "moment"
import PropTypes from "prop-types"
import React from "react"

import {
  containsBus,
  findExpressAtHomeSegment,
  isBlackCar,
  isExpress,
} from "../../../utils/tripUtil-util"
import EditTripColumn from "../../atoms/EditTripColumn"

const SelectSegmentToEdit = ({
  departureData,
  returnData,
  action,
  onEditActionClick,
  onCancelActionClick,
}) => {
  const getLandlineSegmentFromLegs = data =>
    data.segments.filter(
      leg =>
        isBlackCar([leg]) ||
        containsBus([leg]) ||
        isExpress([leg]) ||
        findExpressAtHomeSegment([leg]),
    )
  const departureSegment = getLandlineSegmentFromLegs(departureData)
  const returnSegment = getLandlineSegmentFromLegs(returnData)

  const onEditActionClicked = direction => {
    if (direction === "depart") {
      onEditActionClick(
        direction,
        moment(`${returnSegment[0].departure_date}T${returnSegment[0].departure_time}`),
        departureSegment,
        returnSegment,
      )
    } else {
      onEditActionClick(
        direction,
        moment(
          `${departureSegment[departureSegment.length - 1].arrival_date}T${
            departureSegment[departureSegment.length - 1].arrival_time
          }`,
        ),
        departureSegment,
        returnSegment,
      )
    }
  }

  const getDescriptionForSegment = way => {
    let departureTime
    let arrivalTime
    if (way === "depart") {
      departureTime = moment(
        `${departureData.segments[0].departure_date}T${departureData.segments[0].departure_time}`,
      )
      arrivalTime = moment(
        `${departureData.segments[departureData.segments.length - 1].arrival_date}T${
          departureData.segments[departureData.segments.length - 1].arrival_time
        }`,
      )

      return `${departureTime.format("hh:mm a")} - ${arrivalTime.format(
        "hh:mm a",
      )}, ${departureTime.format("dddd, MMMM DD, YYYY")}`
    }
    departureTime = moment(
      `${returnData.segments[0].departure_date}T${returnData.segments[0].departure_time}`,
    )
    arrivalTime = moment(
      `${returnData.segments[returnData.segments.length - 1].arrival_date}T${
        returnData.segments[returnData.segments.length - 1].arrival_time
      }`,
    )

    return `${departureTime.format("hh:mm a")} - ${arrivalTime.format(
      "hh:mm a",
    )}, ${departureTime.format("dddd, MMMM DD, YYYY")}`
  }

  const getDescription = () => {
    if (returnData.segments.length > 0) {
      return `${departureData.segments[0].depart.iata} - ${
        departureData.segments[departureData.segments.length - 1].arrive.iata
      }, ${returnData.segments[0].depart.iata} - ${
        returnData.segments[returnData.segments.length - 1].arrive.iata
      }`
    }
    if (action === "cancel") {
      return getDescriptionForSegment("depart")
    }
    return `${departureData.segments[0].depart.iata} - ${
      departureData.segments[departureData.segments.length - 1].arrive.iata
    }, `
  }

  const selectSegmentToEditClass = classNames("select-segment-to-edit-container")
  return (
    <div className={selectSegmentToEditClass}>
      {returnSegment.length > 0 && (
        <EditTripColumn
          title="Round Trip"
          description={getDescription()}
          action={action}
          onActionClick={() => {
            if (action === "edit") {
              onEditActionClick("", null)
            } else {
              onCancelActionClick(undefined)
            }
          }}
          icon={
            <Icon
              name="cross-arrows-orange-rectangle"
              width="32"
              height="32"
              withoutProps
              noAbsolute
            />
          }
        />
      )}

      <EditTripColumn
        key={`${departureSegment[0].departure_code} - ${
          departureSegment[departureSegment.length - 1].arrival_code
        }`}
        title={`${departureSegment[0].departure} - ${
          departureSegment[departureSegment.length - 1].arrival
        }`}
        middleColumn="DEPARTURE TRIP"
        description={getDescriptionForSegment("depart")}
        action={action}
        onActionClick={() => {
          if (action === "edit") {
            if (returnSegment.length > 0) {
              onEditActionClicked("depart")
            } else {
              onEditActionClick("", null)
            }
          } else {
            onCancelActionClick(1)
          }
        }}
        icon={
          <>
            {containsBus([departureSegment[0]]) && (
              <Icon name="bus-in-orange-rectangle" width="32" height="32" withoutProps noAbsolute />
            )}
            {isBlackCar([departureSegment[0]]) && (
              <Icon
                name="select-in-orange-rectangle"
                width="32"
                height="32"
                withoutProps
                noAbsolute
              />
            )}
            {(isExpress([departureSegment[0]]) ||
              findExpressAtHomeSegment([departureSegment[0]])) && (
              <Icon
                name="express-in-orange-rectangle"
                width="32"
                height="32"
                withoutProps
                noAbsolute
              />
            )}
          </>
        }
      />
      {returnSegment.length > 0 && (
        <EditTripColumn
          key={`${returnSegment[0].departure_code} - ${
            returnSegment[returnSegment.length - 1].arrival_code
          }`}
          title={`${returnSegment[0].departure} - ${
            returnSegment[returnSegment.length - 1].arrival
          }`}
          middleColumn="RETURN TRIP"
          description={getDescriptionForSegment("return")}
          action={action}
          onActionClick={() => {
            if (action === "edit") {
              onEditActionClicked("return")
            } else {
              onCancelActionClick(2)
            }
          }}
          icon={
            <>
              {containsBus([returnSegment[0]]) && (
                <Icon
                  name="bus-in-orange-rectangle"
                  width="32"
                  height="32"
                  withoutProps
                  noAbsolute
                />
              )}
              {isBlackCar([returnSegment[0]]) && (
                <Icon
                  name="select-in-orange-rectangle"
                  width="32"
                  height="32"
                  withoutProps
                  noAbsolute
                />
              )}
              {(isExpress([returnSegment[0]]) || findExpressAtHomeSegment([returnSegment[0]])) && (
                <Icon
                  name="express-in-orange-rectangle"
                  width="32"
                  height="32"
                  withoutProps
                  noAbsolute
                />
              )}
            </>
          }
        />
      )}
    </div>
  )
}

SelectSegmentToEdit.propTypes = {
  departureData: PropTypes.instanceOf(Object),
  returnData: PropTypes.instanceOf(Object),
  action: PropTypes.string.isRequired,
  onEditActionClick: PropTypes.func,
  onCancelActionClick: PropTypes.func,
}

SelectSegmentToEdit.defaultProps = {
  departureData: {},
  returnData: {},
  onEditActionClick: () => {},
  onCancelActionClick: () => {},
}

export default SelectSegmentToEdit
