/* eslint-disable */
// actions from "./actions" must be before /utils/request !!!

import { all, call, put, select, takeLatest } from "redux-saga/effects"

import actions from "./actions"
import errorActions from "../error/actions"
import cartActions from "../cart/actions"
import reservationActions from "../reservations/actions"
import { httpGet, httpPut } from "../../utils/request"
import { goToNextSegment } from "../../utils/tripUtil-util"
import appUtil from "../../utils/app-util"
import { setBookingFlow$ } from "../trip/sagas"

export function* fetchItinerary$() {
  try {
    const reservations = select(state => state.reservations)
    const { items } = yield reservations
    const response = yield httpGet(`bookings/${items.uuid}/itinerary/`)
    yield put(actions.fetchItinerarySuccess(response))
  } catch (error) {
    yield put(actions.fetchItineraryFail())
    yield put(errorActions.setError(error))
  }
}

function cleanFlightSoldOutTickets(flight) {
  return flight.map(f => {
    return {
      ...f,
      tickets: f.tickets.filter(t => parseInt(t.flight_available) !== 0),
    }
  })
}

export function* updateItinerary$({ payload: history }) {
  try {
    const reservations = select(state => state.reservations)
    const trip = select(state => state.trip)
    const { items, params } = yield reservations

    let ticket = select(state => state.ticket)

    let { departureTicket, returnTicket } = yield ticket
    let { editTrip } = yield trip

    let p = {
      departure_flight: departureTicket,
      uuid: items.uuid,
      next: items.next,
      pnum: params.passengers_adults + params.passengers_children + params.passengers_infants,
      bnum: 0,
      passengers_adults: params.passengers_adults,
      passengers_children: params.passengers_children,
      passengers_infants: params.passengers_infants,
      is_edit: editTrip !== "",
    }

    if (!params.one_way) {
      p = {
        ...p,
        return_flight: returnTicket,
      }
    }

    // Filter sold out tickets
    p.departure_flight = cleanFlightSoldOutTickets(p.departure_flight)
    if (p.return_flight) {
      p.return_flight = cleanFlightSoldOutTickets(p.return_flight)
    }

    if (editTrip !== "") {
      yield put(cartActions.assignPreviousCart(editTrip))
    }
    const response = yield httpPut(`bookings/${items.uuid}/itinerary/`, p)

    yield put(reservationActions.setShouldCheckHeartbeat(true))

    appUtil.checkHeartbeatInterval(history)

    yield put(actions.updateItinerarySuccess(response))

    yield call(setBookingFlow$)

    // if edit
    if (editTrip !== "") {
      goToNextSegment(history)
    } else {
      history.push("/passengers")
    }
  } catch (error) {
    yield put(actions.updateItineraryFail())
    yield put(errorActions.setError(error))
  }
}

export function* clearItinerary$() {
  try {
    yield put(actions.clearItinerarySuccess())
  } catch (error) {
    yield put(actions.clearItineraryFail())
    yield put(errorActions.setError(error))
  }
}

export function fetchSeatsByType(type = "departure_seats", itinerary) {
  const { payload } = itinerary
  if (!payload) {
    return []
  }
  if (!payload[type]) {
    return []
  }
  return payload[type].map(x => x.seats)
}

export function fetchReservedSeats(passenger, totalPassengers, seatType) {
  let reservedSeats = []
  for (let i = 0; i < totalPassengers; i++) {
    if (passenger[i]) {
      if (passenger[i][seatType] && passenger[i][seatType].length > 0) {
        reservedSeats = [...reservedSeats, passenger[i][seatType]]
      }
    }
  }
  return reservedSeats
}

export function findReservedSeats(passenger, totalPassengers, seatType, seatIndex) {
  let reservedSeats = []
  for (let i = 0; i < totalPassengers; i++) {
    if (passenger[i] && passenger[i][seatType] && passenger[i][seatType]) {
      const currentSeat = passenger[i][seatType].find(({ index }) => index === seatIndex)
      if (currentSeat) {
        reservedSeats = [...reservedSeats, currentSeat]
      }
    }
  }
  return reservedSeats
}

export default function*() {
  yield all([
    takeLatest(actions.clearItinerary, clearItinerary$),
    takeLatest(actions.fetchItinerary, fetchItinerary$),
    takeLatest(actions.updateItinerary, updateItinerary$),
  ])
}
