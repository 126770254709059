import "./TripItemsSection.scss"

import { Loading } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"
import InfiniteScroll from "react-infinite-scroller"

import tripUtil, { getVidecomPNR, getVidecomState } from "../../../utils/tripUtil-util"
import TripItem from "../TripItem"

const TripItemsSection = ({
  flights,
  changeSeats,
  history,
  isCurrentTrip,
  loadMore,
  pastTripsOffset,
  upcomingTripsOffset,
  hasMore,
}) => {
  if (!flights) {
    return null
  }

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={() => {
        loadMore(isCurrentTrip, pastTripsOffset, upcomingTripsOffset)
      }}
      hasMore={!!hasMore}
      loader={<Loading small />}
    >
      <>
        {flights.map(flight => {
          const departureData = tripUtil.getTripDirection(flight, 0)
          const returnData = tripUtil.getTripDirection(flight, 1)

          return (
            <TripItem
              departureData={departureData}
              returnData={returnData}
              flightReservation={flight}
              departureLayoverTimes={departureData.layoverTimes}
              returnLayoverTimes={returnData.layoverTimes}
              tripState={getVidecomState(flight.tickets)}
              pnr={getVidecomPNR(flight.tickets)}
              allPassengers={departureData.passengers}
              changeSeats={changeSeats}
              history={history}
              isCurrentTrip={isCurrentTrip}
            />
          )
        })}
      </>
    </InfiniteScroll>
  )
}

TripItemsSection.propTypes = {
  isCurrentTrip: PropTypes.bool.isRequired,
  flights: PropTypes.instanceOf(Array).isRequired,
  changeSeats: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  loadMore: PropTypes.func.isRequired,
  pastTripsOffset: PropTypes.number.isRequired,
  upcomingTripsOffset: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
}

export default TripItemsSection
