import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const fetchInstruction = generateSagaActions(
  "[AIRPORT] Fetch instructions",
  "FETCH_INSTRUCTION",
  "fetchInstruction",
  ["FAIL", "SUCCESS"],
  createAction,
)
const fetchDoor2DoorService = generateSagaActions(
  "[AIRPORT] Fetch door2DoorService",
  "FETCH_DOOR2DOORSERVICE",
  "fetchDoor2DoorService",
  ["FAIL", "SUCCESS"],
  createAction,
)
const clearDoor2DoorService = generateSagaActions(
  "[AIRPORT] Clear door2Doorservice",
  "CLEAR_DOOR2DOORSERVICE",
  "clearDoor2DoorService",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...fetchInstruction.actionMap,
  ...fetchInstruction.actionCreatorMap,
  ...fetchDoor2DoorService.actionMap,
  ...fetchDoor2DoorService.actionCreatorMap,
  ...clearDoor2DoorService.actionMap,
  ...clearDoor2DoorService.actionCreatorMap,
}
