import "./CheckinModalInfo.scss"

import classNames from "classnames"
import { Button, Icon, LegConnection } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"

const CheckinModalInfo = ({
  segments,
  selectedCodes,
  departCityName,
  departCityCode,
  arrivalCityName,
  arrivalCityCode,
  departTime,
  departDate,
  arrivalTime,
  arrivalDate,
  onActionClick,
  textBelowAction,
  actionButtonType,
  actionButtonIcon,
  actionButtonText,
  actionButtonDisabled,
  onTextBelowActionClick,
}) => {
  const textBelowActionClickable = textBelowAction === "View your boarding pass"
  const checkinModalInfoClass = classNames("checkin-modal-info-container")
  const textBelowActionStyle = classNames("text-below-action", {
    clickable: textBelowActionClickable,
  })

  return (
    <div className={checkinModalInfoClass}>
      <LegConnection
        segments={segments}
        showLegConnectionTime={false}
        showLayoverTimes={false}
        showFullCityNames={false}
        selectedCodes={selectedCodes}
        showHeader={false}
      />

      <div className="city-name-container">
        <div className="depart">
          <h4>{departCityCode}</h4>
          <span>{departCityName}</span>
          <span>{departTime}</span>
          <span>{departDate}</span>
        </div>
        {<Icon name="new-arrow-right" width="24" height="24" withoutProps noAbsolute />}
        <div className="return">
          <h4>{arrivalCityCode}</h4>
          <span>{arrivalCityName}</span>
          <span>{arrivalTime}</span>
          <span>{arrivalDate}</span>
        </div>
      </div>
      <div className="checkin-button-container">
        <Button
          buttonType="button"
          type={actionButtonType}
          onClick={onActionClick}
          icon={actionButtonIcon}
          fullWidth
          disabled={actionButtonDisabled}
        >
          {actionButtonText}
        </Button>
      </div>
      {
        <div
          className={textBelowActionStyle}
          role="button"
          tabIndex={0}
          onClick={textBelowActionClickable && onTextBelowActionClick}
        >
          {textBelowAction}
        </div>
      }
    </div>
  )
}

CheckinModalInfo.propTypes = {
  segments: PropTypes.instanceOf(Array),
  selectedCodes: PropTypes.instanceOf(Array),
  departCityName: PropTypes.string,
  departCityCode: PropTypes.string,
  arrivalCityName: PropTypes.string,
  arrivalCityCode: PropTypes.string,
  departTime: PropTypes.string,
  departDate: PropTypes.string,
  arrivalTime: PropTypes.string,
  arrivalDate: PropTypes.string,
  onActionClick: PropTypes.func,
  actionButtonType: PropTypes.string.isRequired,
  actionButtonIcon: PropTypes.instanceOf(PropTypes.node),
  actionButtonText: PropTypes.string.isRequired,
  textBelowAction: PropTypes.string,
  actionButtonDisabled: PropTypes.bool,
  onTextBelowActionClick: PropTypes.func,
}

CheckinModalInfo.defaultProps = {
  segments: [],
  selectedCodes: [],
  departCityName: "",
  departCityCode: "",
  arrivalCityName: "",
  arrivalCityCode: "",
  departTime: "",
  departDate: "",
  arrivalTime: "",
  arrivalDate: "",
  onActionClick: () => {},
  actionButtonIcon: null,
  textBelowAction: "",
  actionButtonDisabled: false,
  onTextBelowActionClick: () => {},
}

export default CheckinModalInfo
