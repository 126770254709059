import React from "react";

const FAQBox = () => {
    return (
        <div className="schedule-faq">
            <div className="schedule-faq-box">
                <h4>Have questions? <a href="../faq" target="_blank" rel="noopener noreferrer">Explore our FAQ</a></h4>
                <p>Contact our team at 1-888-428-1149 via text or chat with us.</p>
            </div>
        </div>
    )
}

export default FAQBox