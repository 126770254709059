import PropTypes from "prop-types"
import React, { useCallback, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useDispatch } from "react-redux"

import redirectActions from "../../../redux/redirect/actions"
import reservationActions from "../../../redux/reservations/actions"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"
import Search from "./Search"

const Home = ({ history }) => {
  const dispatch = useDispatch()

  const setRedirectURL = useCallback(url => dispatch(redirectActions.setRedirectURL(url)), [
    dispatch,
  ])
  const clearSearch = useCallback(() => dispatch(reservationActions.clearSearch()), [dispatch])

  useEffect(() => {
    setRedirectURL("/")
    clearSearch()
  }, [])

  return (
    <div className="home-page">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.landline.com"
          data-baseprotocol="https:"
          data-basehost="www.landline.com"
        />
      </Helmet>
      <TabNavbarLayout pageLayoutBackground="#FFF" render={() => <Search history={history} />} />
    </div>
  )
}

export default Home

Home.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}
