import "./MileMarkerAccrualLogPage.scss"

import classNames from "classnames"
import { AccrualLogList } from "ibe-components"
import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import { BUY_MILES, MILE_ACCRUAL_LOG } from "../../../constants/routes"
import loyaltyActions from "../../../redux/loyalty/actions"
import { accountSelector, loyaltySelector } from "../../../redux/selector"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import PageLayout from "../../layouts/PageLayout"
import { TabNavbar } from "../../organisms/Navbar"

const MileMarkerAccrualLogPage = () => {
  const dispatch = useDispatch()

  const mileMarkerAccrualLogPageClass = classNames("mile-marker-accrual-log-page-container")
  const tabItems = [
    {
      to: BUY_MILES,
      matches: [BUY_MILES],
      title: "Buy miles",
    },
    {
      to: MILE_ACCRUAL_LOG,
      matches: [MILE_ACCRUAL_LOG],
      title: "Mile History",
    },
  ]
  const { isAuthenticated } = useSelector(state => accountSelector(state))
  const loyalty = useSelector(state => loyaltySelector(state))

  const { accrualLog } = loyalty
  const getMileMarkerAccrualLog = useCallback(() =>
    dispatch(loyaltyActions.getMileMarkerAccrualLog(), [dispatch]),
  )
  useEffect(() => {
    getMileMarkerAccrualLog()
  }, [])

  return (
    <ErrorBoundary>
      <PageLayout
        render={() => {
          if (!isAuthenticated) {
            return <Redirect to="/login" />
          }

          return (
            <div className={mileMarkerAccrualLogPageClass}>
              <TabNavbar items={tabItems} />
              <div className="SectionWrapper">
                <div className="title-container">
                  <div className="title">YOUR milemarkers NUMBER</div>
                  <div className="id">#{((loyalty || {}).wallet || {}).loyalty_number}</div>
                </div>
                <div className="log-container">
                  <AccrualLogList accrualList={accrualLog} />
                </div>
              </div>
            </div>
          )
        }}
      />
    </ErrorBoundary>
  )
}

MileMarkerAccrualLogPage.propTypes = {}

MileMarkerAccrualLogPage.defaultProps = {}

export default MileMarkerAccrualLogPage
