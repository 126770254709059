import React from "react"
import SchedulePage from "../SchedulePages"
import ScheduleHeader from "../SchedulePages/Header"

import { ChartersMeta, OurFleet, OurTeam, CustomerTestimonialImages, CharterService, CharterServiceBullets } from "./metadata"
import ModuleTitle from "../../atoms/SchedulePages/ModuleTitle"
import "./Charters.scss"

const ChartersPage = () => {

  const goToQuotePage = () => {
    window.location.href = "https://landline.com/charters-quote/"
  }

  return (
    <SchedulePage>
      <div className="schedule-page">
        <ScheduleHeader metadata={ChartersMeta.header} />
        <button className="charters-page-quote" onClick={goToQuotePage}>Get a Quote Today!</button>

        <ModuleTitle title="Charter Service" />
        <p className="charter-service-main-headline">{CharterService.headline}</p>
        <ChartersMainModule data={CharterService} />

        <ModuleTitle title={OurFleet.headline} />
        <ChartersModule data={OurFleet} />

        <ModuleTitle title={OurTeam.headline} />
        <ChartersModule data={OurTeam} />

        <ModuleTitle title="Customer Testimonials" />
        <CustomerTestimonials images={CustomerTestimonialImages} />
      </div>
    </SchedulePage>
  )
}

const CustomerTestimonials = ({ images }) => {
    return (
        <div className="charter-page-testimonials">
            {images.map((image) => {
                return <img className="charter-testimonial-image" src={image} alt="" />
            })}
        </div>
    )
}

const ChartersMainModule = ({ data }) => {
  return (
      <div className="charters-page-module">
          <img src={data.image} alt="" />
          <div className="charters-page-module-panel">
              {data.title ? <h4>{data.title}</h4> : null}
              <p>{data.description}</p>
              <h4>{data.bullets.subtitle}</h4>
              <ul className="charters-page-main-bullets">
                {data.bullets.items.map((item) => {
                  return <li>{item}</li>
                })}
              </ul>
          </div>
      </div>
  )
}

const ChartersModule = ({ data }) => {
  return (
      <div className="charters-page-module">
          <img src={data.image} alt="" />
          <div className="charters-page-module-panel">
              {data.title ? <h4>{data.title}</h4> : null}
              <p>{data.content}</p>
              <p>{data.extra}</p>
          </div>
      </div>
  )
}

export default ChartersPage
