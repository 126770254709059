import "./Promotion.scss"

import React from "react"

import employee from "../../../media/employee.png"
import luggagecheck from "../../../media/luggagecheck-1.png"
import money from "../../../media/money.png"
import stars from "../../../media/star.png"

const Promotion = () => {
  return (
    <div className="Wrapper--Promotion">
      <div className="Row--Promotion">
        <div className="Quater--Promotion">
          <div>
            <img src={stars} alt="start" />
          </div>
          <h3>Comfort</h3>
          <div className="promotion-separator" />
          <div className="info--wrapper">
            <p>Our vehicles are uniquely designed to make travel more relaxing and comfortable</p>
          </div>
        </div>
        <div className="Quater--Promotion">
          <div>
            <img src={luggagecheck} alt="luggagecheck" />
          </div>
          <h3>Convenience</h3>
          <div className="promotion-separator" />
          <div className="info--wrapper">
            <p>
              Our nonstop service to/from MSP in Minnesota and DEN in Colorado is fast, easy, and
              stress free
            </p>
          </div>
        </div>
        <div className="Quater--Promotion">
          <div>
            <img src={money} alt="money" />
          </div>
          <h3>Value</h3>
          <div className="promotion-separator" />
          <div className="info--wrapper">
            <p>Landline offers travel without the high cost</p>
          </div>
        </div>
        <div className="Quater--Promotion">
          <div>
            <img src={employee} alt="employee" />
          </div>
          <h3>Service</h3>
          <div className="promotion-separator" />
          <div className="info--wrapper">
            <p>
              Friendly agents at pick up / drop off locations are there to make your trip a success
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Promotion
