import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const clearPassengers = generateSagaActions(
  "[PASSENGER] Clear passengers",
  "CLEAR_PASSENGERS",
  "clearPassengers",
  ["FAIL", "SUCCESS"],
  createAction,
)
const updatePassengers = generateSagaActions(
  "[PASSENGER] Update passengers",
  "UPDATE_PASSENGERS",
  "updatePassengers",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setLeadPassenger = generateSagaActions(
  "[PASSENGER] Set lead passenger",
  "SET_LEAD_PASSENGER",
  "setLeadPassenger",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setPassengerByIndex = generateSagaActions(
  "[PASSENGER] Set passenger by index",
  "SET_PASSENGER_BY_INDEX",
  "setPassengerByIndex",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setCurrentPassenger = generateSagaActions(
  "[PASSENGER] Set current passenger",
  "SET_CURRENT_PASSENGER",
  "setCurrentPassenger",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setSeatStatus = generateSagaActions(
  "[PASSENGER] Set seat status",
  "SET_SEAT_STATUS",
  "setSeatStatus",
  ["FAIL", "SUCCESS"],
  createAction,
)

const clearPassengerSeat = generateSagaActions(
  "[PASSENGER] Clear passenger seat",
  "CLEAR_PASSENGER_SEAT",
  "clearPassengerSeat",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setPassengerProperty = generateSagaActions(
  "[PASSENGER] Set passenger property",
  "SET_PASSENGER_PROPERTY",
  "setPassengerProperty",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setPassengerDepartureSeat = generateSagaActions(
  "[PASSENGER] Set passenger departure seat",
  "SET_PASSENGER_DEPARTURE_SEAT",
  "setPassengerDepartureSeat",
  ["SUCCESS"],
  createAction,
)

const setPassengerReturnSeat = generateSagaActions(
  "[PASSENGER] Set passenger return seat",
  "SET_PASSENGER_RETURN_SEAT",
  "setPassengerReturnSeat",
  ["SUCCESS"],
  createAction,
)

const setPassengerSeat = generateSagaActions(
  "[PASSENGER] Set passenger seat",
  "SET_PASSENGER_SEAT",
  "setPassengerSeat",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...clearPassengers.actionMap,
  ...clearPassengers.actionCreatorMap,
  ...updatePassengers.actionMap,
  ...updatePassengers.actionCreatorMap,
  ...setLeadPassenger.actionMap,
  ...setLeadPassenger.actionCreatorMap,
  ...setPassengerByIndex.actionMap,
  ...setPassengerByIndex.actionCreatorMap,
  ...setCurrentPassenger.actionMap,
  ...setCurrentPassenger.actionCreatorMap,
  ...setSeatStatus.actionMap,
  ...setSeatStatus.actionCreatorMap,
  ...setPassengerDepartureSeat.actionMap,
  ...setPassengerDepartureSeat.actionCreatorMap,
  ...setPassengerReturnSeat.actionMap,
  ...setPassengerReturnSeat.actionCreatorMap,
  ...clearPassengerSeat.actionMap,
  ...clearPassengerSeat.actionCreatorMap,
  ...setPassengerProperty.actionMap,
  ...setPassengerProperty.actionCreatorMap,
  ...setPassengerSeat.actionMap,
  ...setPassengerSeat.actionCreatorMap,
}
