import actions from "./actions"
import initialState from "./initialState"

export default (state = initialState(), action) => {
  const { type, payload } = action
  const error1 = [
    "session_expired",
    "api_error",
    "non_field_errors",
    "pick_up_address",
    "drop_off_address",
  ]
  const error2 = ["detail", "payment_failed", "vouchers_id"]
  let error
  switch (type) {
    case actions.SET_ERROR_SUCCESS:
      for (let i = 0; i < error1.length; i++) {
        const key = error1[i]
        if (payload[key]) {
          return payload[key][0]
        }
      }
      for (let i = 0; i < error2.length; i++) {
        const key = error2[i]
        if (payload[key]) {
          return payload[key]
        }
      }
      if (payload.serializer_error) {
        const msg = Object.keys(payload.serializer_error).reduce((pv, cv) => {
          return `${pv}<div>${payload.serializer_error[cv]}</div>`
        }, "")
        error = msg
      } else if (payload.validation_error) {
        error = Object.keys(payload.validation_error).reduce((pv, cv) => {
          const value = payload.validation_error[cv]
          pv[cv] = value.constructor === Array ? value[0] : value
          return pv
        }, {})
      } else if (!error && action.payload) {
        error = action.payload
      }
      return error
    case actions.CLEAR_ERROR:
      return {
        isBusy: true,
      }
    case actions.CLEAR_ERROR_SUCCESS:
      return initialState()
    default:
      return state
  }
}
