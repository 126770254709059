import React from "react";
import PropTypes from "prop-types"
import ModuleTitle from "../../atoms/SchedulePages/ModuleTitle";

const Parking = ({data}) => {
    return (
        <>
        <ModuleTitle title="Parking"  />
        <div className="schedule-page-parking">
            <img src={data.image} alt="" />
            <div className="schedule-page-parking-panel">
                <h4>{data.title}</h4>
                {data.content}
            </div>
        </div>
        </>
    )
}

Parking.propTypes = {
    data: PropTypes.object
}

Parking.defaultProps = {
    data: {
        image: "",
        title: "Title",
        content: "Description goes here."
    }
}

export default Parking