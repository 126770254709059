/* eslint-disable no-underscore-dangle */
import "./PaymentResponse.scss"

import { Button, NotificationConsumer, Section } from "ibe-components"
import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, Redirect, useHistory } from "react-router-dom"
import styled from "styled-components"

import instructionActions from "../../../redux/instruction/actions"
import {
  accountSelector,
  cartSelector,
  instructionSelector,
  loyaltySelector,
  reservationsSelector,
  ticketSelector,
  tripSelector,
} from "../../../redux/selector"
import { allCardProductsEnums } from "../../../utils/cart-util"
import { isEmptyObject } from "../../../utils/common"
import { getSectionType } from "../../../utils/tripUtil-util"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import LoyaltyReward from "../../atoms/LoyaltyReward"
import PageLayout from "../../layouts/PageLayout"
import TripSummary from "../../molecules/TripSummary"
import PaymentPrices from "../../organisms/PaymentPrices"

const ReactMarkdown = require("react-markdown")

const PaymentResponse = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { params: bookingSearchParams } = useSelector(state => reservationsSelector(state))
  const { departureTicket, returnTicket } = useSelector(state => ticketSelector(state))
  const loyalty = useSelector(state => loyaltySelector(state))
  const isLoyaltyActive = loyalty.isActive
  const cart = useSelector(state => cartSelector(state))
  const {
    departureTicketLayoverTimes,
    returnTicketLayoverTimes,
    departureTicketTotalTripTime,
    returnTicketTotalTripTime,
  } = useSelector(state => ticketSelector(state))
  const trip = useSelector(state => tripSelector(state))

  const { editTrip } = trip

  const fetchDoor2DoorService = useCallback(
    res => dispatch(instructionActions.fetchDoor2DoorService(res)),
    [dispatch],
  )
  const instruction = useSelector(state => instructionSelector(state))

  const { isAuthenticated } = useSelector(state => accountSelector(state))

  const { door2DoorService = [] } = instruction

  const GoToHome = () => {
    history.push("/")
  }
  // const GoToTrips = () => {
  //   history.push("/trips")
  // }

  useEffect(() => {
    fetchDoor2DoorService({
      departure: departureTicket[0].departure_code,
      arrival: departureTicket[departureTicket.length - 1].arrival_code,
    })
  }, [history.location])

  useEffect(() => {
    window.gtag("event", "conversion", {
      send_to: `${window._env_.GOOGLE_ADS_ID}/${window._env_.GOOGLE_ADS_PURCHASE_TICKETS}`,
      value: `${cart.summary}.0`,
      currency: "USD",
      transaction_id: `${cart.uuid}`,
    })
  }, [])
  if (!cart && !cart.checked_out_status) {
    return <Redirect to="/" />
  }

  const voucherItem = cart.items_list.find(
    ({ product }) => product.category === allCardProductsEnums.DISCOUNT,
  )

  return (
    <NotificationConsumer>
      {() => {
        return (
          <ErrorBoundary>
            <PageLayout
              background="#fff"
              render={() => (
                <>
                  <div className="faux-breadcrumbs">Payment confirmation</div>
                  <SectionWrapper className="payment-response__section">
                    <div className="payment-response-page">
                      <div className="SectionWrapper">
                        <Container>
                          <div className="thankYouTitle">Thank you for booking with Landline</div>

                          <div className="reference">
                            Your booking reference is&nbsp;
                            <span style={{ color: "#00C08B" }}>{cart.additional_data.rloc}</span>
                          </div>
                          <div className="information">
                            You can find this information at any time under the
                            <Link to="/trips" style={{ padding: "0 5px", color: "#000" }}>
                              My Trips
                            </Link>
                            tab
                          </div>
                          <TripSummary
                            oneWay={bookingSearchParams.one_way}
                            departureTicket={departureTicket}
                            returnTicket={returnTicket}
                            headline={false}
                            departureTicketTotalTripTime={departureTicketTotalTripTime}
                            returnTicketTotalTripTime={returnTicketTotalTripTime}
                            departureTicketLayoverTimes={departureTicketLayoverTimes}
                            returnTicketLayoverTimes={returnTicketLayoverTimes}
                          />
                          <div className="inline-payment-info">
                            <PaymentPrices
                              isLoyaltyActive={isLoyaltyActive}
                              items={cart.items_list}
                              summary={cart.summary}
                              summaryLp={cart.summary_lp}
                              voucher={voucherItem}
                              paymentNeeded={cart.is_payment_necessary}
                              preview={false}
                              previousTicket={
                                isEmptyObject(cart.prev_cart) ? undefined : cart.prev_cart
                              }
                              editTrip={editTrip}
                              count={cart.count}
                              oneWay={bookingSearchParams.one_way}
                            />

                            {!!cart.total_reward_points && false && (
                              <LoyaltyReward
                                title="MILEMARKERS"
                                reward={cart.total_reward_points}
                                loyaltyBalance={cart.user_wallet_points}
                              />
                            )}
                          </div>
                          {door2DoorService.map(city => (
                            <div className="door2doorCointainer">
                              <div className="door2DoorInfo">
                                <ReactMarkdown source={city.Door2DoorInstructions} />
                              </div>
                            </div>
                          ))}
                        </Container>
                      </div>
                    </div>
                  </SectionWrapper>
                </>
              )}
            />
          </ErrorBoundary>
        )
      }}
    </NotificationConsumer>
  )
}

export default PaymentResponse

const Container = styled.div`
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-size: 20px;
  padding: 0px 20px;
  .PaymentResponse--title {
    padding: 15px 20px 5px;
  }
`
const SectionWrapper = styled.div`
  width: 100%;
  @media screen and (min-width: 1200px) {
    width: 1100px;
    margin: 0 auto;
    padding: 0px;
  }
`
