import "./Door2Door.scss"

import classNames from "classnames"
import {
  Button,
  CheckBox,
  CollapsibleButton,
  DropdownMenu,
  IncrementerInput,
  InputFieldSet,
} from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import featureFlags from "../../../constants/feature-flags"
import { TERMS_AND_CONDITIONS } from "../../../constants/routes"
import cartAction from "../../../redux/cart/actions"
import reservationActions from "../../../redux/reservations/actions"
import { reservationsSelector } from "../../../redux/selector"
import { allCardProductsEnums, getCartItemUtil, paymentTypeEnums } from "../../../utils/cart-util"
import config from "../../../utils/config"

const Door2Door = ({
  legs,
  cartItems,
  showNotification,
  door2DoorValidation,
  formErrors,
  passengers,
}) => {
  const dispatch = useDispatch()
  const door2DoorClass = classNames("door2-door-wrapper")

  const reservations = useSelector(state => reservationsSelector(state))

  const addCartItem = useCallback(item => dispatch(cartAction.addCartItem(item)), [dispatch])
  const removeCartItem = useCallback(item => dispatch(cartAction.removeCartItem(item)), [dispatch])
  const setReservationProperty = useCallback(
    (field, value) => dispatch(reservationActions.setReservationProperty({ field, value })),
    [dispatch],
  )

  let previouseItem = cartItems.find(
    ({ product }) => product.category === allCardProductsEnums.DOOR_2_DOOR,
  )

  const [checked, setCheck] = useState(!!previouseItem)

  const door2DoorValues = reservations.params.door2door || {}

  const addItem = qty => {
    const cartItem = getCartItemUtil(
      allCardProductsEnums.DOOR_2_DOOR,
      paymentTypeEnums.REGULAR,
      qty,
      {
        d2d_instruction: 4,
        passenger_name: `${passengers[0].first_name} ${passengers[0].last_name}`,
        email: passengers[0].email,
        phone_number: passengers[0].phone,
        destination_address: `${door2DoorValues["street-name"]} ${door2DoorValues["street-number"]}, ${door2DoorValues.city}`,
        ...door2DoorValues,
      },
    )
    addCartItem(cartItem)
  }

  const allCities = [
    ["Mankato", "Mankato"],
    ["North Mankato", "North Mankato"],
  ]

  useEffect(() => {
    if (!door2DoorValues.count) {
      setReservationProperty("door2door", { ...door2DoorValues, count: 1 })
    }
  }, [])

  const changeValue = (field, value) => {
    const newValue = { ...door2DoorValues, [field]: value }
    setReservationProperty("door2door", newValue)
  }

  const removePreviouseItem = () => {
    previouseItem = cartItems.find(
      ({ product }) => product.category === allCardProductsEnums.DOOR_2_DOOR,
    )
    if (previouseItem) {
      removeCartItem(previouseItem.id)
    }
  }

  const validateAddress = () => {
    if (door2DoorValidation(door2DoorValues)) {
      addItem(door2DoorValues.count)
      showNotification({ type: "is-success", message: "Successfully added to cart" })
    }
  }

  const activate = active => {
    setCheck(active)
    if (!active) {
      removePreviouseItem()
    }
  }

  if (!featureFlags().door2DoorFeatureFlag) return null

  return (
    <div className={door2DoorClass}>
      <CollapsibleButton title={<strong style={{ fontSize: 20 }}>Door to Door</strong>}>
        <p className="door2-door-text">
          Mankato Door to Door Service, ride from your home to/from our terminal for $20.00, for up
          to 4 passengers each way.
          <a href={`${config.LANDLINE_URL}${TERMS_AND_CONDITIONS}`}>Terms & Conditions </a>
        </p>
        <CheckBox
          checked={checked}
          value="I would like to activate Door to Door service and agree with the Terms & Conditions"
          onChange={e => activate(e.target.checked)}
          margin="20px 0 30px 10px"
        />
        {checked && (
          <>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              <IncrementerInput
                onIncrementChange={count => {
                  changeValue("count", count)
                }}
                incrementNumber={door2DoorValues.count}
                label="NUMBER OF PASSENGERS"
                labelAlign="middle"
              />
            </div>
            <DropdownMenu
              labelText="SELECT LEG"
              mandatory
              value={door2DoorValues.leg}
              error={formErrors.leg}
              content={legs}
              onSelect={value => {
                changeValue("leg", value)
              }}
            />
            <span className="door2-door-address">Pick up / Drop off address</span>
            <InputFieldSet
              labelText="STREET NAME"
              fullWidth
              inputValue={door2DoorValues["street-name"]}
              error={formErrors["street-name"]}
              onChange={e => {
                changeValue("street-name", e.target.value)
              }}
            />
            <InputFieldSet
              labelText="STREET NUMBER"
              fullWidth
              inputValue={door2DoorValues["street-number"]}
              error={formErrors["street-number"]}
              onChange={e => {
                changeValue("street-number", e.target.value)
              }}
            />
            <DropdownMenu
              labelText="CITY"
              mandatory
              value={door2DoorValues.city}
              error={formErrors.city}
              content={allCities}
              onSelect={value => {
                changeValue("city", value)
              }}
            />
            <div className="payment-buttons">
              <Button fullWidth type="secondary" onClick={validateAddress}>
                Validate Address
              </Button>
            </div>
          </>
        )}
      </CollapsibleButton>
    </div>
  )
}

Door2Door.propTypes = {
  legs: PropTypes.instanceOf(Array),
  cartItems: PropTypes.instanceOf(Array),
  formErrors: PropTypes.instanceOf(Object),
  showNotification: PropTypes.func.isRequired,
  door2DoorValidation: PropTypes.func.isRequired,
  passengers: PropTypes.instanceOf(Array),
}

Door2Door.defaultProps = {
  legs: [],
  cartItems: [],
  formErrors: {},
  passengers: [],
}

export default Door2Door
