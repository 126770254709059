/* eslint-disable */
// _env_ - no-underscore-dangle problem

const getEnv = variable => {
  let value = false
  if (window._env_) {
    try {
      value = JSON.parse(window._env_[variable])
    } catch (error) {
      value = window._env_[variable]
    }
  }
  return value
}

export default {
  DEFAULT_COUNTRY: "US",
  DEFAULT_CURRENCY: "usd",
  PAYPAL_ENV:
    String(process.env.NODE_ENV).toLowerCase() === "development" ? "sandbox" : "production",
  DEBUG: String(process.env.NODE_ENV).toLowerCase() !== "production",
  API_BASE_URL: getEnv("API_BASE_URL"),
  USE_APPLE_PAY: getEnv("USE_APPLE_PAY"),
  USE_PAYPAL: getEnv("USE_PAYPAL"),
  GA_TRACKING_ID: getEnv("GA_TRACKING_ID"),
  SENTRY_DSN: getEnv("SENTRY_DSN"),
  ZENDESK_KEY: getEnv("ZENDESK_KEY"),
  MAILCHIMP_SUBSCRIBE_LIST_ID: getEnv("MAILCHIMP_SUBSCRIBE_LIST_ID"),
  PUBLIC_URL: getEnv("PUBLIC_URL"),
  LANDLINE_URL: getEnv("LANDLINE_URL"),
  BUILD_NUMBER: getEnv("BUILD_NUMBER"),
  HOTJAR_ID: getEnv("HOTJAR_ID"),
  META_PIXEL_ID: getEnv("META_PIXEL_ID"),
}
