import "./TopMenuProfileLoyalty.scss"

import classNames from "classnames"
import { Button, Icon, PageTitle, Price } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"
import { useHistory } from "react-router-dom"

import featureFlags from "../../../constants/feature-flags"
import { BUY_MILES } from "../../../constants/routes"
import LoyaltyProfileItem from "../../atoms/LoyaltyProfileItem"

const TopMenuProfileLoyalty = ({ balance, loyaltyNumber }) => {
  const history = useHistory()

  if (!featureFlags().loyaltyFeatureFlag) return null

  const topMenuProfileLoyaltyClass = classNames("top-menu-profile-loyalty-wrapper")

  const goToBuyPoints = () => {
    history.push(BUY_MILES)
  }
  return (
    <div className={topMenuProfileLoyaltyClass}>
      <PageTitle title={<span>Your MileMarkers&#8480; Miles</span>} margin="40px 0 0 0" />
      <div className="top-menu-profile-loyalty-data-wrapper">
        <LoyaltyProfileItem
          title={<span>YOUR MILEMARKERS&#8480; NUMBER</span>}
          content={`#${loyaltyNumber}`}
        />
        <LoyaltyProfileItem
          title={<span>YOUR MILEMARKERS&#8480; MILES</span>}
          content={<Price floor amount={balance} currency="PTS" hideCurrency />}
        />
      </div>
      <Button
        type="secondary"
        onClick={goToBuyPoints}
        fullWidth
        icon={<Icon align="right" name="points" />}
      >
        Buy Miles
      </Button>
    </div>
  )
}

TopMenuProfileLoyalty.propTypes = {
  balance: PropTypes.string.isRequired,
  loyaltyNumber: PropTypes.string.isRequired,
}

export default TopMenuProfileLoyalty
