import { all, put, takeLatest } from "redux-saga/effects"

import actions from "./actions"

export function* clearError$() {
  try {
    yield put(actions.clearErrorSuccess())
  } catch (error) {
    yield put(actions.clearErrorFail())
  }
}

export function* setError$({ payload }) {
  try {
    yield put(actions.setErrorSuccess(payload))
  } catch (error) {
    yield put(actions.setErrorFail())
  }
}

export default function*() {
  yield all([takeLatest(actions.clearError, clearError$), takeLatest(actions.setError, setError$)])
}
