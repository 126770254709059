import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const setRedirectId = generateSagaActions(
  "[REDIRECT] Set redirect id",
  "SET_REDIRECT_ID",
  "setRedirectId",
  ["FAIL", "SUCCESS"],
  createAction,
)
const setRedirectURL = generateSagaActions(
  "[REDIRECT] Set redirect url",
  "SET_REDIRECT_URL",
  "setRedirectURL",
  ["FAIL", "SUCCESS"],
  createAction,
)

const clearRedirectURL = generateSagaActions(
  "[REDIRECT] Clear redirect url",
  "CLEAR_REDIRECT_URL",
  "clearRedirectURL",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...setRedirectId.actionMap,
  ...setRedirectId.actionCreatorMap,
  ...setRedirectURL.actionMap,
  ...setRedirectURL.actionCreatorMap,
  ...clearRedirectURL.actionMap,
  ...clearRedirectURL.actionCreatorMap,
}
