import moment from "moment"
import ReactDOM from "react-dom"

import config from "./config"

export const toCamel = s => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
  })
}

export const isArray = a => {
  return Array.isArray(a)
}

export const isEmptyObject = obj => {
  if (obj) return Object.keys(obj).length === 0 && obj.constructor === Object
  return undefined
}

export const isObject = o => {
  return o === Object(o) && !isArray(o) && typeof o !== "function"
}

export const keysToCamel = o => {
  if (isObject(o)) {
    const n = {}

    Object.keys(o).forEach(k => {
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  }
  if (isArray(o)) {
    return o.map(i => {
      return keysToCamel(i)
    })
  }

  return o
}

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  const dates = []
  const currDate = moment(startDate).startOf("day")
  const lastDate = moment(endDate).startOf("day")
  while (currDate.add(1, "days").diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate())
  }
  return dates
}

export const toDateFormatString = (date, format = "MM/DD/YYYY") => {
  return moment(date).format(format)
}

export const toTimeFormatString = (date, format = "hh:mm a") => {
  return moment(date, "HH:mm:ss").format(format)
}

export const decreaseDateBy = (date, decrement) => {
  return new Date(moment(date).subtract(decrement, "DAY"))
}

export const subtractTime = (currentTime, amount, amountUnit = "MINUTE", format = "hh:mm a") =>
  moment(currentTime, "HH:mm")
    .subtract(amount, amountUnit)
    .format(format)

export const increaseDateBy = (date, decrement) => {
  return new Date(moment(date).add(decrement, "DAY"))
}

export const currentDateTime = time => {
  const seconds = moment(time, "HH:mm").diff(moment(new Date()).startOf("day"), "seconds")
  return moment(new Date())
    .add(seconds, "SECOND")
    .toDate()
}

export const isDateAfter = (date, compareTo) => {
  return moment(date).isAfter(moment(compareTo))
}

export const isDateSame = (date, compareTo) => {
  return moment(date).isSame(moment(compareTo))
}

export const scrollToTop = ref => {
  window.scrollTo(0, ReactDOM.findDOMNode(ref.current).top) // eslint-disable-line react/no-find-dom-node
}

export const scrollToRef = (ref, offset) => {
  const scrollTo = ref.current.offsetTop - (offset || 0)
  window.scrollTo(0, scrollTo)
}

export const reduceErrors = errors =>
  Object.keys(errors).reduce((pv, cv) => {
    return `${pv}${errors[cv]} \n`
  }, "")

export const generateSagaActions = (
  messagePrefixDescription,
  messagePrefix,
  actionCreatorPrefix,
  descriptors,
  createAction,
) => {
  const actionCreators = descriptors.reduce((pv, cv) => {
    const normalize = cv.toLowerCase().replace(/^\w/, c => c.toUpperCase())
    const action = `${messagePrefix}_${cv}`
    const actionCreatorName = `${actionCreatorPrefix}${normalize}`
    return {
      ...pv,
      [action]: {
        [actionCreatorName]: createAction(`${messagePrefixDescription} ${cv.toLowerCase()}`),
      },
    }
  }, {})
  const actionMap = descriptors.reduce(
    (pv, cv) => {
      const action = `${messagePrefix}_${cv}`
      return {
        ...pv,
        [action]: `${messagePrefixDescription} ${cv.toLowerCase()}`,
      }
    },
    { [messagePrefix]: messagePrefixDescription },
  )
  const actions = Object.keys(actionCreators)

  const actionCreatorMap = actions.reduce(
    (pv, cv) => {
      const key = Object.keys(actionCreators[cv])[0]
      return {
        ...pv,
        [key]: actionCreators[cv][key],
      }
    },
    { [actionCreatorPrefix]: createAction(messagePrefixDescription) },
  )

  return {
    actionMap,
    actionCreatorMap,
  }
}

export const loadState = () => {
  try {
    const lastBuildVersion = localStorage.getItem("BuildVersion")
    if (lastBuildVersion !== config.BUILD_NUMBER) {
      localStorage.clear()
      localStorage.setItem("BuildVersion", config.BUILD_NUMBER)
      return undefined
    }
    const serializedState = localStorage.getItem("state")
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem("state", serializedState)
  } catch (err) {}
}

export const formatCardParams = (card, rememberMe) => ({
  card_type: card.type || "",
  card_holder: card.holderName || "",
  number: card.number || "",
  month: card.expirationMonth || "",
  year: card.expirationYear || "",
  remember_me: rememberMe,
  verification_value: "",
  amount: 0.0,
  currency: "USD",
})

export const roundToPrecision = (x, precision) => {
  const y = +x + (precision === undefined ? 0.5 : precision / 2)
  return y - (y % (precision === undefined ? 1 : +precision))
}

export const removeEmptyFields = data => {
  const returnData = {}
  Object.keys(data).forEach(key => {
    if (data[key]) returnData[key] = data[key]
  })
  return returnData
}

export const getNextPickupDetails = (nextPickupDetails, activePickupDetails) => {
  return {
    city: nextPickupDetails.city || activePickupDetails.city,
    first_address_line:
      nextPickupDetails.first_address_line || activePickupDetails.first_address_line,
    second_address_line:
      nextPickupDetails.second_address_line || activePickupDetails.second_address_line,
    zip_code: nextPickupDetails.zip_code || activePickupDetails.zip_code,
    state: nextPickupDetails.state || activePickupDetails.state,
  }
}
