import "./LoyaltyProfileItem.scss"

import PropTypes from "prop-types"
import React from "react"

const LoyaltyProfileItem = ({ title, content }) => {
  return (
    <div className="top-menu-profile-loyalty-item">
      <span className="top-menu-profile-loyalty-title">{title}</span>
      <span className="top-menu-profile-loyalty-content">{content}</span>
    </div>
  )
}

LoyaltyProfileItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

LoyaltyProfileItem.defaultProps = {}

export default LoyaltyProfileItem
