import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const activateLoyalty = generateSagaActions(
  "[LOYALTY] Activate loyalty",
  "ACTIVATE_LOYALTY",
  "activateLoyalty",
  ["FAIL", "SUCCESS"],
  createAction,
)

const buyLoyaltyPoints = generateSagaActions(
  "[LOYALTY] Buy Loyalty Points",
  "BUY_LOYALTY_POINTS",
  "buyLoyaltyPoints",
  ["FAIL", "SUCCESS"],
  createAction,
)

const checkLoyaltyPointsPrice = generateSagaActions(
  "[LOYALTY] Check Loyalty Points Price",
  "CHECK_LOYALTY_POINTS_PRICE",
  "checkLoyaltyPointsPrice",
  ["FAIL", "SUCCESS"],
  createAction,
)

const getMileMarkerAccrualLog = generateSagaActions(
  "[LOYALTY] Get MileMarker accrual log",
  "GET_MILEMARKER_ACCRUAL_LOG",
  "getMileMarkerAccrualLog",
  ["FAIL", "SUCCESS"],
  createAction,
)

const updateLoyaltyFields = generateSagaActions(
  "[LOYALTY] Update Loyalty Fields",
  "UPDATE_LOYALTY_FIELDS",
  "updateLoyaltyFields",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...checkLoyaltyPointsPrice.actionMap,
  ...checkLoyaltyPointsPrice.actionCreatorMap,
  ...buyLoyaltyPoints.actionMap,
  ...buyLoyaltyPoints.actionCreatorMap,
  ...activateLoyalty.actionMap,
  ...activateLoyalty.actionCreatorMap,
  ...getMileMarkerAccrualLog.actionMap,
  ...getMileMarkerAccrualLog.actionCreatorMap,
  ...updateLoyaltyFields.actionMap,
  ...updateLoyaltyFields.actionCreatorMap,
}
