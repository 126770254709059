import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const registerForUpdate = generateSagaActions(
  "[NEWSLETTER] Register for updates",
  "REGISTER_FOR_UPDATE",
  "registerForUpdate",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...registerForUpdate.actionMap,
  ...registerForUpdate.actionCreatorMap,
}
