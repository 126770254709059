import moment from "moment"
import { all, call, put, putResolve, select, take, takeLatest } from "redux-saga/effects"

import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../constants/segments"
import { EXPRESS_HOME_TYPE, EXPRESS_TYPE } from "../../constants/transportation-types"
import { isEmptyObject, removeEmptyFields } from "../../utils/common"
import { httpGet, httpPatch, httpPost, httpPut } from "../../utils/request"
import {
  getBookingFlowArray,
  getBreadcrumbsFlowArray,
  getLandlineSegmentIndex,
  goToNextSegment,
  isBlackCarAirline,
  isExpressAtHome,
  parseSegment,
} from "../../utils/tripUtil-util"
import errorActions from "../error/actions"
import actions from "./actions"

export function* clearUpcomingTrips$() {
  try {
    yield put(actions.clearUpcomingTripsSuccess())
  } catch (error) {
    yield put(actions.clearUpcomingTripsFail())
    yield put(errorActions.setError(error))
  }
}

export function* clearPastTrips$() {
  try {
    yield put(actions.clearPastTripsSuccess())
  } catch (error) {
    yield put(actions.clearPastTripsFail())
    yield put(errorActions.setError(error))
  }
}

export function* fetchUpcomingTrips$({ payload }) {
  try {
    yield put(errorActions.clearError())
    const { isAuthenticated } = yield select(state => state.account)
    if (isAuthenticated) {
      const response = yield httpGet(
        `v2/booking/reservations/upcoming/?&limit=10&offset=${payload.offset}`,
      )
      yield put(actions.fetchUpcomingTripsSuccess(response))
    } else {
      yield put(actions.fetchUpcomingTripsFail())
    }
    if (payload.callback) {
      payload.callback()
    }
  } catch (error) {
    yield put(actions.fetchUpcomingTripsFail())
    yield put(errorActions.setError(error))
  }
}

export function* fetchPastTrips$({ payload }) {
  try {
    yield put(errorActions.clearError())
    const { isAuthenticated } = yield select(state => state.account)
    if (isAuthenticated) {
      const response = yield httpGet(
        `v2/booking/reservations/past/?&limit=10&offset=${payload.offset}`,
      )
      yield put(actions.fetchPastTripsSuccess(response))
    } else {
      yield put(actions.fetchPastTripsFail())
    }
    if (payload.callback) {
      payload.callback()
    }
  } catch (error) {
    yield put(actions.fetchPastTripsFail())
    yield put(errorActions.setError(error))
  }
}

export function* cancelTrip$({ payload }) {
  try {
    let lastName = {}
    const { detail } = yield select(state => state.trip)
    if (detail && !isEmptyObject(detail)) {
      lastName = { lastname: detail.last_name }
    }
    const response = yield httpPost(
      `v2/booking/tickets/${payload.pnr}/cancel/${payload.segment ? `${payload.segment}/` : ``}`,
      {},
      lastName,
    )
    yield put(actions.cancelTripSuccess(response))
  } catch (error) {
    yield put(actions.cancelTripFail())
    yield put(errorActions.setError(error))
  }
}

export function* resetCancelState$() {
  try {
    yield put(actions.resetCancelStateSuccess())
  } catch (error) {
    yield put(actions.resetCancelStateFail())
    yield put(errorActions.setError(error))
  }
}

export function* checkReservation$({ payload }) {
  try {
    const response = yield httpGet(
      `v2/booking/tickets/${(payload.pnr || "").trim()}/?refresh=true`,
      {
        LASTNAME: (payload.last_name || "").trim(),
      },
    )

    yield put(
      actions.checkReservationSuccess({
        ...response,
        anonymous: true,
        last_name: payload.last_name,
      }),
    )
    const { detail } = yield select(state => state.trip)
    if (payload.callBack) {
      payload.callBack(detail)
    }
  } catch (error) {
    yield put(actions.checkReservationFail())
    yield put(errorActions.setError(error))
  }
}
export function* resetCheckIn$() {
  try {
    yield put(actions.resetCheckInSuccess())
  } catch (error) {
    yield put(actions.resetCheckInFail())
    yield put(errorActions.setError(error))
  }
}

export function* checkInTrip$({ payload }) {
  const { pnr, segmentDirection } = payload
  try {
    const { detail } = yield select(state => state.trip)
    let lastNameHeader = {}
    if (detail && !isEmptyObject(detail)) {
      lastNameHeader = { lastname: detail.last_name }
    }
    yield httpPost(
      `v2/booking/tickets/${pnr}/check_in/`,
      {
        way: segmentDirection,
      },
      lastNameHeader,
    )
    if (detail) {
      const { anonymous, last_name: lastName } = detail
      if (anonymous) {
        yield putResolve(actions.checkReservation({ last_name: lastName, pnr }))
        yield take(actions.CHECK_RESERVATION_SUCCESS)
      }
    }
    yield put(
      actions.checkInTripSuccess({
        pnr,
        segmentDirection,
        successfullyCheckedIn: !!payload.history,
      }),
    )
    if (payload.history) payload.history.push("/trips")
  } catch (error) {
    yield put(actions.checkInTripFail())
    yield put(errorActions.setError(error))
  }
}

export function* setSeatLeg$({ payload }) {
  try {
    yield put(actions.setSeatLegSuccess(payload))
  } catch (error) {
    yield put(actions.setSeatLegFail())
    yield put(errorActions.setError(error))
  }
}

export function* fetchSeat$({ payload }) {
  try {
    const { pnr, segment } = payload
    const response = yield httpGet(`seats/${pnr}/${segment}/`)
    yield put(actions.setSeatLeg({ segment }))
    yield put(actions.fetchSeatSuccess(response))
  } catch (error) {
    yield put(actions.fetchSeatFail())
    yield put(errorActions.setError(error))
  }
}

export function* changeSeat$({ payload }) {
  try {
    const { pnr, segment, params } = payload
    const response = yield httpPut(`seats/${pnr}/${segment}/`, params)
    const { detail } = yield select(state => state.trip)
    if (detail && (detail.last_name || detail.anonymous)) {
      const { anonymous, last_name: lastName } = detail
      if (anonymous) {
        yield putResolve(actions.checkReservation({ last_name: lastName, pnr }))
        yield take(actions.CHECK_RESERVATION_SUCCESS)
      } else {
        yield take(actions.FETCH_TRIP_SUCCESS)
      }
    }
    yield put(actions.changeSeatSuccess(response))
  } catch (error) {
    yield put(actions.changeSeatFail())
    yield put(errorActions.setError(error))
  }
}

export function* setPickupDetailsProperty$({ payload }) {
  try {
    yield put(actions.setPickupDetailsPropertySuccess(payload))
  } catch (error) {
    yield put(actions.setPickupDetailsPropertyFail())
    yield put(errorActions.setError(error))
  }
}

export function* setPickupDetailsProperties$({ payload }) {
  try {
    yield put(
      actions.setPickupDetailsPropertiesSuccess({
        data: payload.data,
        segmentDirection: payload.segmentDirection,
      }),
    )
  } catch (error) {
    yield put(actions.setPickupDetailsPropertiesFail())
    yield put(errorActions.setError(error))
  }
}

export function* updatePickupDetails$({ payload, direction, isExpress, isPickup }) {
  try {
    const trip = yield select(state => state.trip)
    const { 1: departPickupDetails, 2: returnPickupDetails } = trip.pickupDetails

    if ((!direction || direction === SEGMENT_DEPART) && departPickupDetails.flightNo) {
      const pickupDetailID = payload.pick_up_details.find(
        data => data.leg__flight_number === departPickupDetails.flightNo,
      )
      const filteredPickup = removeEmptyFields(departPickupDetails.pick_up_address)
      const filteredDropOff = removeEmptyFields(departPickupDetails.drop_off_address)
      const filteredActivePickupDetails = removeEmptyFields(departPickupDetails)

      let departureDatetime
      if (departPickupDetails.departure_date) {
        departureDatetime = moment.utc(
          `${moment(departPickupDetails.departure_date).format("YYYY-MM-DD")} ${
            departPickupDetails.departure_time
          }`,
          "YYYY-MM-DD HH:mm A",
        )
      }

      let arrivalDatetime
      if (departPickupDetails.arrival_date) {
        arrivalDatetime = moment.utc(
          `${moment(departPickupDetails.arrival_date).format("YYYY-MM-DD")} ${
            departPickupDetails.arrival_time
          }`,
          "YYYY-MM-DD HH:mm",
        )
      }

      const departPickupDetailsParams = {
        ...filteredActivePickupDetails,
        pick_up_address: !isExpress || isPickup ? filteredPickup : {},
        drop_off_address: !isExpress || !isPickup ? filteredDropOff : {},
        departure_datetime: departureDatetime,
        arrival_datetime: arrivalDatetime,
        flightNo: undefined,
      }

      yield httpPatch(
        `v2/landline/pick_up_details/${pickupDetailID.id}/`,
        departPickupDetailsParams,
      )
    }

    if ((!direction || direction === SEGMENT_RETURN) && returnPickupDetails.flightNo) {
      const pickupDetailID = payload.pick_up_details.find(
        data => data.leg__flight_number === returnPickupDetails.flightNo,
      )
      const filteredPickup = removeEmptyFields(returnPickupDetails.pick_up_address)
      const filteredDropOff = removeEmptyFields(returnPickupDetails.drop_off_address)
      const filteredActivePickupDetails = removeEmptyFields(returnPickupDetails)

      let departureDatetime
      if (returnPickupDetails.departure_date) {
        departureDatetime = moment.utc(
          `${moment(returnPickupDetails.departure_date).format("YYYY-MM-DD")} ${
            returnPickupDetails.departure_time
          }`,
          "YYYY-MM-DD HH:mm A",
        )
      }

      let arrivalDatetime
      if (returnPickupDetails.arrival_date) {
        arrivalDatetime = moment.utc(
          `${moment(returnPickupDetails.arrival_date).format("YYYY-MM-DD")} ${
            returnPickupDetails.arrival_time
          }`,
          "YYYY-MM-DD HH:mm",
        )
      }

      const returnPickupDetailsParams = {
        ...filteredActivePickupDetails,
        pick_up_address: !isExpress || isPickup ? filteredPickup : {},
        drop_off_address: !isExpress || !isPickup ? filteredDropOff : {},
        flightNo: undefined,
        departure_datetime: departureDatetime,
        arrival_datetime: arrivalDatetime,
      }

      yield httpPatch(
        `v2/landline/pick_up_details/${pickupDetailID.id}/`,
        returnPickupDetailsParams,
      )
    }
    yield put(actions.updatePickupDetailsSuccess())
  } catch (error) {
    yield put(actions.setPickupDetailsFail())
    throw error
  }
}

export function* checkAddress$(payload) {
  try {
    const { areaId, address } = payload
    const response = yield httpPost(`v2/landline/operated_areas/${areaId}/is_address_inside/`, {
      address,
    })
    if (!response.is_address_inside) {
      yield put(actions.checkAddressFail())
      const error = { detail: "This service is not available in your area" }
      throw error
    } else {
      yield put(actions.checkAddressSuccess())
    }
  } catch (error) {
    yield put(actions.checkAddressFail())
    throw error
  }
}

export function* setPickupDetails$({ payload }) {
  try {
    const { departureTicket, returnTicket } = yield select(state => state.ticket)
    const trip = yield select(state => state.trip)
    const passenger = yield select(state => state.passenger)
    const { segmentDirection, history, isPickup } = payload
    const shouldCheckAddress = trip.editTrip !== "" && !!trip.operatedArea
    let tickets = []
    if (segmentDirection === SEGMENT_DEPART) {
      tickets = departureTicket || []
    } else {
      tickets = returnTicket || []
    }

    const currentSegment =
      tickets.find(element => {
        const parsedSegment = parseSegment(element)
        return isBlackCarAirline(parsedSegment) || isExpressAtHome(parsedSegment)
      }) || {}
    const pickupDateTime = moment
      .utc(
        `${currentSegment.departure_date} ${currentSegment.departure_time}`,
        "YYYY-MM-DD hh:mm:ss",
      )
      .format()

    const { pickupDetails } = yield select(state => state.trip)
    const activePickupDetails = { ...pickupDetails[segmentDirection] }
    const {
      pick_up_address: pickupAddressData,
      drop_off_address: dropOffAddressData,
    } = activePickupDetails

    const filteredPickup = removeEmptyFields(pickupAddressData)
    const filteredDropOff = removeEmptyFields(dropOffAddressData)
    const filteredActivePickupDetails = removeEmptyFields(activePickupDetails)

    let departureDatetime
    if (activePickupDetails.departure_date) {
      departureDatetime = moment.utc(
        `${moment(activePickupDetails.departure_date).format("YYYY-MM-DD")} ${
          activePickupDetails.departure_time
        }`,
        "YYYY-MM-DD HH:mm A",
      )
    }

    let arrivalDatetime
    if (activePickupDetails.arrival_date) {
      arrivalDatetime = moment.utc(
        `${moment(activePickupDetails.arrival_date).format("YYYY-MM-DD")} ${
          activePickupDetails.arrival_time
        }`,
        "YYYY-MM-DD HH:mm",
      )
    }

    const params = {
      ...filteredActivePickupDetails,
      pick_up_address: filteredPickup,
      drop_off_address: filteredDropOff,
      departure_datetime: departureDatetime,
      arrival_datetime: arrivalDatetime,
      pickup_datetime: pickupDateTime,
    }

    yield put(
      actions.setPickupDetailsSuccess({
        data: params,
        segmentDirection,
        flightNo: `${currentSegment.airline}${currentSegment.flight_no}`,
        isBusy: shouldCheckAddress,
      }),
    )

    if (trip.editTrip === "") {
      yield call(updatePickupDetails$, {
        payload: passenger.payload,
        direction: segmentDirection,
        isExpress: currentSegment.service_type === EXPRESS_TYPE,
        isPickup,
      })
    } else if (
      trip.operatedArea &&
      trip.operatedArea.service_type ===
        (currentSegment.service_type === EXPRESS_TYPE
          ? EXPRESS_HOME_TYPE
          : currentSegment.service_type)
    ) {
      const formatAddress = () => {
        const data = isPickup ? filteredPickup : filteredDropOff

        return `${data.first_address_line}, ${data.city}, ${data.state}${
          data.zip_code ? `, ${data.zip_code}` : ""
        }`
      }

      yield call(checkAddress$, { areaId: trip.operatedArea.id, address: formatAddress() })
    }

    goToNextSegment(history)
  } catch (error) {
    yield put(actions.setPickupDetailsFail())
    yield put(errorActions.setError(error))
  }
}
export function* clearPickupDetails$() {
  try {
    yield put(actions.clearPickupDetailsSuccess())
  } catch (error) {
    yield put(actions.clearPickupDetailsFail())
    yield put(errorActions.setError(error))
  }
}

export function* getFlightDetails$({ payload }) {
  try {
    const params = {
      booking_token: payload.bookingToken,
      pnum: payload.pnum,
      bnum: 0,
    }
    const response = yield httpPost(`bookings/check_flights/`, params)
    yield put(actions.getFlightDetailsSuccess({ response, itineraryIndex: payload.itineraryIndex }))
  } catch (error) {
    yield put(actions.getFlightDetailsFail())
    yield put(errorActions.setError(error))
  }
}

export function* clearFlightDetails$() {
  try {
    yield put(actions.clearFlightDetailsSuccess())
  } catch (error) {
    yield put(actions.clearFlightDetailsFail())
    yield put(errorActions.setError(error))
  }
}

export function* setEditTripPNR$({ payload }) {
  try {
    yield put(actions.setEditTripPNRSuccess(payload))
  } catch (error) {
    yield put(actions.setEditTripPNRFail())
    yield put(errorActions.setError(error))
  }
}
export function* clearEditTripPNR$() {
  try {
    yield put(actions.clearEditTripPNRSuccess())
  } catch (error) {
    yield put(actions.clearEditTripPNRFail())
    yield put(errorActions.setError(error))
  }
}

export function* checkInFlight$({ payload }) {
  try {
    const { flightTicketPnr, segmentDirection } = payload
    yield httpGet(`v2/booking/tickets/${flightTicketPnr}/flight_check_in/${segmentDirection}/`)
    yield put(actions.checkInFlightSuccess())
  } catch (error) {
    yield put(actions.checkInFlightFail())
    yield put(errorActions.setError(error))
  }
}

export function* sendBoardingPassToMail$({ payload }) {
  try {
    const { flightTicketPnr } = payload
    yield httpGet(`v2/booking/tickets/${flightTicketPnr}/flight_e_ticket/`)
    yield put(actions.sendBoardingPassToMailSuccess())
  } catch (error) {
    yield put(actions.sendBoardingPassToMailFail())
    yield put(errorActions.setError(error))
  }
}

export function* getOperatedArea$({ payload }) {
  try {
    const { city, transportationType } = payload
    const response = yield httpGet(
      `v2/landline/operated_areas/?is_active=True&service_type=${
        transportationType === EXPRESS_TYPE ? EXPRESS_HOME_TYPE : transportationType
      }&city=${city}`,
    )
    yield put(actions.getOperatedAreaSuccess(response))
  } catch (error) {
    yield put(actions.getOperatedAreaFail())
    yield put(errorActions.setError(error))
  }
}

export function* setBookingFlow$() {
  try {
    const { departureTicket, returnTicket } = yield select(state => state.ticket)

    const bookingFlow = getBookingFlowArray(departureTicket, returnTicket)
    const departureLandlineSegmentIndex = getLandlineSegmentIndex(departureTicket)
    const returnLandlineSegmentIndex = getLandlineSegmentIndex(returnTicket)

    const breadcrumbsFlow = getBreadcrumbsFlowArray(bookingFlow)

    yield put(
      actions.setBookingFlowSuccess({
        bookingFlow,
        departureLandlineSegmentIndex,
        returnLandlineSegmentIndex,
        breadcrumbsFlow,
      }),
    )
  } catch (error) {
    yield put(actions.setBookingFlowFail())
    yield put(errorActions.setError(error))
  }
}

export function* setBookingFlowIndex$({ payload }) {
  try {
    yield put(actions.setBookingFlowIndexSuccess(payload))
  } catch (error) {
    yield put(actions.setBookingFlowIndexFail())
    yield put(errorActions.setError(error))
  }
}

export function* clearBookingFlow$() {
  try {
    yield put(actions.clearBookingFlowSuccess())
  } catch (error) {
    yield put(actions.clearBookingFlowFail())
    yield put(errorActions.setError(error))
  }
}

export function* setChangeSeatsDirection$({ payload }) {
  try {
    yield put(actions.setChangeSeatsDirectionSuccess(payload))
  } catch (error) {
    yield put(actions.setChangeSeatsDirectionFail())
    yield put(errorActions.setError(error))
  }
}

export default function*() {
  yield all([
    takeLatest(actions.checkReservation, checkReservation$),
    takeLatest(actions.checkInTrip, checkInTrip$),
    takeLatest(actions.fetchSeat, fetchSeat$),
    takeLatest(actions.changeSeat, changeSeat$),
    takeLatest(actions.fetchUpcomingTrips, fetchUpcomingTrips$),
    takeLatest(actions.fetchPastTrips, fetchPastTrips$),
    takeLatest(actions.cancelTrip, cancelTrip$),
    takeLatest(actions.setPickupDetailsProperty, setPickupDetailsProperty$),
    takeLatest(actions.setPickupDetailsProperties, setPickupDetailsProperties$),
    takeLatest(actions.setPickupDetails, setPickupDetails$),
    takeLatest(actions.updatePickupDetails, updatePickupDetails$),
    takeLatest(actions.clearPickupDetails, clearPickupDetails$),
    takeLatest(actions.getFlightDetails, getFlightDetails$),
    takeLatest(actions.resetCancelState, resetCancelState$),
    takeLatest(actions.clearFlightDetails, clearFlightDetails$),
    takeLatest(actions.setEditTripPNR, setEditTripPNR$),
    takeLatest(actions.clearEditTripPNR, clearEditTripPNR$),
    takeLatest(actions.resetCheckIn, resetCheckIn$),
    takeLatest(actions.clearPastTrips, clearPastTrips$),
    takeLatest(actions.clearUpcomingTrips, clearUpcomingTrips$),
    takeLatest(actions.checkInFlight, checkInFlight$),
    takeLatest(actions.sendBoardingPassToMail, sendBoardingPassToMail$),
    takeLatest(actions.getOperatedArea, getOperatedArea$),
    takeLatest(actions.checkAddress, checkAddress$),
    takeLatest(actions.setBookingFlow, setBookingFlow$),
    takeLatest(actions.setBookingFlowIndex, setBookingFlowIndex$),
    takeLatest(actions.clearBookingFlow, clearBookingFlow$),
    takeLatest(actions.setSeatLeg, setSeatLeg$),
    takeLatest(actions.setChangeSeatsDirection, setChangeSeatsDirection$),
  ])
}
