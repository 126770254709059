import "./CheckInPage.scss"

import { Button, Icon, LegConnection, NotificationConsumer, Section } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import errorActions from "../../../redux/error/actions"
import instructionActions from "../../../redux/instruction/actions"
import { errorSelector, tripSelector } from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"
import { toDateFormatString } from "../../../utils/common"
import tripUtil, { getVidecomPNR } from "../../../utils/tripUtil-util"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"

const CheckInPage = ({ history, match }) => {
  const dispatch = useDispatch()
  const checkInTrip = useCallback(payload => dispatch(tripActions.checkInTrip(payload)), [dispatch])
  const trip = useSelector(state => tripSelector(state))
  const error = useSelector(state => errorSelector(state))
  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])
  const fetchInstruction = useCallback(() => dispatch(instructionActions.fetchInstruction()), [
    dispatch,
  ])

  useEffect(() => {
    fetchInstruction()
  }, [])

  const [checkInFlag, changeCheckInFlag] = useState(false)
  const { pnr, segmentDirection } = match.params

  let flight = { segments: [], passengers: [] }

  const currentFlights = ((trip || {}).upcoming_trips || {}).results || []
  if (!trip.isBusy && currentFlights) {
    flight = currentFlights.find(currentFlight => getVidecomPNR(currentFlight.tickets) === pnr)
  }
  if (!currentFlights.length > 0 && trip.detail) {
    flight = trip.detail
  }

  let flightData = { segments: [{}] }
  let layoverTimes = []
  flightData = tripUtil.getTripDirection(flight, segmentDirection - 1)

  layoverTimes = flightData.layoverTimes

  let showBoardingPass = false

  if (flightData.segments.length > 0) {
    showBoardingPass = flightData.segments[0].all_checked_in
  }
  useEffect(() => {
    if (trip.checkin) {
      changeCheckInFlag(true)
    }
  }, [trip.checkin])

  const actionHandler = () => {
    checkInTrip({ pnr, segmentDirection })
  }

  if (checkInFlag) {
    showBoardingPass = true
  }
  return (
    <NotificationConsumer>
      {({ actions }) => {
        return (
          <PageLayout
            render={() => {
              if ((error || {}).isBusy || trip.isBusy) {
                return <Loading />
              }
              if (error) {
                actions.show({ type: "is-danger", message: error })
                clearError()
              }

              return (
                <div className="SectionWrapper">
                  <Section
                    title="Back to My Trips"
                    canGoBack
                    onGoBackClicked={() => {
                      history.push("/trips")
                    }}
                  />
                  <div className="CheckInPage-wrapper">
                    <div className="top-info">
                      <h2>{showBoardingPass ? "Checked-in" : "Check-In"}</h2>

                      {showBoardingPass && <Icon name="checkIn" fill="black" />}

                      <div className="Separator" />
                    </div>
                    <div className="Date-wrapper">
                      <div>{toDateFormatString(flight.departure_date, "dddd, MMMM D, Y")}</div>
                      <div>{pnr}</div>
                    </div>
                    <div className="legTitle">{segmentDirection === "1" ? "Depart" : "Return"}</div>

                    <LegConnection
                      layoverTimes={layoverTimes}
                      segments={flightData.segments}
                      myTrips
                      showLegConnectionDate
                    />
                    <div>
                      <div className="Separator" />
                    </div>
                    {!showBoardingPass ? (
                      <div className="checkin-buttons">
                        <Button
                          fullWidth
                          type="primary"
                          icon={
                            <Icon
                              viewBox="0 0 22 22"
                              align="right"
                              name="checkIn"
                              style={{ marginRight: "5px" }}
                            />
                          }
                          onClick={actionHandler}
                        >
                          Check me in!
                        </Button>
                      </div>
                    ) : (
                      <>
                        <div className="checkin-buttons">
                          <Button
                            fullWidth
                            type="primary"
                            icon={<Icon withoutProps width="20" height="20" name="boardingPass" />}
                            onClick={actionHandler}
                          >
                            Send to Email
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )
            }}
          />
        )
      }}
    </NotificationConsumer>
  )
}

export default CheckInPage

CheckInPage.propTypes = {
  history: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object),
}
CheckInPage.defaultProps = {
  history: {},
  match: {},
}
