const pickupDetailInit = {
  id: "",
  flightNo: "",
  pick_up_address: {
    city: "",
    first_address_line: "",
    second_address_line: "",
    zip_code: "",
    state: "",
  },
  drop_off_address: {
    city: "",
    first_address_line: "",
    second_address_line: "",
    zip_code: "",
    state: "",
  },
  airline: "",
  flight_number: "",
  origin: "",
  departure_datetime: {},
  destination: "",
  arrival_datetime: {},
}

export default () => ({
  detail: null,
  flights: null,
  checkin: null,
  successfullyCheckedIn: false,
  isCanceled: false,
  pass: null,
  isBusy: false,
  error: null,
  upcoming_trips: { results: [] },
  past_trips: { results: [] },
  editTrip: "",
  pickupDetails: {
    1: {
      ...pickupDetailInit,
    },
    2: {
      ...pickupDetailInit,
    },
  },
  flightDetails: {},
  notification: undefined,
  boardingPassNotification: undefined,
})
