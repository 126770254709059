import "./PageLayout.scss"

import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import Footer from "../../organisms/Footer"
import { TopMenu } from "../../organisms/Navbar"

const PageLayout = ({ render, background, contentStyle = {} }) => {
  const [isMobileNavbarVisible, setMobileNavbarVisibility] = useState(null)

  return (
    <>
      <TopMenu />
      <div className="page-layout-line" />

      <Container background={background} style={contentStyle}>
        <Content>{render({ setMobileNavbarVisibility, isMobileNavbarVisible })}</Content>
        <Footer />
      </Container>
    </>
  )
}

PageLayout.propTypes = {
  render: PropTypes.func.isRequired,
  background: PropTypes.string,
}

PageLayout.defaultProps = { background: "#F5F5F5" }

export default PageLayout

const Container = styled.div`
  background-color: ${props => props.background};
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  min-height: calc(100vh - 85px);
  height: 100%;
`
const Content = styled.div`
  display: grid;
  width: 100%;
  align-items: start;

  @media screen and (min-width: 1200px) {
    width: 100%;
  }
`
