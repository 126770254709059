import actions from "./actions"
import initialState from "./initialState"

export default function(state = initialState(), action) {
  const { type, payload } = action
  switch (type) {
    case actions.SET_REDIRECT_ID_SUCCESS:
      return {
        ...state,
        id: payload,
      }
    case actions.SET_REDIRECT_URL:
    case actions.CLEAR_REDIRECT_URL:
      return {
        ...state,
        isBusy: true,
      }
    case actions.SET_REDIRECT_URL_SUCCESS:
      return {
        url: payload,
      }

    case actions.CLEAR_REDIRECT_URL_SUCCESS:
      return {
        url: "",
      }

    default:
      return state
  }
}
