import { all, put, takeLatest } from "redux-saga/effects"

import { httpGet } from "../../utils/request"
import errorActions from "../error/actions"
import actions from "./actions"

export function* fetchInstruction$() {
  try {
    yield put(errorActions.clearError())
    const response = yield httpGet("instructions/")
    yield put(actions.fetchInstructionSuccess(response))
  } catch (error) {
    yield put(actions.fetchInstructionFail())
    yield put(errorActions.setError(error))
  }
}

export function* fetchDoor2DoorService$(payload) {
  try {
    yield put(errorActions.clearError())
    const response = yield httpGet("instructions/")
    const filteredResponse = response.filter(
      city =>
        (city.CityCode === payload.payload.departure ||
          city.CityCode === payload.payload.arrival) &&
        city.Door2DoorInstructions,
    )
    yield put(actions.fetchDoor2DoorServiceSuccess(filteredResponse || []))
  } catch (error) {
    yield put(actions.fetchDoor2DoorServiceFail())
    yield put(errorActions.setError(error))
  }
}

export function* clearDoor2DoorService$() {
  try {
    yield put(actions.clearDoor2DoorServiceSuccess())
  } catch (error) {
    yield put(actions.clearDoor2DoorServiceFail())
  }
}

export default function*() {
  yield all([
    takeLatest(actions.clearDoor2DoorService, clearDoor2DoorService$),
    takeLatest(actions.fetchInstruction, fetchInstruction$),
    takeLatest(actions.fetchDoor2DoorService, fetchDoor2DoorService$),
  ])
}
