import React from "react"
import styled from "styled-components"

import { TERMS_AND_CONDITIONS } from "../../../constants/routes"
import config from "../../../utils/config"

const BookingConfirmationInfo = () => {
  return (
    <BookingConfirmationInfoContent>
      Your email address will be used to send you the booking confirmation, boarding passes, and
      notifications, by making this purchase you agree to the&nbsp;
      <BoldedA href={`${config.LANDLINE_URL}${TERMS_AND_CONDITIONS}`}>
        Landline.com Terms of Use
      </BoldedA>
    </BookingConfirmationInfoContent>
  )
}

export default BookingConfirmationInfo

const BoldedA = styled.a`
  font-weight: bold;
  display: inline;
`

const BookingConfirmationInfoContent = styled.div`
  padding: 20px 0px;
  font-size: 14px;
  line-height: 20px;
  ${BoldedA} a:link {
    color: #3c3835;
  }

  ${BoldedA} a:visited {
    color: #3c3835;
  }

  ${BoldedA} a:hover {
    color: #3c3835;
  }

  ${BoldedA} a:active {
    color: #3c3835;
  }
`
