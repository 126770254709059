import { Loading as IbeLoading } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"

import loading from "../../../media/landline-loading.gif"

const Loading = ({ src }) => {
  return <IbeLoading src={src} />
}

Loading.propTypes = {
  src: PropTypes.string,
}
Loading.defaultProps = {
  src: loading,
}

export default Loading
