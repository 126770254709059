import { setPickupDetails$ } from "../redux/trip/sagas"
import { DEPART_PICKUP, DEPART_SEATS, PAYMENT, RETURN_PICKUP, RETURN_SEATS } from "./routes"
import { BLACK_CAR_TYPE, BUS_TYPE, EXPRESS_TYPE } from "./transportation-types"

export const BOOKING_FLOW_STARTING_INDEX = -1

const EXPRESS_HOME_TYPE = "4Y"

const BUS = [PAYMENT] // [DEPART_SEATS, PAYMENT]
const BUS_AND_BUS = [PAYMENT] // [DEPART_SEATS, RETURN_SEATS, PAYMENT]
const BUS_AND_SELECT = [RETURN_PICKUP, PAYMENT] // [DEPART_SEATS, RETURN_PICKUP, PAYMENT]
const BUS_AND_EXPRESS = [PAYMENT] // [DEPART_SEATS, RETURN_SEATS, PAYMENT]
const BUS_AND_EXPRESS_HOME = [RETURN_PICKUP, PAYMENT] // [DEPART_SEATS, RETURN_SEATS, RETURN_PICKUP, PAYMENT]

const BUS_EDIT = [PAYMENT]
const BUS_AND_BUS_EDIT = [PAYMENT]
const BUS_AND_SELECT_EDIT = [RETURN_PICKUP, PAYMENT]
const BUS_AND_EXPRESS_EDIT = [PAYMENT]
const BUS_AND_EXPRESS_HOME_EDIT = [RETURN_PICKUP, PAYMENT]

const SELECT = [DEPART_PICKUP, PAYMENT]
const SELECT_AND_SELECT = [DEPART_PICKUP, RETURN_PICKUP, PAYMENT]
const SELECT_AND_BUS = [DEPART_PICKUP, PAYMENT] // [RETURN_SEATS, DEPART_PICKUP, PAYMENT]
const SELECT_AND_EXPRESS = [DEPART_PICKUP, PAYMENT] // [RETURN_SEATS, DEPART_PICKUP, PAYMENT]
const SELECT_AND_EXPRESS_HOME = [DEPART_PICKUP, RETURN_PICKUP, PAYMENT] // [RETURN_SEATS, DEPART_PICKUP, RETURN_PICKUP, PAYMENT]

const SELECT_EDIT = [DEPART_PICKUP, PAYMENT]
const SELECT_AND_SELECT_EDIT = [DEPART_PICKUP, RETURN_PICKUP, PAYMENT]
const SELECT_AND_BUS_EDIT = [DEPART_PICKUP, PAYMENT]
const SELECT_AND_EXPRESS_EDIT = [DEPART_PICKUP, PAYMENT]
const SELECT_AND_EXPRESS_HOME_EDIT = [DEPART_PICKUP, RETURN_PICKUP, PAYMENT]

const EXPRESS = [PAYMENT] // [DEPART_SEATS, PAYMENT]
const EXPRESS_AND_EXPRESS = [PAYMENT] // [DEPART_SEATS, RETURN_SEATS, PAYMENT]
const EXPRESS_AND_BUS = [PAYMENT] // [DEPART_SEATS, RETURN_SEATS, PAYMENT]
const EXPRESS_AND_SELECT = [RETURN_PICKUP, PAYMENT] // [DEPART_SEATS, RETURN_PICKUP, PAYMENT]
const EXPRESS_AND_EXPRESS_HOME = [RETURN_PICKUP, PAYMENT] // [DEPART_SEATS, RETURN_SEATS, RETURN_PICKUP, PAYMENT]

const EXPRESS_EDIT = [PAYMENT]
const EXPRESS_AND_EXPRESS_EDIT = [PAYMENT]
const EXPRESS_AND_BUS_EDIT = [PAYMENT]
const EXPRESS_AND_SELECT_EDIT = [RETURN_PICKUP, PAYMENT]
const EXPRESS_AND_EXPRESS_HOME_EDIT = [RETURN_PICKUP, PAYMENT]

const EXPRESS_HOME = [DEPART_PICKUP, PAYMENT] // [DEPART_SEATS, DEPART_PICKUP, PAYMENT]
const EXPRESS_HOME_AND_EXPRESS_HOME = [
  // DEPART_SEATS,
  // RETURN_SEATS,
  DEPART_PICKUP,
  RETURN_PICKUP,
  PAYMENT,
]
const EXPRESS_HOME_AND_BUS = [DEPART_PICKUP, PAYMENT] // [DEPART_SEATS, RETURN_SEATS, DEPART_PICKUP, PAYMENT]
const EXPRESS_HOME_AND_SELECT = [DEPART_PICKUP, RETURN_PICKUP, PAYMENT] // [DEPART_SEATS, DEPART_PICKUP, RETURN_PICKUP, PAYMENT]
const EXPRESS_HOME_AND_EXPRESS = [DEPART_PICKUP, PAYMENT] // [DEPART_SEATS, RETURN_SEATS, DEPART_PICKUP, PAYMENT]

const EXPRESS_HOME_EDIT = [DEPART_PICKUP, PAYMENT]
const EXPRESS_HOME_AND_EXPRESS_HOME_EDIT = [DEPART_PICKUP, RETURN_PICKUP, PAYMENT]
const EXPRESS_HOME_AND_BUS_EDIT = [DEPART_PICKUP, PAYMENT]
const EXPRESS_HOME_AND_SELECT_EDIT = [DEPART_PICKUP, RETURN_PICKUP, PAYMENT]
const EXPRESS_HOME_AND_EXPRESS_EDIT = [DEPART_PICKUP, PAYMENT]

export const BOOKING_FLOWS = {
  [`${BUS_TYPE}`]: BUS,
  [`${BUS_TYPE}${BUS_TYPE}`]: BUS_AND_BUS,
  [`${BUS_TYPE}${BLACK_CAR_TYPE}`]: BUS_AND_SELECT,
  [`${BUS_TYPE}${EXPRESS_TYPE}`]: BUS_AND_EXPRESS,
  [`${BUS_TYPE}${EXPRESS_HOME_TYPE}`]: BUS_AND_EXPRESS_HOME,

  [`${BLACK_CAR_TYPE}`]: SELECT,
  [`${BLACK_CAR_TYPE}${BLACK_CAR_TYPE}`]: SELECT_AND_SELECT,
  [`${BLACK_CAR_TYPE}${BUS_TYPE}`]: SELECT_AND_BUS,
  [`${BLACK_CAR_TYPE}${EXPRESS_TYPE}`]: SELECT_AND_EXPRESS,
  [`${BLACK_CAR_TYPE}${EXPRESS_HOME_TYPE}`]: SELECT_AND_EXPRESS_HOME,

  [`${EXPRESS_TYPE}`]: EXPRESS,
  [`${EXPRESS_TYPE}${EXPRESS_TYPE}`]: EXPRESS_AND_EXPRESS,
  [`${EXPRESS_TYPE}${BUS_TYPE}`]: EXPRESS_AND_BUS,
  [`${EXPRESS_TYPE}${BLACK_CAR_TYPE}`]: EXPRESS_AND_SELECT,
  [`${EXPRESS_TYPE}${EXPRESS_HOME_TYPE}`]: EXPRESS_AND_EXPRESS_HOME,

  [`${EXPRESS_HOME_TYPE}`]: EXPRESS_HOME,
  [`${EXPRESS_HOME_TYPE}${EXPRESS_HOME_TYPE}`]: EXPRESS_HOME_AND_EXPRESS_HOME,
  [`${EXPRESS_HOME_TYPE}${BUS_TYPE}`]: EXPRESS_HOME_AND_BUS,
  [`${EXPRESS_HOME_TYPE}${BLACK_CAR_TYPE}`]: EXPRESS_HOME_AND_SELECT,
  [`${EXPRESS_HOME_TYPE}${EXPRESS_TYPE}`]: EXPRESS_HOME_AND_EXPRESS,
}

export const EDIT_BOOKING_FLOWS = {
  [`${BUS_TYPE}`]: BUS_EDIT,
  [`${BUS_TYPE}${BUS_TYPE}`]: BUS_AND_BUS_EDIT,
  [`${BUS_TYPE}${BLACK_CAR_TYPE}`]: BUS_AND_SELECT_EDIT,
  [`${BUS_TYPE}${EXPRESS_TYPE}`]: BUS_AND_EXPRESS_EDIT,
  [`${BUS_TYPE}${EXPRESS_HOME_TYPE}`]: BUS_AND_EXPRESS_HOME_EDIT,

  [`${BLACK_CAR_TYPE}`]: SELECT_EDIT,
  [`${BLACK_CAR_TYPE}${BLACK_CAR_TYPE}`]: SELECT_AND_SELECT_EDIT,
  [`${BLACK_CAR_TYPE}${BUS_TYPE}`]: SELECT_AND_BUS_EDIT,
  [`${BLACK_CAR_TYPE}${EXPRESS_TYPE}`]: SELECT_AND_EXPRESS_EDIT,
  [`${BLACK_CAR_TYPE}${EXPRESS_HOME_TYPE}`]: SELECT_AND_EXPRESS_HOME_EDIT,

  [`${EXPRESS_TYPE}`]: EXPRESS_EDIT,
  [`${EXPRESS_TYPE}${EXPRESS_TYPE}`]: EXPRESS_AND_EXPRESS_EDIT,
  [`${EXPRESS_TYPE}${BUS_TYPE}`]: EXPRESS_AND_BUS_EDIT,
  [`${EXPRESS_TYPE}${BLACK_CAR_TYPE}`]: EXPRESS_AND_SELECT_EDIT,
  [`${EXPRESS_TYPE}${EXPRESS_HOME_TYPE}`]: EXPRESS_AND_EXPRESS_HOME_EDIT,

  [`${EXPRESS_HOME_TYPE}`]: EXPRESS_HOME_EDIT,
  [`${EXPRESS_HOME_TYPE}${EXPRESS_HOME_TYPE}`]: EXPRESS_HOME_AND_EXPRESS_HOME_EDIT,
  [`${EXPRESS_HOME_TYPE}${BUS_TYPE}`]: EXPRESS_HOME_AND_BUS_EDIT,
  [`${EXPRESS_HOME_TYPE}${BLACK_CAR_TYPE}`]: EXPRESS_HOME_AND_SELECT_EDIT,
  [`${EXPRESS_HOME_TYPE}${EXPRESS_TYPE}`]: EXPRESS_HOME_AND_EXPRESS_EDIT,
}
