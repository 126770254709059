import { all, put, takeLatest } from "redux-saga/effects"

import { httpGet, httpPost, httpPut } from "../../utils/request"
import accountActions from "../account/actions"
import errorActions from "../error/actions"
import actions from "./actions"

export function* activateLoyalty({ payload }) {
  try {
    yield put(errorActions.clearError())
    yield put(actions.activateLoyaltySuccess(payload))
  } catch (error) {
    yield put(actions.activateLoyaltyFail())
    yield put(errorActions.setError(error))
  }
}

export function* checkLoyaltyPointsPrice({ payload }) {
  try {
    yield put(errorActions.clearError())
    const params = { points_amount: payload.points }
    const response = yield httpPost("buy_loyalty_points/", params)
    yield put(
      actions.checkLoyaltyPointsPriceSuccess({
        priceForPoints: response.price_of_purchased_points,
        pointsBeforePayment: payload.pointsBeforePayment,
      }),
    )
  } catch (error) {
    yield put(actions.checkLoyaltyPointsPriceFail())
    yield put(errorActions.setError(error))
  }
}

export function* buyLoyaltyPoints({ payload }) {
  try {
    yield put(errorActions.clearError())
    const params = {
      stripe_token: payload.stripeToken.id,
      points_amount: payload.pointsAmount,
    }
    const response = yield httpPut("buy_loyalty_points/", params)
    // refresh points in redux store
    yield put(accountActions.fetchUserProfile())
    yield put(actions.buyLoyaltyPointsSuccess(response))
  } catch (error) {
    yield put(actions.buyLoyaltyPointsFail())
    yield put(errorActions.setError(error))
  }
}

export function* getMileMarkerAccrualLog$() {
  try {
    const response = yield httpGet("points_records/")
    yield put(actions.getMileMarkerAccrualLogSuccess({ response }))
  } catch (error) {
    yield put(actions.getMileMarkerAccrualLogFail())
    yield put(errorActions.setError(error))
  }
}
export function* updateLoyaltyFields({ payload }) {
  try {
    yield put(actions.getMileMarkerAccrualLogSuccess(payload))
  } catch (error) {
    yield put(actions.updateLoyaltyFieldsFail())
    yield put(errorActions.setError(error))
  }
}

export default function*() {
  yield all([takeLatest(actions.activateLoyalty, activateLoyalty)])
  yield all([takeLatest(actions.buyLoyaltyPoints, buyLoyaltyPoints)])
  yield all([takeLatest(actions.checkLoyaltyPointsPrice, checkLoyaltyPointsPrice)])
  yield all([takeLatest(actions.getMileMarkerAccrualLog, getMileMarkerAccrualLog$)])
}
