export const defaultPassengerParams = {
  title: "",
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  gender: [],
  no_flight: false,
  special_requirements: [],
  airline: "",
  final_destination: "",
  flight_number: "",
  departure_seats: [],
  return_seats: [],
  valid: false,
}

export default () => ({
  index: -1,
  error: undefined,
  isBusy: false,
  response: undefined,
  seat: {
    departureCompleted: false,
    returnCompleted: false,
  },
})
