import "./LoyaltyReward.scss"

import classNames from "classnames"
import { PageTitle, Price } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"

import featureFlags from "../../../constants/feature-flags"

const LoyaltyReward = ({ title, reward, loyaltyBalance, penguin }) => {
  const loyaltyRewardClass = classNames("loyalty-reward-wrapper")
  const trips_needed = penguin.tier_upgrade_threshold - penguin.eligible_trips

  if (!featureFlags().loyaltyFeatureFlag) {
    return null
  }

  return (
    <div className={loyaltyRewardClass}>
      <PageTitle title={title} fontSize="20px" margin="20px 0 0 0" />
      <div className="loyalty-reward-container">
        <div className="loyalty-reward-wrapper-item loyalty-reward-balance">
          <div>Your tier</div>
          <div className="loyalty-reward-wrapper-item-price">
            {/* <Price floor amount={loyaltyBalance} currency="PTS" loyaltyStyle useLoyalty={true} /> */}
            {penguin.tier.toUpperCase()}
          </div>
        </div>
        {trips_needed > 0 &&
        <div className="loyalty-reward-wrapper-item loyalty-reward-value">
          <div className="wrapper">
            <div>Trips needed to upgrade</div>
            <div className="loyalty-info">(before current purchase)</div>
          </div>
          <div className="loyalty-reward-wrapper-item-price">
            {/* <Price floor amount={reward} currency="PTS" loyaltyStyle useLoyalty={true} /> */}
            {trips_needed}
          </div>
        </div>
        }
        {/* <div className="loyalty-reward-wrapper-item loyalty-reward-new-balance">
          <div>You will get a free trip </div>
          <div className="loyalty-reward-wrapper-item-price">
            <Price floor amount={loyaltyBalance + reward} currency="PTS" loyaltyStyle useLoyalty={true} />
          </div>
        </div> */}
      </div>
    </div>
  )
}

LoyaltyReward.propTypes = {
  title: PropTypes.string,
  reward: PropTypes.number.isRequired,
  loyaltyBalance: PropTypes.number.isRequired,
}

LoyaltyReward.defaultProps = {
  title: "Penguin Perks",
}

export default LoyaltyReward
