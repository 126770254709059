const ColoradoLocations = [
    {
        image: "https://content.landline.com/wp-content/uploads/2024/09/DEN-Airport.jpeg",
        title: "Denver International Airport",
        prices: "",
        content: "Please present your ID to the driver to check in. Pickup is located on the West Side of the Terminal on Baggage Claim Level 5 directly outside doors 500-504, immediately curbside. For passengers who booked Landline service with United Airlines, we pick up at Gate B83.",
        button: "Denver Service and Schedule",
        url: "/denver",
    },
    {
        image: "https://content.landline.com/wp-content/uploads/2024/09/Fort-Collins-Stock-Image.jpeg",
        title: "Fort Collins/Loveland, CO",
        prices: "",
        content: "Landline offers service to Fort Collins/Loveland from four convenient locations: CSU Transit Center (CSU), Harmony Transfer Center (HTC), and the Northern Colorado Regional Airport - FREE Parking (FNL).",
        button: "Fort Collins / Loveland Service and Schedule",
        url: "/fort-collins"
    }
]

const MNWLocations = [
    {
        image: "https://content.landline.com/wp-content/uploads/2024/09/Minneapolis-Stock-Image.jpeg",
        title: "Minneapolis-St. Paul International Airport",
        prices: "",
        content: "Terminal 1 pick up is in on level 1 of the Silver Ramp (follow signs for car rental and buses) of the Minneapolis-St. Paul International Airport. Terminal 2 pick up is located on level 1 of the Purple Parking Ramp building (ground transportation center), located across the street from Sun Country baggage claim.",
        button: "Minneapolis Service and Schedule",
        url: "/msp"
    },
    {
        image: "https://content.landline.com/wp-content/uploads/2024/09/Duluth-MN-Stock-Image.jpeg",
        title: "Duluth, MN",
        prices: "",
        content: "Landline offers service to the Duluth International Airport (DLH) in Duluth, MN. The pick-up and drop off point is in front of the main lobby entrance. For more information about our shuttle service to DLH including parking information, please view our Duluth page.",
        button: "Duluth Service and Schedule",
        url: "/duluth"
    },
    {
        image: "https://content.landline.com/wp-content/uploads/2024/05/Fargo_Location.jpg",
        title: "Fargo, ND",
        prices: "",
        content: "Landline offers service to the Hector International Airport (FAR) in Fargo, ND. The pick-up and drop off point is in front of the main terminal entrance. For more information about our shuttle service to FAR including parking information, please view our Fargo page.",
        button: "Fargo Service and Schedule",
        url: "/fargo"
    }

]

export {
    ColoradoLocations, MNWLocations
} 