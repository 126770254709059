import actions from "./actions"
import initialState from "./initialState"

export default (state = initialState(), action) => {
  const { type, payload } = action
  switch (type) {
    case actions.LOGIN_USER:
    case actions.REGISTER_USER:
    case actions.FETCH_USER_PROFILE:
    case actions.EDIT_USER_PROFILE:
    case actions.CHANGE_PASSWORD:
    case actions.ACTIVATE_USER:
    case actions.GET_REFERRAL_DATA:
      return {
        ...state,
        isBusy: true,
      }
    case actions.GET_REFERRAL_DATA_SUCCESS:
      return {
        ...state,
        referral: {
          ...payload,
        },
        isBusy: false,
      }
    case actions.GET_REFERRAL_DATA_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChanged: true,
        isBusy: false,
      }
    case actions.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        emailActivated: payload,
        isBusy: false,
      }
    case actions.AUTHENTICATE_USER_SUCCESS:
    case actions.LOGIN_USER_SUCCESS:
    case actions.REGISTER_USER_SUCCESS:
      return {
        ...initialState(),
        isAuthenticated: true,
      }
    case actions.LOGOUT_USER_SUCCESS:
      localStorage.clear()
      return {
        ...initialState(),
      }
    case actions.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isBusy: false,
        profile: {
          ...payload,
          updated: false,
          data: {
            ...payload,
          },
        },
      }
    case actions.EDIT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isBusy: false,
        profile: {
          ...payload,
          updated: true,
          data: {
            ...payload,
          },
        },
      }
    case actions.LOGIN_USER_FAIL:
    case actions.REGISTER_USER_FAIL:
      return {
        ...initialState(),
      }
    case actions.SET_FORM_FIELD_VALUE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          data: {
            ...((state.profile || {}).data || {}),
            [payload.field]: payload.value,
          },
        },
      }
    case actions.FETCH_USER_PROFILE_FAIL:
    case actions.EDIT_USER_PROFILE_FAIL:
    case actions.CHANGE_PASSWORD_FAIL:
    case actions.ACTIVATE_USER_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.RECOVER_PASSWORD:
    case actions.VERIFY_PASSWORD_RECOVERY_CODE:
    case actions.RESET_PASSWORD:
      return {
        ...state,
        isBusy: true,
      }
    case actions.RECOVER_PASSWORD_FAIL:
    case actions.RESET_PASSWORD_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.VERIFY_PASSWORD_RECOVERY_CODE_FAIL:
      return {
        ...state,
        isBusy: false,
        password: {
          recovery_code_active: false,
        },
      }
    case actions.VERIFY_PASSWORD_RECOVERY_CODE_SUCCESS:
      return {
        ...state,
        isBusy: false,
        password: {
          recovery_code_active: true,
        },
      }
    case actions.RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        isBusy: false,
        password: {
          ...payload,
        },
      }
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isBusy: false,
        password: {
          ...state.password,
          ...payload,
          changed: true,
        },
      }

    case actions.SET_TOKEN_ERROR_SUCCESS:
      return {
        ...state,
        tokenError: action.payload,
      }

    case actions.CLEAR_TOKEN_ERROR_SUCCESS:
      return {
        ...state,
        tokenError: undefined,
      }

    default:
      return state
  }
}
