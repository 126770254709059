import "./ReservationReturnPage.scss"

import {
  EmptyState,
  Icon,
  ItinerarySingleRow,
  Modal,
  NotificationConsumer,
  ReservationHeader,
  SearchFilters,
} from "ibe-components"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, withRouter } from "react-router-dom"
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, WindowScroller } from "react-virtualized"

import featureFlags from "../../../constants/feature-flags"
import { RETURN_TICKET } from "../../../constants/ticket"
import errorActions from "../../../redux/error/actions"
import reservationActions from "../../../redux/reservations/actions"
import {
  accountSelector,
  errorSelector,
  itinerarySelector,
  loyaltySelector,
  reservationsSelector,
  ticketSelector,
  tripSelector,
} from "../../../redux/selector"
import ticketActions from "../../../redux/ticket/actions"
import tripActions from "../../../redux/trip/actions"
import {
  decreaseDateBy,
  increaseDateBy,
  isDateAfter,
  toDateFormatString,
} from "../../../utils/common"
import {
  calculateSeatsLeft,
  findExpressAtHomeSegment,
  getCityName,
  getSectionType,
} from "../../../utils/tripUtil-util"
import Breadcrumbs from "../../atoms/Breadcrumbs"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"
import Search from "../HomePage/Search"

const ReservationReturnPage = ({ history }) => {
  const reservations = useSelector(state => reservationsSelector(state))
  const bookingTicket = useSelector(state => ticketSelector(state))
  const itinerary = useSelector(state => itinerarySelector(state))
  const { isAuthenticated } = useSelector(state => accountSelector(state))
  const loyalty = useSelector(state => loyaltySelector(state))
  const trip = useSelector(state => tripSelector(state))

  const isLoyaltyActive = loyalty.isActive

  const error = useSelector(state => errorSelector(state))

  const dispatch = useDispatch()

  const setReservationProperty = useCallback(
    field => value => dispatch(reservationActions.setReservationProperty({ field, value })),
    [dispatch],
  )
  const setTicketByType = useCallback(params => dispatch(ticketActions.setTicketByType(params)), [
    dispatch,
  ])
  const setTicketIndexByType = useCallback(
    params => dispatch(ticketActions.setTicketIndexByType(params)),
    [dispatch],
  )

  const updateReservation = useCallback(() => dispatch(reservationActions.updateReservation()), [
    dispatch,
  ])

  const clearFilters = useCallback(() => dispatch(reservationActions.clearFilters()), [dispatch])

  const clearFlightDetails = useCallback(() => dispatch(tripActions.clearFlightDetails()), [
    dispatch,
  ])

  const setFilterProperty = useCallback(
    filter => dispatch(reservationActions.setFilterProperty(filter)),
    [dispatch],
  )

  const getFlightDetails = useCallback(
    flightRequestData => dispatch(tripActions.getFlightDetails(flightRequestData)),
    [dispatch],
  )

  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])

  const returnToRef = useRef(null)
  const params = reservations.params || {}

  const [state, setState] = useState({
    departureTo: params.dep_to,
    departureFrom: params.dep_from,
    shouldShowTripSummaryModal: false,
    currentDepartureItineraryIndex: -1,
    currentReturnItineraryIndex: -1,
    error: undefined,
    redirectToPassengers: false,
    mustLogin: false,
    filters: { ...reservations.filters },
    showModalFilters: false,
  })

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...reservations.filters,
      },
    }))
  }, [reservations.filters])

  const setReturnTicketIndex = index =>
    setTicketIndexByType({
      type: RETURN_TICKET,
      index,
    })
  const setReturnTicket = ({
    segments,
    segmentIndex,
    ticket,
    ticketIndex,
    summaryPrice,
    layoverTimes,
    totalTripTime,
    bookingToken,
  }) =>
    setTicketByType({
      type: RETURN_TICKET,
      segments,
      segmentIndex,
      ticket,
      ticketIndex,
      summaryPrice,
      layoverTimes,
      totalTripTime,
      returnFlights: bookingTicket.returnFlights,
      bookingToken,
    })

  useEffect(() => {
    clearFlightDetails()
    if (isLoyaltyActive && !isAuthenticated) {
      setState(prevState => ({
        ...prevState,
        mustLogin: true,
      }))
    }
  }, [])

  const setReturnDate = date => {
    setReturnTicket({
      segments: undefined,
      segmentIndex: -1,
      ticket: undefined,
      ticketIndex: -1,
      summaryPrice: undefined,
      layoverTimes: undefined,
      totalTripTime: undefined,
      returnFlights: bookingTicket.returnFlights,
    })
    setReservationProperty("return_date")(date)
  }
  const onDecreaseReturnDateClick = () => {
    if (isDateAfter(params.return_date, new Date())) {
      const date = decreaseDateBy(params.return_date, 1)
      if (!isDateAfter(date, params.departure_date)) {
        setReservationProperty("departure_date")(date)
      }
      setReturnDate(date)
      updateReservation()
    }
  }
  const onIncreaseReturnDateClick = () => {
    const date = increaseDateBy(params.return_date, 1)
    setReturnDate(date)
    updateReservation()
  }

  const onReturnTicketClick = (
    inheritTicket,
    ticketIndex,
    itineraryIndex,
    segmentIndex,
    segments,
    summaryPrice,
    layoverTimes,
    totalTripTime,
    _returnFlights,
    bookingToken,
  ) => {
    setReturnTicketIndex(ticketIndex)
    setState(prevState => ({
      ...prevState,
      currentReturnItineraryIndex: itineraryIndex,
      redirectToPassengers: true,
    }))
    setReturnTicket({
      segments,
      segmentIndex,
      ticket: inheritTicket,
      ticketIndex,
      summaryPrice,
      layoverTimes,
      totalTripTime,
      bookingToken,
      returnFlights: bookingTicket.returnFlights,
    })
  }

  const onCabinTypeChange = e => {
    const cabin = e.target.value
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        cabins_class: cabin,
      },
    }))
  }
  const onAirlineFilterChange = (e, index) => {
    const { checked } = e.target

    const newAirlines = [...state.filters.airline_filter] // copy the state => anti shadowing

    newAirlines[index] = { ...newAirlines[index], checked } // modify single index instance of airline_filter

    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        airline_filter: newAirlines,
      },
    }))
  }

  const onApplyFilters = () => {
    setState(prevState => ({ ...prevState, showModalFilters: false }))
    setFilterProperty(state.filters)
  }

  const onResetFilters = () => {
    setState(prevState => ({ ...prevState, showModalFilters: false }))
    setState(prevState => ({ ...prevState, filters: { ...reservations.filters } }))
    clearFilters()
  }

  const onMaxLayoverTimeChange = value => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        max_layover: value,
      },
    }))
  }

  const onMaxStopoversChange = e => {
    const maxStops = Number(e.target.value) - 1 // RadioGrup trash component, needs refactor
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        max_stopovers: maxStops,
      },
    }))
  }

  const onTotalTripTimeChange = value => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        total_trip_time_max: value,
      },
    }))
  }

  const onConnectingAirportsChange = (e, index) => {
    const { checked } = e.target

    const connectingAirports = [...state.filters.connecting_airports] // copy the state => anti shadowing

    connectingAirports[index] = { ...connectingAirports[index], checked } // modify single index instance of airline_filter

    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        connecting_airports: connectingAirports,
      },
    }))
  }

  const showFlightFilters =
    featureFlags().showFlightSearchFeatureFlag && reservations.items.is_interlined

  const { currentReturnItineraryIndex } = state

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 229,
  })

  const listRef = useRef()

  const [collapsed, setCollapsed] = useState({})

  useEffect(() => {
    if (listRef.current) {
      listRef.current.recomputeRowHeights()
      cache.clearAll()
    }
  }, [collapsed])

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style,
    parent,
  }) => {
    const data = bookingTicket.returnFlights[index]
    const {
      segments,
      trip_summary: summaryPrice,
      layover_times: layoverTimes,
      total_trip_time: totalTripTime,
      booking_token: bookingToken,
    } = data
    const { tickets } = segments[0]
    const seatsLeft = calculateSeatsLeft(summaryPrice)

    const additionalCharge = isLoyaltyActive
      ? bookingTicket.departureTicketSummaryPrice.price_in_miles
      : bookingTicket.departureTicketSummaryPrice.landline_price

    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          <ItinerarySingleRow
            additionalCharge={additionalCharge || 0}
            oneWay={params.one_way}
            isCollapsableOpen={collapsed[index]}
            useLoyalty={isLoyaltyActive}
            itineraryIndex={index}
            selectedItineraryIndex={currentReturnItineraryIndex}
            selectedTicketIndex={bookingTicket.departureTicketIndex}
            seatsLeft={seatsLeft}
            totalTripTime={totalTripTime}
            passengersAdults={params.passengers_adults}
            passengersChildrens={params.passengers_children}
            passengersInfants={params.passengers_infants}
            onTicketClicked={onReturnTicketClick}
            onCollapse={newColap => {
              setCollapsed(prevState => ({ ...prevState, ...newColap }))
            }}
            bookingToken={bookingToken}
            segments={segments}
            layoverTimes={layoverTimes}
            departureCode={data.departure_code}
            arrivalCode={data.arrival_code}
            priceCeil={isLoyaltyActive}
            currencySymbol={isLoyaltyActive ? "PTS" : "USD"}
            segmentIndex={0}
            summaryPrice={summaryPrice.tickets}
            tickets={tickets}
            classbands={reservations.items.classbands}
            flightSearchActive={featureFlags().showFlightSearchFeatureFlag}
            getFlightDetails={getFlightDetails}
            luggage={trip.flightDetails[index]}
            loading={trip.flightDetails.isBusy}
            showMapOnReturnScreen={!findExpressAtHomeSegment(bookingTicket.departureTicket)}
          />
        </div>
      </CellMeasurer>
    )
  }
  rowRenderer.propTypes = {
    parent: PropTypes.instanceOf(Object).isRequired,
    key: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    style: PropTypes.instanceOf(Object).isRequired,
  }
  if (!params.dep_from) {
    return <Redirect to="/" />
  }
  return (
    <ErrorBoundary>
      <NotificationConsumer>
        {({ actions }) => {
          const isPrevDisabled = () => {
            const currentDate = moment(params.return_date).format("MM DD YYYY")
            const minDate = moment(params.departure_date).format("MM DD YYYY")

            return moment(currentDate).isSame(minDate)
          }
          return (
            <PageLayout
              render={() => {
                if (
                  reservations.isBusy ||
                  itinerary.isBusy ||
                  bookingTicket.isBusy ||
                  (error && error.isBusy)
                ) {
                  return <Loading />
                }
                if (state.error) {
                  actions.show({ type: "is-danger", message: state.error })
                  setState(prevState => ({ ...prevState, error: undefined }))
                }
                if (error) {
                  actions.show({ type: "is-danger", message: error })
                  clearError()
                }
                if (state.redirectToPassengers) {
                  history.push("/reservations/summary")
                }
                if (state.mustLogin) {
                  actions.show({
                    type: "is-danger",
                    message: "Please log in to your account to activate your Penguin Perks benefits.",
                  })
                  setState(prevState => ({ ...prevState, mustLogin: false }))
                }
                return (
                  <div className="reservation-return-page-container">
                    <Breadcrumbs
                      history={history}
                      className="reservation-return-page-breadcrumbs"
                    />

                    <div className="SectionWrapper reservation-section-wrapper">
                      <div className="reservation-return-wrapper">
                        <div className="reservation-container">
                          {!params.one_way && (
                            <div ref={returnToRef}>
                              <ReservationHeader
                                searchPadding={getSectionType()}
                                from={params.dep_to}
                                to={params.dep_from}
                                date={toDateFormatString(params.return_date, "dddd, MMM DD, YYYY")}
                                onPrevClicked={onDecreaseReturnDateClick}
                                onNextClicked={onIncreaseReturnDateClick}
                                search={<Search history={history} remoteCallSearchComponent />}
                                getCityName={getCityName}
                                getMobileCityName={n => n.code}
                                prevDisabled={isPrevDisabled()}
                              />
                              {showFlightFilters && (
                                <div className="filters-mobile-container">
                                  <div
                                    className="filters-mobile-wrapper"
                                    onClick={() => {
                                      setState(prevState => ({
                                        ...prevState,
                                        showModalFilters: true,
                                      }))
                                    }}
                                  >
                                    <Icon
                                      name="filterIcon"
                                      noAbsolute
                                      withoutProps
                                      width="21"
                                      height="18"
                                    />
                                    <span className="text">Filter Results</span>
                                  </div>
                                </div>
                              )}
                              <Modal
                                showFooterButtons={false}
                                isOpen={state.showModalFilters}
                                onCancel={() => {
                                  setState(prevState => ({ ...prevState, showModalFilters: false }))
                                }}
                                onSuccess={() => {
                                  setState(prevState => ({ ...prevState, showModalFilters: false }))
                                }}
                                title="Filter Results"
                                render={() => {
                                  return (
                                    <>
                                      <SearchFilters
                                        cabinTypes={state.filters.cabins_class}
                                        onCabinTypeChange={onCabinTypeChange}
                                        maxStopovers={state.filters.max_stopovers}
                                        onMaxStopoverChange={() => {}}
                                        maxLayover={state.filters.max_layover}
                                        onMaxLayoverTimeChange={onMaxLayoverTimeChange}
                                        airlineFilter={state.filters.airline_filter}
                                        totalTripTime={state.filters.total_trip_time_max}
                                        onTotalTripTimeChange={onTotalTripTimeChange}
                                        onAirlineFilterChange={onAirlineFilterChange}
                                        onApplyFilters={onApplyFilters}
                                        onMaxStopoversChange={onMaxStopoversChange}
                                        connectingAirports={state.filters.connecting_airports}
                                        onConnectingAirportsChange={onConnectingAirportsChange}
                                        modal
                                      />
                                    </>
                                  )
                                }}
                              />

                              <div className="reservation-body">
                                <h2 className="departure-date">
                                  Return: {moment(params.return_date).format("MM/DD/YYYY")}
                                </h2>
                                <div className="itinerary-list-container">
                                  {bookingTicket.returnFlights.length > 0 ? (
                                    <WindowScroller>
                                      {({ height, isScrolling, onChildScroll, scrollTop }) => {
                                        return (
                                          <AutoSizer disableHeight>
                                            {({ width }) => {
                                              return (
                                                <List
                                                  isScrolling={isScrolling}
                                                  onScroll={onChildScroll}
                                                  scrollTop={scrollTop}
                                                  autoHeight
                                                  ref={listRef}
                                                  deferredMeasurementCache={cache}
                                                  height={height}
                                                  rowRenderer={rowRenderer}
                                                  rowCount={bookingTicket.returnFlights.length}
                                                  width={width}
                                                  rowHeight={cache.rowHeight}
                                                />
                                              )
                                            }}
                                          </AutoSizer>
                                        )
                                      }}
                                    </WindowScroller>
                                  ) : (
                                    <EmptyState />
                                  )}
                                </div>
                                {showFlightFilters && (
                                  <div className="filters-container">
                                    <SearchFilters
                                      cabinTypes={state.filters.cabins_class}
                                      onCabinTypeChange={onCabinTypeChange}
                                      maxStopovers={state.filters.max_stopovers}
                                      onMaxStopoverChange={() => {}}
                                      maxLayover={state.filters.max_layover}
                                      totalTripTime={state.filters.total_trip_time_max}
                                      onTotalTripTimeChange={onTotalTripTimeChange}
                                      onMaxLayoverTimeChange={onMaxLayoverTimeChange}
                                      airlineFilter={state.filters.airline_filter}
                                      onAirlineFilterChange={onAirlineFilterChange}
                                      onApplyFilters={onApplyFilters}
                                      onResetFilters={onResetFilters}
                                      onMaxStopoversChange={onMaxStopoversChange}
                                      connectingAirports={state.filters.connecting_airports}
                                      onConnectingAirportsChange={onConnectingAirportsChange}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }}
            />
          )
        }}
      </NotificationConsumer>
    </ErrorBoundary>
  )
}

ReservationReturnPage.propTypes = {
  history: PropTypes.instanceOf(Object),
}
ReservationReturnPage.defaultProps = {
  history: {},
}

export default withRouter(ReservationReturnPage)
