import actions from "./actions"
import initialState from "./initialState"

export default (state = initialState(), action) => {
  const { type, payload } = action
  switch (type) {
    case actions.GET_CART:
    case actions.ADD_CART_ITEM:
    case actions.REMOVE_CART_ITEM:
    case actions.CLEAR_CART_ITEMS:
    case actions.CHECKOUT_CART:
    case actions.GET_ALL_CART_PRODUCTS:
    case actions.ASSIGN_CART_TO_USER:
    case actions.RESET_CART:
    case actions.ASSIGN_PREVIOUS_CART:
    case actions.GET_REFUND_DETAILS:
      return {
        ...state,
        isBusy: true,
      }

    case actions.GET_CART_SUCCESS:
    case actions.ADD_CART_ITEM_SUCCESS:
    case actions.REMOVE_CART_ITEM_SUCCESS:
    case actions.CLEAR_CART_ITEMS_SUCCESS:
    case actions.CHECKOUT_CART_SUCCESS:
    case actions.ASSIGN_CART_TO_USER_SUCCESS:
    case actions.ASSIGN_PREVIOUS_CART_SUCCESS:
      return {
        ...state,
        ...payload,
        isBusy: false,
      }
    case actions.CHECKOUT_CART_FAIL:
      return {
        ...state,
        isBusy: false,
        showPayButton: true,
      }

    case actions.GET_CART_FAIL:
    case actions.ADD_CART_ITEM_FAIL:
    case actions.REMOVE_CART_ITEM_FAIL:
    case actions.CLEAR_CART_ITEMS_FAIL:
    case actions.GET_ALL_CART_PRODUCTS_FAIL:
    case actions.ASSIGN_CART_TO_USER_FAIL:
    case actions.ASSIGN_PREVIOUS_CART_FAIL:
    case actions.GET_REFUND_DETAILS_FAIL:
      return {
        ...state,
        isBusy: false,
      }

    case actions.GET_ALL_CART_PRODUCTS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        allCategories: payload,
      }

    case actions.RESET_CART_SUCCESS:
      return initialState()

    case actions.RESET_CART_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.GET_REFUND_DETAILS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        refundDetails: payload,
      }
    default:
      return state
  }
}
