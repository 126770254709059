import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const clearSearch = generateSagaActions(
  "[RESERVATION] Clear reservation search",
  "CLEAR_SEARCH",
  "clearSearch",
  ["FAIL", "SUCCESS"],
  createAction,
)
const setReservationProperty = generateSagaActions(
  "[RESERVATION] Set reservation property",
  "SET_RESERVATION_PROPERTY",
  "setReservationProperty",
  ["FAIL", "SUCCESS"],
  createAction,
)

const fetchReservationAllCities = generateSagaActions(
  "[RESERVATION] Fetch reservation all cities",
  "FETCH_RESERVATION_ALL_CITIES",
  "fetchReservationAllCities",
  ["FAIL", "SUCCESS"],
  createAction,
)

const fetchBlackCarCities = generateSagaActions(
  "[RESERVATION] Fetch black car cities",
  "FETCH_BLACK_CAR_CITIES",
  "fetchBlackCarCities",
  ["FAIL", "SUCCESS"],
  createAction,
)

const fetchReservationCityPair = generateSagaActions(
  "[RESERVATION] Fetch reservation to city",
  "FETCH_RESERVATION_CITY_PAIR",
  "fetchReservationCityPair",
  ["FAIL", "SUCCESS"],
  createAction,
)

const fetchReservationString = generateSagaActions(
  "[RESERVATION] Fetch reservation string",
  "FETCH_RESERVATION_STRING",
  "fetchReservationString",
  ["FAIL", "SUCCESS"],
  createAction,
)

const updateReservation = generateSagaActions(
  "[RESERVATION] Update reservation",
  "UPDATE_RESERVATION",
  "updateReservation",
  ["FAIL", "SUCCESS"],
  createAction,
)

const fetchDepartureSegments = generateSagaActions(
  "[RESERVATION] Fetch reservation departure segments",
  "FETCH_DEPARTURE_SEGMENTS",
  "fetchDepartureSegments",
  ["FAIL", "SUCCESS"],
  createAction,
)

const fetchReturnSegments = generateSagaActions(
  "[RESERVATION] Fetch reservation return segments",
  "FETCH_RETURN_SEGMENTS",
  "fetchReturnSegments",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setFilterProperty = generateSagaActions(
  "[RESERVATION] Set Filter Property",
  "SET_FILTER_PROPERTY",
  "setFilterProperty",
  ["FAIL", "SUCCESS"],
  createAction,
)

const clearFilters = generateSagaActions(
  "[RESERVATION] Clear Filters",
  "CLEAR_FILTERS",
  "clearFilters",
  ["FAIL", "SUCCESS"],
  createAction,
)

const checkHeartbeat = generateSagaActions(
  "[RESERVATION] Check Hearbeat",
  "CHECK_HEARTBEAT",
  "checkHeartbeat",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setShouldCheckHeartbeat = generateSagaActions(
  "[RESERVATION] Set Should Check Heartbeat",
  "SET_SHOULD_CHECK_HEARTBEAT",
  "setShouldCheckHeartbeat",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...clearSearch.actionMap,
  ...clearSearch.actionCreatorMap,
  ...setReservationProperty.actionMap,
  ...setReservationProperty.actionCreatorMap,
  ...fetchReservationAllCities.actionMap,
  ...fetchReservationAllCities.actionCreatorMap,
  ...fetchBlackCarCities.actionMap,
  ...fetchBlackCarCities.actionCreatorMap,
  ...fetchReservationCityPair.actionMap,
  ...fetchReservationCityPair.actionCreatorMap,
  ...updateReservation.actionMap,
  ...updateReservation.actionCreatorMap,
  ...fetchDepartureSegments.actionMap,
  ...fetchDepartureSegments.actionCreatorMap,
  ...fetchReturnSegments.actionMap,
  ...fetchReturnSegments.actionCreatorMap,
  ...setFilterProperty.actionMap,
  ...setFilterProperty.actionCreatorMap,
  ...clearFilters.actionMap,
  ...clearFilters.actionCreatorMap,
  ...fetchReservationString.actionMap,
  ...fetchReservationString.actionCreatorMap,
  ...checkHeartbeat.actionMap,
  ...checkHeartbeat.actionCreatorMap,
  ...setShouldCheckHeartbeat.actionMap,
  ...setShouldCheckHeartbeat.actionCreatorMap,
}
