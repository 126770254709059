import "./TabLayout.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"

const TabLayout = ({ titles, contents }) => {
  const tabLayoutClass = classNames("tab-layout-container")

  const [activeIndex, setActiveIndex] = useState(0)
  const onTabClick = useCallback(
    index => {
      setActiveIndex(index)
    },
    [setActiveIndex],
  )

  return (
    <div className={tabLayoutClass}>
      <div className="tabs-navigation">
        {titles.map((title, index) => {
          return (
            <Tab
              title={title}
              key={title}
              index={index}
              onTabClick={onTabClick}
              active={activeIndex}
            />
          )
        })}
      </div>
      <div className="tab-content" key={activeIndex}>
        <div className="SectionWrapper">{contents[activeIndex]}</div>
      </div>
    </div>
  )
}

TabLayout.propTypes = {
  titles: PropTypes.instanceOf(Array).isRequired,
  contents: PropTypes.instanceOf(Array).isRequired,
}

const Tab = ({ title, onTabClick, active, index }) => {
  const tabContainer = classNames("tab-container", {
    activeTab: index === active,
  })

  const onTabClickHandler = useCallback(() => {
    onTabClick(index)
  }, [onTabClick, index])

  return (
    <div className={tabContainer} onClick={onTabClickHandler}>
      {title}
    </div>
  )
}

Tab.propTypes = {
  index: PropTypes.number.isRequired,
  active: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
}

export default TabLayout
