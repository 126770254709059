import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const getCart = generateSagaActions(
  "[CART] Get cart",
  "GET_CART",
  "getCart",
  ["FAIL", "SUCCESS"],
  createAction,
)

const addCartItem = generateSagaActions(
  "[CART] Add cart item",
  "ADD_CART_ITEM",
  "addCartItem",
  ["FAIL", "SUCCESS"],
  createAction,
)

const removeCartItem = generateSagaActions(
  "[CART] Remove cart item",
  "REMOVE_CART_ITEM",
  "removeCartItem",
  ["FAIL", "SUCCESS"],
  createAction,
)

const clearCartItems = generateSagaActions(
  "[CART] Clear cart items",
  "CLEAR_CART_ITEMS",
  "clearCartItems",
  ["FAIL", "SUCCESS"],
  createAction,
)

const getAllCartProducts = generateSagaActions(
  "[CART] Get all cart products",
  "GET_ALL_CART_PRODUCTS",
  "getAllCartProducts",
  ["FAIL", "SUCCESS"],
  createAction,
)

const assingCartToUser = generateSagaActions(
  "[CART] Assing cart to user",
  "ASSIGN_CART_TO_USER",
  "assingCartToUser",
  ["FAIL", "SUCCESS"],
  createAction,
)

const checkoutCart = generateSagaActions(
  "[CART] Checkout cart",
  "CHECKOUT_CART",
  "checkoutCart",
  ["FAIL", "SUCCESS"],
  createAction,
)

const resetCart = generateSagaActions(
  "[CART] Reset cart",
  "RESET_CART",
  "resetCart",
  ["FAIL", "SUCCESS"],
  createAction,
)

const assignPreviousCart = generateSagaActions(
  "[CART] Assign Previous Cart",
  "ASSIGN_PREVIOUS_CART",
  "assignPreviousCart",
  ["FAIL", "SUCCESS"],
  createAction,
)

const getRefundDetails = generateSagaActions(
  "[CART] Get Refund Details",
  "GET_REFUND_DETAILS",
  "getRefundDetails",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...getCart.actionMap,
  ...getCart.actionCreatorMap,
  ...addCartItem.actionMap,
  ...addCartItem.actionCreatorMap,
  ...removeCartItem.actionMap,
  ...removeCartItem.actionCreatorMap,
  ...clearCartItems.actionMap,
  ...clearCartItems.actionCreatorMap,
  ...getAllCartProducts.actionMap,
  ...getAllCartProducts.actionCreatorMap,
  ...assingCartToUser.actionMap,
  ...assingCartToUser.actionCreatorMap,
  ...checkoutCart.actionMap,
  ...checkoutCart.actionCreatorMap,
  ...resetCart.actionMap,
  ...resetCart.actionCreatorMap,
  ...assignPreviousCart.actionMap,
  ...assignPreviousCart.actionCreatorMap,
  ...getRefundDetails.actionMap,
  ...getRefundDetails.actionCreatorMap,
}
