import "./Breadcrumbs.scss"

import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useLocation } from "react-router-dom"
import { useMemo } from "react"

const MobileBreadcrumbs = ({ history, className, forceActiveIndex, breadcrumbs = [] }) => {
  const location = useLocation()

  const routeList = useMemo(() => {
    if (breadcrumbs.length) {
      return breadcrumbs
    }

    const newRouteList = []

    newRouteList.push({ path: "/", name: "Trip" })
    newRouteList.push({ path: "/passengers", name: "Passengers" })
    newRouteList.push({ path: /\/seats\/([a-zA-Z0-9]+)/, name: "Seats", route: "/seats/depart" })
    newRouteList.push({ path: "/payments", name: "Review" })
    // This is a faux path
    newRouteList.push({ path: "/payments/capture", name: "Payment" })

    return newRouteList
  }, [])

  const isActive = useCallback(
    (route, pathname) => {
      if (typeof route.path === "string") {
        return route.path === pathname
      }

      return route.path.test(pathname)
    },
    [routeList],
  )

  let activeRouteIndex = routeList.findIndex(n => isActive(n, location.pathname))

  if (forceActiveIndex) {
    activeRouteIndex = forceActiveIndex
  }

  return (
    <div className={`MobileBreadcrumbs-wrapper ${className}`}>
      <div className="MobileBreadcrumbs-container">
        {routeList.map((route, index) => {
          const activeRoute = activeRouteIndex === index
          const isLast = routeList.length - 1 === index

          return (
            <>
              <div
                className={`MobileBreadcrumbs-item ${
                  activeRoute ? "MobileBreadcrumbs-item__active" : ""
                }`}
                onClick={() => {
                  if (activeRouteIndex < index) {
                    return
                  }
                  if (route.path !== location.pathname) {
                    history.push(route.route ? route.route : route.path)
                  } else {
                    // Reload
                    history.go()
                  }
                }}
              >
                {route.name}
              </div>
              {!isLast ? <div className="MobileBreadcrumbs-spacer" /> : null}
            </>
          )
        })}
      </div>
    </div>
  )
}

MobileBreadcrumbs.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

MobileBreadcrumbs.defaultProps = {}

export default MobileBreadcrumbs
