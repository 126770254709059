import { BOOKING_FLOW_STARTING_INDEX } from "../../constants/booking-flows"
import actions from "./actions"
import initialState from "./initialState"

export default (state = initialState(), action) => {
  const { type, payload } = action
  let pickupDetails = {}

  switch (type) {
    case actions.SET_PICKUP_DETAILS:
    case actions.UPDATE_PICKUP_DETAILS:
    case actions.CLEAR_PICKUP_DETAILS:
    case actions.CANCEL_TRIP:
    case actions.GET_OPERATED_AREA:
    case actions.CHECK_ADDRESS:
      return {
        ...state,
        isBusy: true,
      }
    case actions.FETCH_TRIP:
      return {
        ...state,
        detail: null,
        isBusy: true,
      }
    case actions.FETCH_PAST_TRIPS:
    case actions.FETCH_UPCOMING_TRIPS:
      return {
        ...state,
        isBusy: true,
      }
    case actions.CHECK_RESERVATION:
      return {
        ...state,
        isBusy: true,
        detail: null,
      }
    case actions.CHECK_IN_TRIP:
    case actions.CHECK_IN_FLIGHT:
    case actions.SEND_BOARDING_PASS_TO_MAIL:
      return {
        ...state,
        isBusy: true,
      }
    case actions.CHECK_IN_TRIP_FAIL:
      return {
        ...state,
        isBusy: false,
        checkin: false,
        boardingPassNotification: false,
      }
    case actions.FETCH_TRIP_FAIL:
    case actions.FETCH_UPCOMING_TRIPS_FAIL:
    case actions.FETCH_PAST_TRIPS_FAIL:
    case actions.SET_PICKUP_DETAILS_FAIL:
    case actions.UPDATE_PICKUP_DETAILS_FAIL:
    case actions.CHECK_RESERVATION_FAIL:
    case actions.FETCH_SEAT_FAIL:
    case actions.CHANGE_SEAT_FAIL:
    case actions.GET_OPERATED_AREA_FAIL:
    case actions.CHECK_ADDRESS_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.SET_PICKUP_DETAILS_PROPERTY_FAIL:
      return { ...state }
    case actions.FETCH_TRIP_SUCCESS:
    case actions.CHECK_RESERVATION_SUCCESS:
      return {
        ...state,
        isBusy: false,
        detail: payload,
      }
    case actions.CLEAR_UPCOMING_TRIPS_SUCCESS:
      return { ...state, upcoming_trips: initialState().upcoming_trips }

    case actions.CLEAR_PAST_TRIPS_SUCCESS:
      return { ...state, past_trips: initialState().past_trips }

    case actions.FETCH_UPCOMING_TRIPS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        upcoming_trips: {
          ...payload,
          results: [...state.upcoming_trips.results, ...payload.results],
        },
      }
    case actions.FETCH_PAST_TRIPS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        past_trips: { ...payload, results: [...state.past_trips.results, ...payload.results] },
      }
    case actions.RESET_CHECK_IN_SUCCESS:
      return {
        ...state,
        checkin: null,
        notification: undefined,
        boardingPassNotification: undefined,
        successfullyCheckedIn: false,
      }
    case actions.CHECK_IN_TRIP_SUCCESS:
      return {
        ...state,
        isBusy: false,
        checkin: true,
        boardingPassNotification: true,
        successfullyCheckedIn: payload.successfullyCheckedIn,
      }
    case actions.CHECK_IN_FLIGHT_SUCCESS:
    case actions.SEND_BOARDING_PASS_TO_MAIL_SUCCESS:
      return {
        ...state,
        isBusy: false,
        notification: true,
      }
    case actions.SEND_BOARDING_PASS_TO_MAIL_FAIL:
    case actions.CHECK_IN_FLIGHT_FAIL:
      return {
        ...state,
        isBusy: false,
        notification: false,
      }
    case actions.FETCH_SEAT:
      return {
        ...state,
        isBusy: true,
      }
    case actions.FETCH_SEAT_SUCCESS:
      return {
        ...state,
        flight_seat_info: payload,
        isBusy: false,
      }
    case actions.CHANGE_SEAT:
      return {
        ...state,
        isBusy: true,
      }
    case actions.CHANGE_SEAT_SUCCESS:
      return {
        ...state,
        flight_seat_info: payload,
        isBusy: false,
      }

    case actions.SET_PICKUP_DETAILS_PROPERTY_SUCCESS:
      pickupDetails = {
        ...state.pickupDetails,
        [payload.segmentDirection]: {
          ...state.pickupDetails[payload.segmentDirection],
          [payload.field]: payload.value,
        },
      }
      return {
        ...state,
        pickupDetails,
      }

    case actions.SET_PICKUP_DETAILS_PROPERTIES_SUCCESS:
      pickupDetails = {
        ...state.pickupDetails,
        [payload.segmentDirection]: {
          ...state.pickupDetails[payload.segmentDirection],
          ...payload.data,
        },
      }
      return {
        ...state,
        isBusy: false,
        pickupDetails,
      }

    case actions.SET_PICKUP_DETAILS_SUCCESS:
      // TODO(sb): save previous pickup location id
      pickupDetails = {
        ...state.pickupDetails,
        [payload.segmentDirection]: {
          ...state.pickupDetails[payload.segmentDirection],
          flightNo: payload.flightNo,
          // id: payload.data.id,
        },
      }
      return {
        ...state,
        isBusy: payload.isBusy,
        pickupDetails,
      }
    case actions.CHECK_ADDRESS_SUCCESS:
    case actions.UPDATE_PICKUP_DETAILS_SUCCESS:
      return {
        ...state,
        isBusy: false,
      }

    case actions.CLEAR_PICKUP_DETAILS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        pickupDetails: initialState().pickupDetails,
      }
    case actions.CLEAR_PICKUP_DETAILS_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.CANCEL_TRIP_FAIL:
      return {
        ...state,
        isBusy: false,
        isCanceled: false,
      }
    case actions.CANCEL_TRIP_SUCCESS:
      return {
        ...state,
        isBusy: false,
        isCanceled: true,
      }
    case actions.RESET_CANCEL_STATE_SUCCESS:
      return {
        ...state,
        isCanceled: false,
      }
    case actions.GET_FLIGHT_DETAILS:
      return {
        ...state,
        flightDetails: {
          ...state.flightDetails,
          isBusy: true,
        },
      }
    case actions.GET_FLIGHT_DETAILS_FAIL:
      return {
        ...state,
        flightDetails: {
          ...state.flightDetails,
          isBusy: false,
        },
      }
    case actions.GET_FLIGHT_DETAILS_SUCCESS:
      return {
        ...state,
        flightDetails: {
          ...state.flightDetails,
          isBusy: false,
          [payload.itineraryIndex]: { ...payload.response },
        },
      }
    case actions.CLEAR_FLIGHT_DETAILS_SUCCESS:
      return {
        ...state,
        flightDetails: {},
      }
    case actions.SET_EDIT_TRIP_PNR_SUCCESS:
      return {
        ...state,
        editTrip: payload,
      }
    case actions.CLEAR_EDIT_TRIP_PNR_SUCCESS:
      return {
        ...state,
        editTrip: "",
        detail: {},
      }
    case actions.GET_OPERATED_AREA_SUCCESS:
      return {
        ...state,
        operatedArea: payload.results[0],
        isBusy: false,
      }

    case actions.SET_BOOKING_FLOW_SUCCESS:
      return {
        ...state,
        bookingFlow: payload.bookingFlow,
        departureLandlineSegmentIndex: payload.departureLandlineSegmentIndex,
        returnLandlineSegmentIndex: payload.returnLandlineSegmentIndex,
        breadcrumbsFlow: payload.breadcrumbsFlow,
        bookingFlowIndex: BOOKING_FLOW_STARTING_INDEX,
      }

    case actions.SET_BOOKING_FLOW_INDEX_SUCCESS: {
      return {
        ...state,
        bookingFlowIndex: payload,
      }
    }

    case actions.CLEAR_BOOKING_FLOW_SUCCESS: {
      return {
        ...state,
        breadcrumbsFlow: [],
        bookingFlow: [],
        bookingFlowIndex: BOOKING_FLOW_STARTING_INDEX,
        departureLandlineSegmentIndex: 0,
        returnLandlineSegmentIndex: 0,
      }
    }

    case actions.SET_SEAT_LEG_SUCCESS: {
      return {
        ...state,
        seatLeg: payload.segment,
      }
    }

    case actions.SET_CHANGE_SEATS_DIRECTION: {
      return {
        ...state,
        changeSeatsDirection: payload,
      }
    }

    default:
      return state
  }
}
