import "./ChangeSeatPage.scss"

import classNames from "classnames"
import { Button, NotificationConsumer, PassengerSeats, Section } from "ibe-components"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import { SEGMENT_DEPART } from "../../../constants/segments"
import cartAction from "../../../redux/cart/actions"
import { cartSelector, tripSelector } from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"

const ChangeSeatPage = ({ history }) => {
  const dispatch = useDispatch()

  const trip = useSelector(state => tripSelector(state))
  const cart = useSelector(state => cartSelector(state))

  const getCart = useCallback(cartId => dispatch(cartAction.getCart(cartId)), [dispatch])

  const departureSeats =
    trip.flight_seat_info && trip.flight_seat_info.available_seats
      ? trip.flight_seat_info.available_seats
      : []

  const parsePassengers = passengersSeats => {
    return passengersSeats.map((passenger, index) => {
      return {
        ...passenger,
        passenger: index + 1,
        passengerId: index,
        code: passenger.seat?.code,
        first_name: passenger.firstname || passenger.first_name,
        last_name: passenger.lastname || passenger.last_name,
      }
    })
  }

  const allPassengers =
    trip.flight_seat_info && trip.flight_seat_info.seat_per_passenger?.passengers
      ? parsePassengers(trip.flight_seat_info.seat_per_passenger.passengers)
      : []

  const [selectedSeats, changeSelectedSeats] = useState(allPassengers)

  const [loading, changeLoading] = useState(false)

  useEffect(() => {
    if (trip.flight_seat_info) {
      changeSelectedSeats(
        parsePassengers(trip.flight_seat_info.seat_per_passenger?.passengers || []),
      )
    }
  }, [trip.flight_seat_info])

  const changeSeat = useCallback(
    (pnr, segment, params) => dispatch(tripActions.changeSeat({ pnr, segment, params })),
    [dispatch],
  )

  const onSeatSelect = seat => {
    const newSeats = selectedSeats.map(currentSeat => {
      if (currentSeat.passengerId === seat.passengerId) {
        return {
          ...currentSeat,
          seat: seat.code,
          price: seat.price,
          code: seat.code,
        }
      }
      return currentSeat
    })
    changeSelectedSeats(newSeats)
  }
  const departureClearSeat = () => {}

  const saveChangedSeats = segment => {
    const changedSeats = selectedSeats.filter(
      (seat, index) => seat.code !== allPassengers[index].code,
    )

    changeSeat(trip.flight_seat_info.pnr, segment, {
      passengers: changedSeats,
    })
  }

  const changeSeatPageClass = classNames("change-seat-page-wrapper")
  return (
    <ErrorBoundary>
      <NotificationConsumer>
        {({ actions }) => {
          return (
            <PageLayout
              render={() => {
                if (trip.isBusy) {
                  return <Loading />
                }

                if (trip.flight_seat_info && trip.flight_seat_info.message) {
                  if (trip.flight_seat_info.is_payment_needed) {
                    if (cart.uuid === trip.flight_seat_info.cart_id) {
                      return <Redirect to="/payments" />
                    }

                    if (!loading) {
                      changeLoading(true)
                      getCart(trip.flight_seat_info.cart_id)
                      actions.show({
                        type: "is-warning",
                        message: `Please pay for the premium seats`,
                      })
                    }

                    return <Loading />
                  }
                  actions.show({
                    type: "is-success",
                    message: `Seats successfully changed`,
                  })
                  return <Redirect to="/trips" />
                }

                const leg = {
                  ...trip.flight_seat_info,
                  departure_date: moment(trip.flight_seat_info.departure_time).format("YYYY-MM-DD"),
                  arrival_date: moment(trip.flight_seat_info.arrival_time).format("YYYY-MM-DD"),
                  departure_time: moment(trip.flight_seat_info.departure_time).format("HH:mm"),
                  arrival_time: moment(trip.flight_seat_info.arrival_time).format("HH:mm"),

                  arrival_info: {
                    code: trip.flight_seat_info.arrive.iata,
                    name: trip.flight_seat_info.arrive.city,
                  },
                  departure_info: {
                    code: trip.flight_seat_info.depart.iata,
                    name: trip.flight_seat_info.depart.city,
                  },
                }
                const segments = [leg]

                const from = { code: trip.flight_seat_info.depart.iata }
                const to = { code: trip.flight_seat_info.arrive.iata }

                return (
                  <div className="SectionWrapper">
                    <div className={changeSeatPageClass}>
                      <Section title="Reserve Seats" onGoBackClicked={() => history.goBack()} />
                      <PassengerSeats
                        isChange
                        key="Depart"
                        title={trip.changeSeatsDirection === SEGMENT_DEPART ? "Depart" : "Return"}
                        leg={leg}
                        allSelectedSeats={selectedSeats}
                        passengers={allPassengers}
                        seats={departureSeats}
                        onSeatClicked={onSeatSelect}
                        segments={segments}
                        from={from}
                        to={to}
                        clearPassengerSeat={departureClearSeat}
                      />
                      <div className="seats-buttons">
                        <Button
                          fullWidth
                          type="primary"
                          onClick={() => {
                            saveChangedSeats(trip.seatLeg)
                          }}
                        >
                          Change seats
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              }}
            />
          )
        }}
      </NotificationConsumer>
    </ErrorBoundary>
  )
}

ChangeSeatPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

ChangeSeatPage.defaultProps = {}

export default ChangeSeatPage
