export default () => ({
  isBusy: false,
  departureTicketIndex: -1,
  returnTicketIndex: -1,
  departureTicket: null,
  returnTicket: null,
  returnTicketSummaryPrice: null,
  departureTicketSummaryPrice: null,
  returnTicketLayoverTimes: null,
  departureTicketLayoverTimes: null,
  departureTicketTotalTripTime: null,
  returnTicketTotalTripTime: null,
})
