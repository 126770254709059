import "./MyTripsPage.scss"

import { EmptyState, NotificationConsumer } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { PAGE_LIMIT } from "../../../constants/offset"
import { MANAGE_TRIPS } from "../../../constants/routes"
import accountActions from "../../../redux/account/actions"
import redirectActions from "../../../redux/redirect/actions"
import { accountSelector, tripSelector } from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import TabLayout from "../../layouts/TabLayout"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"
import TripItemsSection from "../../organisms/TripItemsSection"

const MyTripsPage = ({ history }) => {
  const dispatch = useDispatch()

  const setRedirectURL = useCallback(() => dispatch(redirectActions.setRedirectURL(MANAGE_TRIPS)), [
    dispatch,
  ])

  const clearUpcomingTrips = useCallback(() => dispatch(tripActions.clearUpcomingTrips()), [
    dispatch,
  ])
  const clearPastTrips = useCallback(() => dispatch(tripActions.clearPastTrips()), [dispatch])

  const fetchUpcomingTrips = useCallback(
    upcomingTripsOffset => dispatch(tripActions.fetchUpcomingTrips(upcomingTripsOffset)),
    [dispatch],
  )
  const fetchPastTrips = useCallback(
    pastTripsOffset => dispatch(tripActions.fetchPastTrips(pastTripsOffset)),
    [dispatch],
  )
  const resetCheckIn = useCallback(() => dispatch(tripActions.resetCheckIn()), [dispatch])

  const fetchUserProfile = useCallback(() => dispatch(accountActions.fetchUserProfile()), [
    dispatch,
  ])

  const trip = useSelector(state => tripSelector(state))
  const account = useSelector(state => accountSelector(state))

  const [pastTripsOffset, setPastTripsOffset] = useState()
  const [upcomingTripsOffset, setUpcomingTripsOffset] = useState()
  const loadMore = (isCurrentTrip, currentPastTripsOffset, currentUpcomingTripsOffset) => {
    if (!trip.isBusy) {
      let currentOffset
      if (isCurrentTrip) {
        currentOffset = currentUpcomingTripsOffset + PAGE_LIMIT
        fetchUpcomingTrips({ offset: currentOffset })
        setUpcomingTripsOffset(currentOffset)
        return
      }
      currentOffset = currentPastTripsOffset + PAGE_LIMIT
      fetchPastTrips({ offset: currentOffset })
      setPastTripsOffset(currentOffset)
    }
  }

  const fetchSeat = useCallback(
    (pnr, segment) => dispatch(tripActions.fetchSeat({ pnr, segment })),
    [dispatch],
  )

  useEffect(() => {
    resetCheckIn()
  }, [])

  useEffect(() => {
    setRedirectURL()
    if (account.isAuthenticated) {
      clearUpcomingTrips()
      clearPastTrips()
      fetchUserProfile()
      fetchUpcomingTrips({
        offset: 0,
        callback: () => {
          setUpcomingTripsOffset(0)
        },
      })
      fetchPastTrips({
        offset: 0,
        callback: () => {
          setPastTripsOffset(0)
        },
      })
    }
  }, [history.location, account.isAuthenticated])

  const changeSeats = (pnr, segment) => {
    fetchSeat(pnr, segment)
    history.push("/seats/change")
  }

  const {
    upcoming_trips: { results: upcomingResults },
    past_trips: { results: pastResults },
    successfullyCheckedIn,
  } = trip

  const [checkedIn, setCheckedIn] = useState(false)

  useEffect(() => {
    if (successfullyCheckedIn) {
      setCheckedIn(true)
    }
  }, [successfullyCheckedIn])

  const hasUpcomingTrips = upcomingResults && upcomingResults.length > 0
  const hasPastTrips = pastResults && pastResults.length > 0
  const upcomingTripsContent = (
    <>
      {hasUpcomingTrips ? (
        <TripItemsSection
          hasMore={trip.upcoming_trips.next}
          flights={upcomingResults}
          changeSeats={changeSeats}
          upcomingTripsOffset={upcomingTripsOffset}
          loadMore={loadMore}
          history={history}
          isCurrentTrip
        />
      ) : (
        <>
          <EmptyState type="mytrips" />
        </>
      )}
      <Link to={MANAGE_TRIPS} className="my-trip-manage-link">
        Don’t see your booking? click here
      </Link>
    </>
  )

  const pastTripsContent = (
    <>
      {hasPastTrips ? (
        <>
          <TripItemsSection
            hasMore={trip.past_trips.next}
            flights={pastResults}
            changeSeats={changeSeats}
            history={history}
            pastTripsOffset={pastTripsOffset}
            loadMore={loadMore}
          />
        </>
      ) : (
        <>
          <EmptyState type="mytrips" />
        </>
      )}
      <Link to={MANAGE_TRIPS} className="my-trip-manage-link">
        Don’t see your booking? click here
      </Link>
    </>
  )

  const tabLayoutContent = [upcomingTripsContent, pastTripsContent]

  return (
    <NotificationConsumer>
      {({ actions }) => {
        return (
          <ErrorBoundary>
            <TabNavbarLayout
              render={() => {
                if (
                  account.isBusy ||
                  (trip.isBusy &&
                    typeof upcomingTripsOffset === "undefined" &&
                    typeof pastTripsOffset === "undefined")
                ) {
                  return <Loading />
                }
                if (!account.isAuthenticated) {
                  return history.push(MANAGE_TRIPS)
                }

                if (checkedIn) {
                  setCheckedIn(false)
                  actions.show({ type: "is-success", message: "Successfully checked in" })
                }

                return (
                  <div className="myTrips-container">
                    <TabLayout
                      titles={["Upcoming Trips", "Past Trips"]}
                      contents={tabLayoutContent}
                    />
                  </div>
                )
              }}
            />
          </ErrorBoundary>
        )
      }}
    </NotificationConsumer>
  )
}

MyTripsPage.propTypes = {
  history: PropTypes.instanceOf(Object),
}
MyTripsPage.defaultProps = {
  history: {},
}

export default MyTripsPage
