import "./ConnectionGarantie.scss"

import classNames from "classnames"
import {
  Button,
  CheckBox,
  CollapsibleButton,
  DropDownDatePicker,
  Icon,
  InputFieldSet,
} from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import featureFlags from "../../../constants/feature-flags"
import { TERMS_AND_CONDITIONS } from "../../../constants/routes"
import cartAction from "../../../redux/cart/actions"
import reservationActions from "../../../redux/reservations/actions"
import { reservationsSelector } from "../../../redux/selector"
import { allCardProductsEnums, getCartItemUtil, paymentTypeEnums } from "../../../utils/cart-util"
import config from "../../../utils/config"

const ConnectionGarantie = ({
  cartItems,
  connectionGarantieValidation,
  showNotification,
  formErrors,
}) => {
  const dispatch = useDispatch()
  const connectionGarantieClass = classNames("connection-garantiee-wrapper")

  const reservations = useSelector(state => reservationsSelector(state))

  const addCartItem = useCallback(item => dispatch(cartAction.addCartItem(item)), [dispatch])
  const removeCartItem = useCallback(item => dispatch(cartAction.removeCartItem(item)), [dispatch])
  const setReservationProperty = useCallback(
    (field, value) => dispatch(reservationActions.setReservationProperty({ field, value })),
    [dispatch],
  )
  let previouseItem = cartItems.find(
    ({ product }) => product.category === allCardProductsEnums.CONNECTION_GARANTIE,
  )

  const [checked, setCheck] = useState(!!previouseItem)

  const addItem = qty => {
    const cartItem = getCartItemUtil(
      allCardProductsEnums.CONNECTION_GARANTIE,
      paymentTypeEnums.REGULAR,
      qty,
    )
    addCartItem(cartItem)
  }

  const connectionGarantieValues = reservations.params.connectionGarantie || {}

  const changeValue = (field, value) => {
    const newValue = { ...connectionGarantieValues, [field]: value }
    setReservationProperty("connectionGarantie", newValue)
  }

  const removePreviouseItem = () => {
    previouseItem = cartItems.find(
      ({ product }) => product.category === allCardProductsEnums.CONNECTION_GARANTIE,
    )
    if (previouseItem) {
      removeCartItem(previouseItem.id)
    }
  }

  const validateFlight = () => {
    if (connectionGarantieValidation(connectionGarantieValues)) {
      addItem(connectionGarantieValues.count)
      showNotification({ type: "is-success", message: "Successfully added to cart" })
    }
  }

  const activate = active => {
    setCheck(active)
    if (!active) {
      removePreviouseItem()
    }
  }

  if (!featureFlags().connectionGarantieFeatureFlag) return null

  return (
    <div className={connectionGarantieClass}>
      <CollapsibleButton
        title={<strong style={{ fontSize: 20 }}>Guarantee Your Connection</strong>}
      >
        <div>
          <p className="connection-garantie-text">
            Book your Landline trip with even greater peace of mind. With connection guarantee
            active, if you miss your flight because of a Landline delay, we will cover the cost of
            the new ticket.
            <a href={`${config.LANDLINE_URL}${TERMS_AND_CONDITIONS}`}>Terms & Conditions </a>
          </p>
          <CheckBox
            checked={checked}
            value="I choose to guarantee my connection, and have read and agree with the Terms & Conditions"
            onChange={e => activate(e.target.checked)}
            margin="20px 0 30px 10px"
          />
        </div>
        {checked && (
          <>
            <InputFieldSet
              fullWidth
              labelText="AIRLINE"
              inputValue={connectionGarantieValues.airline}
              error={formErrors.airline}
              onChange={e => {
                changeValue("airline", e.target.value)
              }}
            />
            <InputFieldSet
              fullWidth
              labelText="FLIGHT NUMBER"
              inputValue={connectionGarantieValues.flight_number}
              error={formErrors.flight_number}
              onChange={e => {
                changeValue("flight_number", e.target.value)
              }}
            />
            <InputFieldSet
              fullWidth
              labelText="ORIGIN"
              inputValue={connectionGarantieValues.origin}
              error={formErrors.origin}
              onChange={e => {
                changeValue("origin", e.target.value)
              }}
            />
            <InputFieldSet
              fullWidth
              labelText="DESTINATION"
              inputValue={connectionGarantieValues.destination}
              error={formErrors.destination}
              onChange={e => {
                changeValue("destination", e.target.value)
              }}
            />
            <InputFieldSet
              fullWidth
              labelText="DEPARTURE TIME"
              inputValue={connectionGarantieValues.departure_time}
              error={formErrors.departure_time}
              onChange={e => {
                changeValue("departure_time", e.target.value)
              }}
            />
            <DropDownDatePicker
              fullWidth
              labelText="DEPARTURE DATE"
              customInputIcon={<Icon name="datepicker" />}
              onDateChange={date => changeValue("departure_date", date)}
              error={formErrors.departure_date}
              value={connectionGarantieValues.departure_date}
            />
            <div className="payment-buttons">
              <Button type="secondary" onClick={validateFlight} fullWidth>
                Validate Flight
              </Button>
            </div>
          </>
        )}
      </CollapsibleButton>
    </div>
  )
}

ConnectionGarantie.propTypes = {
  cartItems: PropTypes.instanceOf(Array),
  formErrors: PropTypes.instanceOf(Object),
  showNotification: PropTypes.func.isRequired,
  connectionGarantieValidation: PropTypes.func.isRequired,
}

ConnectionGarantie.defaultProps = {
  cartItems: [],
  formErrors: {},
}

export default ConnectionGarantie
