import "./ChangeOfPlansModalBody.scss"

import classNames from "classnames"
import { Button, Icon } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"

const ChangeOfPlansModalBody = ({ resetCancelState, changeModalState, beginEditTrip, oneWay }) => {
  const changeOfPlansModalBodyClass = classNames("change-of-plans-modal-body-container")
  return (
    <div className={changeOfPlansModalBodyClass}>
      <div className="upper-container">
        <div className="gray-circle-luggage-icon">
          <Icon name="gray-circle-luggage" noAbsolute withoutProps width="56" height="56" />
        </div>
        <div className="change-of-plans-title">Change of plans?</div>
        <div className="change-of-plans-text">
          <p>You want to edit or cancel your trip? No problem!</p>
        </div>
      </div>

      <div className="change-of-plans-button-container">
        <Button
          type="modalButton primary"
          icon={<Icon name="editing" width="16" height="16" />}
          onClick={() => {
            if (oneWay) {
              beginEditTrip()
            } else {
              changeModalState({ isChangeOfPlansModalVisible: false, isEditTripModalVisible: true })
            }
          }}
        >
          Edit trip
        </Button>
        <Button
          type="modalButton destructive"
          icon={<Icon name="white-background-x" withoutProps width="16" height="16" />}
          onClick={() => {
            resetCancelState()
            changeModalState({ isChangeOfPlansModalVisible: false, isCancelTripModalVisible: true })
          }}
        >
          Cancel trip
        </Button>
      </div>
    </div>
  )
}

ChangeOfPlansModalBody.propTypes = {
  changeModalState: PropTypes.func.isRequired,
  beginEditTrip: PropTypes.func.isRequired,
  resetCancelState: PropTypes.func.isRequired,
  oneWay: PropTypes.bool,
}

ChangeOfPlansModalBody.defaultProps = {
  oneWay: false,
}

export default ChangeOfPlansModalBody
