import queryString from "query-string"
import { all, put, select, takeLatest } from "redux-saga/effects"

import { httpGet, httpPost, httpPut } from "../../utils/request"
import { containsFlight } from "../../utils/tripUtil-util"
import accountActions from "../account/actions"
import errorActions from "../error/actions"
import reservationActions from "../reservations/actions"
import tripActions from "../trip/actions"
import actions from "./actions"

export function* getCart$({ payload = "" }) {
  try {
    const response = yield httpGet(`cart/get_cart/${payload}`)
    yield put(actions.getCartSuccess(response))

    const { isAuthenticated } = yield select(state => state.account)
    if (isAuthenticated && !payload) {
      yield put(actions.assingCartToUser(response.uuid))
    }
  } catch (error) {
    yield put(actions.getCartFail())
    yield put(errorActions.setError(error))
  }
}

export function* resetCart$() {
  try {
    yield put(actions.resetCartSuccess())
  } catch (error) {
    yield put(actions.resetCartFail())
    yield put(errorActions.setError(error))
  }
}

export function* addCartItem$({ payload }) {
  try {
    const { uuid } = yield select(state => state.cart)

    const response = yield httpPut(`v2/cart/cart/${uuid}/add_item/${payload.productId}/`, {
      item: payload.item,
      ...payload.data,
    })

    yield put(actions.addCartItemSuccess(response))
  } catch (error) {
    yield put(actions.addCartItemFail())
    yield put(errorActions.setError(error))
  }
}

export function* removeCartItem$({ payload }) {
  try {
    const { uuid } = yield select(state => state.cart)
    const { items } = yield select(state => state.reservations)

    const response = yield httpPut(`cart/remove_item_from_cart/`, {
      cart_id: uuid,
      item_id: payload,
      reservation_uuid: items.uuid,
    })
    yield put(actions.removeCartItemSuccess(response))
  } catch (error) {
    yield put(actions.removeCartItemFail())
    yield put(errorActions.setError(error))
  }
}

export function* clearCartItems$() {
  try {
    const { uuid } = yield select(state => state.cart)
    const response = yield httpPut(`cart/clear_items/`, { cart_id: uuid })
    yield put(actions.clearCartItemsSuccess(response))
  } catch (error) {
    yield put(actions.clearCartItemsFail())
    yield put(errorActions.setError(error))
  }
}

export function* getAllCartProducts$() {
  try {
    const response = yield httpGet(`v2/cart/products/`)
    yield put(actions.getAllCartProductsSuccess(response))
  } catch (error) {
    yield put(actions.getAllCartProductsFail())
    yield put(errorActions.setError(error))
  }
}

export function* assingCartToUser$({ payload: uuid }) {
  try {
    if (uuid) {
      const response = yield httpPost(`v2/cart/cart/${uuid}/assign_cart_to_user/`)
      yield put(actions.assingCartToUserSuccess(response))
    }
  } catch (error) {
    yield put(actions.assingCartToUserFail())
    yield put(errorActions.setError(error))
  }
}
export function* assignPreviousCart$({ payload }) {
  try {
    const { uuid } = yield select(state => state.cart)
    const response = yield httpPost(`v2/cart/cart/${uuid}/assign_previous_cart/`, {
      booking_reference: payload,
    })
    yield put(actions.assignPreviousCartSuccess(response))
  } catch (error) {
    yield put(actions.assignPreviousCartFail())
    yield put(errorActions.setError(error))
  }
}
export function* checkoutCart$({ payload }) {
  try {
    const passenger = yield select(state => state.passenger)
    const trip = yield select(state => state.trip)
    const { departureTicket, returnTicket } = yield select(state => state.ticket)
    const holderName =
      payload.cardHolderName || `${passenger[0].first_name} ${passenger[0].last_name}`
    const { email } = passenger[0] || { email: trip.flight_seat_info.email }

    const { uuid } = yield select(state => state.cart)
    const {
      items,
      params: { is_edit_per_segment: isEditPerSegment, editingTripContainsFlight },
    } = yield select(state => state.reservations)
    let isGroundOnly
    if (editingTripContainsFlight) {
      if (returnTicket) {
        isGroundOnly = !containsFlight(departureTicket) || !containsFlight(returnTicket)
      } else {
        isGroundOnly = !containsFlight(departureTicket)
      }
    }
    const data = {
      cart_id: uuid,
      is_edit_per_segment: isEditPerSegment,
      is_ground_only: isGroundOnly,
      ticket_id: items.uuid || trip.flight_seat_info.ticket_id,
      payment_data: { holder_name: holderName, email },
    }

    if (payload) {
      data.stripe_token = payload.stripeToken
    }

    // const heartbeatResponse = yield httpGet(`v2/booking/reservations/${items.uuid}/is_alive/`)
    const heartbeatResponse = { is_alive: true }

    if (heartbeatResponse.is_alive) {
      yield put(reservationActions.setShouldCheckHeartbeat(false))

      const response = yield httpPut(`cart/check_out/`, data)

      // refresh points in redux store
      yield put(accountActions.fetchUserProfile())

      yield put(actions.checkoutCartSuccess(response))

      if (trip.editTrip !== "") {
        yield put(tripActions.updatePickupDetails(response))
      }
    } else {
      payload.history.push("/")
    }
  } catch (error) {
    yield put(reservationActions.setShouldCheckHeartbeat(true))
    yield put(actions.checkoutCartFail())
    yield put(errorActions.setError(error))
  }
}
export function* getRefundDetails$({ payload }) {
  try {
    const queryParams = { segment: payload.segment }
    const urlQuery = queryString.stringify(queryParams)
    const response = yield httpGet(`v2/cart/cart/${payload.uuid}/get_refund_details/?${urlQuery}`)
    yield put(actions.getRefundDetailsSuccess(response))
  } catch (error) {
    yield put(actions.getRefundDetailsFail())
    yield put(errorActions.setError(error))
  }
}

export default function*() {
  yield all([
    takeLatest(actions.getCart, getCart$),
    takeLatest(actions.addCartItem, addCartItem$),
    takeLatest(actions.removeCartItem, removeCartItem$),
    takeLatest(actions.clearCartItems, clearCartItems$),
    takeLatest(actions.getAllCartProducts, getAllCartProducts$),
    takeLatest(actions.assingCartToUser, assingCartToUser$),
    takeLatest(actions.checkoutCart, checkoutCart$),
    takeLatest(actions.resetCart, resetCart$),
    takeLatest(actions.assignPreviousCart, assignPreviousCart$),
    takeLatest(actions.getRefundDetails, getRefundDetails$),
  ])
}
