import { createAction } from "redux-actions"

import { generateSagaActions } from "../../utils/common"

const fetchPastTrips = generateSagaActions(
  "[TRIP] Fetch past trips",
  "FETCH_PAST_TRIPS",
  "fetchPastTrips",
  ["FAIL", "SUCCESS"],
  createAction,
)

const fetchUpcomingTrips = generateSagaActions(
  "[TRIP] Fetch upcoming trips",
  "FETCH_UPCOMING_TRIPS",
  "fetchUpcomingTrips",
  ["FAIL", "SUCCESS"],
  createAction,
)

const checkInTrip = generateSagaActions(
  "[TRIP] Check in trip",
  "CHECK_IN_TRIP",
  "checkInTrip",
  ["FAIL", "SUCCESS"],
  createAction,
)

const checkInFlight = generateSagaActions(
  "[TRIP] Check in flight",
  "CHECK_IN_FLIGHT",
  "checkInFlight",
  ["FAIL", "SUCCESS"],
  createAction,
)

const sendBoardingPassToMail = generateSagaActions(
  "[TRIP] Send Boarding Pass to Mail",
  "SEND_BOARDING_PASS_TO_MAIL",
  "sendBoardingPassToMail",
  ["FAIL", "SUCCESS"],
  createAction,
)

const cancelSeat = generateSagaActions(
  "[TRIP] Cancel trip",
  "CANCEL_TRIP",
  "cancelTrip",
  ["FAIL", "SUCCESS"],
  createAction,
)

const resetCancelState = generateSagaActions(
  "[TRIP] Reset cancel state",
  "RESET_CANCEL_STATE",
  "resetCancelState",
  ["FAIL", "SUCCESS"],
  createAction,
)

const checkReservation = generateSagaActions(
  "[TRIP] Check reservation",
  "CHECK_RESERVATION",
  "checkReservation",
  ["FAIL", "SUCCESS"],
  createAction,
)

const fetchSeat = generateSagaActions(
  "[TRIP] Fetch seat",
  "FETCH_SEAT",
  "fetchSeat",
  ["FAIL", "SUCCESS"],
  createAction,
)

const changeSeat = generateSagaActions(
  "[TRIP] Change seat",
  "CHANGE_SEAT",
  "changeSeat",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setPickupDetailsProperty = generateSagaActions(
  "[TRIP] Set Pickup Details Property",
  "SET_PICKUP_DETAILS_PROPERTY",
  "setPickupDetailsProperty",
  ["FAIL", "SUCCESS"],
  createAction,
)
const resetCheckIn = generateSagaActions(
  "[TRIP] Reset Check In",
  "RESET_CHECK_IN",
  "resetCheckIn",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setPickupDetailsProperties = generateSagaActions(
  "[TRIP] Set Pickup Details Properties",
  "SET_PICKUP_DETAILS_PROPERTIES",
  "setPickupDetailsProperties",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setPickupDetails = generateSagaActions(
  "[TRIP] Set Pickup Details",
  "SET_PICKUP_DETAILS",
  "setPickupDetails",
  ["FAIL", "SUCCESS"],
  createAction,
)

const updatePickupDetails = generateSagaActions(
  "[TRIP] Update Pickup Details",
  "UPDATE_PICKUP_DETAILS",
  "updatePickupDetails",
  ["FAIL", "SUCCESS"],
  createAction,
)

const clearPickupDetails = generateSagaActions(
  "[TRIP] Clear Pickup Details",
  "CLEAR_PICKUP_DETAILS",
  "clearPickupDetails",
  ["FAIL", "SUCCESS"],
  createAction,
)

const getFlightDetails = generateSagaActions(
  "[TRIP] Get Flight Details",
  "GET_FLIGHT_DETAILS",
  "getFlightDetails",
  ["FAIL", "SUCCESS"],
  createAction,
)
const clearFlightDetails = generateSagaActions(
  "[TRIP] Clear Flight Details",
  "CLEAR_FLIGHT_DETAILS",
  "clearFlightDetails",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setEditTripPNR = generateSagaActions(
  "[TRIP] Set edit trip PNR",
  "SET_EDIT_TRIP_PNR",
  "setEditTripPNR",
  ["FAIL", "SUCCESS"],
  createAction,
)
const clearEditTripPNR = generateSagaActions(
  "[TRIP] Clear edit trip PNR",
  "CLEAR_EDIT_TRIP_PNR",
  "clearEditTripPNR",
  ["FAIL", "SUCCESS"],
  createAction,
)

const clearUpcomingTrips = generateSagaActions(
  "[TRIP] Clear Upcoming Trips",
  "CLEAR_UPCOMING_TRIPS",
  "clearUpcomingTrips",
  ["FAIL", "SUCCESS"],
  createAction,
)

const clearPastTrips = generateSagaActions(
  "[TRIP] Clear past trips",
  "CLEAR_PAST_TRIPS",
  "clearPastTrips",
  ["FAIL", "SUCCESS"],
  createAction,
)

const getOperatedArea = generateSagaActions(
  "[TRIP] Get operated area",
  "GET_OPERATED_AREA",
  "getOperatedArea",
  ["FAIL", "SUCCESS"],
  createAction,
)

const checkAddress = generateSagaActions(
  "[TRIP] Check address",
  "CHECK_ADDRESS",
  "checkAddress",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setBookingFlow = generateSagaActions(
  "[TRIP] Set booking flow",
  "SET_BOOKING_FLOW",
  "setBookingFlow",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setBookingFlowIndex = generateSagaActions(
  "[TRIP] Set booking flow index",
  "SET_BOOKING_FLOW_INDEX",
  "setBookingFlowIndex",
  ["FAIL", "SUCCESS"],
  createAction,
)

const clearBookingFlow = generateSagaActions(
  "[TRIP] Clear booking flow",
  "CLEAR_BOOKING_FLOW",
  "clearBookingFlow",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setSeatLeg = generateSagaActions(
  "[TRIP] Set seat leg",
  "SET_SEAT_LEG",
  "setSeatLeg",
  ["FAIL", "SUCCESS"],
  createAction,
)

const setChangeSeatsDirection = generateSagaActions(
  "[TRIP] Set change seats direction",
  "SET_CHANGE_SEATS_DIRECTION",
  "setChangeSeatsDirection",
  ["FAIL", "SUCCESS"],
  createAction,
)

export default {
  ...fetchPastTrips.actionMap,
  ...fetchPastTrips.actionCreatorMap,
  ...fetchUpcomingTrips.actionMap,
  ...fetchUpcomingTrips.actionCreatorMap,
  ...checkReservation.actionMap,
  ...checkReservation.actionCreatorMap,
  ...fetchSeat.actionMap,
  ...fetchSeat.actionCreatorMap,
  ...changeSeat.actionMap,
  ...changeSeat.actionCreatorMap,
  ...cancelSeat.actionMap,
  ...cancelSeat.actionCreatorMap,
  ...setPickupDetailsProperty.actionMap,
  ...setPickupDetailsProperty.actionCreatorMap,
  ...setPickupDetailsProperties.actionMap,
  ...setPickupDetailsProperties.actionCreatorMap,
  ...setPickupDetails.actionMap,
  ...setPickupDetails.actionCreatorMap,
  ...updatePickupDetails.actionMap,
  ...updatePickupDetails.actionCreatorMap,
  ...clearPickupDetails.actionMap,
  ...clearPickupDetails.actionCreatorMap,
  ...getFlightDetails.actionMap,
  ...getFlightDetails.actionCreatorMap,
  ...resetCancelState.actionMap,
  ...resetCancelState.actionCreatorMap,
  ...clearFlightDetails.actionMap,
  ...clearFlightDetails.actionCreatorMap,
  ...setEditTripPNR.actionMap,
  ...setEditTripPNR.actionCreatorMap,
  ...clearEditTripPNR.actionMap,
  ...clearEditTripPNR.actionCreatorMap,
  ...resetCheckIn.actionMap,
  ...resetCheckIn.actionCreatorMap,
  ...clearUpcomingTrips.actionMap,
  ...clearUpcomingTrips.actionCreatorMap,
  ...clearPastTrips.actionMap,
  ...clearPastTrips.actionCreatorMap,
  ...checkInTrip.actionMap,
  ...checkInTrip.actionCreatorMap,
  ...checkInFlight.actionMap,
  ...checkInFlight.actionCreatorMap,
  ...sendBoardingPassToMail.actionMap,
  ...sendBoardingPassToMail.actionCreatorMap,
  ...getOperatedArea.actionMap,
  ...getOperatedArea.actionCreatorMap,
  ...checkAddress.actionMap,
  ...checkAddress.actionCreatorMap,
  ...setBookingFlow.actionMap,
  ...setBookingFlow.actionCreatorMap,
  ...setBookingFlowIndex.actionMap,
  ...setBookingFlowIndex.actionCreatorMap,
  ...clearBookingFlow.actionMap,
  ...clearBookingFlow.actionCreatorMap,
  ...setSeatLeg.actionMap,
  ...setSeatLeg.actionCreatorMap,
  ...setChangeSeatsDirection.actionMap,
  ...setChangeSeatsDirection.actionCreatorMap,
}
