import "./EditTripColumn.scss"

import classNames from "classnames"
import { Icon } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"

const EditTripColumn = ({
  icon,
  title,
  margin,
  padding,
  description,
  action,
  onActionClick,
  middleColumn,
}) => {
  const editTripColumnClass = classNames("edit-trip-column-container")
  return (
    <div className={editTripColumnClass} style={{ margin, padding }} onClick={onActionClick}>
      <div className="left-container">
        <div className="icon-container">{icon}</div>
        <div className="column-text-container">
          <div className="column-title">{title}</div>
          <div className="middle-column">{middleColumn}</div>
          <div className="column-description">{description}</div>
        </div>
      </div>
      <div className="action-button-container">
        {action === "edit" && (
          <Icon name="edit-trip-white-gray-circle" width="32" height="32" noAbsolute withoutProps />
        )}
        {action === "cancel" && (
          <Icon
            name="cancel-trip-white-gray-circle"
            width="32"
            height="32"
            noAbsolute
            withoutProps
          />
        )}
      </div>
    </div>
  )
}

EditTripColumn.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  margin: PropTypes.string,
  padding: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.string,
  onActionClick: PropTypes.func,
  middleColumn: PropTypes.string,
}

EditTripColumn.defaultProps = {
  icon: <></>,
  title: "",
  description: "",
  margin: null,
  padding: null,
  action: "",
  onActionClick: () => {},
  middleColumn: null,
}

export default EditTripColumn
