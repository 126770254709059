import "./Bus.scss"

import React from "react"

const Bus = () => {
  return (
    <div className="image-text-wrapper">
      <div className="ImageWrapper">
        <h2 className="header mobile-header">Save time & money when you choose Landline</h2>
      </div>
      <div className="text-container">
        <div className="TextWrapper">
          <h2 className="header">Save time & money when you choose Landline</h2>
          <h3>Over driving yourself:</h3>
          <ul className="bus-container">
            <li>Convenient curb-side drop off & pick up</li>
            <li>Significant savings over airport parking fees</li>
            <li>Relax and catch up on email or your favorite shows</li>
          </ul>
          <h3 style={{ marginTop: 30 }}>Over Uber or Lyft:</h3>
          <ul className="bus-container">
            <li>Fully licensed and trained drivers</li>
            <li>Significant savings over rideshare fees </li>
            <li>Room for all your luggage, even oversized.</li>
            <li>Pets allowed!</li>
          </ul>
          <div style={{ display: "flex" }}>
            <a href="/" className="book-now-button">
              Book Now
            </a>
          </div>
        </div>
      </div>
      <div className="filler" />
    </div>
  )
}

export default Bus
