import React from "react"
import SchedulePage from ".."
import ScheduleHeader from "../Header"
import OurService from "../OurService"
import ModuleTitle from "../../../atoms/SchedulePages/ModuleTitle"
import { PickupLocations, PickupLocation } from "../Locations"
import { Schedule, ScheduleTable } from "../Schedule"
import Search from "../../HomePage/Search"

import Pickup_Locations from "./pickup-locations"
import FargoData from "./schedule"
import { FargoMeta, FargoService, FargoParking } from "./metadata"
import Parking from "../Parking"


const FargoSchedule = ({ history }) => {

  return (
    <SchedulePage>
      <div className="schedule-search-form">
        <Search history={history} remoteCallSearchComponent={true} />
      </div>
      <div className="schedule-page">
        <ScheduleHeader metadata={FargoMeta.header} />

        <ModuleTitle title="Schedule" />
        <Schedule>
          <ScheduleTable schedule={FargoData.from} />
          <ScheduleTable schedule={FargoData.to} />
        </Schedule>

        <PickupLocations>
          {Pickup_Locations.map(location => {
            return <PickupLocation location={location} />
          })}
        </PickupLocations>

        <Parking data={FargoParking} />

        <OurService service={FargoService} />

      </div>
    </SchedulePage>
  )
}

export default FargoSchedule