import "./style.scss"

import { Button, InputFieldSet } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"

const TripManageForm = ({ inputs, onInputChanged, error = {}, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="Input--Fields">
        <InputFieldSet
          fullWidth
          labelText="LAST NAME"
          inputValue={inputs.last_name}
          onChange={e => onInputChanged("last_name")(e.target.value)}
          error={error.last_name}
        />
        <InputFieldSet
          fullWidth
          labelText="BOOKING REFERENCE"
          inputValue={inputs.pnr}
          onChange={e => onInputChanged("pnr")(e.target.value)}
          error={error.pnr}
        />
        <Button type="primary" fullWidth buttonType="submit">
          Locate
        </Button>
      </div>
    </form>
  )
}
TripManageForm.propTypes = {
  error: PropTypes.instanceOf(Object),
  onInputChanged: PropTypes.func,
  onSubmit: PropTypes.func,
  inputs: PropTypes.instanceOf(Object),
}

TripManageForm.defaultProps = {
  error: {},
  onInputChanged: () => null,
  onSubmit: () => null,
  inputs: {},
}

export default TripManageForm
