import React from "react"
import "./SchedulePage.desktop.scss"
import "./SchedulePage.mobile.scss"

import { NotificationConsumer } from "ibe-components"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"
import Subscribe from "../../organisms/Subscribe"
import FAQBox from "./FAQ"

const SchedulePage = (props) => {

  return (
    <NotificationConsumer>
      {({ actions }) => {
        return (
          <ErrorBoundary>
            <TabNavbarLayout
            pageLayoutBackground="#FFF"
              render={() => {

                return (
                  <>
                    {props.children}
                    <FAQBox />
                    <Subscribe />
                  </>
                )
              }}
            />
          </ErrorBoundary>
        )
      }}
    </NotificationConsumer>
  )
}


export default SchedulePage