import { all, put, select, takeLatest } from "redux-saga/effects"

import errorActions from "../error/actions"
import actions from "./actions"

export function* setTicketByType$({ payload }) {
  const {
    type,
    segments,
    ticket,
    ticketIndex,
    summaryPrice,
    layoverTimes,
    totalTripTime,
    returnFlights,
    bookingToken,
  } = payload
  const { LANDLINE_IATA_CODE } = yield select(state => state.settings)
  if (!segments && !ticket) {
    yield put(
      actions.setTicketByTypeSuccess({
        type,
        returnFlights,
        bookingToken,
        ticket: null,
        [`${type}SummaryPrice`]: summaryPrice,
        [`${type}LayoverTimes`]: layoverTimes,
        [`${type}TotalTripTime`]: totalTripTime,
      }),
    )
    return
  }
  const selectedSegments = []

  for (let i = 0; i < segments.length; i++) {
    if (segments[i].airline !== LANDLINE_IATA_CODE) {
      selectedSegments.push(segments[i])
    } else {
      selectedSegments.push({ ...segments[i], tickets: [segments[i].tickets[ticketIndex]] })
    }
  }
  yield put(
    actions.setTicketByTypeSuccess({
      type,
      returnFlights,
      bookingToken,
      ticket: [...selectedSegments],
      [`${type}SummaryPrice`]: summaryPrice[ticketIndex],
      [`${type}LayoverTimes`]: layoverTimes,
      [`${type}TotalTripTime`]: totalTripTime,
    }),
  )
}

export function* setTicketIndexByType$({ payload }) {
  try {
    yield put(actions.setTicketIndexByTypeSuccess(payload))
  } catch (error) {
    yield put(actions.setTicketIndexByTypeFail())
    yield put(errorActions.setError(error))
  }
}

export function* clearBookingProcess$() {
  try {
    yield put(actions.clearBookingProcessSuccess())
  } catch (error) {
    yield put(actions.clearBookingProcessFail())
    yield put(errorActions.setError(error))
  }
}
export default function*() {
  yield all([
    takeLatest(actions.setTicketByType, setTicketByType$),
    takeLatest(actions.setTicketIndexByType, setTicketIndexByType$),
    takeLatest(actions.clearBookingProcess, clearBookingProcess$),
  ])
}
