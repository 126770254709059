import { store } from "../redux/store"

export const allCardProductsEnums = {
  PREMIUM_SEATING: "premium_seating",
  CONNECTION_GARANTIE: "connection_guarantee",
  DOOR_2_DOOR: "door_2_door",
  FLIGHT_TICKET: "flight_ticket",
  LANDLINE_TICKET: "landline_ticket",
  DISCOUNT: "vouchers",
  PREVIOUS_TICKET: "previous_landline_ticket",
  STORE_CREDITS: "store_credits",
}

export const paymentTypeEnums = {
  REGULAR: "regular",
  LOYALTY_POINTS: "loyalty_points",
  STORE_CREDITS: "store_credits",
}

export const getCartItemUtil = (
  cardProduct,
  paymentType = paymentTypeEnums.REGULAR,
  quantity = 1,
  data = {},
) => {
  const { cart } = store.getState()
  const { allCategories } = cart
  const productId = allCategories.find(({ category }) => category === cardProduct).id
  return { productId, item: { payment_type: paymentType, quantity }, data }
}
