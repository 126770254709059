import moment from "moment"
import queryString from "query-string"
import { all, put, putResolve, select, take, takeLatest } from "redux-saga/effects"

import { SEGMENT_DEPART } from "../../constants/segments"
import { getNextPickupDetails, toDateFormatString } from "../../utils/common"
import { httpGet, httpPut } from "../../utils/request"
import cartActions from "../cart/actions"
import errorActions from "../error/actions"
import instructionActions from "../instruction/actions"
import itineraryActions from "../itinerary/actions"
import loyaltyActions from "../loyalty/actions"
import passengerActions from "../passenger/actions"
import redirectActions from "../redirect/actions"
import ticketActions from "../ticket/actions"
import tripActions from "../trip/actions"
import actions from "./actions"

export function* clearSearch$() {
  try {
    yield put(cartActions.getCart())
    yield put(loyaltyActions.activateLoyalty(false))
    yield put(tripActions.clearEditTripPNR())
    yield put(cartActions.resetCart())
    yield put(instructionActions.clearDoor2DoorService())
    yield put(actions.clearSearchSuccess())
    yield put(itineraryActions.clearItinerary())
    yield put(passengerActions.clearPassengers())
    yield put(tripActions.clearPickupDetails())
    yield put(tripActions.clearUpcomingTrips())
    yield put(tripActions.clearPastTrips())
    yield put(errorActions.clearError())
    yield put(ticketActions.clearBookingProcess())
    yield put(actions.setShouldCheckHeartbeat(false))
    yield put(tripActions.clearBookingFlow())
    yield put(actions.clearFilters())
  } catch (error) {
    yield put(actions.clearSearchFail())
    yield put(errorActions.setError(error))
  }
}
export function* setReservationProperty$({ payload }) {
  try {
    yield put(actions.setReservationPropertySuccess(payload))
  } catch (error) {
    yield put(actions.setReservationPropertyFail())
    yield put(errorActions.setError(error))
  }
}

export function* fetchReservationAllCities$() {
  try {
    const response = yield httpGet("v2/booking/airports/")
    yield put(actions.fetchReservationAllCitiesSuccess(response))
  } catch (error) {
    yield put(actions.fetchReservationAllCitiesFail())
    yield put(errorActions.setError(error))
  }
}

export function* fetchBlackCarCities$({ payload }) {
  try {
    const { pickupDetails } = yield select(state => state.trip)
    const { departureCityCode, arrivalCityCode, newSegmentDirection } = payload
    const parsedDepartureCityCode = departureCityCode ? `&airport=${departureCityCode}` : ""
    const parsedArrivalCityCode = arrivalCityCode ? `&airport=${arrivalCityCode}` : ""

    const departureResponse = yield httpGet(
      `v2/landline/cities/?limit=1000${parsedDepartureCityCode}`,
    )

    const pickupCities = departureResponse.results.map((item, index) => {
      const newItem = {
        code: item.airport,
        id: index,
        isAirport: item.is_airport,
        cityName: item.name,
        state: item.state,
      }
      if (item.is_airport) {
        newItem.items = item.data.items
      }
      return newItem
    })

    const arrivalResponse = yield httpGet(`v2/landline/cities/?limit=1000${parsedArrivalCityCode}`)

    const dropOffCities = arrivalResponse.results.map((item, index) => {
      const newItem = {
        code: item.airport,
        id: index,
        isAirport: item.is_airport,
        cityName: item.name,
        state: item.state,
      }
      if (item.is_airport) {
        newItem.items = item.data.items
      }
      return newItem
    })

    const pickupFlightData = {
      ...pickupDetails[1].pick_up_address,
    }
    const dropOffFlightData = {
      ...pickupDetails[1].drop_off_address,
    }
    // check if segment departure_code is airport
    const departAirport = pickupCities[0].isAirport
    const arrivalAirport = dropOffCities[0].isAirport
    if (departAirport) {
      // if yes then set pickup to msp airport
      pickupFlightData.city = departAirport.cityName
      pickupFlightData.state = departAirport.state
    } else if (arrivalAirport) {
      dropOffFlightData.city = arrivalAirport.cityName
      dropOffFlightData.state = arrivalAirport.state
    }

    // else set dropoff to msp airport
    if (newSegmentDirection === SEGMENT_DEPART) {
      yield put(
        tripActions.setPickupDetailsProperties({
          data: {
            pick_up_address: getNextPickupDetails(
              pickupDetails[1].pick_up_address,
              pickupFlightData, // prepopulate with flight
            ),
            drop_off_address: getNextPickupDetails(
              pickupDetails[1].drop_off_address,
              dropOffFlightData, // prepopulate with flight
            ),
          },
          segmentDirection: newSegmentDirection,
        }),
      )
    } else {
      yield put(
        tripActions.setPickupDetailsProperties({
          data: {
            pick_up_address: getNextPickupDetails(
              pickupDetails[2].pick_up_address,
              pickupDetails[1].drop_off_address,
            ),
            drop_off_address: getNextPickupDetails(
              pickupDetails[2].drop_off_address,
              pickupDetails[1].pick_up_address,
            ),
          },
          segmentDirection: newSegmentDirection,
        }),
      )
    }

    yield put(actions.fetchBlackCarCitiesSuccess([pickupCities, dropOffCities]))
  } catch (error) {
    yield put(actions.fetchBlackCarCitiesFail())
    yield put(errorActions.setError(error))
  }
}

export function* fetchReservationString$({ payload }) {
  try {
    const queryParams = { search: payload.searchString, search_code: payload.code }
    if (payload.all_airports) {
      queryParams.all_airports = true
    }
    const urlQuery = queryString.stringify(queryParams)
    const response = yield httpGet(`v2/booking/airports/?${urlQuery}`)
    yield put(actions.fetchReservationStringSuccess({ cities: response, way: payload.way }))
  } catch (error) {
    yield put(actions.fetchReservationStringFail())
    yield put(errorActions.setError(error))
  }
}

export function* fetchReservationCityPair$({ payload }) {
  try {
    const queryParams = {
      search_code: payload.city.code,
      direction: payload.way,
    }
    if (payload.city.all_airports) {
      queryParams.all_airports = true
    }
    const urlQuery = queryString.stringify(queryParams)
    const response = yield httpGet(`v2/booking/airports/?${urlQuery}`)
    yield put(actions.fetchReservationCityPairSuccess({ cities: response, type: payload.way }))
  } catch (error) {
    yield put(actions.fetchReservationCityPairFail())
    yield put(errorActions.setError(error))
  }
}

export function* updateReservation$() {
  try {
    const reservations = yield select(state => state.reservations)

    // if (!reservations.params.dep_from || Object.keys(reservations.params.dep_from).length === 0) {
    //   yield put(errorActions.setError("Can't fetch flights now"))
    //   return
    // }

    let params = {
      dep_from: reservations.params.dep_from.code,
      dep_to: reservations.params.dep_to.code,
      city_from: reservations.params.dep_from.city_from,
      city_to: reservations.params.dep_to.city_to,
      one_way: true,
      passengers_adults: reservations.params.passengers_adults,
      passengers_children: reservations.params.passengers_children,
      passengers_infants: reservations.params.passengers_infants,
      departure_date: toDateFormatString(reservations.params.departure_date),
      ...reservations.filters,
      bus_layover_min: reservations.filters.maxBusLayover[0],
      bus_layover_max: reservations.filters.maxBusLayover[1],
      airport_layover_min: reservations.filters.maxFlightLayover[0],
      airport_layover_max: reservations.filters.maxFlightLayover[1],
    }

    if (reservations.params.return_date) {
      params = {
        ...params,
        return_date: toDateFormatString(reservations.params.return_date),
        one_way: false,
      }
      yield putResolve(actions.setReservationProperty({ field: "one_way", value: false }))
      yield take(actions.SET_RESERVATION_PROPERTY_SUCCESS)
    } else {
      yield putResolve(actions.setReservationProperty({ field: "one_way", value: true }))
    }

    const response = yield httpPut("bookings/reservation/", params)
    let filteredResponse = response
    if (response && reservations.params.editTripLessThenFilterDate) {
      const filteredDepartFlights = response.depart_flights.filter(flight =>
        moment(
          `${flight.segments[flight.segments.length - 1].arrival_date}T${
            flight.segments[flight.segments.length - 1].arrival_time
          }`,
        ).isBefore(reservations.params.editTripLessThenFilterDate),
      ) // Editing only departure segment, departure flights must be before return trip
      filteredResponse = { ...filteredResponse, depart_flights: filteredDepartFlights }
    }
    if (response && reservations.params.editTripGreaterThanFilterDate) {
      const filteredDepartFlights = response.depart_flights.filter(flight =>
        moment(`${flight.segments[0].arrival_date}T${flight.segments[0].arrival_time}`).isAfter(
          reservations.params.editTripGreaterThanFilterDate,
        ),
      ) // Editing only return segment, return flights must be after departure trip
      filteredResponse = { ...filteredResponse, depart_flights: filteredDepartFlights }
    }

    yield put(actions.updateReservationSuccess(filteredResponse))
  } catch (error) {
    yield put(actions.updateReservationFail())
    yield put(errorActions.setError(error))
  }
}
export function* fetchDepartureSegments$() {
  try {
    const { items } = yield select(state => state.reservations)
    const departs = (items || {}).depart_flights || []
    let segments = []
    for (let i = 0; i < departs.length; i++) {
      segments = [...segments, ...departs[i].segments]
    }
    yield put(actions.fetchDepartureSegmentsSuccess(segments))
  } catch (error) {
    yield put(errorActions.setError(error))
  }
}
export function* fetchReturnSegments$() {
  try {
    const { items } = yield select(state => state.reservations)
    const returns = (items || {}).requested_return_date || []
    let segments = []
    for (let i = 0; i < returns.length; i++) {
      segments = [...segments, ...returns[i].segments]
    }
    yield put(actions.fetchReturnSegmentsSuccess(segments))
  } catch (error) {
    yield put(errorActions.setError(error))
  }
}

export function* setFilterProperty$({ payload }) {
  try {
    yield put(actions.setFilterPropertySuccess(payload))
    yield put(actions.updateReservation())
  } catch (error) {
    yield put(errorActions.setError(error))
  }
}

export function* clearFilters$() {
  try {
    yield put(actions.clearFiltersSuccess())
    yield put(actions.updateReservation())
  } catch (error) {
    yield put(errorActions.setError(error))
  }
}

export function* checkHeartbeat$({ payload }) {
  try {
    const { items } = yield select(state => state.reservations)

    // const response = yield httpGet(`v2/booking/reservations/${items.uuid}/is_alive/`)
    const response = { is_alive: true }
    if (!response.is_alive) {
      yield put(redirectActions.setRedirectURL("/"))
      payload.history.push("/")
    }
  } catch (error) {
    yield put(errorActions.setError(error))
  }
}

export function* setShouldCheckHeartbeat$({ payload }) {
  try {
    yield put(actions.setShouldCheckHeartbeatSuccess(payload))
  } catch (error) {
    yield put(errorActions.setError(error))
  }
}

export default function*() {
  yield all([
    takeLatest(actions.clearSearch, clearSearch$),
    takeLatest(actions.setReservationProperty, setReservationProperty$),
    takeLatest(actions.fetchReservationAllCities, fetchReservationAllCities$),
    takeLatest(actions.fetchReservationCityPair, fetchReservationCityPair$),
    takeLatest(actions.updateReservation, updateReservation$),
    takeLatest(actions.fetchDepartureSegments, fetchDepartureSegments$),
    takeLatest(actions.fetchReturnSegments, fetchReturnSegments$),
    takeLatest(actions.setFilterProperty, setFilterProperty$),
    takeLatest(actions.clearFilters, clearFilters$),
    takeLatest(actions.fetchReservationString, fetchReservationString$),
    takeLatest(actions.fetchBlackCarCities, fetchBlackCarCities$),
    takeLatest(actions.checkHeartbeat, checkHeartbeat$),
    takeLatest(actions.setShouldCheckHeartbeat, setShouldCheckHeartbeat$),
  ])
}
