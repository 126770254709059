import "./style.scss"
import "@brainhubeu/react-carousel/lib/style.css"

import { NotificationConsumer, SearchForm } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import featureFlags from "../../../constants/feature-flags"
import accountActions from "../../../redux/account/actions"
import loyaltyActions from "../../../redux/loyalty/actions"
import reservationActions from "../../../redux/reservations/actions"
import {
  loyaltySelector,
  reservationsSelector,
  settingsSelector,
  tripSelector,
} from "../../../redux/selector"
import settingsActions from "../../../redux/settings/actions"
import { trackSearchActionTradeDesk } from "../../../utils/trackingService"
import { validateSearchForm } from "../../../utils/validation"
import CommentsCarousel from "../../atoms/CommentsCarousel"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import Bus from "../../organisms/Bus"
import Promotion from "../../organisms/Promotion"
import Subscribe from "../../organisms/Subscribe"
import { MotorCoach } from "../../promotionals/MotorCoach"
import { BookEarlyAndSave } from "../../promotionals/BookEarlyAndSave"
import { Hero } from "./Hero"

const Search = ({ history, remoteCallSearchComponent }) => {
  const reservations = useSelector(state => reservationsSelector(state))
  const trip = useSelector(state => tripSelector(state))
  const settings = useSelector(state => settingsSelector(state))
  const loyalty = useSelector(state => loyaltySelector(state))
  const dispatch = useDispatch()
  const fetchReservationAllCities = useCallback(
    () => dispatch(reservationActions.fetchReservationAllCities()),
    [dispatch],
  )
  const getAllCarouselImages = useCallback(() => dispatch(settingsActions.getAllCarouselImages()), [
    dispatch,
  ])
  const fetchReservationCityPair = useCallback(
    code => dispatch(reservationActions.fetchReservationCityPair(code)),
    [dispatch],
  )

  const fetchReservationString = useCallback(
    searchString => dispatch(reservationActions.fetchReservationString(searchString)),
    [dispatch],
  )

  const setReservationProperty = useCallback(
    (field, value) => dispatch(reservationActions.setReservationProperty({ field, value })),
    [dispatch],
  )
  const fetchUserProfile = useCallback(() => dispatch(accountActions.fetchUserProfile()), [
    dispatch,
  ])
  const updateReservation = useCallback(() => dispatch(reservationActions.updateReservation()), [
    dispatch,
  ])

  const activateLoyalty = useCallback(active => dispatch(loyaltyActions.activateLoyalty(active)), [
    dispatch,
  ])

  const [carouselImages, setCarouselImages] = useState([])

  const searchParams = reservations.params || {}

  const initState = () => {
    return {
      formErrors: {},
      modalErrors: {},
      dep_from: searchParams.dep_from || {},
      dep_to: searchParams.dep_to || {},
      disabled: {
        disableFrom: searchParams.disableFrom,
        disableTo: searchParams.disableTo,
        disableStartDate: searchParams.disableStartDate,
        disableEndDate: searchParams.disableEndDate,
      },
      fromMinDate: searchParams.fromMinDate,
      fromMaxDate: searchParams.fromMaxDate,
      passengers_adults: searchParams.passengers_adults || 1,
      passengers_children: searchParams.passengers_children || 0,
      passengers_infants: searchParams.passengers_infants || 0,
      departure_date: searchParams.departure_date,
      return_date: searchParams.return_date,
    }
  }

  const [state, setState] = useState({ ...initState() })
  const init = () => {
    getAllCarouselImages()

    // TODO
    fetchUserProfile()
    fetchReservationAllCities()
  }
  useEffect(() => {
    setState(initState())
  }, [
    searchParams.passengers_adults,
    searchParams.passengers_children,
    searchParams.passengers_infants,
    searchParams.departure_date,
    searchParams.return_date,
  ])

  useEffect(() => {
    if (settings.carouselImages.length > 0 && carouselImages.length === 0) {
      const loadedCarouselImages = settings.carouselImages.map(carouselImage => {
        const img = new Image()
        img.src = carouselImage.image
        return carouselImage
      })
      setCarouselImages(loadedCarouselImages)
    }
  }, [settings.carouselImages])

  useEffect(() => {
    if (!remoteCallSearchComponent) {
      init()
    }
  }, [history.location])

  const onSubmit = async (loyaltyFlag = false) => {
    const errors = validateSearchForm({
      dep_from: searchParams.dep_from.code,
      dep_to: searchParams.dep_to.code,
      departure_date: state.departure_date && new Date(state.departure_date),
      return_date: state.return_date && new Date(state.return_date),
    })
    if (errors) {
      setState(prevState => ({
        ...prevState,
        formErrors: errors,
        modalErrors: errors,
      }))
      return false
    }

    await trackSearchActionTradeDesk()

    setReservationProperty("passengers_adults", state.passengers_adults)
    setReservationProperty("passengers_children", state.passengers_children)
    setReservationProperty("passengers_infants", state.passengers_infants)
    setReservationProperty("departure_date", state.departure_date)
    setReservationProperty("return_date", state.return_date)

    activateLoyalty(loyaltyFlag === true)
    updateReservation()
    history.push("/reservations/depart")

    return true
  }

  const onLoyaltySubmit = async () => {
    await trackSearchActionTradeDesk()
    onSubmit(true)
  }

  const onInputChanged = field => value => {
    setState(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const onDepartureDayChanged = day => {
    onInputChanged("departure_date")(day)
  }

  const onReturnDayChanged = day => {
    onInputChanged("return_date")(day)
  }

  const { formErrors: errors } = state
  const { froms, tos } = reservations

  const onLocationStringChange = (searchString, way, otherField) => {
    if (otherField) {
      fetchReservationString({
        code: otherField.code,
        way,
        searchString,
        all_airports: otherField.city_from || otherField.city_to,
      })
      return
    }

    fetchReservationString({ code: {}, searchString, way })
  }

  const onPlaceSelect = (way, city) => {
    fetchReservationCityPair({ city, way })
    setReservationProperty(way, city)
  }

  const onAdultCountChange = count => {
    onInputChanged("passengers_adults")(count)
  }

  const onChildrenCountChange = count => {
    onInputChanged("passengers_children")(count)
  }

  const onInfantsCountChange = count => {
    onInputChanged("passengers_infants")(count)
  }

  return (
    <ErrorBoundary>
      <NotificationConsumer>
        {() => {
          if (reservations.isBusy || trip.isBusy) {
            return <Loading />
          }

          return (
            <>
              <div className="Search__container">
                {!remoteCallSearchComponent && <Hero carouselImages={carouselImages} />}
                <div className="Search--Form">
                  <SearchForm
                    fromList={froms}
                    toList={tos}
                    setReservationProperty={setReservationProperty}
                    onLocationStringChange={onLocationStringChange}
                    departureDate={reservations.params.departure_date}
                    returnDate={reservations.params.return_date}
                    departureFrom={reservations.params.dep_from}
                    departureTo={reservations.params.dep_to}
                    editTrip={trip.editTrip !== ""}
                    passengersAdults={reservations.params.passengers_adults}
                    passengersChildrens={reservations.params.passengers_children}
                    passengersInfants={reservations.params.passengers_infants}
                    onPlaceSelect={onPlaceSelect}
                    onInputChanged={onInputChanged}
                    onDepartureDayChanged={onDepartureDayChanged}
                    onReturnDayChanged={onReturnDayChanged}
                    onAdultCountChange={onAdultCountChange}
                    onChildrenCountChange={onChildrenCountChange}
                    onInfantsCountChange={onInfantsCountChange}
                    onSubmit={onSubmit}
                    errors={errors}
                    loyaltyActive={loyalty.isActive}
                    onLoyaltySubmit={onLoyaltySubmit}
                    showFlightSearch={featureFlags().showFlightSearchFeatureFlag}
                    dateFromError={state.formErrors.departure_date}
                    disabled={initState().disabled}
                    fromMinDate={searchParams.fromMinDate}
                    fromMaxDate={searchParams.fromMaxDate}
                  />
                </div>
              </div>
              {!remoteCallSearchComponent && <SearchBottomContent />}
            </>
          )
        }}
      </NotificationConsumer>
    </ErrorBoundary>
  )
}

export default Search

Search.propTypes = {
  history: PropTypes.instanceOf(Object),
  remoteCallSearchComponent: PropTypes.bool,
}
Search.defaultProps = {
  history: {},
  remoteCallSearchComponent: false,
}

const SearchBottomContent = () => {
  return (
    <>
      <MotorCoach />
      <BookEarlyAndSave />
      <Promotion />
      <Bus />
      <Subscribe />
    </>
  )
}
