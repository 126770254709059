export default () => ({
  params: {
    passengers_adults: 1,
    passengers_children: 0,
    passengers_infants: 0,
    departure_date: new Date(),
    one_way: true,
    dep_to: {},
    dep_from: {},
    disableFrom: false,
    disableTo: false,
    disableStartDate: false,
    disableEndDate: false,
    fromMinDate: undefined,
    fromMaxDate: undefined,
    editTripLessThenFilterDate: null,
    editTripGreaterThanFilterDate: null,
    is_edit_per_segment: undefined,
    editingTripContainsFlight: null,
  },
  items: { depart_flights: [] },
  allCities: [],
  froms: [],
  tos: [],
  isBusy: false,
  departure_segments: [],
  return_segments: [],
  filters: {
    max_stopovers: 3,
    cabins_class: "M",
    maxFlightLayover: [1 * 3600, 3 * 3600],
    maxBusLayover: [1 * 3600, 3 * 3600],
    total_trip_time_max: 24 * 3600,
  },
  blackCarCities: {
    pickupCities: [],
    dropOffCities: [],
  },
  shouldCheckHeartbeat: false,
})
