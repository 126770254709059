import actions from "./actions"
import initialState, { defaultPassengerParams } from "./initialState"

export default (state = initialState(), action) => {
  const { type, payload } = action
  const { category, seat, seatIndex } = payload || {}
  let currentSeats = []
  let specialRequirementsList = []
  const fields = [
    "title",
    "first_name",
    "last_name",
    "email",
    "phone_number",
    "date_of_birth",
    "gender",
  ]

  switch (type) {
    case actions.CLEAR_PASSENGERS_SUCCESS:
      return initialState()
    case actions.SET_LEAD_PASSENGER:
    case actions.SET_PASSENGER_BY_INDEX:
    case actions.SET_CURRENT_PASSENGER:
    case actions.SET_SEAT_STATUS:
    case actions.UPDATE_PASSENGERS:
      return {
        ...state,
        isBusy: true,
      }
    case actions.SET_LEAD_PASSENGER_SUCCESS:
      return {
        ...state,
        isBusy: false,
        index: 0,
        0: {
          ...defaultPassengerParams,
          ...state[0],
          ...Object.keys(payload).reduce(pv => {
            const field = fields.pop()
            if (field) {
              pv[field] = payload[field]
            }
            return pv
          }, {}),
        },
      }
    case actions.SET_PASSENGER_BY_INDEX_SUCCESS:
      return {
        ...state,
        isBusy: false,
        [payload.index]: {
          ...defaultPassengerParams,
          ...state[0],
          ...Object.keys(payload).reduce(pv => {
            const field = fields.pop()
            if (field && field !== "index") {
              pv[field] = payload[field]
            }
            return pv
          }, {}),
        },
      }
    case actions.SET_CURRENT_PASSENGER_SUCCESS:
      return {
        ...state,
        isBusy: false,
        index: Number(payload),
        [Number(payload)]: {
          ...defaultPassengerParams,
          ...state[Number(payload)],
        },
      }
    case actions.SET_SEAT_STATUS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        seat: {
          ...state.seat,
          [`${payload.type}Completed`]: payload.status,
        },
      }
    case actions.UPDATE_PASSENGERS_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.UPDATE_PASSENGERS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        payload,
      }
    case actions.SET_PASSENGER_PROPERTY_SUCCESS:
      if (payload.field === "special_requirements") {
        specialRequirementsList = []
        if (payload.value.fax_code_id) {
          specialRequirementsList.push(payload.value)
        }
        payload.value = specialRequirementsList
      }
      return {
        ...state,
        [state.index]: {
          ...state[state.index],
          [payload.field]: payload.value,
        },
      }
    case actions.SET_PASSENGER_DEPARTURE_SEAT_SUCCESS:
      return {
        ...state,
        [state.index]: {
          ...state[state.index],
          departure_seat: payload,
        },
      }
    case actions.SET_PASSENGER_RETURN_SEAT_SUCCESS:
      return {
        ...state,
        [state.index]: {
          ...state[state.index],
          return_seat: payload,
        },
      }
    case actions.SET_PASSENGER_SEAT_SUCCESS:
      currentSeats = (state[state.index][category] || []).filter(
        selectedSeat => selectedSeat.index !== seatIndex,
      )
      currentSeats.push({ ...seat, index: seatIndex })
      currentSeats.sort((a, b) => {
        return parseFloat(a.index) - parseFloat(b.index)
      })
      return {
        ...state,
        [state.index]: {
          ...state[state.index],
          [category]: [...currentSeats],
        },
      }
    case actions.CLEAR_PASSENGER_SEAT_SUCCESS:
      currentSeats = (state[state.index][category] || []).filter(
        selectedSeat => selectedSeat.index !== seatIndex,
      )
      return {
        ...state,
        [state.index]: {
          ...state[state.index],
          [category]: [...currentSeats],
        },
      }
    default:
      return state
  }
}
