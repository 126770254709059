import React from "react"
import "./MotorCoach.scss"
import motorcoach from "../../media/motorcoach.svg"
import motorcoachSteps from "../../media/motorcoach-steps.svg"

export function MotorCoach() {
  return (
    <div className="MotorCoach">
      <h2>The best connection to your flight at an incredible price</h2>
      <div className="info-row">
        <img src={motorcoach} width={338} alt="Landline black motorcoach" />
        <div className="info-row-text__container">
          <div className="info-row-text">
            Our motor coach and express shuttle service to and from MSP or DEN gets you to your
            flight connection or home more quickly. When you arrive, our service agents will unload
            your bags. <a href="/how-it-works">Learn more about how it works.</a>
          </div>
          <img
            src={motorcoachSteps}
            width={396}
            className="motorcoach"
            alt="1. Book Trip, 2. Check In, 3. Enjoy the ride!"
          />
        </div>
      </div>
    </div>
  )
}
