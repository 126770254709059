/* eslint-disable jsx-a11y/label-has-associated-control */
import "./PickupDetailsPage.scss"

import classNames from "classnames"
import {
  Button,
  DropDownDatePicker,
  DropdownMenu,
  Icon,
  InputFieldSet,
  NotificationConsumer,
  PageTitle,
  TimePicker,
} from "ibe-components"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { DEPART_PICKUP } from "../../../constants/routes"
import { SEGMENT_DEPART, SEGMENT_RETURN } from "../../../constants/segments"
import errorActions from "../../../redux/error/actions"
import reservationActions from "../../../redux/reservations/actions"
import {
  errorSelector,
  itinerarySelector,
  passengerSelector,
  reservationsSelector,
  ticketSelector,
  tripSelector,
} from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"
import { reduceErrors } from "../../../utils/common"
import {
  containsFlight,
  getRouteIndex,
  isBlackCar,
  isExpress,
  isExpressAtHome,
} from "../../../utils/tripUtil-util"
import { validatePickupDetails } from "../../../utils/validation"
import Breadcrumbs from "../../atoms/Breadcrumbs"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"

const PickupDetailsPage = ({ history }) => {
  const pickupDetailsPageClass = classNames("pickup-details-page-container")

  const dispatch = useDispatch()
  const location = useLocation()

  const ticket = useSelector(state => ticketSelector(state))
  const itinerary = useSelector(state => itinerarySelector(state))
  const passenger = useSelector(state => passengerSelector(state))
  const trip = useSelector(state => tripSelector(state))
  const reservations = useSelector(state => reservationsSelector(state))
  const error = useSelector(state => errorSelector(state))

  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])

  const [state, setState] = useState({
    testDate: null,
    testDateFocus: false,
    formErrors: {},
    modalErrors: {},
  })

  const { pickupCities, dropOffCities } = reservations.blackCarCities

  const setPickupDetailsProperty = useCallback(
    (segmentDirection, field, value) =>
      dispatch(tripActions.setPickupDetailsProperty({ segmentDirection, field, value })),
    [dispatch],
  )
  const setPickupDetailsProperties = useCallback(
    (segmentDirection, data) =>
      dispatch(tripActions.setPickupDetailsProperties({ segmentDirection, data })),
    [dispatch],
  )

  const setPickupDetails = useCallback(pickup => dispatch(tripActions.setPickupDetails(pickup)), [
    dispatch,
  ])

  const segmentDirection = location.pathname === DEPART_PICKUP ? SEGMENT_DEPART : SEGMENT_RETURN
  const { departureLandlineSegmentIndex, returnLandlineSegmentIndex, bookingFlowIndex } = trip

  const currentSegments =
    (segmentDirection === SEGMENT_DEPART ? ticket.departureTicket : ticket.returnTicket) || []

  const getCitiesToFetch = () => {
    return {
      departureCityCode:
        currentSegments[
          segmentDirection === SEGMENT_DEPART
            ? departureLandlineSegmentIndex
            : returnLandlineSegmentIndex
        ].departure_code,
      arrivalCityCode:
        currentSegments[
          segmentDirection === SEGMENT_DEPART
            ? departureLandlineSegmentIndex
            : returnLandlineSegmentIndex
        ].arrival_code,
    }
  }

  useEffect(() => {
    dispatch(
      reservationActions.fetchBlackCarCities({
        ...getCitiesToFetch(),
        newSegmentDirection: segmentDirection,
      }),
    )
  }, [segmentDirection])

  const routeIndex = getRouteIndex(location.pathname)

  useEffect(() => {
    if (bookingFlowIndex !== routeIndex) {
      dispatch(tripActions.setBookingFlowIndex(routeIndex))
    }
  }, [routeIndex])

  const isExpressHome =
    segmentDirection === SEGMENT_DEPART
      ? isExpressAtHome(ticket.departureTicket[departureLandlineSegmentIndex])
      : isExpressAtHome(ticket.returnTicket[returnLandlineSegmentIndex])

  const activePickupLocation = trip.pickupDetails[segmentDirection]

  const checkForAirport = cities => {
    return cities[0]?.isAirport
  }

  const getCities = directionName => {
    const cities = directionName === "departure_code" ? pickupCities : dropOffCities
    return cities.map(city => [city.cityName, city.cityName])
  }

  const cityForOperatedArea = !checkForAirport(pickupCities)
    ? pickupCities[0]?.code
    : dropOffCities[0]?.code

  useEffect(() => {
    if (trip.editTrip !== "" && cityForOperatedArea) {
      dispatch(
        tripActions.getOperatedArea({
          city: cityForOperatedArea,
          transportationType: currentSegments[0].service_type,
        }),
      )
    }
  }, [cityForOperatedArea])

  const onInputPickupChanged = (field, value) => {
    if (value !== trip.pickupDetails[segmentDirection].pick_up_address[field]) {
      setPickupDetailsProperties(segmentDirection, {
        pick_up_address: {
          ...trip.pickupDetails[segmentDirection].pick_up_address,
          [field]: value,
        },
      })
    }
  }

  const onCityPickupChanged = value => {
    const cityState = pickupCities.find(city => {
      return city.cityName === value
    })

    if (value !== trip.pickupDetails[segmentDirection].pick_up_address.city) {
      setPickupDetailsProperties(segmentDirection, {
        pick_up_address: {
          ...trip.pickupDetails[segmentDirection].pick_up_address,
          city: value,
          state: cityState.state,
        },
      })
    }
  }

  const onInputDropoffChanged = (field, value) => {
    if (value !== trip.pickupDetails[segmentDirection].drop_off_address[field]) {
      setPickupDetailsProperties(segmentDirection, {
        drop_off_address: {
          ...trip.pickupDetails[segmentDirection].drop_off_address,
          [field]: value,
        },
      })
    }
  }

  const onCityDropOffChanged = value => {
    const cityState = dropOffCities.find(city => {
      return city.cityName === value
    })

    if (value !== trip.pickupDetails[segmentDirection].drop_off_address.city) {
      setPickupDetailsProperties(segmentDirection, {
        drop_off_address: {
          ...trip.pickupDetails[segmentDirection].drop_off_address,
          city: value,
          state: cityState.state,
        },
      })
    }
  }

  const onInputAirlineChanged = (field, value) => {
    setPickupDetailsProperty(segmentDirection, field, value)
  }

  const goToPayment = () => {
    setState(prevState => ({
      ...prevState,
      formErrors: {},
      modalErrors: {},
    }))

    const validationData = {
      pickupCity: activePickupLocation.pick_up_address.city,
      pickupFirstAddress: activePickupLocation.pick_up_address.first_address_line,
      pickupState: activePickupLocation.pick_up_address.state,
      dropOffCity: activePickupLocation.drop_off_address.city,
      dropOffFirstAddress: activePickupLocation.drop_off_address.first_address_line,
      dropOffState: activePickupLocation.drop_off_address.state,
      departureDate: activePickupLocation.departure_date,
      departureTime: activePickupLocation.departure_time,
      arrivalDate: activePickupLocation.arrival_date,
      arrivalTime: activePickupLocation.arrival_time,
    }
    // "2020-11-02T23:00:00Z"

    const errors = validatePickupDetails(
      validationData,
      isExpressHome,
      checkForAirport(pickupCities),
      checkForAirport(dropOffCities),
    )
    if (errors && Object.keys(errors).length > 0) {
      setState(prevState => ({
        ...prevState,
        formErrors: errors,
        modalErrors: reduceErrors(errors),
        showReserveSeat: false,
      }))
      return
    }
    const isPickup = !checkForAirport(pickupCities)
    setPickupDetails({
      segmentDirection,
      history,
      isPickup,
    })
  }

  return (
    <ErrorBoundary>
      <NotificationConsumer>
        {({ actions }) => {
          return (
            <PageLayout
              render={() => {
                if (itinerary.isBusy || passenger.isBusy || trip.isBusy || reservations.isBusy) {
                  return <Loading />
                }
                if (state.modalErrors) {
                  actions.show({ type: "is-danger", message: state.modalErrors })
                  setState(prevState => ({
                    ...prevState,
                    modalErrors: undefined,
                  }))
                }
                if (error && !error.isBusy) {
                  actions.show({
                    type: "is-address",
                    message: error,
                    miliseconds: 8000,
                  })
                  clearError()
                }

                return (
                  <div className="pick-up-details-page-wrapper">
                    <Breadcrumbs history={history} />

                    <div className="SectionWrapper pickup-details-page">
                      <div className={pickupDetailsPageClass}>
                        {(!isExpressHome || !checkForAirport(pickupCities)) && (
                          <div>
                            <PageTitle title="Pick Up Location" margin="20px 0px 20px 0px" />

                            {checkForAirport(pickupCities) ? (
                              <div className="form-container">
                                <div className="city-wrapper">
                                  <DropdownMenu
                                    mandatory
                                    labelText="Airport terminal"
                                    error={state.formErrors.pickupFirstAddress}
                                    value={activePickupLocation.pick_up_address.first_address_line}
                                    content={pickupCities[0].items}
                                    onSelect={value => {
                                      onInputPickupChanged("first_address_line", value)
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="street-address-wrapper">
                                  <InputFieldSet
                                    labelText="Address line 1"
                                    mandatory
                                    fullWidth
                                    error={state.formErrors.pickupFirstAddress}
                                    inputValue={
                                      activePickupLocation.pick_up_address.first_address_line
                                    }
                                    onChange={e => {
                                      onInputPickupChanged("first_address_line", e.target.value)
                                    }}
                                  />
                                </div>
                                <div className="street-number-wrapper">
                                  <InputFieldSet
                                    labelText="Address line 2"
                                    fullWidth
                                    inputValue={
                                      activePickupLocation.pick_up_address.second_address_line
                                    }
                                    onChange={e => {
                                      onInputPickupChanged("second_address_line", e.target.value)
                                    }}
                                  />
                                </div>
                              </>
                            )}
                            <div className="form-container">
                              <div className="city-wrapper">
                                <DropdownMenu
                                  labelText={checkForAirport(pickupCities) ? "Airport" : "City"}
                                  value={activePickupLocation.pick_up_address.city}
                                  error={state.formErrors.pickupCity}
                                  content={getCities("departure_code")}
                                  mandatory
                                  onSelect={value => {
                                    onCityPickupChanged(value)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-container dropdown-container zip-state-container">
                              {!checkForAirport(pickupCities) && (
                                <div className="zip-code-wrapper">
                                  <InputFieldSet
                                    labelText="ZIP CODE"
                                    fullWidth
                                    inputValue={activePickupLocation.pick_up_address.zip_code}
                                    onChange={e => {
                                      onInputPickupChanged("zip_code", e.target.value)
                                    }}
                                  />
                                </div>
                              )}
                              <div className="state-wrapper">
                                <InputFieldSet
                                  labelText="STATE"
                                  error={state.formErrors.pickupState}
                                  inputValue={activePickupLocation.pick_up_address.state}
                                  mandatory
                                  fullWidth
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {(!isExpressHome || !checkForAirport(dropOffCities)) && (
                          <div>
                            <PageTitle
                              title="Drop off Location"
                              margin={isExpressHome ? "20px 0px 20px 0px" : "0px 0px 20px 0px"}
                            />
                            {checkForAirport(dropOffCities) ? (
                              <div className="form-container">
                                <div className="city-wrapper">
                                  <DropdownMenu
                                    mandatory
                                    labelText="Airport terminal"
                                    error={state.formErrors.dropOffFirstAddress}
                                    value={activePickupLocation.drop_off_address.first_address_line}
                                    content={dropOffCities[0].items}
                                    onSelect={value => {
                                      onInputDropoffChanged("first_address_line", value)
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="street-address-wrapper">
                                  <InputFieldSet
                                    labelText="Address line 1"
                                    mandatory
                                    fullWidth
                                    error={state.formErrors.dropOffFirstAddress}
                                    inputValue={
                                      activePickupLocation.drop_off_address.first_address_line
                                    }
                                    onChange={e => {
                                      onInputDropoffChanged("first_address_line", e.target.value)
                                    }}
                                  />
                                </div>
                                <div className="street-number-wrapper">
                                  <InputFieldSet
                                    labelText="Address line 2"
                                    fullWidth
                                    inputValue={
                                      activePickupLocation.drop_off_address.second_address_line
                                    }
                                    onChange={e => {
                                      onInputDropoffChanged("second_address_line", e.target.value)
                                    }}
                                  />
                                </div>
                              </>
                            )}
                            <div className="form-container">
                              <div className="city-wrapper">
                                <DropdownMenu
                                  labelText={checkForAirport(dropOffCities) ? "Airport" : "City"}
                                  value={activePickupLocation.drop_off_address.city}
                                  error={state.formErrors.dropOffCity}
                                  mandatory
                                  content={getCities()}
                                  onSelect={value => {
                                    onCityDropOffChanged(value)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-container dropdown-container zip-state-container">
                              {!checkForAirport(dropOffCities) && (
                                <div className="zip-code-wrapper">
                                  <InputFieldSet
                                    labelText="ZIP CODE"
                                    fullWidth
                                    inputValue={activePickupLocation.drop_off_address.zip_code}
                                    onChange={e => {
                                      onInputDropoffChanged("zip_code", e.target.value)
                                    }}
                                  />
                                </div>
                              )}
                              <div className="state-wrapper">
                                <InputFieldSet
                                  labelText="STATE"
                                  error={state.formErrors.dropOffState}
                                  inputValue={activePickupLocation.drop_off_address.state}
                                  mandatory
                                  fullWidth
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {!isExpressHome && (
                          <>
                            <PageTitle title="Pick up Time" margin="0px 0px 20px 0px" />
                            <div className="form-container pickup-information-container">
                              <div className="first-wrapper">
                                <InputFieldSet
                                  labelText="DATE"
                                  inputValue={
                                    isExpressHome
                                      ? moment(isExpress(currentSegments).departure_date).format(
                                          "DD MMM yyyy",
                                        )
                                      : moment(isBlackCar(currentSegments).departure_date).format(
                                          "DD MMM yyyy",
                                        )
                                  }
                                  disabled
                                  fullWidth
                                />
                              </div>
                              <div className="second-wrapper">
                                <InputFieldSet
                                  labelText="TIME"
                                  inputValue={
                                    isExpressHome
                                      ? moment(
                                          isExpress(currentSegments).departure_time,
                                          "HH:mm:ss",
                                        ).format("hh:mm A")
                                      : moment(
                                          isBlackCar(currentSegments).departure_time,
                                          "HH:mm:ss",
                                        ).format("hh:mm A")
                                  }
                                  disabled
                                  fullWidth
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {/* {!containsFlight(currentSegments) && !isExpressHome && (
                          <div>
                            <PageTitle title="Flight Information" margin="0px 0px 20px 0px" />
                            <div className="form-container pickup-information-container">
                              <div className="first-wrapper">
                                <InputFieldSet
                                  labelText="AIRLINE"
                                  fullWidth
                                  inputValue={activePickupLocation.airline}
                                  onChange={e => {
                                    onInputAirlineChanged("airline", e.target.value)
                                  }}
                                />
                              </div>
                              <div className="second-wrapper">
                                <InputFieldSet
                                  labelText="FLIGHT NUMBER"
                                  fullWidth
                                  inputValue={activePickupLocation.flight_number}
                                  onChange={e => {
                                    onInputAirlineChanged("flight_number", e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-container pickup-information-container">
                              <div className="first-wrapper">
                                <InputFieldSet
                                  labelText="ORIGIN"
                                  fullWidth
                                  inputValue={activePickupLocation.origin}
                                  onChange={e => {
                                    onInputAirlineChanged("origin", e.target.value)
                                  }}
                                />
                              </div>
                              <div className="second-wrapper">
                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                  <DropDownDatePicker
                                    fullWidth
                                    labelText="DEPARTURE DATE"
                                    value={activePickupLocation.departure_date}
                                    onDateChange={date =>
                                      onInputAirlineChanged("departure_date", date)
                                    }
                                  />

                                  <TimePicker
                                    error={state.formErrors.departureTime}
                                    margin="0 0 0 10px"
                                    fullWidth
                                    labelText="DEPARTURE TIME"
                                    onChange={time => onInputAirlineChanged("departure_time", time)}
                                    value={activePickupLocation.departure_time}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-container pickup-information-container">
                              <div className="first-wrapper">
                                <InputFieldSet
                                  labelText="DESTINATION"
                                  fullWidth
                                  inputValue={activePickupLocation.destination}
                                  onChange={e => {
                                    onInputAirlineChanged("destination", e.target.value)
                                  }}
                                />
                              </div>
                              <div className="second-wrapper">
                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                  <DropDownDatePicker
                                    fullWidth
                                    labelText="ARRIVAL DATE"
                                    value={activePickupLocation.arrival_date}
                                    onDateChange={date =>
                                      onInputAirlineChanged("arrival_date", date)
                                    }
                                  />

                                  <TimePicker
                                    error={state.formErrors.arrivalTime}
                                    margin="0 0 0 10px"
                                    fullWidth
                                    labelText="ARRIVAL TIME"
                                    onChange={time => onInputAirlineChanged("arrival_time", time)}
                                    value={activePickupLocation.arrival_time}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}

                        <div className="button-wrapper">
                          <Button
                            type="primary"
                            fullWidth
                            onClick={goToPayment}
                            icon={
                              <Icon align="right" name="arrowRight" style={{ marginRight: 10 }} />
                            }
                          >
                            Continue
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }}
            />
          )
        }}
      </NotificationConsumer>
    </ErrorBoundary>
  )
}

PickupDetailsPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

PickupDetailsPage.defaultProps = {}

export default PickupDetailsPage
