import { Button, InputFieldSet } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const VoucherForm = ({
  voucher,
  voucherCode,
  onPromoCodeChanged,
  onAddPromoCodeClicked,
  onRemovePromoCodeClicked,
}) => {
  const voucherData = voucher && voucher.data && voucher.data.vouchers_id

  const handleVoucherCodeChange = e => {
    e.preventDefault()
    if (voucherData) {
      onRemovePromoCodeClicked()
    } else {
      onAddPromoCodeClicked()
    }
  }

  return (
    <form>
      <VoucherWrapper>
        <InputFieldSetContainer>
          <InputFieldSet
            fullWidth
            labelText="PROMO CODE"
            inputValue={voucherData || voucherCode}
            onChange={e => onPromoCodeChanged(e.target.value)}
          />
        </InputFieldSetContainer>
        <ButtonContainer>
          <Button
            buttonType="submit"
            type={voucherData ? "destructive" : "secondary"}
            onClick={handleVoucherCodeChange}
          >
            {voucherData ? "Remove" : "Add"}
          </Button>
        </ButtonContainer>
      </VoucherWrapper>
    </form>
  )
}

export default VoucherForm

VoucherForm.propTypes = {
  voucher: PropTypes.instanceOf(Object),
  voucherCode: PropTypes.string,
  onPromoCodeChanged: PropTypes.func,
  onAddPromoCodeClicked: PropTypes.func,
  onRemovePromoCodeClicked: PropTypes.func,
}

VoucherForm.defaultProps = {
  voucher: {},
  voucherCode: "",
  onPromoCodeChanged: () => {},
  onAddPromoCodeClicked: () => {},
  onRemovePromoCodeClicked: () => {},
}

const VoucherWrapper = styled.div`
  flex: 1;
  padding-top: 20px;
  display: flex;
`

const InputFieldSetContainer = styled.div`
  flex: 1;
  padding-right: 20px;
  @media screen and (max-width: 450px) {
    padding-bottom: 20px;
  }
`

const ButtonContainer = styled.div``
