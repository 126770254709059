import "./CarouselRatings.scss"

import PropTypes from "prop-types"
import React from "react"

const CarouselRatings = ({ role, peopleName, text }) => {
  return (
    <div className="carouselRatingsContainer">
      <div className="starsContainer">
        <div className="dividerTop" />
      </div>
      <p>{text}</p>
      <div className="dividerBottom" />
      <p className="name">{peopleName}</p>
      <p className="role">{role}</p>
    </div>
  )
}

CarouselRatings.propTypes = {
  text: PropTypes.string,
  peopleName: PropTypes.string,
  role: PropTypes.string,
}

CarouselRatings.defaultProps = {
  text: "",
  peopleName: "",
  role: "",
}

export default CarouselRatings
