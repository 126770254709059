const ADD_TO_CART_ACTION = `?adv=qzp04nh&ct=0:fg9ya9f&fmt=3`
const SEARCH_ACTION = `?adv=qzp04nh&ct=0:ua6vy9f&fmt=3`

/**
 * Fires a pixel-like tracking event for the add to cart action. For us this
 * means pressing 'continue' in the summary page
 */
export async function trackAddToCartActionTradeDesk() {
  try {
    await fetch(`https://insight.adsrvr.org/track/pxl/${ADD_TO_CART_ACTION}`, {
      mode: "no-cors",
    })
  } catch (e) {
    // eslint-disable-next-line
    console.log("Failed to track action: add to cart", e)
  }
}

/**
 * Fires a pixel-like tracking event for the search action. This is tracking
 * intent from the booking engine
 */
export async function trackSearchActionTradeDesk() {
  try {
    await fetch(`https://insight.adsrvr.org/track/pxl/${SEARCH_ACTION}`, {
      mode: "no-cors",
    })
  } catch (e) {
    // eslint-disable-next-line
    console.log("Failed to track action: search", e)
  }
}
