import "./style.scss"

import { Button, CheckBox, InputFieldSet, NotificationConsumer, PageTitle } from "ibe-components"
import PropTypes from "prop-types"
import queryString from "query-string"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import featureFlags from "../../../constants/feature-flags"
import { TERMS_AND_CONDITIONS } from "../../../constants/routes"
import accountActions from "../../../redux/account/actions"
import errorActions from "../../../redux/error/actions"
import { accountSelector, errorSelector } from "../../../redux/selector"
import { reduceErrors } from "../../../utils/common"
import config from "../../../utils/config"
import { validateSignUp } from "../../../utils/validation"
import Loading from "../../atoms/Loading"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"

const styles = {
  legendStyle: {
    fontWeight: "600",
    backgroundColor: "#FFFFFF",
    padding: "0 5px",
  },
  inputStyle: {
    marginBottom: "10px",
  },
}

const SignUp = ({ history, location }) => {
  const dispatch = useDispatch()
  const registerUser = useCallback(params => dispatch(accountActions.registerUser(params)), [
    dispatch,
  ])
  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])

  const error = useSelector(state => errorSelector(state))
  const account = useSelector(state => accountSelector(state))

  const params = queryString.parse(location.search)
  const { referalcode } = params
  const [state, setState] = useState({
    isPasswordHidden: false,
    isPasswordFieldFocused: false,
    data: {
      referal_code: referalcode || "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      retypePassword: "",
      phone_number: "",
      subscribe_to_newsletter: true,
    },
    modalErrors: [],
    formErrors: [],
  })
  const onInputChanged = field => value => {
    setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [field]: value,
      },
    }))
  }

  const validateForm = errors => {
    if (errors && Object.keys(errors).length > 0) {
      if (typeof errors === "string") {
        setState(prevState => ({ ...prevState, modalErrors: errors }))
      } else {
        setState(prevState => ({
          ...prevState,
          formErrors: errors,
          modalErrors: reduceErrors(errors),
        }))
      }
      return false
    }
    setState(prevState => ({
      ...prevState,
      formErrors: {},
      modalErrors: {},
    }))
    return true
  }
  const onPasswordFieldFocus = isPasswordFieldFocused => {
    setState(prevState => ({
      ...prevState,
      isPasswordFieldFocused,
    }))
  }

  const onSubmit = e => {
    e.preventDefault()
    const errors = validateSignUp(state.data)
    if (validateForm(errors)) {
      registerUser({
        ...state.data,
        referal_code: state.data.referal_code ? state.data.referal_code : undefined,
        history,
      })
    }
  }
  const onSubscriptionCheckBoxChange = () => {
    setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        subscribe_to_newsletter: !prevState.data.subscribe_to_newsletter,
      },
    }))
  }

  return (
    <NotificationConsumer>
      {() => {
        return (
          <TabNavbarLayout
            render={() => {
              if (account.isBusy || (error && error.isBusy)) {
                return <Loading />
              }

              if (error) {
                validateForm(error)
                clearError()
              }

              const { data } = state
              return (
                <div className="SignUp--top">
                  <form onSubmit={onSubmit}>
                    <PageTitle title="Welcome" margin="0" />
                    <div className="Input--Fields">
                      <InputFieldSet
                        style={styles.inputStyle}
                        fullWidth
                        labelText="FIRST NAME"
                        mandatory
                        margin="20px 0 10px 0"
                        inputValue={data.first_name}
                        onChange={e => onInputChanged("first_name")(e.target.value)}
                        error={!!state.formErrors.first_name}
                      />
                      {state.formErrors.first_name && (
                        <div className="singup-inputfield-error">{state.formErrors.first_name}</div>
                      )}
                      <InputFieldSet
                        style={styles.inputStyle}
                        fullWidth
                        labelText="LAST NAME"
                        mandatory
                        margin="10px 0 10px 0"
                        inputValue={data.last_name}
                        onChange={e => onInputChanged("last_name")(e.target.value)}
                        error={!!state.formErrors.last_name}
                      />
                      {state.formErrors.last_name && (
                        <div className="singup-inputfield-error">{state.formErrors.last_name}</div>
                      )}
                      <InputFieldSet
                        inputType="email"
                        inputProps={{ id: "email", name: "email" }}
                        style={styles.inputStyle}
                        fullWidth
                        labelText="EMAIL"
                        mandatory
                        margin="10px 0 10px 0"
                        inputValue={data.email}
                        onChange={e => onInputChanged("email")(e.target.value.trim())}
                        error={!!state.formErrors.email}
                      />
                      {state.formErrors.email && (
                        <div className="singup-inputfield-error">{state.formErrors.email}</div>
                      )}
                      <InputFieldSet
                        inputType="password"
                        style={styles.inputStyle}
                        fullWidth
                        labelText="CREATE PASSWORD"
                        mandatory
                        margin="10px 0 10px 0"
                        onFocus={() => {
                          onPasswordFieldFocus(true)
                        }}
                        onBlur={() => {
                          onPasswordFieldFocus(false)
                        }}
                        inputValue={data.password}
                        onChange={e => onInputChanged("password")(e.target.value)}
                        error={!!state.formErrors.password}
                      />
                      {state.formErrors.password && (
                        <div className="singup-inputfield-error">{state.formErrors.password}</div>
                      )}
                      {state.isPasswordFieldFocused && (
                        <div className="singup-inputfield-pwrequirements">
                          Please, provide a password that is at least 8 characters long and contain
                          an uppercase letter or special character.
                        </div>
                      )}
                      <InputFieldSet
                        inputType="password"
                        labelText="RETYPE PASSWORD"
                        fullWidth
                        margin="10px 0 10px 0"
                        inputValue={data.retypePassword}
                        onChange={e => {
                          onInputChanged("retypePassword")(e.target.value)
                        }}
                        error={!!state.formErrors.retypePassword}
                      />
                      {state.formErrors.retypePassword && (
                        <div className="singup-inputfield-error">
                          {state.formErrors.retypePassword}
                        </div>
                      )}
                      <InputFieldSet
                        style={styles.inputStyle}
                        fullWidth
                        maskType="phone"
                        labelText="PHONE NUMBER"
                        mandatory
                        margin="10px 0 10px 0"
                        inputValue={data.phone_number}
                        onChange={e => onInputChanged("phone_number")(e.target.value)}
                        error={!!state.formErrors.phone_number}
                      />
                      {state.formErrors.phone_number && (
                        <div className="singup-inputfield-error">
                          {state.formErrors.phone_number}
                        </div>
                      )}
                      {featureFlags().referralFeatureFlag && (
                        <>
                          <InputFieldSet
                            style={styles.inputStyle}
                            fullWidth
                            labelText="REFERRAL CODE"
                            margin="10px 0 20px 0"
                            inputValue={data.referal_code}
                            onChange={e => onInputChanged("referal_code")(e.target.value)}
                            error={!!state.formErrors.referal_code}
                            disabled={!!referalcode}
                          />
                          {state.formErrors.referal_code && (
                            <div className="singup-inputfield-error">
                              {state.formErrors.referal_code}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="subscription-checkbox-wrapper" style={{ display: "none" }}>
                      <CheckBox
                        checked={state.data.subscribe_to_newsletter}
                        onChange={onSubscriptionCheckBoxChange}
                        value="Sign up to receive instant savings and news to your inbox"
                      />
                    </div>
                    <div className="Login--Buttons">
                      <Button buttonType="submit" fullWidth type="primary">
                        {state.data.referal_code.length > 0
                          ? "Sign up and redeem"
                          : "Create Account"}
                      </Button>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#302D2B",
                        marginBottom: 40,
                        marginTop: 10,
                      }}
                    >
                      By creating an account, you agree to the&nbsp;
                      <a
                        href={`${config.LANDLINE_URL}${TERMS_AND_CONDITIONS}`}
                        style={{
                          color: "#302D2B",
                          fontWeight: "bold",
                        }}
                      >
                        Terms & Conditions
                      </a>
                    </div>
                    <div
                      style={{
                        textAlign: "flex-start",
                        fontSize: "14px",
                        marginBottom: 10,
                      }}
                    >
                      Already have an account?
                    </div>
                    <div className="Login--Buttons">
                      <Button
                        className="Profile--create-account-button"
                        fullWidth
                        type="secondary"
                        buttonType="submit"
                        onClick={() => {
                          history.push("/login")
                        }}
                      >
                        Login
                      </Button>
                    </div>
                  </form>
                </div>
              )
            }}
          />
        )
      }}
    </NotificationConsumer>
  )
}

export default SignUp

SignUp.propTypes = {
  history: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
}
SignUp.defaultProps = {
  history: {},
  location: {},
}
