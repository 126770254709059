import React from "react"
import "./Locations.scss"
import "../SchedulePages/SchedulePage.desktop.scss"
import "../SchedulePages/SchedulePage.mobile.scss"
import { ColoradoLocations, MNWLocations} from "./locations"
import { NotificationConsumer } from "ibe-components"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"
import Subscribe from "../../organisms/Subscribe"
import { ScheduleLocation } from "../SchedulePages/Locations"

const PickupLocationsPage = ({ history }) => {

  function gotoLocation(location) {
    history.push(location)
  }

  return (
    <NotificationConsumer>
      {({ actions }) => {
        return (
          <ErrorBoundary>
            <TabNavbarLayout
            pageLayoutBackground="#FFF"
              render={() => {

                return (
                  <> 
                  <div className="locations-container">
                  <h4 className="locations-container-title">Serving Colorado</h4>
                    {ColoradoLocations.map((location) => {
                      return < ScheduleLocation data={location} onButtonClick={() => {gotoLocation(location.url)}} />
                    })}
                    <h4 className="locations-container-title">Serving Minnesota and North Dakota</h4>
                    {MNWLocations.map((location) => {
                      return < ScheduleLocation data={location} onButtonClick={() => {gotoLocation(location.url)}} />
                    })}
                  </div>
                  </>
                )
              }}
            />
          </ErrorBoundary>
        )
      }}
    </NotificationConsumer>
  )
}


export default PickupLocationsPage