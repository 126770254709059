import PropTypes from "prop-types"
import { useEffect } from "react"
import { withRouter } from "react-router-dom"

function ScrollToTop({ history, children }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [history.location])

  return children
}

export default withRouter(ScrollToTop)

ScrollToTop.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.element.isRequired,
}
