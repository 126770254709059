import PropTypes from "prop-types"
import React, { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Redirect } from "react-router-dom"

import accountActions from "../../../redux/account/actions"
import errorActions from "../../../redux/error/actions"

const Logout = ({ history }) => {
  const dispatch = useDispatch()
  const logoutUser = useCallback(() => dispatch(accountActions.logoutUser()), [dispatch])
  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])
  useEffect(() => {
    clearError()
    return () => {
      logoutUser()
    }
  }, [history.location])
  return <Redirect to="/" />
}

export default Logout

Logout.propTypes = {
  history: PropTypes.instanceOf(Object),
}

Logout.defaultProps = {
  history: {},
}
