import actions from "./actions"
import initialState from "./initialState"

export default (state = initialState(), action) => {
  const { type, payload } = action
  switch (type) {
    case actions.GET_SETTINGS_DATA:
      return {
        ...state,
        isBusy: true,
      }

    case actions.GET_SETTINGS_DATA_SUCCESS:
      return {
        ...state,
        isBusy: false,
        ...payload,
      }

    case actions.GET_SETTINGS_DATA_FAIL:
      return {
        ...state,
        isBusy: false,
      }

    case actions.GET_CAROUSEL_IMAGES:
      return {
        ...state,
        isBusy: true,
      }

    case actions.GET_CAROUSEL_IMAGES_SUCCESS:
      return {
        ...state,
        isBusy: false,
        carouselImages: payload,
      }

    case actions.GET_CAROUSEL_IMAGES_FAIL:
      return {
        ...state,
        isBusy: false,
      }

    default:
      return state
  }
}
