/* eslint-disable */
// _objectPath - no-underscore-dangle problem

import Validator from "validatorjs"
import { ADULT_MIN_AGE, CHILD_MAX_AGE, CHILD_MIN_AGE } from "../constants/passenger-age"

const validateInput = (data, rules, customMessages, attributeNames) => {
  const validator = new Validator(data, rules, customMessages)
  if (attributeNames) {
    validator.setAttributeNames(attributeNames)
  }
  if (validator.fails()) {
    const { errors } = validator.errors
    const errs = Object.keys(errors).reduce((pr, cv) => {
      pr[cv] = errors[cv][0]
      return pr
    }, {})
    return errs
  }
  return undefined
}
function strictNumRequiredIf(value, requirement) {
  requirement = this.getParameters()
  if (value === undefined || value === null || value === 0) {
    const compareValue = this.validator._objectPath(this.validator.input, requirement[0])
    if (!compareValue || compareValue === requirement[1]) {
      return false
    }
  }
  return true
}

function strictBoolRequiredIf(value, requirement) {
  requirement = this.getParameters()
  if (value === undefined || value === null) {
    const compareValue = this.validator._objectPath(this.validator.input, requirement[0])
    if (!compareValue || compareValue === requirement[1]) {
      return false
    }
  }
  return true
}

Validator.register(
  "name",
  function(value) {
    // requirement parameter defaults to null
    return value.match(/^([a-zA-Z]+)*$/u)
  },
  "The :attribute must contain only letters with no special characters or spaces",
)

Validator.register(
  "phone",
  function(value) {
    // requirement parameter defaults to null
    return value.match(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3,4})[-. )]*(\d{3,4})[-. ]*(\d{3,4})(?:x(\d+))?$/,
    )
  },
  "Please make sure your :attribute number is correct",
)

Validator.register(
  "strict_num_required_if",
  strictNumRequiredIf,
  "The :attribute or :parameters is required",
)
Validator.register("strict_bool_required_if", strictBoolRequiredIf, ":attribute is required")
export const validateSearchForm = params => {
  const rules = {
    dep_from: "required",
    dep_to: "required",
    departure_date: ["required", "date"],
    return_date: "date",
  }

  const customMessages = {
    "required.dep_from": "Choose the depart from location",
    "required.dep_to": "Choose the depart to location",
    "required.departure_date": "Choose the departure date",
  }
  const attributeNames = {
    dep_from: "Depart From",
    dep_to: "Depart To",
    departure_date: "Departure Date",
    return_date: "Return Date",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateLogin = params => {
  const rules = {
    email: "required|email",
    password: "required",
  }
  const customMessages = {
    "required.email": "Without :attribute, we can't reach you",
    "email.email": "Please enter a valid :attribute",
    "required.password": "Password is required",
  }
  const attributeNames = {
    email: "Email address",
    password: "Password",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateSignUp = params => {
  const rules = {
    first_name: "required|name",
    last_name: "required|name",
    email: "required|email",
    password: "required|min:8",
    phone_number: "required|phone",
    retypePassword: "required|same:password",
  }
  const customMessages = {
    "required.first_name": "First Name is required",
    "required.last_name": "Last Name is required",
    "required.email": "Without :attribute, we can't reach you",
    "email.email": "This is not a valid e-mail format.",
    "required.retypePassword": "Please type again your password.",
    "same.retypePassword":
      "Your passwords do not match. Please, check your typing in both fields and submit again.",
    "min.password": "Your password does not meet requirements.",
  }
  const attributeNames = {
    first_name: "FirstName",
    last_name: "LastName",
    email: "Email address",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateProfileBasic = params => {
  const rules = {
    title: "required",
    first_name: "required|name",
    last_name: "required|name",
    date_of_birth: "required",
    gender: "required",
  }
  const customMessages = {
    "required.title": "Title is required",
    "required.first_name": "First Name is required",
    "required.last_name": "Last Name is required",
    "required.date_of_birth": "Date of birth is required",
    "required.gender": "Gender is required",
  }
  const attributeNames = {
    title: "Title",
    first_name: "FirstName",
    last_name: "LastName",
    date_of_birth: "DateOfBirth",
    gender: "Gender",
  }

  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateProfileContact = params => {
  const rules = {
    email: "required|email",
    phone_number: "required|phone",
  }
  const customMessages = {
    "required.email": "Without :attribute, we can't reach you",
    "required.phone_number": "Phone number is required",
    "email.email": "Please enter a valid :attribute",
  }
  const attributeNames = {
    email: "Email address",
    phone_number: "Phone",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateProfileTravel = (params, suggestions) => {
  if (params.preferred_airport && params.preferred_airport.length > 0) {
    if (!suggestions.find(suggestion => suggestion === params.preferred_airport)) {
      return {
        preferred_airport: `${params.preferred_airport} is not a valid choise`,
      }
    }
  }
}

export const validatePassengerDoB = (dob, type, tripDate) => {
  const currentDob = new Date(dob) - 0

  if (type === "Adult") {
    let minDate = new Date(tripDate)
    minDate.setFullYear(minDate.getFullYear() - ADULT_MIN_AGE)
    minDate = minDate - 0

    if (currentDob > minDate) {
      return {
        date_of_birth: `Adult must have at least ${ADULT_MIN_AGE} years`,
      }
    }
  } else if ((type = "Child")) {
    let minDate = new Date(tripDate)
    minDate.setFullYear(minDate.getFullYear() - CHILD_MIN_AGE)
    minDate = minDate - 0

    let maxDate = new Date(tripDate)
    maxDate.setFullYear(maxDate.getFullYear() - CHILD_MAX_AGE - 1)
    maxDate = maxDate - 0

    if (currentDob > minDate || currentDob < maxDate) {
      return {
        date_of_birth: `Child must have at least ${CHILD_MIN_AGE} and max ${CHILD_MAX_AGE} years`,
      }
    }
  }
}

export const validatePassengerBasic = params => {
  const rules = {
    email: "email",
    title: "required",
    first_name: "required|name",
    last_name: "required|name",
    date_of_birth: "required",
    gender: "required",
  }
  const customMessages = {
    "required.title": "Title is required",
    "required.first_name": "First Name is required",
    "required.last_name": "Last Name is required",
    "required.date_of_birth": "Date of birth is required",
    "required.gender": "Gender is required",
    "email.email": "Please enter a valid :attribute",
  }
  const attributeNames = {
    title: "Title",
    first_name: "FirstName",
    last_name: "LastName",
    date_of_birth: "DateOfBirth",
    gender: "Gender",
    email: "Email address",
  }

  return validateInput(params, rules, customMessages, attributeNames)
}

export const validatePassengerContact = params => {
  const rules = {
    email: "required|email",
    phone_number: "required|phone",
  }
  const customMessages = {
    "required.email": "Without :attribute, we can't reach you",
    "required.phone_number": "Phone number is required",
    "email.email": "Please enter a valid :attribute",
  }
  const attributeNames = {
    email: "Email address",
    phone_number: "Phone",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validatePickupDetails = (params, isExpressHome, isPickupAirport) => {
  let rules = {}

  if (isExpressHome) {
    if (isPickupAirport) {
      rules = { dropOffCity: "required", dropOffFirstAddress: "required", dropOffState: "required" }
    } else {
      rules = {
        pickupCity: ["required", "different:dropOffCity"],
        pickupFirstAddress: "required",
        pickupState: "required",
      }
    }
  } else {
    rules = {
      pickupCity: ["required", "different:dropOffCity"],
      pickupFirstAddress: "required",
      pickupState: "required",
      dropOffCity: "required",
      dropOffFirstAddress: "required",
      dropOffState: "required",
      departureTime: "required_with:departureDate",
      arrivalTime: "required_with:arrivalDate",
    }
  }
  const customMessages = {
    "required.*": ":attribute is required.",
    "different.pickupCity": "The Pick Up and Drop Off City/Airport cannot be the same.",
  }
  const attributeNames = {
    pickupCity: "Pick Up Location City/Airport",
    pickupFirstAddress: "Pick Up Location Address Line 1",
    pickupState: "Pick Up Location State",
    dropOffCity: "Drop Off Location City/Airport",
    dropOffFirstAddress: "Drop Off Location Address Line 1",
    dropOffState: "Drop Off Location State",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateDoor2Door = params => {
  const rules = {
    count: "required",
    "street-name": "required",
    "street-number": "required",
    city: "required",
    leg: "required",
  }
  const customMessages = {}
  const attributeNames = {
    count: "Number of passengers",
    "street-name": "Street name",
    "street-number": "Street number",
    city: "City",
    leg: "Selected leg",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateConnectionGarantie = params => {
  const rules = {
    airline: "required",
    flight_number: "required",
    origin: "required",
    destination: "required",
    departure_time: "required",
    departure_date: "required",
  }
  const customMessages = {}
  const attributeNames = {
    airline: "Airline",
    flight_number: "Flight number",
    origin: "Origin",
    destination: "Destination",
    departure_time: "Departure time",
    departure_date: "Departure date",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}

export const validateBuyPoints = params => {
  const rules = {
    points: "required",
  }
  const customMessages = {
    "required.points": "Number of miles is required",
  }
  const attributeNames = {
    points: "points",
  }
  return validateInput(params, rules, customMessages, attributeNames)
}
