import "./TopMenu.scss"

import classNames from "classnames"
import { Price } from "ibe-components"
// import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import featureFlags from "../../../constants/feature-flags"
import { accountSelector, loyaltySelector } from "../../../redux/selector"
import TopMenuLogo from "../../atoms/TopMenuLogo"
import TopMenuHamburger from "../TopMenuHamburger"
import TopMenuLinks from "../TopMenuLinks"
import TopMenuProfile from "../TopMenuProfile"

const TopMenu = () => {
  const topMenuClass = classNames("top-menu-wrapper")
  const account = useSelector(state => accountSelector(state))
  const { wallet } = useSelector(state => loyaltySelector(state))
  const walletBalance = (wallet || {}).points

  return (
    <div className={topMenuClass}>
      <div className="top-menu-container">
        <div className="logo-links-wrapper">
          <TopMenuHamburger />
          <div className="logo-points-wrapper">
            <TopMenuLogo />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a href="/" className="book-now-button">
              Book Now
            </a>
            <TopMenuLinks />
            <TopMenuProfile />
          </div>
        </div>
      </div>
    </div>
  )
}

TopMenu.propTypes = {}

TopMenu.defaultProps = {}

export default TopMenu
