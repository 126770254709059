import "./CancelTrip.scss"

import { Button, NotificationConsumer, Section } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, withRouter } from "react-router-dom"

import cartAction from "../../../redux/cart/actions"
import redirectActions from "../../../redux/redirect/actions"
import { cartSelector, tripSelector } from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"
import { isEmptyObject } from "../../../utils/common"
import tripUtil, { getVidecomPNR } from "../../../utils/tripUtil-util"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"
import TripSummary from "../../molecules/TripSummary"
import PaymentPrices from "../../organisms/PaymentPrices"

const CancelTrip = ({ match }) => {
  const trip = useSelector(state => tripSelector(state))
  const dispatch = useDispatch()

  const history = useHistory()

  const cancelTrip = useCallback(pnr => dispatch(tripActions.cancelTrip({ pnr })), [dispatch])
  const getCart = useCallback(cartId => dispatch(cartAction.getCart(cartId)), [dispatch])

  const { pnr } = match.params

  let flight = { segments: [], passengers: [] }

  const currentFlights = ((trip || {}).upcoming_trips || {}).results || {}
  if (!trip.isBusy && currentFlights) {
    flight = currentFlights.find(currentFlight => getVidecomPNR(currentFlight.tickets) === pnr)
  }

  useEffect(() => {
    getCart(pnr)
  }, [pnr])
  const departureData = tripUtil.getTripDirection(flight, 0)
  const returnData = tripUtil.getTripDirection(flight, 1)
  const pointsRecord = (flight || {}).points_record || {}
  const isPaidWithMiles = pointsRecord.transaction_type === 2

  const cart = useSelector(state => cartSelector(state))

  const setRedirectURL = useCallback(url => dispatch(redirectActions.setRedirectURL(url)), [
    dispatch,
  ])

  return (
    <NotificationConsumer>
      {() => {
        return (
          <ErrorBoundary>
            <PageLayout
              render={() => {
                if (trip.isBusy || cart.isBusy) {
                  return <Loading />
                }
                return (
                  <div className="cancelTrip-container">
                    <div className="SectionWrapper">
                      <Section
                        title={trip.isCanceled ? "Confirmation" : "Cancel Trip Summary"}
                        type="cancel-trip"
                        canGoBack
                        onGoBackClicked={() => {
                          history.push("/trips")
                        }}
                      />
                      <div className="cancel-trip-wrapper">
                        <TripSummary
                          departureTicketLayoverTimes={departureData.layoverTimes}
                          returnTicketLayoverTimes={returnData.layoverTimes}
                          departureTicket={departureData.segments}
                          returnTicket={
                            returnData.segments.length > 0 ? returnData.segments : undefined
                          }
                          headline
                          title={
                            trip.isCanceled
                              ? "You have canceled your trip successfully!"
                              : undefined
                          }
                        />

                        <PaymentPrices
                          isLoyaltyActive={isPaidWithMiles}
                          items={cart.items_list}
                          summary={cart.summary}
                          summaryLp={cart.summary_lp}
                          paymentNeeded={cart.is_payment_necessary}
                          previousTicket={
                            isEmptyObject(cart.prev_cart) ? undefined : cart.prev_cart
                          }
                          refundData={cart.refund_data || {}}
                          hideVoucher
                          setRedirectURL={setRedirectURL}
                          cancelTrip
                        />

                        <Button
                          fullWidth
                          type={trip.isCanceled ? "regular" : "destructive"}
                          onClick={() => {
                            if (trip.isCanceled) {
                              history.push("/trips")
                            } else {
                              cancelTrip(pnr)
                            }
                          }}
                        >
                          {trip.isCanceled ? "Done" : "Cancel Trip"}
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              }}
            />
          </ErrorBoundary>
        )
      }}
    </NotificationConsumer>
  )
}

CancelTrip.propTypes = {
  match: PropTypes.instanceOf(Object),
}

CancelTrip.defaultProps = {
  match: {},
}

export default withRouter(CancelTrip)
