import moment from "moment"

import {
  BOOKING_FLOW_STARTING_INDEX,
  BOOKING_FLOWS,
  EDIT_BOOKING_FLOWS,
} from "../constants/booking-flows"
import { hiddenCityCodes } from "../constants/city-constants"
import { KIWI } from "../constants/operators"
import {
  DEPART_PICKUP,
  DEPART_SEATS,
  PAYMENT,
  RETURN_PICKUP,
  RETURN_SEATS,
} from "../constants/routes"
import { EXPRESS_AT_HOME_CLASS } from "../constants/ticket"
import {
  BLACK_CAR_TYPE,
  BUS_TYPE,
  EXPRESS_HOME_TYPE,
  EXPRESS_TYPE,
  FLIGHT_TYPE,
} from "../constants/transportation-types"
import passengerActions from "../redux/passenger/actions"
import redirectActions from "../redux/redirect/actions"
import { store } from "../redux/store"
import tripActions from "../redux/trip/actions"

export const isBlackCarAirline = segment => segment.service_type === BLACK_CAR_TYPE

export const isExpressAirline = segment => segment.service_type === EXPRESS_TYPE

export const isBusAirline = segment => segment.service_type === BUS_TYPE

export const isFlightAirline = segment => segment.service_type === FLIGHT_TYPE

export const parseSegment = segment => {
  let { airline } = segment
  if (typeof airline === "object") {
    airline = segment.airline.iata
  }

  let parsedFlightNumber = segment.flight_no || segment.flight_number

  if (parsedFlightNumber.substring(0, 1).match(/^[A-Za-z]+$/)) {
    parsedFlightNumber = parsedFlightNumber.substring(2)
  }

  return {
    ...segment,
    airlineCode: airline,
    parsedFlightNumber,
    airlineName: segment.airline_name || (segment.airline || {}).name,
  }
}

export const isBlackCar = (segments = []) => {
  return segments.find(segment => isBlackCarAirline(segment))
}

export const isExpress = (segments = []) => {
  return segments.find(segment => isExpressAirline(segment))
}

export const isExpressAtHome = segment => {
  return (
    segment.service_type === EXPRESS_HOME_TYPE ||
    (segment.service_type === EXPRESS_TYPE && segment.tickets[0].id === EXPRESS_AT_HOME_CLASS)
  )
}

export const findExpressAtHomeSegment = (segments = []) => {
  return segments.find(segment => isExpressAtHome(segment))
}

export const containsFlight = (segments = []) => {
  return !!segments.find(segment => segment.service_type === FLIGHT_TYPE)
}

export const containsBus = (segments = []) => {
  return !!segments.find(segment => segment.service_type === BUS_TYPE)
}
export const getFlightTicket = tickets => tickets.find(ticket => ticket.operator === KIWI)

export const getVidecomTicket = tickets => {
  let videcomTicket
  if (tickets.length > 0) {
    videcomTicket = tickets.find(ticket => {
      return ticket.operator === "VIDECOM"
    })
  }
  return videcomTicket
}
export const getVidecomPNR = tickets => {
  const videcomTicket = getVidecomTicket(tickets)
  return videcomTicket?.booking_reference
}
export const getVidecomState = tickets => {
  const videcomTicket = getVidecomTicket(tickets)
  return videcomTicket?.state
}

const getTripDirection = ({ segments: oldLegs, passengers, tickets }, direction) => {
  let segments = []
  let layoverTimes = []
  if (oldLegs[direction]) {
    layoverTimes = oldLegs[direction].layover_times
    segments = oldLegs[direction].legs.map((segment, index) => {
      return {
        ...segment,
        all_checked_in: oldLegs[direction].all_checked_in,
        arrival: segment.arrive.AirportName,
        arrival_code: segment.arrive.CityCode,
        arrival_date: moment(segment.arrival_time).format("YYYY-MM-DD"),
        arrival_time: moment(segment.arrival_time).format("HH:mm"),
        checkin_status: oldLegs[direction].checkin_status,
        departure: segment.depart.AirportName,
        departure_code: segment.depart.CityCode,
        departure_date: moment(segment.departure_time).format("YYYY-MM-DD"),
        departure_time: moment(segment.departure_time).format("HH:mm"),
        flight_no: segment.flight_number,
        segment: index + 1,
        way: oldLegs[direction].way,
      }
    })
  }
  return {
    segments,
    passengers,
    layoverTimes,
    tickets,
  }
}

export const calculateSeatsLeft = ({ tickets }) => {
  const allMins = []

  for (let i = 0; i < tickets.length; i++) {
    if (tickets[i].flight_availability) {
      allMins.push(Math.min(tickets[i].flight_availability, tickets[i].landline_availability))
    } else {
      allMins.push(tickets[i].landline_availability)
    }
  }

  if (!allMins.length) {
    return 0
  }

  return Math.min(...allMins)
}

export const getSectionType = () => {
  const {
    // loyalty: { isActive: isLoyaltyActive },
    trip: { editTrip },
  } = store.getState()

  // if (isLoyaltyActive) {
  //   return "loyalty"
  // }
  if (editTrip !== "") {
    return "edit-trip"
  }
  return undefined
}

export const goToNextSegment = history => {
  const {
    trip: { bookingFlow, bookingFlowIndex },
    loyalty: { isActive: isLoyaltyActive },
    account: { isAuthenticated },
  } = store.getState()

  const newBookingFlowIndex = bookingFlowIndex + 1

  const currentPageSeats =
    bookingFlow[bookingFlowIndex] === DEPART_SEATS || bookingFlow[bookingFlowIndex] === RETURN_SEATS

  const nextPagePickup =
    bookingFlow[newBookingFlowIndex] === DEPART_PICKUP ||
    bookingFlow[newBookingFlowIndex] === RETURN_PICKUP

  const nextPagePayment = bookingFlow[newBookingFlowIndex] === PAYMENT

  const setRedirectURL = url => store.dispatch(redirectActions.setRedirectURL(url))
  const updatePassengers = callback => store.dispatch(passengerActions.updatePassengers(callback))

  const goNext = () => {
    if (isLoyaltyActive && !isAuthenticated && nextPagePayment) {
      // set redirect url to payment and go to login if use miles active and not loggedin user
      setRedirectURL(PAYMENT)
      history.push("/login")
      store.dispatch(tripActions.setBookingFlowIndex(newBookingFlowIndex))
    } else {
      history.push(bookingFlow[newBookingFlowIndex])
      store.dispatch(tripActions.setBookingFlowIndex(newBookingFlowIndex))
    }
  }

  if (
    (currentPageSeats || bookingFlowIndex === BOOKING_FLOW_STARTING_INDEX) &&
    (nextPagePickup || nextPagePayment)
  ) {
    updatePassengers(goNext)
  } else if (!currentPageSeats && nextPagePayment) {
    goNext()
  } else {
    history.push(bookingFlow[newBookingFlowIndex])
    store.dispatch(tripActions.setBookingFlowIndex(newBookingFlowIndex))
  }
}

export const goToBackSegment = history => {
  const {
    trip: { bookingFlowIndex },
  } = store.getState()

  const newBookingFlowIndex = bookingFlowIndex - 1

  history.goBack()
  store.dispatch(tripActions.setBookingFlowIndex(newBookingFlowIndex))
}

export const isTicketCancelled = flightState => {
  if (flightState === 2) {
    return true
  }
  return false
}

export const getCityName = city => {
  let cityName = city.name
  const cityCode = city.code

  if (city.city_from || city.city_to) {
    if (!hiddenCityCodes.includes(cityCode)) {
      cityName += ` (All airports, ${cityCode})`
    } else {
      cityName += " (All airports)"
    }
  } else if (cityCode) {
    return `${cityName} - ${cityCode}`
  }

  return cityName
}

export const getTransportationType = ticket => {
  const {
    settings: { LANDLINE_IATA_CODE },
  } = store.getState()

  if (!ticket) {
    return ""
  }

  const landlineSegment = ticket.find(segment => segment.airline === LANDLINE_IATA_CODE)

  if (
    landlineSegment.service_type === EXPRESS_TYPE &&
    landlineSegment.tickets[0].id === EXPRESS_AT_HOME_CLASS
  ) {
    return `${landlineSegment.service_type}${landlineSegment.tickets[0].id}`
  }

  return landlineSegment.service_type
}

export const getLandlineSegmentIndex = ticket => {
  const {
    settings: { LANDLINE_IATA_CODE },
  } = store.getState()

  return ticket ? ticket.findIndex(segment => segment.airline === LANDLINE_IATA_CODE) : -1
}

export const getBookingFlowArray = (departureTicket, returnTicket) => {
  const {
    trip: { editTrip },
  } = store.getState()
  const flowKey = `${getTransportationType(departureTicket)}${getTransportationType(returnTicket)}`

  return editTrip ? EDIT_BOOKING_FLOWS[flowKey] : BOOKING_FLOWS[flowKey]
}

export const getRouteIndex = value => {
  const {
    trip: { bookingFlow },
  } = store.getState()

  return bookingFlow.findIndex(route => route === value)
}

export const getBreadcrumbsFlowArray = bookingFlow => {
  const bookingFlowCopy = JSON.parse(JSON.stringify(bookingFlow))
  const getRouteName = path => {
    switch (path) {
      case DEPART_SEATS:
        return "Departure seats"
      case RETURN_SEATS:
        return "Return seats"
      case DEPART_PICKUP:
        return "Departure details"
      case RETURN_PICKUP:
        return "Return details"
      case PAYMENT:
        return "Payment"
      default:
        return ""
    }
  }

  return bookingFlowCopy.map(item => ({
    path: item,
    name: getRouteName(item),
  }))
}

export default {
  getTripDirection,
  calculateSeatsLeft,
  isBlackCarAirline,
  getSectionType,
  goToNextSegment,
  goToBackSegment,
  isBlackCar,
  parseSegment,
  containsFlight,
  containsBus,
  getFlightTicket,
  getCityName,
  isExpress,
  isExpressAirline,
  findExpressAtHomeSegment,
  getRouteIndex,
  isBusAirline,
  isFlightAirline,
}
