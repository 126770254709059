import { createSelector } from "reselect"

const getReservations = state => state.reservations
const getItinerary = state => state.itinerary
const getTicket = state => state.ticket
const getError = state => state.error
const getTrip = state => state.trip
const getAccount = state => state.account
const getPassenger = state => state.passenger
const getInstructions = state => state.instruction
const getRedirect = state => state.redirect
const getNewsletter = state => state.newsletter
const getBanner = state => state.banner
const getLoyalty = state => state.loyalty
const getCart = state => state.cart
const getSettings = state => state.settings
// NOTE(sb): plop reduxGen create redux selector

export const reservationsSelector = createSelector(getReservations, reservations => reservations)
export const itinerarySelector = createSelector(getItinerary, itinerary => itinerary)
export const ticketSelector = createSelector(getTicket, ticket => ticket)
export const errorSelector = createSelector(getError, error => error)
export const tripSelector = createSelector(getTrip, trip => trip)
export const accountSelector = createSelector(getAccount, account => account)
export const passengerSelector = createSelector(getPassenger, passenger => passenger)
export const instructionSelector = createSelector(getInstructions, instruction => instruction)
export const redirectSelector = createSelector(getRedirect, redirect => redirect)
export const bannerSelector = createSelector(getBanner, banner => banner)
export const newsletterSelector = createSelector(getNewsletter, newsletter => newsletter)
export const loyaltySelector = createSelector(getLoyalty, loyalty => loyalty)
export const cartSelector = createSelector(getCart, cart => cart)
export const settingsSelector = createSelector(getSettings, settings => settings)
// NOTE(sb): plop reduxGen export redux selector
