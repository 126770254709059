import "./EditTripModalLayout.scss"

import classNames from "classnames"
import { Icon, TabModalGroup } from "ibe-components"
import PropTypes from "prop-types"
import React, { useState } from "react"

const EditTripModalLayout = ({
  onGoBack,
  contents,
  departOrReturnContainsFlight,
  action,
  staticContent,
  showOnGoBack,
}) => {
  const editTripModalLayoutClass = classNames("edit-trip-modal-layout-container")
  const [activeIndex, setActiveIndex] = useState(!departOrReturnContainsFlight ? 1 : 0)
  return (
    <div className={editTripModalLayoutClass}>
      {showOnGoBack && (
        <div className="go-back-button-container" onClick={onGoBack}>
          <Icon name="goBackArrow" width="16" height="16" withoutProps noAbsolute />
          <span className="go-back-text">Back</span>
        </div>
      )}
      <div className="gray-circle-editing-icon">
        {action === "edit" ? (
          <Icon
            name="edit-trip-icon-gray-background"
            noAbsolute
            withoutProps
            width="56"
            height="56"
          />
        ) : (
          <Icon name="cancel-trip-red-circle" noAbsolute withoutProps width="56" height="56" />
        )}
      </div>
      <div className="title">{action === "edit" ? "Edit trip" : "Cancel trip"}</div>
      {action !== "cancelConfirmation" ? (
        <>
          <div className="buttons-container">
            {departOrReturnContainsFlight && (
              <TabModalGroup
                firstItemTitle="Flight + ground"
                secondItemTitle="Ground only"
                firstItemIcon={<Icon name="newAirplane" noAbsolute margin="0px 0px 0px 8px" />}
                secondItemIcon={
                  <Icon
                    name="blackCarNoPlaceholder"
                    withoutProps
                    noAbsolute
                    margin="0px 0px 0px 8px"
                  />
                }
                firstActive={activeIndex === 0}
                secondActive={activeIndex === 1}
                onFirstItemClick={() => {
                  setActiveIndex(0)
                }}
                onSecondItemClick={() => {
                  setActiveIndex(1)
                }}
              />
            )}
          </div>

          <div className="content-container">{contents[activeIndex]}</div>
        </>
      ) : (
        <>{staticContent}</>
      )}
    </div>
  )
}

EditTripModalLayout.propTypes = {
  onGoBack: PropTypes.func,
  contents: PropTypes.instanceOf(Array),
  departOrReturnContainsFlight: PropTypes.bool,
  action: PropTypes.oneOf(["cancel", "edit"]).isRequired,
  staticContent: PropTypes.element,
  showOnGoBack: PropTypes.bool,
}

EditTripModalLayout.defaultProps = {
  onGoBack: () => {},
  departOrReturnContainsFlight: false,
  contents: [],
  staticContent: null,
  showOnGoBack: true,
}

export default EditTripModalLayout
