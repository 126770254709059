const Pickup_Locations = [
  {
    image: "https://content.landline.com/wp-content/uploads/2024/04/CSU-Map.jpg",
    title: "CSU Transit Center (CSU)",
    prices: "Adults: $32 | Kids: FREE (12 & under)",
    link: "https://maps.google.com/maps?ll=40.576033,-105.085223&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=12154003753580978015",
    top: "Pick up/drop off is on the north side of Plum Ave just east of the service drive for Allison Hall.",
    bottom: "There is no public parking available on the CSU campus, however, it is steps away from public transportation and is easily accessible via S Meridian St. or W Plumb St. for family/friend drop off/pick up."
  },
  {
    image: "https://content.landline.com/wp-content/uploads/2024/10/HTC2.jpeg",
    title: "Harmony Transfer Center (HTC)",
    prices: "Adults: $32 | Kids: FREE (12 & under)",
    link: "https://maps.google.com/maps?ll=40.524117,-104.996167&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=14298134024812418491",
    top: "Pick up/drop off is in front of the east covered shelter in the bus loop. This pick up location is shared by other transportation companies. Please make sure you are boarding the Landline branded vehicle.",
    bottom: "This parking lot is short term (24hr) only and monitored by Fort Collins parking enforcement. There is a covered outdoor shelter and seating available at the pick up location for your convenience. "
  },
  {
    image: "https://content.landline.com/wp-content/uploads/2024/05/FNL_stop.jpg",
    title: "Northern Colorado Regional Airport (FNL) - FREE Parking",
    prices: "Adults: $35 | Kids: FREE (12 & under)",
    link: "https://maps.google.com/maps?ll=40.449842,-105.005387&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=2460148492228003283",
    top: "4825 Earhart Rd. Loveland, CO 80538.\n Check in is located inside the main entrance of the small terminal with the blue roof at the Landline/United desk. Parking at FNL is FREE for up to three weeks for all Landline passengers.",
    bottom: "For trips longer than three weeks, please contact the FNL Airport admin at 970-962-2862 to purchase an extended parking pass."
  },
]

export default Pickup_Locations