const Pickup_Locations = [
  {
    image: "https://content.landline.com/wp-content/uploads/2024/05/DLH_Map.jpg",
    title: "Duluth International Airport (DLH)",
    prices: "Adults: $39 and up  |   Kids: FREE (12 & under)",
    link: "https://www.google.com/maps/place/Duluth+International+Airport/@46.8383703,-92.1826191,17z/data=!3m1!4b1!4m6!3m5!1s0x52afb37fd2be58df:0xaeb5dd880c512cff!8m2!3d46.8383667!4d-92.1800442!16zL20vMDVzcTFi?entry=ttu",
    top: "Landline picks up in front of the main terminal lobby of the Duluth International Airport. ",
    bottom: "Please check in at the Landline/Sun Country counter inside the terminal. There is a waiting area inside the terminal for your convenience. A Landline agent will announce when it is time for boarding. "
  }
]

export default Pickup_Locations