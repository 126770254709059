import accountActions from "../account/actions"
import actions from "./actions"
import initialState from "./initialState"

export default (state = initialState(), action) => {
  const { type, payload } = action
  switch (type) {
    case actions.ACTIVATE_LOYALTY:
    case actions.BUY_LOYALTY_POINTS:
      return {
        ...state,
        isBusy: true,
      }

    case actions.ACTIVATE_LOYALTY_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.ACTIVATE_LOYALTY_SUCCESS:
      return {
        ...state,
        isBusy: false,
        isActive: payload,
      }
    case actions.CHECK_LOYALTY_POINTS_PRICE_SUCCESS:
      return {
        ...state,
        isBusy: false,
        priceForPoints: payload.priceForPoints,
        pointsBeforePayment: payload.pointsBeforePayment,
      }

    case actions.CHECK_LOYALTY_POINTS_PRICE_FAIL:
      return {
        ...state,
        isBusy: false,
      }

    case actions.BUY_LOYALTY_POINTS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        payload,
      }

    case actions.BUY_LOYALTY_POINTS_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case accountActions.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        wallet: payload.wallet,
      }
    case actions.GET_MILEMARKER_ACCRUAL_LOG:
      return {
        ...state,
        isBusy: true,
      }
    case actions.GET_MILEMARKER_ACCRUAL_LOG_SUCCESS:
      return {
        ...state,
        isBusy: false,
        accrualLog: payload.response.results,
      }

    case actions.GET_MILEMARKER_ACCRUAL_LOG_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.UPDATE_LOYALTY_FIELDS_SUCCESS:
      return {
        ...state,
        [payload.field]: payload.value,
      }
    default:
      return state
  }
}
