import { httpPost } from "./request"

// eslint-disable-next-line import/prefer-default-export
export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken")
  const response = await httpPost("refresh_sessions/", { refresh: refreshToken })

  if (response.access && response.refresh) {
    localStorage.setItem("accessToken", response.access)
    localStorage.setItem("refreshToken", response.refresh)
  }
}
