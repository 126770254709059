import actions from "./actions"
import initialState from "./initialState"

export default (state = initialState(), action) => {
  const { type, payload } = action

  switch (type) {
    case actions.SET_FILTER_PROPERTY:
      return {
        ...state,
        isBusy: true,
      }
    case actions.SET_RESERVATION_PROPERTY:
      return {
        ...state,
        params: {
          ...state.params,
          [payload.field]: payload.value,
        },
      }
    case actions.UPDATE_RESERVATION:
      return {
        ...state,
        isBusy: true,
        items: { depart_flights: [] },
        departure_segments: [],
        return_segments: [],
      }
    case actions.UPDATE_RESERVATION_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.UPDATE_RESERVATION_SUCCESS:
      return {
        ...state,
        isBusy: false,
        items: { ...payload },
        filters: {
          ...state.filters,
          airline_filter: payload.airline_filter,
          connecting_airports: payload.connecting_airports,
        },
      }
    case actions.FETCH_RESERVATION_ALL_CITIES:
      return {
        ...state,
        isBusy: true,
        items: {},
        departure_segments: [],
        return_segments: [],
        froms: [],
        tos: [],
      }
    case actions.FETCH_RESERVATION_ALL_CITIES_SUCCESS:
      return {
        ...state,
        isBusy: false,
        allCities: payload,
        froms: payload,
        tos: payload,
      }

    case actions.FETCH_RESERVATION_ALL_CITIES_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.FETCH_RESERVATION_STRING_SUCCESS:
      return {
        ...state,
        [payload.way]: payload.cities,
      }
    case actions.FETCH_RESERVATION_CITY_PAIR:
      return {
        ...state,
        // isBusy: true,
      }
    case actions.FETCH_RESERVATION_CITY_PAIR_SUCCESS:
      if (payload.type === "dep_to") {
        return {
          ...state,
          // isBusy: false,
          froms: payload.cities,
        }
      }
      return {
        ...state,
        // isBusy: false,
        tos: payload.cities,
      }
    case actions.FETCH_BLACK_CAR_CITIES:
    case actions.FETCH_DEPARTURE_SEGMENTS:
    case actions.FETCH_RETURN_SEGMENTS:
      return {
        ...state,
        isBusy: true,
      }
    case actions.FETCH_DEPARTURE_SEGMENTS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        departure_segments: payload,
      }
    case actions.FETCH_RETURN_SEGMENTS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        return_segments: payload,
      }
    case actions.SET_FILTER_PROPERTY_SUCCESS:
      return {
        ...state,
        isBusy: false,
        filters: { ...state.filters, ...payload },
      }
    case actions.FETCH_BLACK_CAR_CITIES_FAIL:
    case actions.SET_FILTER_PROPERTY_FAIL:
      return {
        ...state,
        isBusy: false,
      }
    case actions.CLEAR_FILTERS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        filters: initialState().filters,
      }
    case actions.FETCH_BLACK_CAR_CITIES_SUCCESS:
      return {
        ...state,
        isBusy: false,
        blackCarCities: {
          pickupCities: payload[0],
          dropOffCities: payload[1],
        },
      }
    case actions.SET_SHOULD_CHECK_HEARTBEAT_SUCCESS:
      return {
        ...state,
        shouldCheckHeartbeat: payload,
      }

    case actions.CLEAR_SEARCH_SUCCESS:
      return initialState()
    default:
      return state
  }
}
