const initialState = () => ({
  isBusy: false,
  isAuthenticated: false,
  error: "",
  profile: {},
  referral: {
    referral_id: "",
    list_sign_ups: [],
    total_signup: 0,
    created: "",
    modified: "",
    disabled: false,
  },
  user: null,
  passwordChanged: false,
  password: {
    changed: false,
    recovery_code_active: false,
    recovery_key: null,
  },
})

export default initialState
