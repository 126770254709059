import "./Tab.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { Link, withRouter } from "react-router-dom"

const Tab = ({ items, history }) => {
  return (
    <div className="tabs-container">
      {items.map((item, index) => {
        const activeTab = item.matches.find(link => link === history.location.pathname)
        const referralTab = item.matches.find(link => link === "/referral")
        const tabWrapper = classNames("tab-wrapper", {
          first: index === 0,
          last: index === items.length - 1,
        })
        const linkClass = classNames("link", {
          active: !!activeTab,
        })
        return (
          <>
            <div className={tabWrapper}>
              <Link to={item.to}>
                <div
                  className={linkClass}
                  key={item.to}
                  activeTab={activeTab}
                  referralTab={referralTab}
                >
                  {item.title}
                  <div className="hoverBox" />
                </div>
              </Link>
            </div>
            {index !== items.length - 1 && (
              <div className="separator-wrapper">
                <div className="separator-line" />
              </div>
            )}
          </>
        )
      })}
    </div>
  )
}

Tab.propTypes = {
  items: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
}

export default withRouter(Tab)
