import React from "react";
import PropTypes from "prop-types"
import ModuleTitle from "../../atoms/SchedulePages/ModuleTitle"


const PickupLocations = props => {
    return (
        <>
        <ModuleTitle title="Pick-Up Locations" />
        {props.children}
        </>
    )
}

const PickupLocation = ({location}) => {
    return (
        <div className="schedule-pickup-location">
            <img className="schedule-pickup-location-image" src={location.image} alt="" />
            <div className="schedule-pickup-location-panel">
                <h5>{location.title}</h5>
                <a href={location.link} target="_blank" rel="noopener noreferrer">View map and get directions</a>
                <p>{location.prices ? <b>{location.prices}</b> : null}</p>
                <p>{location.top}</p>
                <p>{location.bottom}</p>
            </div>
        </div>
    )
}

const ScheduleLocation = ({ data, onButtonClick }) => {
    return (
        <div className="schedule-location">
            <img src={data.image} alt="" />
            <div className="schedule-location-panel">
                {data.title ? <h4>{data.title}</h4> : null}
                <p>{data.prices ? <b>{data.prices}</b> : null}</p>
                <p>{data.content}</p>
                {data.button ? <button onClick={() => {onButtonClick()}}>{data.button}</button> : null }
            </div>
        </div>
    )
}

PickupLocation.propTypes = {
    location: PropTypes.object
}

PickupLocation.defaultProps = {
    location: {
        title: "Pick-up Location",
        image: "",
        link: "javascript:void(0);",
        top: "This is a card containing the default information for a pick-up location.",
        bottom: ""
    }
}

ScheduleLocation.propTypes = {
    data: PropTypes.object,
    onButtonClick: PropTypes.func
}

ScheduleLocation.defaultProps = {
    data: {
        image: "",
        title: "",
        content: "",
        button: ""
    },
    onButtonClick: () => {}
}

export {
    PickupLocation,
    PickupLocations,
    ScheduleLocation
}