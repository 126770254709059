import { Button, Icon, InputFieldSet } from "ibe-components"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from "react-stripe-elements"
import styled from "styled-components"
import "./CreditCardForm.scss"

import { cartSelector } from "../../../redux/selector"
import BookingConfirmationInfo from "../../atoms/BookingConfirmationInfo"

const baseStyle = {
  base: {
    fontSize: "18px",
  },
  invalid: {
    color: "#9e2146",
  },
}

const CreditCardForm = ({
  stripe,
  holderName,
  onHolderNameChanged,
  onSubmit,
  onError,
  hideBookingInformation,
  paymentNeeded,
  isLoyaltyActive,
  pricePoints,
  showPayButton,
  setShowPayButton,
}) => {
  const cart = useSelector(state => cartSelector(state))
  useState(() => {
    setShowPayButton(cart.showPayButton)
  }, [cart.showPayButton])

  const buttonData = {
    type: "primary",
    text: "Complete Payment",
    icon: null,
  }
  if (isLoyaltyActive) {
    if (pricePoints <= 0) {
      buttonData.text = "Confirm"
      // buttonData.icon = undefined
    } else {
      buttonData.type = "secondary"
      // buttonData.icon = "points"
    }
  } else if (!paymentNeeded) {
    buttonData.text = "Confirm"
    // buttonData.icon = undefined
  }
  const onFormSubmit = e => {
    if (showPayButton) {
      onSubmit(e, stripe)
    }
  }
  return (
    <CreditCardFormWrapper className="credit-card-form__wrapper">
      <form onSubmit={onFormSubmit}>
        {paymentNeeded && (
          <>
            <Headline className="credit-card-form__header">Credit Card Information</Headline>

            <div className="credit-card-form__fieldset-wrapper">
              <InputFieldSet
                fullWidth
                labelText="CARD HOLDER NAME"
                inputValue={holderName}
                onChange={e => onHolderNameChanged(e.target.value)}
              />
              <InputContainer noMargin style={{ background: "#fff" }}>
                <Label>CARD NUMBER</Label>
                <CardNumberElement
                  style={baseStyle}
                  onChange={e => {
                    onError(e)
                  }}
                />
              </InputContainer>

              <InputContainer style={{ background: "#fff" }}>
                <Label>CVC</Label>
                <CardCVCElement
                  style={baseStyle}
                  onChange={e => {
                    onError(e)
                  }}
                />
              </InputContainer>

              <InputContainer style={{ background: "#fff" }}>
                <Label>EXPIRATION MONTH/YEAR</Label>
                <CardExpiryElement
                  style={baseStyle}
                  onChange={e => {
                    onError(e)
                  }}
                />
              </InputContainer>
            </div>
          </>
        )}
        {!hideBookingInformation && <BookingConfirmationInfo />}
        <div className="payment-buttons">
          <Button
            disabled={!showPayButton}
            type={buttonData.type}
            icon={buttonData.icon && <Icon name={buttonData.icon} />}
            fullWidth
            buttonType="submit"
          >
            {buttonData.text}
          </Button>
        </div>
      </form>
    </CreditCardFormWrapper>
  )
}

export default injectStripe(CreditCardForm)

CreditCardForm.propTypes = {
  hideBookingInformation: PropTypes.bool,
  stripe: PropTypes.instanceOf(Object).isRequired,
  holderName: PropTypes.string.isRequired,
  onHolderNameChanged: PropTypes.func,
  onSubmit: PropTypes.instanceOf(Object).isRequired,
  onError: PropTypes.instanceOf(Object),
  paymentNeeded: PropTypes.bool,
  isLoyaltyActive: PropTypes.bool,
  pricePoints: PropTypes.number,
  showPayButton: PropTypes.bool,
  setShowPayButton: PropTypes.func,
}
CreditCardForm.defaultProps = {
  hideBookingInformation: false,
  onError: {},
  onHolderNameChanged: () => {},
  paymentNeeded: true,
  isLoyaltyActive: false,
  pricePoints: 0,
  showPayButton: true,
  setShowPayButton: () => {},
}

const CreditCardFormWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  form {
    width: 100%;
  }
`

const InputContainer = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid #cdccc8;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  padding: 10px 15px;
  margin-top: ${props => (props.noMargin ? "0" : "20px")};
`

const Label = styled.label`
  position: absolute;
  top: -8px;
  left: 20px;

  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding: 0 10px;
  background-color: white;

  color: #3c3835;
`

const Headline = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #3c3835;
  border-bottom: 1px solid #3c3835;
`
