import "./TripSummary.scss"

import classNames from "classnames"
import { ItinerarySingleRow, LegConnection } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { isBlackCar } from "../../../utils/tripUtil-util"
import moment from "moment/moment"

import { useSelector } from "react-redux"
import { reservationsSelector } from "../../../redux/selector"

const TripSummary = ({
  departureTicket,
  returnTicket,
  oneWay,
  headline,
  title,
  departureTicketLayoverTimes,
  returnTicketLayoverTimes,
  pnr,
  departureTicketTotalTripTime,
  returnTicketTotalTripTime,
  showPnr,
}) => {
  const legConnectionPaymentWrapperClass = classNames("leg-payment-wrapper", {
    "leg-wrapper-black-car": isBlackCar(departureTicket),
  })
  const legConnectionHeadlineClass = classNames("leg-headline", {
    "leg-headline-black-car": isBlackCar(departureTicket),
  })
  const legConnectionPaymentReturnWrapperClass = classNames("leg-payment-wrapper", {
    "leg-wrapper-return-black-car": isBlackCar(returnTicket || []),
  })

  const reservations = useSelector(state => reservationsSelector(state))

  if (!departureTicket[0]) {
    return null
  }
  
  const departureDate = moment(departureTicket[0].departure_date).format("MM/DD/yyyy")
  const returnDate =
    !oneWay && returnTicket && moment(returnTicket[0].departure_date).format("MM/DD/yyyy")

  return (
    <TripSummaryContainer className="trip-summary-container">
      {headline && (
        <HeadlineContainer>
          <Headline>{title}</Headline>
          <FlightNumber>{pnr}</FlightNumber>
        </HeadlineContainer>
      )}
      <LegConnectionContainer className="leg-departure">
        <div className={legConnectionHeadlineClass}>
          <span>Departure: {departureDate}</span>
          {showPnr && pnr ? (
            <span
              className="trip-pnr"
              style={{
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              PNR: {pnr}
            </span>
          ) : null}
        </div>
        <div className={legConnectionPaymentWrapperClass}>
          <ItinerarySingleRow
            segments={departureTicket}
            tickets={[]}
            summaryPrice={[]}
            layoverTimes={departureTicketLayoverTimes}
            seatsLeft={4}
            itineraryIndex={0}
            classbands={[]}
            departureCode={null}
            arrivalCode={null}
            selectedItineraryIndex={0}
            summaryPreview={true}
            passengersAdults={reservations.params.passengers_adults}
            passengersChildrens={reservations.params.passengers_children}
            passengersInfants={reservations.params.passengers_infants}
            totalTripTime={departureTicketTotalTripTime}
          />
        </div>
      </LegConnectionContainer>
      {!oneWay && returnTicket && (
        <LegConnectionContainer className="leg-return">
          <div className={legConnectionHeadlineClass}>Return: {returnDate}</div>
          <div className={legConnectionPaymentReturnWrapperClass}>
            <ItinerarySingleRow
              segments={returnTicket}
              tickets={[]}
              summaryPrice={[]}
              layoverTimes={returnTicketLayoverTimes}
              seatsLeft={4}
              itineraryIndex={0}
              classbands={[]}
              departureCode={null}
              arrivalCode={null}
              selectedItineraryIndex={0}
              summaryPreview={true}
              passengersAdults={reservations.params.passengers_adults}
              passengersChildrens={reservations.params.passengers_children}
              passengersInfants={reservations.params.passengers_infants}
              totalTripTime={returnTicketTotalTripTime}
            />
          </div>
        </LegConnectionContainer>
      )}
    </TripSummaryContainer>
  )
}
export default TripSummary

TripSummary.propTypes = {
  departureTicket: PropTypes.instanceOf(Array).isRequired,
  returnTicket: PropTypes.instanceOf(Array),
  oneWay: PropTypes.bool,
  headline: PropTypes.bool,
  title: PropTypes.string,
  departureTicketLayoverTimes: PropTypes.instanceOf(Array),
  returnTicketLayoverTimes: PropTypes.instanceOf(Array),
  pnr: PropTypes.string,
  departureTicketTotalTripTime: PropTypes.string,
  returnTicketTotalTripTime: PropTypes.string,
}

TripSummary.defaultProps = {
  returnTicket: undefined,
  oneWay: false,
  headline: false,
  title: "Trip Summary",
  departureTicketLayoverTimes: [],
  returnTicketLayoverTimes: [],
  pnr: "",
  departureTicketTotalTripTime: undefined,
  returnTicketTotalTripTime: undefined,
}

const Headline = styled.div`
  padding: 20px 0px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #3c3835;
`

const HeadlineContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 70% auto;
  border-bottom: 1px solid #3c3835;
`

const FlightNumber = styled.div`
  width: 100%;
  text-align: right;
  padding: 20px 0;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 100%;
  color: #00c08b;
`

const TripSummaryContainer = styled.div``

const LegConnectionContainer = styled.div``
