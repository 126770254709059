import "./BookEarlyAndSave.scss"
import React, { useState, useEffect } from "react"
import { httpGet } from "../../utils/request"

export function BookEarlyAndSave() {
  const [prices, setPrices] = useState()

  useEffect(() => {
    httpGet("v2/web/prices/")
      .then(({ results }) => {
        setPrices(results)
      })
      .catch(e => {
        console.log("Failed to get list of prices", e)
      })
  }, [setPrices])

  // Prices loaded, but no actual prices
  if (!prices?.length) {
    return null
  }

  return (
    <div className="BookEarlyAndSave">
      <h2>Book early to lock in the best rates:</h2>
      <div className="price-grid">
        {prices?.map(d => {
          return (
            <div key={d.id}>
              <strong>${d.price}</strong> from {d.location}
            </div>
          )
        })}
      </div>
    </div>
  )
}
