import { NotificationConsumer } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, Redirect } from "react-router-dom"
import styled from "styled-components"

import accountActions from "../../../redux/account/actions"
import { accountSelector, errorSelector } from "../../../redux/selector"
import Loading from "../../atoms/Loading"
import PageLayout from "../../layouts/PageLayout"

const Activate = ({ match }) => {
  const dispatch = useDispatch()
  const activateUser = useCallback(
    (code, referralCode) => dispatch(accountActions.activateUser({ code, referralCode })),
    [dispatch],
  )
  const account = useSelector(state => accountSelector(state))
  const error = useSelector(state => errorSelector(state))

  const { code, referralCode } = match.params
  useEffect(() => {
    if (code) {
      activateUser(code, referralCode ? `${referralCode}/` : "")
    }
  }, [])

  if (!code) {
    return <Redirect to="/" />
  }
  return (
    <NotificationConsumer>
      {() => {
        return (
          <PageLayout
            render={() => {
              if (account.isBusy) {
                return <Loading />
              }
              if (account.emailActivated) {
                return (
                  <div className="SectionWrapper" style={{ padding: 20 }}>
                    <div>Your email has been confirmed</div>
                    <Link to="/profile">Continue to your Profile</Link>
                  </div>
                )
              }
              return (
                <div className="SectionWrapper" style={{ padding: 20 }}>
                  <div>Your email could not be confirmed</div>
                  {error && <Error>{error}</Error>}
                </div>
              )
            }}
          />
        )
      }}
    </NotificationConsumer>
  )
}

export default Activate

Activate.propTypes = {
  match: PropTypes.instanceOf(Object),
}

Activate.defaultProps = {
  match: {},
}

const Error = styled.div`
  color: #f2f2f2;
  font-weight: bold;
  background-color: #f00;
  padding: 10px;
`
