import reservationActions from "../redux/reservations/actions"
import { store } from "../redux/store"

export const checkHeartbeatInterval = history => {
  const interval = setInterval(async () => {
    const { reservations } = store.getState()
    if (!reservations.shouldCheckHeartbeat) {
      clearInterval(interval)
    } else {
      await store.dispatch(reservationActions.checkHeartbeat({ history }))
    }
  }, 30000)
}

export default { checkHeartbeatInterval }
