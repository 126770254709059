import { all, put, takeLatest } from "redux-saga/effects"

import { httpGet } from "../../utils/request"
import errorActions from "../error/actions"
import actions from "./actions"

export function* getSettingsData() {
  try {
    yield put(errorActions.clearError())
    const response = yield httpGet("settings/")
    yield put(actions.getSettingsDataSuccess(response))
  } catch (error) {
    yield put(actions.getSettingsDataFail())
    yield put(errorActions.setError(error))
  }
}

export function* getAllCarouselImages() {
  try {
    yield put(errorActions.clearError())
    const response = yield httpGet("v2/web/carousel_images/")
    yield put(actions.getAllCarouselImagesSuccess(response))
  } catch (error) {
    yield put(actions.getAllCarouselImagesFail())
    yield put(errorActions.setError(error))
  }
}

export default function*() {
  yield all([
    takeLatest(actions.getSettingsData, getSettingsData),
    takeLatest(actions.getAllCarouselImages, getAllCarouselImages),
  ])
}
