/* eslint-disable react/jsx-props-no-spreading */
import "./CommentsCarousel.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import PropTypes from "prop-types"
import React from "react"
import Slider from "react-slick"

import CarouselRatings from "../CarouselRatings"

const CommentsCarousel = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: true,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  return (
    <div className="peopleAreTalkingSection">
      <div className="peopleAreTalkingContainer">
        <div className="peopleAreTalkingTextContainer">
          <h3>People are talking</h3>
          <div className="carouselWrapper">
            <Slider {...settings} className="comments-carousel">
              <CarouselRatings
                peopleName="Satisfied Customer"
                text="“Professionalism.
               ...no other words needed.“"
              />
              <CarouselRatings
                peopleName="Satisfied Customer"
                text="“Service! Ease! Price! Convenience!“"
              />
              <CarouselRatings
                peopleName="Satisfied Customer"
                text="“This is my fourth trip with Landline. I give them five stars.
                    Clean, great staff, fast!“"
              />
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}

function PrevButton({ onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="slick-prev"
      style={{ left: "-111px", paddingBottom: "70px" }}
    >
      Prev
    </button>
  )
}

PrevButton.propTypes = {
  onClick: PropTypes.func,
}

PrevButton.defaultProps = {
  onClick: () => {},
}

function NextButton({ onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="slick-next"
      style={{ right: "-111px", paddingBottom: "70px" }}
    >
      Next
    </button>
  )
}

NextButton.propTypes = {
  onClick: PropTypes.func,
}

NextButton.defaultProps = {
  onClick: () => {},
}

CommentsCarousel.propTypes = {}

CommentsCarousel.defaultProps = {}

export default CommentsCarousel
