import "./TopMenuLogo.scss"

import classNames from "classnames"
// import PropTypes from "prop-types"
import React from "react"

import logo from "../../../media/landline-logo.svg"

const TopMenuLogo = () => {
  const topMenuLogoClass = classNames("top-menu-logo-wrapper")
  return (
    <a href="/" className={topMenuLogoClass}>
      <img src={logo} alt="LANDLINE" />
    </a>
  )
}

TopMenuLogo.propTypes = {}

TopMenuLogo.defaultProps = {}

export default TopMenuLogo
