import { Button, InputFieldSet } from "ibe-components"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import cartActions from "../../../redux/cart/actions"
import { cartSelector } from "../../../redux/selector"
import { allCardProductsEnums, getCartItemUtil, paymentTypeEnums } from "../../../utils/cart-util"

const StoreCreditsForm = () => {
  const [value, setValue] = useState()
  const dispatch = useDispatch()
  const cart = useSelector(state => cartSelector(state))

  const storeCreditsItem = cart.items_list.find(
    ({ product }) => product.category === allCardProductsEnums.STORE_CREDITS,
  )

  const applyStoreCredits = useCallback(() => {
    const cartItem = getCartItemUtil(
      allCardProductsEnums.STORE_CREDITS,
      paymentTypeEnums.STORE_CREDITS,
      1,
      {
        store_credits_amount: +value,
      },
    )
    dispatch(cartActions.addCartItem(cartItem))
  })

  const removeStoreCredits = useCallback(
    () => dispatch(cartActions.removeCartItem(storeCreditsItem.id)),
    [dispatch],
  )

  return (
    <form>
      <StoreCreditsWrapper>
        <InputFieldSetContainer>
          <InputFieldSet
            fullWidth
            labelText="CHECK STORE CREDIT"
            inputValue={value}
            onChange={e => setValue(e.target.value)}
            inputPlaceholder="Enter the amount"
            mask={Number}
            disabled={storeCreditsItem}
          />
        </InputFieldSetContainer>
        <Button
          buttonType="submit"
          type={storeCreditsItem ? "destructive" : "secondary"}
          onClick={storeCreditsItem ? removeStoreCredits : applyStoreCredits}
          disabled={!storeCreditsItem && (!value || value <= 0)}
        >
          {storeCreditsItem ? "Remove Store Credits" : "Apply Store Credits"}
        </Button>
      </StoreCreditsWrapper>
    </form>
  )
}

export default StoreCreditsForm

StoreCreditsForm.propTypes = {}

StoreCreditsForm.defaultProps = {}

const StoreCreditsWrapper = styled.div`
  flex: 1;
  padding-top: 20px;
  display: flex;
  border-top: 1px solid #cdccc8;
`

const InputFieldSetContainer = styled.div`
  flex: 1;
  padding-right: 20px;
  @media screen and (max-width: 450px) {
    padding-bottom: 20px;
  }
`
